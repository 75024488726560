import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Purchase } from '@/icons/Purchase';
import styles from '../../styles.module.scss';
import { getPurchaseContent } from './purchaseContent';
// создан заказ
export const purchaseOrder = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleOrder } = entityHandlers;
    const refId = event.refId;
    const { status, txid, orderId, orderDeleted } = event.params || {};
    const finalOrderId = status !== 1 && !orderDeleted ? orderId || refId : undefined;
    return {
        Icon: _jsx(Purchase, {}),
        title: (_jsxs(_Fragment, { children: [t('Сделал'), "\u00A0", _jsx("span", { className: finalOrderId && handleOrder ? styles.underline : '', onClick: () => {
                        if (finalOrderId)
                            handleOrder === null || handleOrder === void 0 ? void 0 : handleOrder(finalOrderId);
                    }, children: txid ? (_jsxs(_Fragment, { children: [t('заказ №'), txid] })) : (t('заказ')) })] })),
        content: getPurchaseContent(args),
    };
};
