import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// отменены начисленные бонусы из-за отмены покупки
export const bonus7 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handlePurchase } = entityHandlers;
    const { amount, purchaseId, purchaseDeleted } = event.params || {};
    const hasPurchaseLink = !!purchaseId && !purchaseDeleted && !!handlePurchase;
    return {
        color: 'red',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon }),
        title: (_jsxs(BonusTitle, Object.assign({}, args, { children: [amount
                    ? t('BONUSES_FOR_CANCELATION_OR_DELETION', { count: amount })
                    : t('Списаны бонусы за отмену (или удаление)'), "\u00A0", _jsx("span", { className: hasPurchaseLink ? styles.underline : '', onClick: () => {
                        if (hasPurchaseLink)
                            handlePurchase === null || handlePurchase === void 0 ? void 0 : handlePurchase(purchaseId);
                    }, children: t('purchase_range_one') })] }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['purchase'] })),
    };
};
