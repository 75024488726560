import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { ClientAddModal } from './ClientAddModal/ClientAddModal';
import { ClientEditModal } from './ClientEditModal/ClientEditModal';
import { OrderModal } from './OrderModal/OrderModal';
import { PurchaseAddModal } from './PurchaseAddModal/PurchaseAddModal';
import { PurchaseEditModal } from './PurchaseEditModal/PurchaseEditModal';
export const AppModals = () => {
    const { brand, user } = useCurrentUser();
    if (!brand || !user)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(ClientAddModal, {}), _jsx(ClientEditModal, {}), _jsx(PurchaseAddModal, {}), _jsx(PurchaseEditModal, {}), _jsx(OrderModal, {})] }));
};
