import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
export const FormInvoice = memo(({ id, onSubmit }) => {
    var _a;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, } = useForm();
    return (_jsxs("form", { id: id, onSubmit: handleSubmit(onSubmit), children: [_jsx("div", { className: styles.title, children: t('Запросить новый счет на пополнение') }), _jsx(FormFieldRow, { controlSize: '250', help: (_a = errors.amount) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.amount, hasNoMargin: true, hasLabelHidden: true, children: _jsx(Controller, { name: 'amount', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { type: 'number', inputMode: 'numeric', placeholder: t('введите сумму'), rightText: '\u20BD', shouldHideSpinButtons: true, autoFocus: true, hasError: !!errors.amount }))) }) })] }));
});
