import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { addPurchaseModalSelector } from '@/store/selectors/modals';
import { updateAddPurchaseModal, } from '@/store/slice/modals';
export const usePurchaseAddModal = () => {
    const dispatch = useAppDispatch();
    const addPurchaseModalState = useAppSelector(addPurchaseModalSelector);
    const open = useCallback((payload = {}) => {
        dispatch(updateAddPurchaseModal(Object.assign({ isOpen: true }, payload)));
    }, []);
    const close = useCallback((payload) => {
        var _a;
        (_a = addPurchaseModalState.closeHandler) === null || _a === void 0 ? void 0 : _a.call(addPurchaseModalState, payload);
        dispatch(updateAddPurchaseModal({
            isOpen: false,
            client: undefined,
            closeHandler: undefined,
        }));
    }, [addPurchaseModalState.closeHandler]);
    const setVisibility = useCallback((isVisible) => {
        dispatch(updateAddPurchaseModal({ isOpen: isVisible }));
    }, []);
    return {
        isOpen: addPurchaseModalState.isOpen,
        client: addPurchaseModalState.client,
        open,
        close,
        setVisibility,
    };
};
