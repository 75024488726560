import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Greater } from '@/components/arbitration/ArbitrationOperators/Greater';
import { Max } from '@/components/arbitration/ArbitrationOperators/Max';
import { Plus } from '@/components/arbitration/ArbitrationOperators/Plus';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { AutocompleteButton } from '@/components/Autocomplete/variants/AutocompleteButton';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { FormArbitrationGroupOperatorHelp } from './FormArbitrationGroupOperatorHelp';
import styles from './styles.module.scss';
export const FormArbitrationGroup = ({ defaultValues, currency, onSubmit, onDelete, hideTitleInput = false, hideConstraintInput = false, hideDeleteButton = false, disabledOperators, }) => {
    var _a;
    const { t, i18n } = useTranslation();
    const { control, handleSubmit, formState, setValue, watch, trigger } = useForm({
        defaultValues: Object.assign({ title: 'Новая подгруппа', operator: 'sum', hasConstraint: false, constraintValue: '', constraintType: 'percent' }, defaultValues),
    });
    const { errors, isSubmitted, isSubmitting } = formState;
    const validateTrimValue = (value) => !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    let operatorOptions = [
        {
            value: 'sum',
            label: t('каждая по очереди'),
            icon: _jsx(Plus, {}),
        },
        {
            value: 'priority',
            label: t('та, что выше в очереди'),
            icon: _jsx(Greater, {}),
        },
        {
            value: 'max',
            label: t('та, что выгоднее клиенту'),
            icon: _jsx(Max, {}),
        },
    ];
    operatorOptions = operatorOptions.filter((option) => !(disabledOperators === null || disabledOperators === void 0 ? void 0 : disabledOperators.includes(option.value)));
    const constraintTypeOptions = [
        { value: 'currency', label: currency },
        { value: 'percent', label: '% ' + t('от чека') },
    ];
    const revalidateConstraintValue = () => {
        setTimeout(() => {
            if (isSubmitted)
                trigger('constraintValue');
        }, 10);
    };
    const titleInput = (_jsx(Controller, { name: 'title', control: control, rules: { validate: validateTrimValue }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'title', hasError: !!errors.title, autoFocus: true }))) }));
    const operatorInput = (_jsx(Controller, { name: 'operator', control: control, render: ({ field }) => (_jsxs("div", { children: [_jsx(AutocompleteButton, { id: 'operator', value: operatorOptions.find((item) => item.value === field.value) || null, onChange: (operator) => {
                        if (operator)
                            setValue('operator', operator.value);
                    }, options: operatorOptions, renderOption: (option) => (_jsxs("div", { className: styles.operatorOption, children: [option === null || option === void 0 ? void 0 : option.label, option === null || option === void 0 ? void 0 : option.icon] })), getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, minListWidth: 288, asSelect: true }), _jsx(FormArbitrationGroupOperatorHelp, { operator: field.value, currency: currency })] })) }));
    const constraintValueInput = (_jsx("div", { className: styles.constraintValueInput, children: _jsx(Controller, { name: 'constraintValue', control: control, rules: {
                max: watch('constraintType') === 'percent'
                    ? { value: 100, message: t('MAX_ERROR_MESSAGE', { amount: 100 }) }
                    : undefined,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { type: 'number', inputMode: 'numeric', preventChars: ['-', '+', '.', ',', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.constraintValue }))) }) }));
    const constraintTypeInput = (_jsx(Autocomplete, { value: constraintTypeOptions.find((item) => item.value === watch('constraintType')) || null, onChange: (constraintType) => {
            if (constraintType) {
                setValue('constraintType', constraintType.value);
                revalidateConstraintValue();
            }
        }, options: constraintTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, isInline: true }));
    const hasConstraintInput = (_jsx(Controller, { name: 'hasConstraint', control: control, render: ({ field }) => (_jsxs(Checkbox, Object.assign({}, field, { id: 'hasConstraint', value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                setValue('hasConstraint', e.target.checked);
            }, preventInnerClicks: true, children: [t('не более'), constraintValueInput, constraintTypeInput] }))) }));
    return (_jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsxs("div", { className: classNames(styles.formWrapper, {
                    [styles.en]: i18n.language === 'en',
                }), children: [!hideTitleInput && (_jsx(FormFieldRow, { className: styles.row, controlClassName: styles.control, labelClassName: styles.label, label: t('Название группы'), labelFor: 'title', help: (_a = errors.title) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.title, children: titleInput })), _jsx(FormFieldRow, { className: styles.row, controlClassName: styles.control, labelClassName: styles.label, label: watch('operator') === 'max'
                            ? t('При совпадении в чеке сработает')
                            : t('При совпадении в строке чека сработает'), labelFor: 'operator', children: operatorInput }), !hideConstraintInput && (_jsx(FormFieldRow, { className: styles.row, controlClassName: styles.control, labelClassName: styles.label, label: t('Ограничить скидку в группе'), labelFor: 'hasConstraint', children: hasConstraintInput }))] }), _jsxs("div", { className: styles.footer, children: [_jsx(Button, { type: 'submit', theme: 'primary', size: 'full-width', disabled: isSubmitting, children: t('Сохранить') }), !hideDeleteButton && (_jsx(Button, { theme: 'danger', onClick: onDelete, size: 'full-width', disabled: isSubmitting, isOutlined: true, children: t('Удалить') }))] })] }));
};
