var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useCallback } from 'react';
import { createHref, ROUTES } from '@/app/pages/routes';
import { fgColor } from '@/utils/colors';
import styles from './styles.module.scss';
import { truncateSegmentLabel } from './truncateSegmentLabel';
export const getBadgeColor = (segment = {}) => {
    return (segment === null || segment === void 0 ? void 0 : segment.type) === 'filter'
        ? 'transparent'
        : (segment === null || segment === void 0 ? void 0 : segment.type) === 'rfm' && (segment === null || segment === void 0 ? void 0 : segment.code) === 'rfm_lost'
            ? '#fff'
            : segment.color;
};
export const getTextColor = (color, segmentType) => {
    return !color
        ? 'rgba(0, 0, 0, 0.5)'
        : segmentType === 'rfm'
            ? fgColor(color, 'rgba(0, 0, 0, 0.5)', '#fff', 180)
            : fgColor(color);
};
export const SegmentBadge = forwardRef((_a, ref) => {
    var { children, onClick, segment, isFullSize, maxWidth, className, hasWrapper = true, onMouseEnter, onMouseLeave } = _a, otherProps = __rest(_a, ["children", "onClick", "segment", "isFullSize", "maxWidth", "className", "hasWrapper", "onMouseEnter", "onMouseLeave"]);
    const badgeColor = getBadgeColor(segment);
    const textColor = getTextColor(segment === null || segment === void 0 ? void 0 : segment.color, segment === null || segment === void 0 ? void 0 : segment.type);
    const BadgeElement = (onClick ? 'a' : 'span');
    const handleClick = useCallback((e) => {
        if (onClick) {
            e.preventDefault();
            e.stopPropagation();
            onClick();
        }
    }, [onClick]);
    const badge = (_jsxs(BadgeElement, Object.assign({ ref: ref, className: classNames(styles.badge, {
            [styles.ultraflow]: (segment === null || segment === void 0 ? void 0 : segment.code) === 'rfm_lost',
            [styles.fullSize]: isFullSize,
        }), style: {
            backgroundColor: badgeColor,
            color: textColor,
            maxWidth,
        }, href: onClick ? createHref(ROUTES.CLIENTS) : undefined, onClick: handleClick, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave }, otherProps, { children: [(segment === null || segment === void 0 ? void 0 : segment.type) === 'rfm' && (_jsx("span", { className: styles.rfmLabel, children: "RFM" })), typeof children === 'string' && !isFullSize
                ? truncateSegmentLabel(children)
                : children] })));
    return hasWrapper ? (_jsx("span", { className: classNames(styles.wrapper, className), children: badge })) : (badge);
});
