import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo } from 'react';
import { Button } from '@/components/Button2/Button';
import styles from './styles.module.scss';
export const Pagination = memo(({ total, limit, offset, siblings = 2, onChangeOffset }) => {
    const allPages = useMemo(() => new Array(Math.ceil(total / limit)).fill(null).map((_, i) => i), [total, limit]);
    const activePage = offset / limit;
    const handleGoToPrevPage = useCallback(() => {
        if (activePage > 0)
            onChangeOffset((activePage - 1) * limit);
    }, [activePage, onChangeOffset]);
    const handleGoToNextPage = useCallback(() => {
        if (activePage < allPages[allPages.length - 1])
            onChangeOffset((activePage + 1) * limit);
    }, [allPages, activePage, onChangeOffset]);
    const handleGoToPage = useCallback((page) => {
        if (page !== activePage)
            onChangeOffset(page * limit);
    }, [activePage, onChangeOffset]);
    const visiblePages = useMemo(() => {
        if (allPages.length <= siblings * 2 + 1)
            return allPages;
        if (activePage <= siblings)
            return allPages.slice(0, siblings * 2 + 1);
        if (activePage >= allPages.length - siblings - 1)
            return allPages.slice(-siblings * 2 - 1);
        return allPages.slice(activePage - siblings, activePage + siblings + 1);
    }, [allPages, activePage, siblings]);
    if (allPages.length < 2)
        return null;
    return (_jsx("div", { className: styles.wrapper, children: _jsxs("ul", { className: styles.pagination, children: [_jsx("li", { children: _jsx(Button, { onClick: handleGoToPrevPage, disabled: activePage === 0, isOutlined: true, children: "\u2190" }) }, 'prev'), visiblePages.map((page) => (_jsx("li", { children: _jsx(Button, { onClick: () => handleGoToPage(page), isActive: page === activePage, isOutlined: true, children: page + 1 }) }, `page-${page}`))), _jsx("li", { children: _jsx(Button, { onClick: handleGoToNextPage, disabled: activePage === allPages[allPages.length - 1], isOutlined: true, children: "\u2192" }) }, 'next')] }) }));
});
