import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { CheckboxSearchList } from '@/components/CheckboxSearchList/CheckboxSearchList';
import { CHECKBOXES_HAS_SEARCH_THRESHOLD } from './constants';
import { Wrapper } from './Wrapper';
import styles from './Wrapper.module.scss';
export const CheckboxesLoader = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const [draft, setDraft] = useState(item);
    const [type, setType] = useState(((_a = item.value) === null || _a === void 0 ? void 0 : _a.type) || ((_b = item.additionalSelectorOptions) === null || _b === void 0 ? void 0 : _b[0].id));
    useEffect(() => setDraft(Object.assign(Object.assign({}, draft), { options: item.options })), [item.options]);
    useEffect(() => {
        if (!item.options)
            item.loadOptions();
    }, []);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (itemIds) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { value: itemIds }) }));
    };
    const handleChangeSearch = (search) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { search }) }));
    };
    const handleApply = () => {
        if (type) {
            const applyData = Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { type: type }) });
            onApply(applyData);
            return;
        }
        onApply(draft);
    };
    const hasSearch = !!item.options &&
        Object.keys(item.options).length > CHECKBOXES_HAS_SEARCH_THRESHOLD;
    const isValid = !!((_d = (_c = draft.value) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.length);
    const additionalSelectorOptions = (_e = item.additionalSelectorOptions) === null || _e === void 0 ? void 0 : _e.map((item) => {
        const Icon = item.Icon;
        return Object.assign(Object.assign({}, item), { label: (_jsxs("div", { className: styles.additionalSelectorItem, children: [_jsx(Icon, {}), item.label] })), onClick: () => setType(item.id) });
    });
    const additionalSelectorTitle = (_f = additionalSelectorOptions === null || additionalSelectorOptions === void 0 ? void 0 : additionalSelectorOptions.find((item) => item.id === type)) === null || _f === void 0 ? void 0 : _f.label;
    return (_jsx(Wrapper, { onBack: onBack, label: item.label, operator: ((_g = draft.value) === null || _g === void 0 ? void 0 : _g.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, isLoading: !item.options, additionalSelectorTitle: additionalSelectorTitle && (_jsx("span", { className: styles.additionalSelectorTitle, children: additionalSelectorTitle })), additionalSelectorOptions: additionalSelectorOptions, count: (_j = (_h = draft.value) === null || _h === void 0 ? void 0 : _h.value) === null || _j === void 0 ? void 0 : _j.length, children: _jsx(CheckboxSearchList, { items: Object.entries(item.options || {}).map(([optValue, optLabel]) => ({
                id: optValue,
                label: optLabel,
            })), selectedItemIds: ((_l = (_k = draft.value) === null || _k === void 0 ? void 0 : _k.value) === null || _l === void 0 ? void 0 : _l.map((i) => i.toString())) || [], onChange: handleChangeValue, hasUndefinedItem: item.hasUndefinedOption, undefinedItemId: item.undefinedOptionValue, search: (_m = draft.value) === null || _m === void 0 ? void 0 : _m.search, onChangeSearch: handleChangeSearch, searchDebounceDelay: 0, hasSearch: hasSearch }) }));
});
