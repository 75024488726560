import { jsx as _jsx } from "react/jsx-runtime";
import { User } from '@/icons/User';
import { getClientUpdateContent } from './clientUpdateContent';
import { ClientUpdateTitle } from './clientUpdateTitle';
// создан клиент (по API)
export const clientUpdate6 = (args) => {
    const { translate: t } = args;
    return {
        Icon: _jsx(User, { size: 20 }),
        title: (_jsx(ClientUpdateTitle, Object.assign({}, args, { children: t('Добавлен по API') }))),
        content: getClientUpdateContent(Object.assign({}, args)),
    };
};
