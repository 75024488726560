var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { FormPromocodeGenerate, } from './FormPromocodeGenerate';
import styles from './styles.module.scss';
export const FormPromocodeGenerateModal = memo((_a) => {
    var { id = 'generate-promocode-form', isOpen, onClose, promocode, isSubmitting, error } = _a, props = __rest(_a, ["id", "isOpen", "onClose", "promocode", "isSubmitting", "error"]);
    const { t } = useTranslation();
    const footer = (_jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, { className: classNames(styles.message, styles.error), controlClassName: styles.errorText, asErrorMessage: true, asModalFooter: true, hasLabelHidden: true, children: error })), _jsx("div", { className: styles.footer, children: _jsx(Button, { type: 'submit', form: id, theme: 'primary', size: 'large', disabled: isSubmitting, children: t('Сгенерировать') }) })] }));
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, title: t('PROMOCODE_GENERATE_MODAL_TITLE', { promocode }), footerClassName: styles.modalFooter, footer: footer, size: '550', hasNoPadding: true, isForm: true, children: _jsx(FormPromocodeGenerate, Object.assign({ id: id }, props)) }));
});
