import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
export const FormMailingPushExpireInDays = ({ labelWidth }) => {
    var _a;
    const { t } = useTranslation();
    const { control, watch, trigger, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const walletPushExpireInDays = watch('walletPushExpireInDays') || 0;
    const walletImage = watch('walletImage');
    const isRequired = !!(channels === null || channels === void 0 ? void 0 : channels.includes('push')) && !!walletImage;
    useEffect(() => {
        if (isSubmitted)
            trigger('walletPushExpireInDays');
    }, [isSubmitted, walletImage]);
    return (_jsx(FormFieldRow, { label: t('Показывать картинку'), labelFor: 'walletPushExpireInDays', labelWidth: labelWidth, controlSize: '150', help: (_a = errors.walletPushExpireInDays) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.walletPushExpireInDays, isRequired: isRequired, children: _jsx(Controller, { name: 'walletPushExpireInDays', control: control, rules: {
                required: {
                    value: isRequired,
                    message: t('FIELD_REQUIRED_ERROR'),
                },
                min: (channels === null || channels === void 0 ? void 0 : channels.includes('push'))
                    ? { value: 1, message: t('MIN_ERROR_MESSAGE', { amount: 1 }) }
                    : undefined,
                max: (channels === null || channels === void 0 ? void 0 : channels.includes('push'))
                    ? { value: 30, message: t('MAX_ERROR_MESSAGE', { amount: 30 }) }
                    : undefined,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'walletPushExpireInDays', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.walletPushExpireInDays, preventChars: ['+', ',', '.'], preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: t('DAYS_EXPIRING', {
                    count: Number(walletPushExpireInDays),
                }) }))) }) }));
};
