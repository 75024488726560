import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { URL_VALIDATOR } from '~/src/utils/url';
export const FormMailingTelegramURL = ({ labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, watch, trigger, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const telegramButton = watch('telegramButton');
    const isRequired = (channels === null || channels === void 0 ? void 0 : channels.includes('telegram')) && !!telegramButton;
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    useEffect(() => {
        if (isSubmitted)
            trigger('telegramUrl');
    }, [isSubmitted, telegramButton]);
    return (_jsx(FormFieldRow, { label: t('Ссылка'), labelFor: 'telegramUrl', labelWidth: labelWidth, controlSize: '360', help: (_a = errors.telegramUrl) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.telegramUrl, isRequired: isRequired, children: _jsx(Controller, { name: 'telegramUrl', control: control, rules: {
                validate: validateTrimValue,
                pattern: (channels === null || channels === void 0 ? void 0 : channels.includes('telegram'))
                    ? {
                        value: URL_VALIDATOR,
                        message: t('Неверный формат'),
                    }
                    : undefined,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'telegramUrl', placeholder: t('ссылка из кнопки'), hasError: !!errors.telegramUrl, autoFocus: true }))) }) }));
};
