import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { WarningCircle } from '@/icons/WarningCircle';
import styles from './styles.module.scss';
export const PromocodeWarning = ({ rewards, text, }) => {
    const { t } = useTranslation();
    const promocodeReward = rewards === null || rewards === void 0 ? void 0 : rewards.find((r) => r.type === 'promocode');
    const promocodePH = '!' + t('Промокод');
    if (promocodeReward && (text === null || text === void 0 ? void 0 : text.indexOf(promocodePH)) === -1)
        return (_jsxs("div", { className: classNames(styles.wrapper, styles.promocode), children: [_jsx("div", { className: styles.icon, children: _jsx(WarningCircle, {}) }), _jsxs("div", { className: styles.text, children: [t('Не забудьте вставить параметр'), ' ', _jsx("span", { className: styles.bold, children: t('промокод') })] })] }));
    return null;
};
