import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { APPLY_ON_ALL_UNITS, APPLY_ON_CONDITION_UNITS, APPLY_ON_SOME_UNITS, } from '../constants';
import styles from '../styles.module.scss';
export const FormOfferSpreadDiscountOpted = ({ labelWidth }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const offerType = watch('type');
    const applyOn = watch('applyOn');
    const eachNOpted = watch('eachNOpted');
    const notMoreNOpted = watch('notMoreNOpted');
    const shouldDisplay = (applyOn === APPLY_ON_SOME_UNITS ||
        applyOn === APPLY_ON_ALL_UNITS ||
        applyOn === APPLY_ON_CONDITION_UNITS) &&
        offerType !== 'bonus' &&
        offerType !== 'free_product' &&
        (applyOn == APPLY_ON_SOME_UNITS ||
            offerType == 'growing_discount' ||
            eachNOpted ||
            notMoreNOpted);
    if (shouldDisplay)
        return (_jsx(FormFieldRow, { className: styles.checkboxRow, labelWidth: labelWidth, alignItems: 'center', asCheckbox: true, children: _jsx(Controller, { name: 'spreadDiscountOpted', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                        setValue('spreadDiscountOpted', e.target.checked);
                    }, children: t('разделить скидку между всеми товарами в чеке') }))) }) }));
    return null;
};
