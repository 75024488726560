import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { clientModalSelector } from '@/store/selectors/modals';
import { updateClientModal, } from '@/store/slice/modals';
export const useClientModal = () => {
    const dispatch = useAppDispatch();
    const clientModalState = useAppSelector(clientModalSelector);
    const open = useCallback((payload) => {
        dispatch(updateClientModal(Object.assign({ isOpen: true }, payload)));
    }, []);
    const close = useCallback((result) => {
        var _a;
        (_a = clientModalState.closeHandler) === null || _a === void 0 ? void 0 : _a.call(clientModalState, result);
        dispatch(updateClientModal({
            isOpen: false,
            clientId: undefined,
            activeTab: 'overview',
            closeHandler: undefined,
        }));
    }, [clientModalState.closeHandler]);
    const changeTab = useCallback((activeTab) => {
        dispatch(updateClientModal({ activeTab }));
    }, []);
    const setVisibility = useCallback((isVisible) => {
        dispatch(updateClientModal({ isOpen: isVisible }));
    }, []);
    return {
        isOpen: clientModalState.isOpen,
        clientId: clientModalState.clientId,
        activeTab: clientModalState.activeTab || 'overview',
        open,
        close,
        changeTab,
        setVisibility,
    };
};
