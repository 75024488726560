import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useOfferConditionsFilters } from '@/services/filters/data/useOfferConditionsFilters';
import styles from '../styles.module.scss';
export const FormOfferConditions = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const conditionsProduct = watch('conditionsProduct');
    const conditionsProductCount = (conditionsProduct === null || conditionsProduct === void 0 ? void 0 : conditionsProduct.length) || 0;
    const filtersTree = useOfferConditionsFilters({
        conditionsProductCount,
    });
    return (_jsx(FormFieldRow, { className: styles.conditionsRow, label: t('Условия по покупке'), labelWidth: labelWidth, children: _jsx(Controller, { name: 'conditions', control: control, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: filtersTree, value: field.value || [], onUpdate: (value) => setValue('conditions', value, {
                    shouldValidate: true,
                    shouldDirty: true,
                }), hasHangingOperator: false, rightPanelClassName: styles.conditionsRightPanel })) }) }));
};
