import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from '@/components/Skeleton/Skeleton';
import styles from './styles.module.scss';
export const TableSkeleton = ({ count }) => {
    const sizes = [
        '850',
        '750',
        '650',
        '550',
        '450',
        '350',
        '250',
    ];
    return (_jsx("div", { className: styles.table, children: sizes.slice(0, count).map((size) => (_jsx("div", { className: styles.item, children: _jsx(Skeleton, { size: size }) }, size))) }));
};
