import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
export const FormMailingPushSender = ({ brand, labelWidth, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const messagingApps = ((_a = brand === null || brand === void 0 ? void 0 : brand.mailing) === null || _a === void 0 ? void 0 : _a.messagingApps) || [];
    const shouldDisplaySelect = ((_b = brand === null || brand === void 0 ? void 0 : brand.features) === null || _b === void 0 ? void 0 : _b.mobilePush) && messagingApps.length > 1;
    const senderSelect = (_jsx(Controller, { name: 'messagingApp', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'messagingApp', value: messagingApps.find((item) => item.id === field.value) || null, onChange: (sender) => {
                setValue('messagingApp', sender === null || sender === void 0 ? void 0 : sender.id, { shouldDirty: true });
            }, options: messagingApps, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasResetButton: false, asSelect: true }))) }));
    return shouldDisplaySelect ? (_jsx(FormFieldRow, { label: t('Приложение'), labelFor: 'messagingApp', labelWidth: labelWidth, controlSize: '300', children: senderSelect })) : null;
};
