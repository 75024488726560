import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import { WalletCardCounter } from '@/components/wallet-cards/WalletCardCounter/WalletCardCounter';
import { getImageLightness } from '@/utils/getImageLightness';
import styles from './styles.module.scss';
export const GoogleWalletCardBanner = ({ imageUrl = '', imageTitle = '', imageTitleColor = '', imageTitleSize = '', imageDescription = '', imageDescriptionColor = '', imageDescriptionSize = '', onElementClick, clicksDisabled = false, counter, counterTickImageUrl, counterBlankImageUrl, }) => {
    const darkTextColor = '#3a3a3a';
    const lightTextColor = '#fff';
    const lightnessThreshold = 120;
    const titleSize = imageTitleSize || '65';
    const descrSize = imageDescriptionSize || '16';
    const [color, setColor] = useState(darkTextColor);
    useEffect(() => {
        if (imageUrl)
            getImageLightness(imageUrl, (lightness) => {
                if (lightness < lightnessThreshold)
                    setColor(lightTextColor);
                else
                    setColor(darkTextColor);
            });
        else
            setColor(darkTextColor);
    }, [imageUrl]);
    if (!imageUrl && !imageTitle && !imageDescription && !counter)
        return null;
    return (_jsxs(WalletCardClickableElement, { className: classNames(styles.detailsImage, styles.bannerImage, {
            [styles.clicksDisabled]: clicksDisabled,
        }), style: { backgroundImage: `url(${imageUrl})`, color }, type: 'google-banner', onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('banner'), disabled: clicksDisabled, children: [_jsxs("div", { className: styles.detailsImageTitleWrapper, children: [!!imageTitle && (_jsx("div", { className: styles.detailsImageTitle, style: {
                            color: imageTitleColor || undefined,
                            fontSize: titleSize ? `${titleSize}px` : undefined,
                            height: titleSize ? `${0.85 * Number(titleSize)}px` : undefined,
                        }, children: _jsx("div", { children: imageTitle }) })), !!imageDescription && (_jsx("div", { className: styles.detailsImageDescription, style: {
                            color: imageDescriptionColor || undefined,
                            fontSize: descrSize ? `${descrSize}px` : undefined,
                            height: descrSize ? `${0.85 * Number(descrSize)}px` : undefined,
                        }, children: _jsx("div", { children: imageDescription }) }))] }), counter && (_jsx(WalletCardCounter, { counter: counter, tickImageUrl: counterTickImageUrl, blankImageUrl: counterBlankImageUrl }))] }));
};
