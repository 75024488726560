var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeletePurchaseMutation } from '@/api/query/purchases';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { buildClientFullName } from '@/utils/getClientFullName';
export const useDeletePurchase = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deletePurchase] = useDeletePurchaseMutation();
    return (_a) => __awaiter(void 0, [_a], void 0, function* ({ purchase, txid, }) {
        const clientName = buildClientFullName(purchase.client);
        const confirmTitle = clientName
            ? t('Удалить покупку клиента {{clientName}}?', { clientName })
            : t('Удалить покупку?');
        const confirmAgreeLabel = t('Удалить покупку');
        const successMessage = t('Покупка удалена');
        const yes = yield confirm({
            confirmation: confirmTitle,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return false;
        const result = yield deletePurchase({
            purchaseId: purchase.id,
            txid,
        });
        const error = getErrorMessage(result);
        if (error) {
            addToast({ type: 'error', message: error });
            return false;
        }
        else {
            addToast({ type: 'success', message: successMessage });
            return true;
        }
    });
};
