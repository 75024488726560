import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const AccordionContent = ({ currency, offersList, }) => {
    const { t } = useTranslation();
    return (_jsx("ul", { className: styles.accordionContent, children: offersList.map(({ itemCount, product, totalPrice }, index) => (_jsxs("li", { className: styles.accordionContentItem, children: [_jsx("span", { className: styles.accordionContentItemTitle, children: (product === null || product === void 0 ? void 0 : product.title) || t('без названия') }), _jsx("div", { className: styles.accordionContentItemLine }), _jsx("span", { className: styles.accordionContentCount, children: `${itemCount} ${t('шт')}.` }), _jsx("div", { className: styles.accordionContentItemLineShort }), _jsx("span", { className: classNames(styles.accordionContentValue, {
                        [styles.bonusCell]: !currency,
                    }), children: currency
                        ? money({
                            input: totalPrice,
                            withFraction: true,
                            removeFractionZeros: true,
                            currency,
                        })
                        : totalPrice })] }, index))) }));
};
