import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RowExpand } from '@/icons/RowExpand';
import { capitalize } from '@/utils/capitalize';
import { COLOR_MAP } from './COLOR_MAP';
import styles from './Description.module.scss';
import { DESCRIPTION_MAP } from './DESCRIPTION_MAP';
export const Description = ({ group, isExpanded = false, onToggle, }) => {
    const { t } = useTranslation();
    const isUnordered = group.guid === 'unordered';
    const description = isUnordered
        ? 'К чеку применяется последний введенный из'
        : DESCRIPTION_MAP[group.type];
    const descriptionColor = isUnordered ? undefined : COLOR_MAP[group.type];
    return (_jsxs("button", { className: styles.descriptionWrapper, type: 'button', onClick: onToggle, children: [_jsx("div", { className: styles.description, style: { color: descriptionColor }, children: !group.children.length
                    ? t('Пустая группа')
                    : capitalize(t(description)) }), _jsxs("div", { className: styles.expandText, children: [_jsx("span", { style: { color: descriptionColor }, children: group.children.length || null }), _jsx(RowExpand, { className: classNames(styles.expandIcon, {
                            [styles.movedUp]: !group.children.length,
                        }), expanded: isExpanded })] })] }));
};
