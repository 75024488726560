import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { ChannelDelivered } from '@/icons/flow/ChannelDelivered';
import { EmailNotPurchased } from '@/icons/flow/EmailNotPurchased';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const ChannelReceivedFormIcon = () => {
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    let icon = _jsx(_Fragment, {});
    switch (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.type) {
        case 'delivered_channel': {
            icon = _jsx(ChannelDelivered, {});
            break;
        }
        case 'purchased_channel': {
            icon = _jsx(EmailNotPurchased, {});
            break;
        }
    }
    return _jsx("div", { className: styles.icon, children: icon });
};
