import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Input } from '@/components/Input/Input';
import { TRIGGER_TYPE_BIRTHDAY, TRIGGER_TYPE_BONUS_EXPIRING, TRIGGER_TYPE_CHILD_BIRTHDAY, } from '@/const/mailing-triggers';
import { capitalize } from '@/utils/capitalize';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { branchHasNode } from '../../nodes/utils';
import styles from './styles.module.scss';
const OFFSET_SIGN_AFTER = '1';
const OFFSET_SIGN_BEFORE = '-1';
export const DelayForm = () => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { triggerType, selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const selectedNodeNode = flow.getNode(selectedNode.uuid);
    const branchHasChannelNodes = !!selectedNodeNode &&
        branchHasNode({
            flow,
            toNode: selectedNodeNode,
            predicate: (node) => node.type === 'channel',
        });
    const offsetSignOptions = [
        { value: OFFSET_SIGN_AFTER, label: capitalize(t('через')) },
        { value: OFFSET_SIGN_BEFORE, label: capitalize(t('за')) },
    ];
    const offsetUnitOptions = [
        {
            value: 'minute',
            label: t('OFFSET_VALUE_MIN', { offset: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetValue }),
        },
        {
            value: 'hour',
            label: t('OFFSET_VALUE_HOUR', { offset: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetValue }),
        },
        {
            value: 'day',
            label: t('OFFSET_VALUE_DAY', { offset: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetValue }),
        },
        {
            value: 'month',
            label: t('OFFSET_VALUE_MONTH', { offset: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetValue }),
        },
    ];
    const hasBeforeOption = !branchHasChannelNodes &&
        [
            TRIGGER_TYPE_BIRTHDAY,
            TRIGGER_TYPE_BONUS_EXPIRING,
            TRIGGER_TYPE_CHILD_BIRTHDAY,
        ].includes(triggerType);
    const offsetSign = offsetSignOptions.find((item) => { var _a; return item.value === ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetSign) === null || _a === void 0 ? void 0 : _a.toString()); }) || null;
    const handleUpdateOffsetSign = (value) => {
        if (value)
            selectedNode.update({
                offsetSign: Number(value.value),
            });
    };
    const offsetValue = (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetValue) || '';
    const handleUpdateOffsetValue = (e) => {
        const newValue = Number(e.target.value);
        if (!newValue)
            e.target.value = '1';
        if ((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetValue) !== (newValue || 1))
            selectedNode.update({
                offsetValue: newValue || 1,
            });
    };
    const offsetUnit = offsetUnitOptions.find((item) => item.value === (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.offsetUnit)) || null;
    const handleUpdateOffsetUnit = (value) => {
        if (value)
            selectedNode.update({
                offsetUnit: value.value,
            });
    };
    return (_jsx("div", { className: styles.wrapper, children: _jsxs("div", { className: styles.control, children: [_jsx("div", { children: hasBeforeOption ? (_jsx(Autocomplete, { value: offsetSign, onChange: handleUpdateOffsetSign, options: offsetSignOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, minListWidth: 141, asSelect: true, isInline: true, disabled: !isEditMode })) : (_jsx("div", { className: styles.offsetSignText, children: capitalize(t('через')) })) }), _jsx("div", { className: styles.valueInputWrapper, children: _jsx(Input, { type: 'number', inputMode: 'numeric', step: 'any', preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, defaultValue: offsetValue, onBlur: handleUpdateOffsetValue, autoFocus: true, disabled: !isEditMode }) }), _jsx("div", { className: styles.unitInputWrapper, children: _jsx(Autocomplete, { value: offsetUnit, onChange: handleUpdateOffsetUnit, options: offsetUnitOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, disabled: !isEditMode }) })] }) }));
};
