import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BASIC_END_YEAR, BASIC_START_YEAR, DateInput, } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { TimeInput } from '@/components/TimeInput/TimeInput';
import { formatOffset } from '@/utils/numbers';
import styles from '../styles.module.scss';
export const FormMailingSchedule = ({ timeZone, labelWidth, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, formState, setValue, trigger, watch } = useFormContext();
    const { errors, isSubmitted } = formState;
    const tzOffset = timeZone ? moment().tz(timeZone).utcOffset() / 60 : '';
    const tzHelp = timeZone
        ? t('по часовому поясу') + ` ${timeZone} ${formatOffset(tzOffset || 0)}`
        : undefined;
    const schedule = watch('schedule');
    const scheduleTime = watch('scheduleTime');
    const validateTime = (value) => {
        if (!value)
            return t('FIELD_REQUIRED_ERROR');
        if (value.includes('_'))
            return t('FIELD_REQUIRED_ERROR');
        return true;
    };
    useEffect(() => {
        if (isSubmitted)
            trigger('scheduleTime');
    }, [scheduleTime, isSubmitted]);
    return (_jsxs(_Fragment, { children: [_jsx(FormFieldRow, { labelWidth: labelWidth, children: _jsx(Controller, { name: 'schedule', control: control, render: ({ field }) => (_jsx(RadioGroup, Object.assign({}, field, { id: 'schedule', value: field.value, onChange: (value) => setValue('schedule', value, { shouldDirty: true }), items: [
                            { value: '0', label: t('Сейчас') },
                            { value: '1', label: t('Запланировать') },
                        ], align: 'vertical', gap: 'small' }))) }) }), schedule === '1' && (_jsx(FormFieldRow, { label: t('SEND_FOR'), labelFor: 'scheduleDate', labelWidth: labelWidth, help: ((_a = errors.scheduleDate) === null || _a === void 0 ? void 0 : _a.message) ||
                    ((_b = errors.scheduleTime) === null || _b === void 0 ? void 0 : _b.message) ||
                    tzHelp, hasError: !!errors.scheduleDate || !!errors.scheduleTime, isRequired: true, children: _jsxs("div", { className: styles.scheduleRow, children: [_jsx("div", { className: styles.scheduleDateInput, children: _jsx(Controller, { name: 'scheduleDate', control: control, rules: {
                                    required: {
                                        value: true,
                                        message: t('FIELD_REQUIRED_ERROR'),
                                    },
                                }, render: ({ field }) => (_jsx(DateInput, { id: 'scheduleDate', selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                        setValue('scheduleDate', date ? moment(date).format() : '', { shouldValidate: isSubmitted });
                                    }, portalId: 'form-mailing-dates-portal', hasError: !!errors.scheduleDate, minDate: new Date(), startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR })) }) }), _jsx("div", { className: styles.scheduleDivider, children: t('SEND_AT') }), _jsx("div", { className: styles.scheduleTimeInput, children: _jsx(Controller, { name: 'scheduleTime', control: control, rules: { validate: validateTime }, render: ({ field }) => (_jsx(TimeInput, Object.assign({}, field, { id: 'scheduleTime', onAccept: (time) => {
                                        setValue('scheduleTime', time);
                                    }, onClear: () => {
                                        setValue('scheduleTime', '');
                                    }, hasError: !!errors.scheduleTime }))) }) })] }) }))] }));
};
