import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { buildClientFullName } from '@/utils/getClientFullName';
import { highlightText } from '@/utils/highlightText';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import styles from '../styles.module.scss';
import { AutocompleteInput } from '../variants/AutocompleteInput';
export const AutocompleteClients = memo(({ id, value, onChange, getClients, showClientLevel, hasError, shouldLoadInitialData, }) => {
    const { t } = useTranslation();
    const getOptionKey = (option) => (option === null || option === void 0 ? void 0 : option.id) || 0;
    const getOptionLabel = (option) => {
        const fullName = buildClientFullName(option);
        const phone = formatPhoneNumber(option === null || option === void 0 ? void 0 : option.phoneNumber, ' ') || '';
        return [fullName, phone].filter(Boolean).join(', ');
    };
    const renderOption = (option, searchText) => {
        if (option) {
            const fullName = highlightText(buildClientFullName(option), searchText);
            const phone = highlightText(formatPhoneNumber(option.phoneNumber, ' ') || '', searchText);
            const email = highlightText(option.email || '', searchText);
            const level = option.level;
            return (_jsxs("div", { className: styles.client, children: [fullName ? (_jsx("span", { dangerouslySetInnerHTML: { __html: fullName } })) : (_jsx("span", { className: styles.placeholder, children: t('Без ФИО') })), phone && _jsx("small", { dangerouslySetInnerHTML: { __html: phone } }), email && _jsx("small", { dangerouslySetInnerHTML: { __html: email } }), !!level && showClientLevel && (_jsxs("small", { children: [t('уровень'), " ", level] }))] }));
        }
        return null;
    };
    return (_jsx(AutocompleteInput, { className: styles.clientsInput, id: id, value: value, onChange: onChange, options: getClients, getOptionKey: getOptionKey, getOptionLabel: getOptionLabel, renderOption: renderOption, placeholder: t('введите номер телефона, ФИО, Email или карту'), hasError: hasError, shouldLoadInitialData: shouldLoadInitialData, isLarge: true }));
});
