import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { Input } from '@/components/Input/Input';
import { FormBonusCheckboxes } from '~/src/forms/common/FormBonusCheckboxes/FormBonusCheckboxes';
import { useProductFilters } from '~/src/services/filters/data/useProductFilters';
import { APPLY_ON_PURCHASE, APPLY_ON_SET, DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT, } from '../../../FormOffer/constants';
import { BuyerWillReceiveDiscount } from './BuyerWillReceiveDiscount';
import styles from './styles.module.scss';
export const FormPromocodesBuyerWillReceive = ({ isFriend, labelWidth, currency, }) => {
    const { t } = useTranslation();
    const { control, formState: { errors }, setValue, watch, clearErrors, } = useFormContext();
    const [customBadges, setCustomBadges] = useState([]);
    const applyOn = watch('applyOn');
    useEffect(() => {
        if (applyOn === APPLY_ON_PURCHASE)
            setCustomBadges([{ id: 'check', label: t('Чек') }]);
    }, []);
    const handleRemoveCustomBadge = () => setCustomBadges([]);
    const allowedCategoriesFiltersTree = useProductFilters([
        {
            type: 'custom',
            id: 'check',
            label: t('Чек'),
            onClick: () => {
                setValue('applyOn', APPLY_ON_PURCHASE);
                setCustomBadges([{ id: 'check', label: t('Чек') }]);
                clearErrors('allowedCategories');
            },
        },
    ]);
    const discountAmount = watch('discountAmount');
    const discountType = watch('discountType');
    const type = watch('type');
    const typeOptions = [
        {
            value: 'discount',
            label: t('скидка'),
            isActive: true,
        },
        {
            value: 'bonus',
            label: t('бонусы'),
            isActive: true,
        },
    ];
    const discountTypeOptions = [
        {
            value: DISCOUNT_TYPE_FIXED,
            label: type === 'bonus'
                ? t('BONUSES_LABEL', { count: discountAmount || 0 })
                : currency,
        },
        {
            value: DISCOUNT_TYPE_PERCENT,
            label: '%',
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs(FormFieldset, { title: t('Покупатель получит'), titleOffset: labelWidth, children: [_jsx(FormFieldRow, { label: isFriend ? t('Вознаграждение другу') : t('Вознаграждение'), labelFor: 'type', labelWidth: labelWidth, className: styles.typeFieldRow, children: _jsxs("div", { className: styles.typeRow, children: [_jsx("div", { className: styles.typeSelect, children: _jsx(Controller, { name: 'type', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'type', value: typeOptions.find((item) => item.value === field.value) ||
                                            null, onChange: (type) => {
                                            setValue('type', type === null || type === void 0 ? void 0 : type.value, {
                                                shouldDirty: true,
                                            });
                                        }, options: typeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, asSelect: true, hasResetButton: false }))) }) }), _jsx(Controller, { name: 'discountAmount', control: control, rules: {
                                    required: {
                                        value: true,
                                        message: t('FIELD_REQUIRED_ERROR'),
                                    },
                                    max: discountType === 1
                                        ? {
                                            value: 100,
                                            message: t('MAX_ERROR_MESSAGE', { amount: 100 }),
                                        }
                                        : undefined,
                                }, render: ({ field }) => (_jsx("div", { className: styles.discountInput, children: _jsx(Input, Object.assign({}, field, { id: 'discountAmount', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.discountAmount, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true })) })) }), _jsx(Controller, { name: 'discountType', control: control, render: ({ field }) => (_createElement(Autocomplete, Object.assign({}, field, { key: type, id: 'discountType', className: styles.discountTypeSelect, value: discountTypeOptions.find((item) => item.value === discountType) || null, onChange: (discountType) => {
                                        setValue('discountType', discountType === null || discountType === void 0 ? void 0 : discountType.value, {
                                            shouldDirty: true,
                                        });
                                    }, options: discountTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, minListWidth: type === 'bonus' ? 100 : undefined }))) })] }) }), _jsx(FormBonusCheckboxes, { labelWidth: labelWidth }), _jsx(BuyerWillReceiveDiscount, { currency: currency, labelWidth: labelWidth }), _jsx(FormFieldRow, { label: t('начисляются за'), className: styles.allowedCategories, labelWidth: labelWidth, help: errors.allowedCategories
                        ? t('Выберите хотя бы один критерий применения')
                        : undefined, hasError: !!errors.allowedCategories, isRequired: true, children: _jsx(Controller, { name: 'allowedCategories', control: control, rules: {
                            required: {
                                value: !customBadges.length,
                                message: t('FIELD_REQUIRED_ERROR'),
                            },
                        }, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: allowedCategoriesFiltersTree, value: field.value, customBadges: customBadges, onRemoveCustomBadge: handleRemoveCustomBadge, shouldDisplayActions: !customBadges.length, onUpdate: (value) => {
                                setValue('allowedCategories', value, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                });
                                // группа товаров
                                setValue('applyOn', APPLY_ON_SET);
                            }, hasHangingOperator: false })) }) })] }) }));
};
