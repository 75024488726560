import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Input } from '@/components/Input/Input';
import styles from './styles.module.scss';
import { useBuildOptions } from './useBuildOptions';
export const GrowingDiscountInput = memo(({ id, value, onChange, scales, selectedScale, onChangeScale, isPercentScale = false, hasError = false, }) => {
    const buildOptions = useBuildOptions();
    const [options, setOptions] = useState(buildOptions(value));
    const handleChangeItem = (index, data) => {
        const oldItem = value[index];
        const newItem = Object.assign(Object.assign({}, oldItem), data);
        const newArray = [...value];
        newArray.splice(index, 1, newItem);
        if (Object.keys(data).includes('to')) {
            const newOptions = buildOptions(newArray, onChange);
            setOptions(newOptions);
        }
        else
            onChange(newArray);
    };
    const hasPercentError = (item) => {
        if (!isPercentScale)
            return false;
        const n = Number(item.amount);
        return n < 0 || n > 100;
    };
    return (_jsx(_Fragment, { children: value.map((item, index) => {
            var _a;
            return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: styles.selectWrapper, children: [_jsx("div", { className: styles.select, children: _jsx(Autocomplete, { id: index === 0 ? id : undefined, value: options[index].find((i) => i.value === item.to) || null, onChange: (to) => {
                                        handleChangeItem(index, { to: to === null || to === void 0 ? void 0 : to.value });
                                    }, options: options[index], getOptionKey: (option) => option.value, getOptionLabel: (option) => option.title, hasResetButton: false, asSelect: true }, options[index].map((i) => i.title).join()) }), _jsx("span", { className: styles.divider, children: "\u2014" }), _jsx("div", { className: styles.input, children: _jsx(Input, { type: 'number', value: item.amount, onChange: (e) => {
                                        handleChangeItem(index, { amount: e.target.value });
                                    }, inputMode: 'numeric', step: 'any', hasError: !item.amount
                                        ? hasError
                                        : hasPercentError(item)
                                            ? hasError
                                            : false, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: ((_a = scales.find((scale) => scale.value === selectedScale)) === null || _a === void 0 ? void 0 : _a.label) || '' }) })] }), index === 0 && (_jsx(Autocomplete, { className: styles.scaleSelect, value: scales.find((item) => item.value === selectedScale) || null, onChange: (scale) => {
                            onChangeScale(scale === null || scale === void 0 ? void 0 : scale.value);
                        }, options: scales, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))] }, index));
        }) }));
});
