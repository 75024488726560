import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { convertToInputValue } from '@/components/MailingRewardsPills/utils';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { GiftCardWarning } from '../../Warnings/GiftCardWarning';
import { PromocodeWarning } from '../../Warnings/PromocodeWarning';
import formStyles from '../styles.module.scss';
import { useCalculate } from '../useCalculate';
export const TelegramFormText = ({ telegramText, onChangeTelegramText, }) => {
    var _a;
    const { t } = useTranslation();
    const { brand, currency } = useCurrentUser();
    const { triggerType, selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const selectedNodeData = selectedNode.getData();
    const calculate = useCalculate();
    const updateTelegramTextLength = (uuid, data) => {
        calculate({ telegramText }).then((response) => {
            selectedNode.update({
                telegram: Object.assign(Object.assign({}, data), { telegramText, telegramTextLength: response.telegramTextLength }),
            }, uuid);
        });
    };
    const updateTelegramText = (telegramText) => {
        selectedNode.update({
            telegram: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram), { telegramText }),
        });
        updateTelegramTextLength(selectedNode.uuid, Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram));
    };
    const handleUpdateTelegramText = () => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _a === void 0 ? void 0 : _a.telegramText) !== telegramText)
            updateTelegramText(telegramText);
    };
    const editorContext = {
        channel: 'telegram',
        triggerType: triggerType,
        rewards: convertToInputValue((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || []),
        hasWalletFeature: brandHasWalletFeature(brand === null || brand === void 0 ? void 0 : brand.brand),
    };
    return (_jsxs("div", { children: [_jsx(MessageEditor, { initialValue: telegramText, onChange: onChangeTelegramText, onBlur: handleUpdateTelegramText, placeholder: t('введите сообщение'), context: editorContext, currency: currency, hasAutoFocus: false, isTelegram: true, hasError: !!(errors === null || errors === void 0 ? void 0 : errors.telegramText), isDisabled: !isEditMode }), (errors === null || errors === void 0 ? void 0 : errors.telegramText) ? (_jsx("div", { className: formStyles.error, children: errors.telegramText })) : (_jsx("div", { className: formStyles.help, children: t('Длина:') +
                    ' ' +
                    (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _a === void 0 ? void 0 : _a.telegramTextLength) || 0) +
                    '/1000' })), _jsx(PromocodeWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: telegramText }), _jsx(GiftCardWarning, { rewards: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards, text: telegramText })] }));
};
