import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Modal } from '@/components/Modal/Modal';
import { FormReportName } from './FormReportName';
import styles from './styles.module.scss';
export const FormReportNameModal = memo(({ id = 'rename-report-form', defaultName, isOpen, onClose, error, onSubmit, isSubmitting, }) => {
    const { t } = useTranslation();
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, footer: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, { className: classNames(styles.message, styles.error), controlClassName: classNames(styles.errorText), asErrorMessage: true, asModalFooter: true, hasLabelHidden: true, children: error })), _jsx("div", { className: styles.footer, children: _jsx(Button, { type: 'submit', form: id, theme: 'primary', disabled: isSubmitting, size: 'large', children: t('Переименовать отчет') }) })] }), size: '550', hasNoPadding: true, children: _jsx("div", { className: styles.body, children: _jsx(FormReportName, { id: id, defaultName: defaultName, onSubmit: onSubmit, isSubmitting: isSubmitting }) }) }));
});
