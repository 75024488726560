var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@/components/Toast/ToastContext';
import { useAppDispatch } from '@/hooks/store';
import { renameDashboard } from '@/store/thunk/reports';
export const useRenameDashboard = (dashboard) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isRenaming, setIsRenaming] = useState(false);
    const addToast = useToastContext();
    const handleRenameDashboard = (_a) => __awaiter(void 0, [_a], void 0, function* ({ title }) {
        if (!dashboard)
            return;
        const successMessage = t('Отчет сохранен');
        try {
            const isDraft = dashboard.id < 0;
            setIsRenaming(true);
            yield dispatch(renameDashboard({
                id: dashboard.id,
                title,
                isSaved: !isDraft,
            }));
            setIsRenaming(false);
            if (!isDraft)
                addToast({ type: 'success', message: successMessage });
        }
        catch (error) {
            const message = typeof error === 'string'
                ? error
                : error === null || error === void 0 ? void 0 : error.message;
            addToast({ type: 'error', message: message || t('Произошла ошибка') });
        }
    });
    return { isRenaming, renameDashboard: handleRenameDashboard };
};
