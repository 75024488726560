import { jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@/app/useCurrentUser';
import { ClientAddModal as ClientAddModalView } from '@/components/ClientModal/ClientAddModal';
import { useClientAddModal } from './useClientAddModal';
export const ClientAddModal = () => {
    const { brand, user } = useCurrentUser();
    const countryCode = brand === null || brand === void 0 ? void 0 : brand.brand.defaultCountry;
    const extraFields = brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields;
    const isChildrenEnabled = brand === null || brand === void 0 ? void 0 : brand.brand.features.children;
    const clientAddModal = useClientAddModal();
    const handleAddClient = () => {
        clientAddModal.close({ added: true });
    };
    if (!brand || !user)
        return null;
    return (_jsx(ClientAddModalView, { isOpen: clientAddModal.isOpen, onClose: clientAddModal.close, countryCode: countryCode, extraFields: extraFields, isChildrenEnabled: isChildrenEnabled, onAdded: handleAddClient }));
};
