import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { confirmable, createConfirmation } from 'react-confirm';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Modal } from '@/components/Modal/Modal';
import { Cancel } from '@/icons/Cancel';
import styles from './styles.module.scss';
export const ConfirmDialog = ({ disagreeLabel, agreeLabel, shopToDeleteId, shops = [], show, proceed, confirmation, }) => {
    const { t } = useTranslation();
    const [shouldReplace, setShouldReplace] = useState(false);
    const [replaceShop, setReplaceShop] = useState(null);
    const handleClose = () => proceed(false);
    const handleCancel = () => proceed(false);
    const handleAgree = () => proceed((replaceShop === null || replaceShop === void 0 ? void 0 : replaceShop.id.toString()) || true);
    return (_jsxs(Modal, { className: classNames(styles.modal, styles.left), isOpen: show, onClose: handleClose, bodyClassName: styles.body, size: '600', children: [_jsx("div", { className: styles.title, children: confirmation }), _jsxs("div", { className: classNames(styles.content, styles.large), children: [_jsx(Checkbox, { className: styles.checkbox, checked: shouldReplace, onChange: () => {
                            setShouldReplace(!shouldReplace);
                        }, children: t('Перенести продажи удаляемой точки в...') }), _jsx("div", { className: styles.autocomplete, children: _jsx(Autocomplete, { placeholder: t('выберите точку продаж'), value: replaceShop, onChange: setReplaceShop, options: shops
                                .filter((shop) => shop.isActive)
                                .filter((shop) => shop.id !== shopToDeleteId), getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, disabled: !shouldReplace }) })] }), _jsxs("div", { className: styles.actions, children: [_jsx(Button, { theme: 'danger', icon: _jsx(Cancel, {}), size: 'large', onClick: handleAgree, disabled: shouldReplace && !replaceShop, children: agreeLabel || 'OK' }), _jsx(Button, { theme: 'primary', size: 'large', onClick: handleCancel, autoFocus: true, children: disagreeLabel || t('Отмена') })] })] }));
};
export const confirm = createConfirmation(confirmable(ConfirmDialog));
