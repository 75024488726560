import { jsx as _jsx } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputInfo } from '@/components/InputInfo/InputInfo';
import styles from './styles.module.scss';
export const FormCounterInfo = () => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const applyOn = watch('applyOn');
    const requiredValue = watch('requiredValue');
    const periodType = watch('periodType');
    const periodValue = watch('periodValue');
    const periodUnit = watch('periodUnit');
    let periodInfo = '';
    if (periodType === 'any')
        periodInfo = t('Счетчик можно пополнять все время пока действует акция');
    if (periodType === 'period' && periodUnit === 'day' && periodValue)
        periodInfo = t('COUNTER_RESET_HELP_DAY', { count: Number(periodValue) });
    if (periodType === 'period' && periodUnit === 'month' && periodValue)
        periodInfo = t('COUNTER_RESET_HELP_MONTH', { count: Number(periodValue) });
    let rewardInfo = '';
    if (applyOn === 'purchase' && requiredValue)
        rewardInfo = t('COUNTER_REWARD_HELP_PURCHASE', {
            count: Number(requiredValue),
            ordinal: true,
        });
    if (applyOn === 'product' && requiredValue)
        rewardInfo = t('COUNTER_REWARD_HELP_PRODUCT', {
            count: Number(requiredValue),
            ordinal: true,
        });
    const info = [rewardInfo, periodInfo].join(' ');
    return info ? (_jsx("div", { className: styles.info, children: _jsx(InputInfo, { children: info }) })) : null;
};
