import { jsx as _jsx } from "react/jsx-runtime";
import { generatePath, useLocation, useNavigate, useParams, } from 'react-router-dom';
import { useGetCounterListQuery } from '@/api/query/counters';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { FormOffer } from '@/forms/FormOffer/FormOffer';
import { useBrandSegments } from '@/hooks/useBrandSegments';
import { useDeleteOffer } from '../Offers/useDeleteOffer';
import { createHref, ROUTES } from '../routes';
import { useOfferData } from './useOfferData';
import { useSaveOffer } from './useSaveOffer';
import { useSetOfferActive } from './useSetOfferActive';
export const OffersForm = () => {
    var _a, _b, _c, _d;
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const segments = useBrandSegments();
    const { data: counters = [] } = useGetCounterListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const { brand } = useCurrentUser();
    const tz = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.defaultTimezone;
    const offerId = (params === null || params === void 0 ? void 0 : params.offerId)
        ? Number(params === null || params === void 0 ? void 0 : params.offerId)
        : (_b = location.state) === null || _b === void 0 ? void 0 : _b.offerId;
    const onGoToList = () => navigate(ROUTES.OFFERS);
    const getMailingLink = (mo) => {
        const mailingId = mo.mailing.id.toString();
        const route = mo.mailing.type === 0
            ? ROUTES.MAILINGS_AUTOMATIC_EDIT
            : ROUTES.MAILINGS_MANUAL_EDIT;
        const path = generatePath(route, { mailingId });
        return createHref(path);
    };
    const rfmSegment = (_c = location.state) === null || _c === void 0 ? void 0 : _c.segment;
    const { selectedOffer, defaultValues, isLoading } = useOfferData(offerId, rfmSegment, tz);
    const isClone = !!((_d = location.state) === null || _d === void 0 ? void 0 : _d.clone);
    const deleteOffer = useDeleteOffer({ onSuccess: onGoToList });
    const setOfferActive = useSetOfferActive(offerId);
    const { saveOffer, isSubmitting, error } = useSaveOffer({
        id: isClone ? undefined : offerId,
        onSuccess: onGoToList,
    });
    if (!brand)
        return null;
    return isLoading ? null : (_jsx(Container, { hidePageTitle: true, children: _jsx(FormOffer, { isClone: isClone, onSubmit: saveOffer, error: error, isSubmitting: isSubmitting, defaultValues: defaultValues, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort, isEdit: !!offerId && !isClone, onDelete: () => {
                if (offerId && selectedOffer)
                    deleteOffer(selectedOffer);
            }, hasDiscountOfferFeature: brand.brand.features.discountOffer, clientExtraFields: brand.brand.clientExtraFields, counters: counters, mailingOffers: selectedOffer === null || selectedOffer === void 0 ? void 0 : selectedOffer.mailingOffers, getMailingLink: getMailingLink, segments: segments, setActive: setOfferActive }) }));
};
