import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useGetClientQuery } from '@/api/query/client';
import { useCurrentUser } from '@/app/useCurrentUser';
import { ClientEditForm } from '@/components/ClientModal/ClientEditForm';
import { ClientEditModal as ClientEditModalView } from '@/components/ClientModal/ClientEditModal';
import { ClientOverviewPanelContainer } from '@/components/ClientOverviewPanel/ClientOverviewPanelContainer';
import { FormClientBonusesModalContainer } from '@/forms/FormClientBonuses/FormClientBonusesModalContainer';
import { useModalLoading } from '@/hooks/useModalLoading';
import { usePurchaseAddModal } from '../PurchaseAddModal/usePurchaseAddModal';
import { useClientModal } from './useClientModal';
export const ClientEditModal = () => {
    var _a;
    const { brand, user, currency } = useCurrentUser();
    const countryCode = brand === null || brand === void 0 ? void 0 : brand.brand.defaultCountry;
    const extraFields = brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields;
    const isChildrenEnabled = brand === null || brand === void 0 ? void 0 : brand.brand.features.children;
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const [bonusModalIsOpen, setBonusModalIsOpen] = useState(false);
    const clientModal = useClientModal();
    const purchaseAddModal = usePurchaseAddModal();
    const { data: client, error } = useGetClientQuery({ id: clientModal.clientId || 0 }, { skip: !clientModal.clientId });
    const isLoadingClient = !!clientModal.isOpen &&
        clientModal.clientId !== ((_a = client === null || client === void 0 ? void 0 : client.client) === null || _a === void 0 ? void 0 : _a.id) &&
        !error;
    useModalLoading(isLoadingClient);
    const handleOpenBonusModal = () => {
        clientModal.setVisibility(false);
        setBonusModalIsOpen(true);
    };
    const handleCloseBonusModal = () => {
        clientModal.setVisibility(true);
        setBonusModalIsOpen(false);
    };
    const handleAddPurchase = (client) => {
        clientModal.setVisibility(false);
        purchaseAddModal.open({
            client,
            closeHandler: () => clientModal.setVisibility(true),
        });
    };
    const handleUpdateClient = () => {
        clientModal.close({ updated: true });
    };
    const handleDeleteClient = () => {
        clientModal.close({ deleted: true });
    };
    if (!brand || !user || !client)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(ClientEditModalView, { isOpen: clientModal.isOpen && !isLoadingClient, onClose: clientModal.close, activeTab: clientModal.activeTab, onChangeTab: clientModal.changeTab, hasFormChanged: hasFormChanged, clientOverview: _jsx(ClientOverviewPanelContainer, { client: client.client, bonusHistory: client.bonusHistory, clientReservedBonuses: client.reservedBonuses || 0, user: user, currency: currency, onCorrectBonuses: handleOpenBonusModal, onAddPurchase: handleAddPurchase }), clientForm: _jsx(ClientEditForm, { selectedClient: client.client, countryCode: countryCode, extraFields: extraFields, isChildrenEnabled: isChildrenEnabled, setHasFormChanged: setHasFormChanged, onUpdated: handleUpdateClient, onDeleted: handleDeleteClient }) }), _jsx(FormClientBonusesModalContainer, { client: client.client, isOpen: bonusModalIsOpen, onClose: handleCloseBonusModal })] }));
};
