import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input } from '@/components/Input/Input';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const TelegramFormUrl = () => {
    var _a;
    const { t } = useTranslation();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const handleUpdateTelegramUrl = (e) => {
        var _a;
        if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _a === void 0 ? void 0 : _a.telegramUrl) !== e.target.value)
            selectedNode.update({
                telegram: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram), { telegramUrl: e.target.value }),
            });
    };
    return (_jsxs("div", { children: [_jsx("label", { className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-telegram-url', children: t('Ссылка') }), _jsx(Input, { id: 'channel-form-telegram-url', defaultValue: ((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.telegram) === null || _a === void 0 ? void 0 : _a.telegramUrl) || '', onBlur: handleUpdateTelegramUrl, placeholder: t('ссылка из кнопки'), hasError: !!(errors === null || errors === void 0 ? void 0 : errors.telegramUrl), disabled: !isEditMode }), !!(errors === null || errors === void 0 ? void 0 : errors.telegramUrl) && (_jsx("div", { className: formStyles.error, children: errors.telegramUrl }))] }));
};
