import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { CounterBlankIcon } from './CounterBlankIcon';
import { CounterTickIcon } from './CounterTickIcon';
import styles from './styles.module.scss';
const MAX_ICONS = 60;
const DEFAULT_TICKS = 3;
export const WalletCardCounter = ({ counter, tickImageUrl, blankImageUrl, }) => {
    const totalCount = Math.min(counter.requiredValue, MAX_ICONS);
    const tickCount = totalCount > DEFAULT_TICKS ? DEFAULT_TICKS : 1;
    const icons = [];
    for (let i = 0; i < totalCount; i += 1)
        if (i < tickCount)
            icons.push(tickImageUrl ? (_jsx("span", { style: { backgroundImage: `url(${tickImageUrl})` } })) : (_jsx(CounterTickIcon, {})));
        else
            icons.push(tickImageUrl ? (_jsx("span", { className: classNames({
                    [styles.transparent]: !blankImageUrl,
                }), style: { backgroundImage: `url(${blankImageUrl || tickImageUrl})` } })) : (_jsx(CounterBlankIcon, {})));
    return (_jsx("div", { className: classNames(styles.wrapper, {
            [styles.count13]: totalCount >= 13,
            [styles.count15]: totalCount >= 15,
            [styles.count17]: totalCount >= 17,
            [styles.count28]: totalCount >= 28,
            [styles.count41]: totalCount >= 41,
            [styles.count45]: totalCount >= 45,
        }), children: icons.map((icon, i) => (_jsx("div", { children: icon }, `icon-${i}`))) }));
};
