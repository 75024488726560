import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import styles from './styles.module.scss';
export const YearDropdown = memo(({ startYear = 1900, endYear, monthDate, onChange }) => {
    const { i18n } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const selectedYear = moment(monthDate).get('year');
    const currentYear = endYear || moment().get('year');
    const years = new Array(currentYear - startYear + 1)
        .fill(null)
        .map((_, yearIndex) => {
        const year = startYear + yearIndex;
        return {
            id: year,
            label: year.toString(),
            isSelected: year === selectedYear,
            onClick: () => onChange(year),
        };
    });
    return (_jsx(Dropdown, { items: years, onOpen: () => setIsActive(true), onClose: () => setIsActive(false), listMaxHeight: 330, placement: 'bottom', offset: [0, -10], disablePortal: true, children: _jsx("span", { className: classNames(styles.year, {
                [styles.active]: isActive,
            }), children: moment(monthDate).locale(i18n.language).format('YYYY') }) }));
});
