import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { useAvailableSteps } from '../../hooks/useAvailableSteps';
import { useCreateNode } from '../../hooks/useCreateNode';
import { NewStepNodeSteps } from './NewStepNodeSteps';
import { NewStepNodeTriggerFilters } from './NewStepNodeTriggerFilters';
import styles from './styles.module.scss';
export const NewStepNode = ({ id }) => {
    const flow = useReactFlow();
    const { triggerFilters = [], newStepPopup } = useInteractiveFlow();
    const steps = useAvailableSteps(flow.getNode(id));
    const createNode = useCreateNode();
    const handleSelectStep = (step) => {
        createNode(step);
        newStepPopup.reset();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.wrapper, children: [_jsx(NewStepNodeTriggerFilters, { filters: triggerFilters, onSelectFilter: handleSelectStep }), _jsx(NewStepNodeSteps, { filters: triggerFilters, steps: steps, onSelectStep: handleSelectStep })] }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
