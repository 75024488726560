import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { Wrapper } from './Wrapper';
export const Radios = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d;
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (value) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { value: Number(value) }) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = typeof ((_a = draft.value) === null || _a === void 0 ? void 0 : _a.value) !== 'undefined';
    return (_jsx(Wrapper, { onBack: onBack, label: item.label, operator: ((_b = draft.value) === null || _b === void 0 ? void 0 : _b.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, children: _jsx(RadioGroup, { name: item.id, value: (_d = (_c = draft.value) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.toString(), onChange: handleChangeValue, items: Object.entries(item.options).map(([optValue, optLabel]) => ({
                value: optValue,
                label: optLabel,
            })), align: 'vertical', gap: 'small' }) }));
});
