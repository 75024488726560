import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DoughnutChart } from '@/components/DoughnutChart/DoughnutChart';
import { DropdownPills } from '@/components/DropdownPills/DropdownPills';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { calculatePercent } from '@/utils/calculatePercent';
import { PointsStatusPanelFooter, } from './PointsStatusPanelFooter';
import styles from './styles.module.scss';
export const PointsStatusPanel = memo(({ selectedScaleId, onChangeScale, notSpentValue, notSpentColor = '#7ABA88', redeemedValue, redeemedColor = '#DD6B5B', expiredOrCancelledValue, expiredOrCancelledColor = '#DFDFDF', isLoading = false, noTotalValue = 100, }) => {
    const { t } = useTranslation();
    const scales = [
        { id: 'period', label: t('За период') },
        { id: 'all-time', label: t('За все время') },
    ];
    const total = notSpentValue + redeemedValue + expiredOrCancelledValue;
    const data = useMemo(() => [
        {
            label: t('Не потрачено'),
            tooltip: t('Количество бонусов, которое было начислено в заданный период и остается на бонусных счетах клиентов'),
            value: notSpentValue,
            percentage: calculatePercent(notSpentValue, total, noTotalValue),
            color: notSpentColor,
        },
        {
            label: t('Использовано'),
            tooltip: t('Количество бонусов из числа начисленных за заданный период, которое было использовано клиентами'),
            value: redeemedValue,
            percentage: calculatePercent(redeemedValue, total, noTotalValue),
            color: redeemedColor,
        },
        {
            label: t('Сгорело или отменено'),
            tooltip: t('Количество бонусов из числа начисленных за заданный период, которое сгорело (не были использованы) или было отменено (например, в связи с возвратами)'),
            value: expiredOrCancelledValue,
            percentage: calculatePercent(expiredOrCancelledValue, total, noTotalValue),
            color: expiredOrCancelledColor,
        },
    ], [
        notSpentValue,
        redeemedValue,
        expiredOrCancelledValue,
        total,
        noTotalValue,
    ]);
    const chartLabels = useMemo(() => data.map((i) => i.label), [data]);
    const chartData = useMemo(() => data.map((i) => i.value), [data]);
    const chartColors = useMemo(() => data.map((i) => i.color), [data]);
    return (_jsx(StatsPanel, { className: styles.panel, title: _jsxs("div", { className: styles.title, children: [t('Движение бонусов'), _jsx(DropdownPills, { items: scales, selectedItemId: selectedScaleId, onChange: onChangeScale })] }), isLoading: isLoading, children: _jsxs("div", { className: styles.wrapper, children: [_jsx("div", { className: styles.chart, children: _jsx(DoughnutChart, { labels: chartLabels, data: chartData, colors: chartColors }) }), _jsx(PointsStatusPanelFooter, { data: data })] }) }));
});
