import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { largeNumberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const PointsStatusPanelFooter = memo(({ data }) => {
    const { t } = useTranslation();
    return (_jsx("div", { className: styles.footer, children: data.map(({ color, label, tooltip, value, percentage }) => (_jsxs("div", { className: styles.legendItem, children: [_jsxs("div", { className: styles.legendItemLabel, children: [_jsx("div", { className: styles.legendItemColor, style: { backgroundColor: color } }), _jsx(HelpTooltip, { title: tooltip, placement: 'right', hasLightIcon: true, children: label })] }), _jsxs("div", { className: styles.nowrap, children: [_jsx("span", { className: styles.legendItemValue, children: largeNumberRound({
                                value: value,
                                million: t('млн'),
                                thousand: t('тыс'),
                            }) }), ' ', _jsxs("span", { className: styles.legendItemPercent, children: [percentage, "%"] })] })] }, label))) }));
});
