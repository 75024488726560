import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
export const TwoColumnLayout = memo(({ left, leftClassName, right, rightClassName }) => {
    const leftRef = useRef(null);
    useEffect(() => {
        scrollToSelectedNode(leftRef.current);
    }, []);
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx("div", { className: classNames(styles.left, leftClassName), ref: leftRef, children: left }), _jsx("div", { className: classNames(styles.right, rightClassName), children: right })] }));
});
const scrollToSelectedNode = (container) => {
    const selectedNode = container === null || container === void 0 ? void 0 : container.querySelector('[data-selected=true]');
    selectedNode === null || selectedNode === void 0 ? void 0 : selectedNode.scrollIntoView();
};
