var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { LogoSmall } from '@/icons/logo/LogoSmall';
import styles from './styles.module.scss';
export const EmailEditorModalHeader = ({ title, onSave, onClose, }) => {
    const { t } = useTranslation();
    const handleClose = () => __awaiter(void 0, void 0, void 0, function* () {
        const title = t('Выйти без сохранения?');
        const agreeLabel = t('Выйти');
        const yes = yield confirm({
            confirmation: title,
            agreeLabel,
            isDanger: true,
            agreeButtonSize: 'extra-large',
        });
        if (yes)
            onClose();
    });
    return (_jsxs("div", { className: styles.header, children: [_jsxs("div", { className: styles.logo, children: [_jsx(LogoSmall, { className: styles.icon }), _jsx("div", { className: styles.text, children: title })] }), _jsxs("div", { className: styles.toolbar, children: [_jsx(Button, { type: 'button', onClick: handleClose, children: t('Выйти') }), _jsx(Button, { type: 'button', theme: 'primary', onClick: onSave, children: t('Сохранить') })] })] }));
};
