var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useGetShopListQuery } from '@/api/query/shop';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { ShopsTable } from './ShopsTable';
import { useShopGroupList } from './useShopGroupList';
export const Shops = () => {
    const { user } = useCurrentUser();
    const _a = useGetShopListQuery(), { data: shops = [] } = _a, shopsQueryState = __rest(_a, ["data"]);
    const { shopGroups, refetchShopGroups } = useShopGroupList();
    const isLoading = shopsQueryState.isLoading;
    const isFetching = shopsQueryState.isFetching;
    if (!user)
        return null;
    return (_jsx(Container, { notMobileRightPadding: true, children: _jsx(ShopsTable, { shops: shops, shopGroups: shopGroups, refetchShopGroups: refetchShopGroups, isLoading: isLoading, isFetching: isFetching }) }));
};
