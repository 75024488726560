import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { AreaChart } from '@/icons/AreaChart';
import { HorizontalBars } from '@/icons/HorizontalBars';
import styles from './styles.module.scss';
export const RFMChartWrongDate = ({ chartType, onActionClick, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.empty, children: [_jsx("div", { className: styles.emptyIcon, children: chartType === 'area' ? _jsx(AreaChart, {}) : _jsx(HorizontalBars, {}) }), _jsx("div", { className: styles.emptyTitle, children: t('Недостаточно данных') }), _jsx("div", { className: styles.emptyDescription, children: t('Пожалуйста, установите начальную дату не ранее 8 июля 2023') }), _jsx("div", { className: styles.emptyAction, children: _jsx(Button, { onClick: onActionClick, children: t('RFM_CHART_SET_DATE') }) })] }));
};
