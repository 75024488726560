import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { Lock } from '@/icons/Lock';
import { Magic } from '@/icons/Magic';
import { RFMRecommendationsItem, } from './RFMRecommendationsItem';
import styles from './styles.module.scss';
export const RFMRecommendations = ({ onAction, openedSegments = [], onOpenSegment, onCloseSegment, isLocked = false, }) => {
    const { t } = useTranslation();
    const toggleSegment = (id) => {
        if (openedSegments.includes(id))
            onCloseSegment === null || onCloseSegment === void 0 ? void 0 : onCloseSegment(id);
        else
            onOpenSegment === null || onOpenSegment === void 0 ? void 0 : onOpenSegment(id);
    };
    const icon = isLocked ? _jsx(Lock, {}) : _jsx(Magic, {});
    const segmentIds = [
        'VIP',
        'outflowVIP',
        'leavingVIP',
        'potentialVIP',
        'loyal',
        'potentialLoyal',
        'outflowPossible',
        'outflow',
        'newcomers',
        'oneTimeClients',
        'ultraflow',
        'ultraLargeSales',
        'ultraActiveCustomers',
        'possibleFraud',
        'wholesalers',
    ];
    return (_jsx(StatsPanel, { className: classNames(styles.wrapper, {
            [styles.locked]: isLocked,
        }), titleClassName: styles.title, title: _jsxs(_Fragment, { children: [icon, " ", t('Рекомендации')] }), bodyId: 'rfm-recommendations-panel', bodyClassName: styles.body, children: segmentIds.map((id) => (_jsx(RFMRecommendationsItem, { id: id, onAction: onAction, isOpen: openedSegments.includes(id), onToggle: toggleSegment, isLocked: isLocked }, id))) }));
};
