import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ChannelEmail } from '@/icons/ChannelEmail';
import { FormMailingEmailBody } from './controls/FormMailingEmailBody';
import { FormMailingEmailSender } from './controls/FormMailingEmailSender';
import { FormMailingEmailSubject } from './controls/FormMailingEmailSubject';
import { FormMailingEmailTest } from './controls/FormMailingEmailTest';
import styles from './styles.module.scss';
export const FormMailingStepEmail = ({ type, unlayerUrl, unlayerProjectId, brand, templatesData, uploadImage, refreshEmailPreview, currency, onSendTestEmail, labelWidth, }) => {
    return (_jsxs(FormFieldset, { title: _jsxs("div", { className: styles.channelTitle, children: [_jsx("div", { className: classNames(styles.channel, styles.email), children: _jsx(ChannelEmail, {}) }), "Email"] }), titleOffset: labelWidth, children: [_jsx(FormMailingEmailSender, { brand: brand, labelWidth: labelWidth }), _jsx(FormMailingEmailSubject, { brand: brand, currency: currency, labelWidth: labelWidth }), _jsx(FormMailingEmailBody, { type: type, unlayerUrl: unlayerUrl, unlayerProjectId: unlayerProjectId, brand: brand, templatesData: templatesData, uploadImage: uploadImage, refreshEmailPreview: refreshEmailPreview, labelWidth: labelWidth }), _jsx(FormMailingEmailTest, { onSendTestEmail: onSendTestEmail, labelWidth: labelWidth })] }));
};
