import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { ClickHand } from '@/icons/ClickHand';
import styles from '../../styles.module.scss';
export const mailingSendingClick = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleEntityLink } = entityHandlers;
    const { mailingBrandId, mailingBrandType, mailingBrandName, mailingBrandDeleted, linkHash, } = event.params || {};
    const mailingLink = !mailingBrandDeleted
        ? handleEntityLink('mailing', mailingBrandId, mailingBrandType)
        : '';
    const mailingLinkElement = mailingLink ? (_jsxs(_Fragment, { children: ["\u00A0", _jsx(Link, { className: styles.underline, to: mailingLink, target: '_blank', children: mailingBrandName || t('рассылке') })] })) : null;
    const mailingNameElement = (_jsxs(_Fragment, { children: ["\u00A0", mailingBrandName ? `"${mailingBrandName}"` : t('рассылке')] }));
    const linkElement = linkHash ? (_jsx(Link, { className: styles.underline, to: linkHash, target: '_blank', children: t('ссылке') })) : (_jsx("span", { children: t('ссылке') }));
    return {
        Icon: _jsx(ClickHand, {}),
        title: (_jsxs(_Fragment, { children: [t('Кликнул по'), "\u00A0", linkElement, "\u00A0", t('в email'), mailingLinkElement || mailingNameElement] })),
    };
};
