import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Pagination } from '@/components/Pagination/Pagination';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Download } from '@/icons/Download';
import { Plus } from '@/icons/Plus';
import { useClientAddModal } from '@/modals/ClientAddModal/useClientAddModal';
import { useClientModal } from '@/modals/ClientEditModal/useClientModal';
import { numberRound } from '@/utils/numbers';
import { ClientsTable } from './ClientsTable';
export const ClientsPanel = memo(({ count, clients = [], isLoading, isFetching, onClientUpdate, onSegmentClick, onExportXlsx, limit, offset, onChangeOffset, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasExportsManage = userHasAccessTo('EXPORTS_MANAGE');
    const userHasClientsManage = userHasAccessTo([
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const clientAddModal = useClientAddModal();
    const clientModal = useClientModal();
    const handleOpenClientAddForm = () => {
        clientAddModal.open({ closeHandler: onClientUpdate });
    };
    const handleOpenClientOverview = (clientId) => {
        clientModal.open({
            clientId,
            activeTab: 'overview',
            closeHandler: onClientUpdate,
        });
    };
    const handleOpenClientEditForm = (clientId) => {
        clientModal.open({
            clientId,
            activeTab: 'edit',
            closeHandler: onClientUpdate,
        });
    };
    const toolbar = (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: !userHasExportsManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { icon: _jsx(Download, {}), title: t('Выгрузить в Excel'), onClick: onExportXlsx, hasIconMargin: false, disabled: !userHasExportsManage, children: "XLS" }) }), _jsx(Tooltip, { title: !userHasClientsManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { icon: _jsx(Plus, {}), onClick: handleOpenClientAddForm, theme: 'primary', size: 'extra-large', disabled: !userHasClientsManage, children: t('Добавить клиента') }) })] }));
    const pagination = !!count && (_jsx(Pagination, { total: count, limit: limit, offset: offset, onChangeOffset: onChangeOffset }));
    return (_jsx(TabsPanel, { tabs: [
            {
                id: 'clients',
                label: t('CLIENTS_PANEL_HEADER', {
                    amount: numberRound(count || 0),
                    count: count || 0,
                }),
            },
        ], rightToolbar: toolbar, footer: pagination, isSkeleton: isLoading, children: _jsx(ClientsTable, { clients: clients, isLoading: isFetching, onOpenClientOverview: handleOpenClientOverview, onOpenClientEditForm: handleOpenClientEditForm, onSegmentClick: onSegmentClick }) }));
});
