import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Panel } from '~/src/components/Panel2/Panel';
import styles from './styles.module.scss';
export const FormArbitrationPriorityLayout = ({ title, activeTab, methods, children, setActiveTab, }) => {
    const { t } = useTranslation();
    return (_jsx("div", { className: styles.channelsTabsWrapper, children: _jsxs(FormProvider, Object.assign({}, methods, { children: [_jsxs("ul", { className: styles.channelsTabs, children: [_jsx("li", { children: _jsx("button", { className: classNames(styles.tabButton, {
                                    [styles.active]: activeTab === 'general',
                                }), onClick: () => setActiveTab('general'), children: t('Общее') }) }), _jsx("li", { children: _jsx("button", { className: classNames(styles.tabButton, {
                                    [styles.active]: activeTab === 'priority',
                                }), onClick: () => setActiveTab('priority'), children: t('Приоритет') }) })] }), _jsxs("div", { className: styles.channelsTabsPanel, children: [_jsx("h1", { className: classNames('page-title', styles.pageTitle), children: title }), _jsx(Panel, { size: '800', isCentered: true, children: _jsx("form", { children: children }) })] })] })) }));
};
