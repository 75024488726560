/* eslint-disable @typescript-eslint/ban-ts-comment */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useCreateOfferMutation, useUpdateOfferMutation, } from '@/api/query/offers';
import { useToastContext } from '@/components/Toast/ToastContext';
import { appendFilter, compileFilters } from '@/services/filters/compileFilters';
export const useSaveOffer = ({ id, onSuccess, }) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const addToast = useToastContext();
    const [createOffer, { isLoading: isCreating }] = useCreateOfferMutation();
    const [updateOffer, { isLoading: isUpdating }] = useUpdateOfferMutation();
    const saveOffer = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const successMessage = id ? t('Акция изменена') : t('Акция создана');
        let result;
        const recipientsAll = (_a = payload.recipientTags) === null || _a === void 0 ? void 0 : _a.all;
        const recipientsWithCards = (_b = payload.recipientTags) === null || _b === void 0 ? void 0 : _b.withCards;
        const recipientsNoCards = (_c = payload.recipientTags) === null || _c === void 0 ? void 0 : _c.noCards;
        const recipientFilters = payload.recipientFilters || [];
        const conditions = (_d = payload.conditions) === null || _d === void 0 ? void 0 : _d.filter((i) => i.code !== 'purchaseItem');
        const body = Object.assign(Object.assign({}, payload), { newItemOffsetTop: undefined, arbitrationPriority: undefined, arbitrationGroup: undefined, arbitrationPriorityAfterError: undefined, arbType: undefined, arbPrio: undefined, isAddition: undefined, bonusesAvailableAt: payload.bonusesAvailableAt
                ? moment(payload.bonusesAvailableAt).format('YYYY-MM-DD')
                : null, bonusesExpireAt: payload.bonusesExpireAt
                ? moment(payload.bonusesExpireAt).format('YYYY-MM-DD')
                : null, conditions: {
                configuration: conditions || [],
                conditions: compileFilters(conditions || []),
            }, itemConditions: {
                configuration: payload.itemConditions || [],
                conditions: compileFilters(payload.itemConditions || []),
            }, recipients: {
                all: recipientsAll || false,
                withCards: recipientsAll ? false : recipientsWithCards || false,
                noCards: recipientsAll ? false : recipientsNoCards || false,
                segments: recipientsAll ? [] : ((_e = payload.recipientTags) === null || _e === void 0 ? void 0 : _e.segments) || [],
                phones: recipientsAll ? [] : ((_f = payload.recipientTags) === null || _f === void 0 ? void 0 : _f.phones) || [],
                filters: {
                    configuration: recipientFilters,
                    conditions: compileFilters(recipientFilters),
                },
            }, weekdays: (_g = payload.weekdays) === null || _g === void 0 ? void 0 : _g.reduce((sum, w) => sum + w, 0), timeFrom: ((_h = payload.timeFrom) === null || _h === void 0 ? void 0 : _h.replace(/(\d\d):?(\d\d)/, '$1:$2:00')) || null, timeTo: ((_j = payload.timeTo) === null || _j === void 0 ? void 0 : _j.replace(/(\d\d):?(\d\d)/, '$1:$2:00')) || null, limitMax: payload.limitType === 1 ? payload.limitMax : null, limitPeriod: payload.limitType === 1 ? payload.limitPeriod : null, eachN: payload.eachNOpted && (payload.eachN || 0) > 1 ? payload.eachN : null, notMoreN: payload.notMoreNOpted && (payload.notMoreN || 0) > 0
                ? payload.notMoreN
                : null, cheapest: payload.cheapestOpted, spreadDiscount: payload.spreadDiscountOpted, growingDiscount: (_k = payload.growingDiscount) === null || _k === void 0 ? void 0 : _k.map((i) => ({
                to: (i.to || null),
                amount: i.amount,
            })), bonusApplyMaxAmount: typeof payload.bonusApplyMaxAmount === 'string' &&
                payload.bonusApplyMaxAmount !== ''
                ? Number(payload.bonusApplyMaxAmount)
                : undefined });
        const configurationProduct = payload.conditionsProduct || [];
        if (configurationProduct.length > 0 && body.conditions) {
            body.conditions.configuration.push({
                code: '_configurationProduct',
                // @ts-ignore
                value: configurationProduct.map((i) => i.filters),
            });
            for (let i = 0; i < configurationProduct.length; i += 1)
                body.conditions.conditions = appendFilter(body.conditions.conditions, 'purchaseProduct', 'include', compileFilters(configurationProduct[i].filters));
        }
        if (id)
            result = yield updateOffer({ id, payload: body });
        else
            result = yield createOffer(body);
        if ('error' in result)
            setError(getErrorMessage(result.error));
        else {
            addToast({ type: 'success', message: successMessage });
            onSuccess();
        }
    });
    return {
        saveOffer,
        isSubmitting: isCreating || isUpdating,
        error,
    };
};
