import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { COLOR_MAP } from './COLOR_MAP';
import styles from './TitleRow.module.scss';
export const TitleRow = ({ className, group, subtitle, children, isActive, isExpanded = false, levelGap = 0, }) => {
    var _a;
    const TITLE_INITIAL_GAP = 24;
    const paddingLeft = TITLE_INITIAL_GAP + levelGap;
    const colorVarialbe = { '--color': COLOR_MAP[group.type] };
    return (_jsx("div", { className: classNames(className, styles.titleRow, {
            [styles.hasSubTitle]: !!subtitle,
            [styles.active]: isActive,
            [styles.static]: group.guid === 'unordered' || group.mode === 'exclusive',
            [styles.expanded]: ((_a = group.children) === null || _a === void 0 ? void 0 : _a.length) > 0 && isExpanded,
        }), style: Object.assign({ paddingLeft }, colorVarialbe), children: _jsxs("div", { children: [_jsx("div", { className: styles.titleWrapper, children: children }), !!subtitle && _jsx("div", { className: styles.subtitle, children: subtitle })] }) }));
};
