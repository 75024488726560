import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DATE_FORMAT } from '../plugins/VariablePlugin/AutocompleteDateFormats';
import styles from './styles.module.scss';
import { VarsPanelItem } from './VarsPanelItem';
import { VarsPanelRightSettings } from './VarsPanelRightSettings';
export const VarsPanelRight = ({ variables, onSelectVariable, currency, }) => {
    const { t } = useTranslation();
    const [selectedCounter, setSelectedCounter] = useState(null);
    const [selectedPromocode, setSelectedPromocode] = useState(null);
    const [selectedDateFormat, setSelectedDateFormat] = useState(null);
    return (_jsxs(_Fragment, { children: [_jsx(VarsPanelRightSettings, { variables: variables, selectedCounter: selectedCounter, onChangeCounter: setSelectedCounter, selectedPromocode: selectedPromocode, onChangePromocode: setSelectedPromocode, selectedDateFormat: selectedDateFormat, onChangeDateFormat: setSelectedDateFormat }), _jsx("div", { className: styles.varListDescription, children: t('Выберите параметр') }), _jsx("ul", { className: styles.varList, children: variables.map((item) => (_jsx(VarsPanelItem, { item: item, onClick: () => {
                        onSelectVariable(item, {
                            counter: selectedCounter,
                            promocode: selectedPromocode,
                            dateFormat: (selectedDateFormat === null || selectedDateFormat === void 0 ? void 0 : selectedDateFormat.value) || DEFAULT_DATE_FORMAT,
                        });
                    }, currency: currency }, item.id))) })] }));
};
