import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { AnalyticsChartContainer } from '@/components/AnalyticsChart/AnalyticsChartContainer';
import { AnalyticsChartPillsContainer } from '@/components/AnalyticsChartPills/AnalyticsChartPillsContainer';
import { AnalyticsTableContainer } from '@/components/AnalyticsTable/AnalyticsTableContainer';
import { Container } from '@/components/Container/Container';
import { AnalyticsFilters } from '@/components/Filters/AnalyticsFilters';
import { ReportsNavContainer } from '@/components/ReportsNav/ReportsNavContainer';
import { useAddRFMDashboard } from '@/components/ReportsNav/useAddDashboard';
import { SparklineButtonGroupContainer } from '@/components/SparklineButtonGroup/SparklineButtonGroupContainer';
import { useAppSelector } from '@/hooks/store';
import { useConfirmNavigation } from '@/hooks/useConfirmNavigation';
import { activeDashboardSelector } from '@/store/selectors/reports';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { ROUTES } from '../routes';
import styles from './styles.module.scss';
import { useExportReport } from './useExportReport';
import { useSaveTouchedDashboards } from './useSaveTouchedDashboards';
export const Reports = () => {
    var _a;
    const navigate = useNavigate();
    const location = useLocation();
    const source = (_a = location.state) === null || _a === void 0 ? void 0 : _a.source;
    const { user, brand } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    const activeDashboard = useAppSelector(activeDashboardSelector);
    const [interval, setInterval] = useState('day');
    const { saveTouchedDashboards, touchedDashboards } = useSaveTouchedDashboards();
    const userHasAccessTo = useUserPermissions();
    useConfirmNavigation(touchedDashboards.length > 0 && userHasAccessTo('REPORTS_MANAGE'), saveTouchedDashboards);
    const [isRFMDashboardAdded, setIsRFMDashboardAdded] = useState(false);
    const addRFMDashboard = useAddRFMDashboard();
    useEffect(() => {
        if (source === 'rfm' && !isRFMDashboardAdded) {
            addRFMDashboard();
            setIsRFMDashboardAdded(true);
        }
    }, [source]);
    const exportReport = useExportReport(interval);
    if (!user || !brand)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(ReportsNavContainer, {}), activeDashboard ? (_jsxs(Container, { hidePageTitle: true, notMobileRightPadding: true, children: [_jsx("div", { className: styles.filters, children: _jsx(AnalyticsFilters, { extraFields: brand.brand.clientExtraFields }) }), _jsx("div", { className: styles.sparkline, children: _jsx(SparklineButtonGroupContainer, { currency: currency }) }), _jsx("div", { className: styles.chartWrapper, children: _jsxs("div", { className: styles.chart, children: [_jsx(AnalyticsChartPillsContainer, { interval: interval, exportReport: exportReport, onChangeInterval: setInterval }), _jsx(AnalyticsChartContainer, { interval: interval }), _jsx(AnalyticsTableContainer, { onSegmentClick: (segment) => {
                                        navigate(ROUTES.CLIENTS, {
                                            state: {
                                                segmentId: segment.id,
                                                conditions: segment.conditions,
                                            },
                                        });
                                    }, currency: currency, isRFMEnabled: isRFMEnabled })] }) })] })) : null] }));
};
