var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetCounterListQuery } from '@/api/query/counters';
import { ROUTES } from '@/app/pages/routes';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { CurrentUserFranchisePicker } from '@/components/CurrentUserFranchisePicker/CurrentUserFranchisePicker';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { FiltersPanel } from '@/components/FiltersPanel/FiltersPanel';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { useClientFilters } from '@/services/filters/data/useClientFilters';
import { ClientsPanel } from './ClientsPanel';
import styles from './styles.module.scss';
import { useData } from './useData';
import { useExportXlsx } from './useExportXlsx';
import { useFiltersState } from './useFiltersState';
import { useSaveSegment } from './useSaveSegment';
export const Clients = () => {
    var _a;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand, user, currency } = useCurrentUser();
    const extraFields = brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields;
    const { data: counters = [] } = useGetCounterListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const [searchText, setSearchText] = useState('');
    const { segment, filters, compiledFilters, updateFilters, reset, untouchLS } = useFiltersState({ currency });
    const filtersTree = useClientFilters(extraFields, counters, [], filters === null || filters === void 0 ? void 0 : filters.filters);
    const { saveSegment, isAdding: isAddingSegment, isSetting: isSettingSegment, } = useSaveSegment({
        onSuccess: (segment) => untouchLS(segment),
    });
    const { count, data, isLoading, isFetching, refetchClients } = useData({
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : undefined,
        searchText,
        limit,
        offset,
    });
    const exportXlsx = useExportXlsx({
        searchText: searchText || undefined,
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : {},
        onSuccess: () => navigate(ROUTES.EXPORTS),
    });
    const handleApplySearch = useCallback((searchText) => {
        setOffset(0);
        setSearchText(searchText);
    }, []);
    const handleUpdateFilters = useCallback((filters) => {
        setOffset(0);
        updateFilters(filters);
    }, []);
    const handleResetFilters = useCallback(() => {
        setOffset(0);
        reset();
    }, []);
    if (!brand || !user)
        return null;
    return (_jsxs(Container, { notMobileRightPadding: true, topElementClassName: classNames({
            [styles.hasFilters]: (_a = filters === null || filters === void 0 ? void 0 : filters.filters) === null || _a === void 0 ? void 0 : _a.length,
        }), topElement: _jsxs("div", { className: styles.searchPanel, children: [_jsx(FiltersButton, { className: styles.filterButton, filtersTree: filtersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters }), _jsx(SearchPanel, { className: styles.search, name: 'searchText', placeholder: t('Номер телефона, ФИО, email или отсканируйте карту'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch }), _jsx(CurrentUserFranchisePicker, { user: user.user })] }), children: [_jsx(FiltersPanel, { filtersTree: filtersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters, hasResetButton: !!(filters === null || filters === void 0 ? void 0 : filters.filters.length), onResetFilters: handleResetFilters, segment: segment, isSegmentFiltersTouched: filters === null || filters === void 0 ? void 0 : filters.touched, onSaveSegment: (values) => __awaiter(void 0, void 0, void 0, function* () {
                    if (!(filters === null || filters === void 0 ? void 0 : filters.filters.length))
                        return;
                    if (segment === null || segment === void 0 ? void 0 : segment.id)
                        return saveSegment(values ? Object.assign(Object.assign({}, segment), values) : segment, filters.filters, !values);
                }), onCreateSegment: (newSegment) => saveSegment(newSegment, filters === null || filters === void 0 ? void 0 : filters.filters), isCreatingSegment: isAddingSegment, isSettingSegment: isSettingSegment, user: user }), _jsx(ClientsPanel, { count: count, clients: data, isLoading: isLoading, isFetching: isFetching, limit: limit, offset: offset, onChangeOffset: setOffset, onClientUpdate: (result) => {
                    if ((result === null || result === void 0 ? void 0 : result.added) || (result === null || result === void 0 ? void 0 : result.updated) || (result === null || result === void 0 ? void 0 : result.deleted))
                        if (offset !== 0)
                            setOffset(0);
                        else
                            refetchClients();
                }, onExportXlsx: exportXlsx, onSegmentClick: (segment) => {
                    navigate(ROUTES.CLIENTS, {
                        state: { segmentId: segment },
                        replace: true,
                    });
                } })] }));
};
