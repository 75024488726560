import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { useClientAddModal } from '@/modals/ClientAddModal/useClientAddModal';
import { usePurchaseAddModal } from '@/modals/PurchaseAddModal/usePurchaseAddModal';
import { useCurrentUser } from '../../useCurrentUser';
import { useSidebarState } from '../AppSidebar/useSidebarState';
import { AppHeader } from './AppHeader';
export const AppHeaderContainer = memo(() => {
    const { user, brand } = useCurrentUser();
    const navigate = useNavigate();
    const { toggleHiddenSidebar, isCollapsed, isHidden } = useSidebarState();
    const clientAddModal = useClientAddModal();
    const purchaseAddModal = usePurchaseAddModal();
    if (!brand || !user)
        return null;
    return (_jsx(_Fragment, { children: _jsx(AppHeader, { balance: brand.balance, brand: brand.brand, isCollapsed: isCollapsed, isHidden: isHidden, onToggleHidden: toggleHiddenSidebar, onGoToBalance: () => navigate(ROUTES.BILLING), onAddClient: clientAddModal.open, onAddPurchase: purchaseAddModal.open }) }));
});
