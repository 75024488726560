import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { EmailNotOpened } from '@/icons/flow/EmailNotOpened';
import { Goal } from '@/icons/Goal';
import { Done } from '@/icons/status/Done';
import { Error } from '@/icons/status/Error';
import { ErrorTriangle } from '@/icons/status/ErrorTriangle';
import { Planned } from '@/icons/status/Planned';
import { Sending } from '@/icons/status/Sending';
import { MAILING_STATUS_CASCADE, MAILING_STATUS_DONE, MAILING_STATUS_ERROR, } from '@/types/mailingStatus';
import { formatDate, formatDateTimeFirst } from '@/utils/dates';
import styles from '../../styles.module.scss';
export const getMailingDescription = (args) => {
    const { event } = args;
    let iconElement;
    let descriptionElement;
    let color = undefined;
    if (event.eventType === 'mailing_event' ||
        event.eventType === 'sending_push' ||
        event.eventType === 'sending_webpush' ||
        event.eventType === 'sending_sms' ||
        event.eventType === 'sending_viber' ||
        event.eventType === 'sending_email' ||
        event.eventType === 'sending_chat') {
        const content = mailingEventContent(args);
        color = content.color;
        iconElement = content.iconElement;
        descriptionElement = content.descriptionElement;
    }
    if (event.eventType === 'mailing_sending') {
        const content = mailingSendingContent(args);
        color = content.color;
        iconElement = content.iconElement;
        descriptionElement = content.descriptionElement;
    }
    const content = (_jsxs("span", { className: styles.channelDescription, children: [iconElement, descriptionElement] }));
    return { color, content };
};
const mailingEventContent = (args) => {
    const { event, translate: t } = args;
    const { status, openedAt, bouncedAt, deliveredAt, finishedAt, scheduledAt } = event.params || {};
    let iconElement;
    let descriptionElement;
    let color = undefined;
    const isMailingEvent = event.eventType === 'mailing_event';
    if (openedAt) {
        iconElement = _jsx(EmailNotOpened, { size: 14 });
        descriptionElement = (_jsxs(_Fragment, { children: [_jsx("span", { children: formatDate(openedAt, true) }), _jsx("span", { children: isMailingEvent ? t('Открыта') : t('Открыто') })] }));
    }
    else if (bouncedAt) {
        color = 'red';
        iconElement = _jsx(Error, {});
        descriptionElement = (_jsxs(_Fragment, { children: [_jsx("span", { children: formatDate(bouncedAt, true) }), _jsx("span", { children: isMailingEvent ? t('Не доставлена') : t('Не доставлено') })] }));
    }
    else if (deliveredAt) {
        iconElement = _jsx(Done, { type: 'single' });
        descriptionElement = (_jsxs(_Fragment, { children: [_jsx("span", { children: formatDate(deliveredAt, true) }), _jsx("span", { children: isMailingEvent ? t('Доставлена') : t('Доставлено') })] }));
    }
    else if (status === MAILING_STATUS_ERROR) {
        color = 'red';
        iconElement = _jsx(ErrorTriangle, { size: 14 });
        descriptionElement = (_jsxs(_Fragment, { children: [finishedAt ? _jsx("span", { children: formatDate(finishedAt, true) }) : null, _jsx("span", { children: t('Ошибка отправки') })] }));
    }
    else if (status === MAILING_STATUS_DONE) {
        iconElement = _jsx(Sending, {});
        descriptionElement = (_jsxs(_Fragment, { children: [finishedAt ? _jsx("span", { children: formatDate(finishedAt, true) }) : null, _jsx("span", { children: isMailingEvent ? t('Отправлена') : t('Отправлено') })] }));
    }
    else if (status === MAILING_STATUS_CASCADE)
        descriptionElement = (_jsxs(_Fragment, { children: [finishedAt ? _jsx("span", { children: formatDate(finishedAt, true) }) : null, _jsx("span", { children: t('В каскаде') })] }));
    else if (!finishedAt) {
        iconElement = _jsx(Planned, {});
        descriptionElement = (_jsx(_Fragment, { children: _jsxs("span", { children: [t('Ожидает отправки'), scheduledAt ? (_jsxs("span", { children: [' ', t('в'), " ", formatDateTimeFirst(scheduledAt)] })) : null] }) }));
    }
    return { color, iconElement, descriptionElement };
};
const mailingSendingContent = (args) => {
    const { event, translate: t } = args;
    const { goalReachedAt, openedAt, succeeded, unsubscribed } = event.params || {};
    let iconElement;
    let descriptionElement;
    const color = undefined;
    if (goalReachedAt) {
        iconElement = _jsx(Goal, { size: 14 });
        descriptionElement = (_jsxs(_Fragment, { children: [_jsx("span", { children: formatDate(goalReachedAt, true) }), _jsx("span", { children: t('Цель достигнута') })] }));
    }
    else if (openedAt) {
        iconElement = _jsx(EmailNotOpened, { size: 14 });
        descriptionElement = (_jsxs(_Fragment, { children: [_jsx("span", { children: formatDate(openedAt, true) }), _jsx("span", { children: t('Открыта') })] }));
    }
    else if (unsubscribed) {
        iconElement = undefined;
        descriptionElement = _jsx("span", { children: t('Отписался') });
    }
    else if (succeeded) {
        iconElement = _jsx(Done, { type: 'single' });
        descriptionElement = _jsx("span", { children: t('Доставлена') });
    }
    return { color, iconElement, descriptionElement };
};
