import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormPurchaseWithDetailsModal } from '@/forms/FormPurchaseWithDetails/FormPurchaseWithDetailsModal';
import { useClientModal } from '../ClientEditModal/useClientModal';
import styles from '../styles.module.scss';
import { usePurchaseModal } from './usePurchaseModal';
export const PurchaseEditModal = () => {
    var _a;
    const { t } = useTranslation();
    const { currency } = useCurrentUser();
    const purchaseModal = usePurchaseModal();
    const clientModal = useClientModal();
    const selectedPurchase = purchaseModal.purchaseList && purchaseModal.currentPurchaseIndex !== null
        ? purchaseModal.purchaseList[purchaseModal.currentPurchaseIndex]
        : null;
    const isPaginationEnbled = purchaseModal.purchaseList
        ? purchaseModal.purchaseList.length > 0
        : false;
    const handleOpenClientModal = (clientId) => (_jsx("button", { type: 'button', className: styles.clientModalButton, onClick: () => {
            purchaseModal.setVisibility(false);
            clientModal.open({
                clientId,
                closeHandler: () => {
                    purchaseModal.setVisibility(true);
                },
            });
        }, children: t('Перейти в профиль') }));
    return (_jsx(FormPurchaseWithDetailsModal, { handleOpenClientModal: handleOpenClientModal, isOpen: purchaseModal.isOpen, onClose: purchaseModal.close, currency: currency, selectedPurchase: selectedPurchase, selectedPurchaseIndex: purchaseModal.currentPurchaseIndex, setSelectedPurchaseIndex: purchaseModal.setPurchaseIndex, purchasesListLength: ((_a = purchaseModal.purchaseList) === null || _a === void 0 ? void 0 : _a.length) || 0, isPaginationEnbled: isPaginationEnbled, isReturnFirst: purchaseModal.isReturnFirst }));
};
