import { loyaltyLevelUp } from './loyaltyLevelUp';
import { loyaltySegmentJoin } from './loyaltySegmentJoin';
import { loyaltySegmentLeave } from './loyaltySegmentLeave';
export const loyalty = (args) => {
    const { event } = args;
    switch (event.eventType) {
        // повысился уровень
        case 'levelup': {
            return loyaltyLevelUp(args);
        }
        // вошел в сегмент
        case 'segment_join': {
            return loyaltySegmentJoin(args);
        }
        // покинул сегмент
        case 'segment_leave': {
            return loyaltySegmentLeave(args);
        }
        default: {
            return {};
        }
    }
};
