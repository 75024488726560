import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_PERSONAL, PROMOCODE_TYPE_SHARED, } from '@/const/promocode';
import { FormPromocodeGenerateModal } from '@/forms/FormPromocodeGenerate/FormPromocodeGenerateModal';
import { useLastListState } from '~/src/hooks/useLastListState';
import { ROUTES } from '../routes';
import { PromocodesPanel } from './PromocodesPanel';
import { useData } from './useData';
import { useDeletePromocode } from './useDeletePromocode';
import { useGeneratePromocodes } from './useGeneratePromocodes';
import { useOpenPurchaseFilter } from './useOpenPurchaseFilter';
export const Promocodes = ({ isFriend }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentTab, handleCleanData, handleCurrentTab } = useLastListState();
    const [activeTabId, setActiveTabId] = useState(currentTab || 'active');
    const [searchText, setSearchText] = useState('');
    const limit = 100;
    const [offset, setOffset] = useState(0);
    const [selectedToExportPromocode, setSelectedToExportPromocode] = useState(null);
    const section = isFriend ? 'friend' : 'common';
    const { brand } = useCurrentUser();
    const { count, data, isLoading, isFetching } = useData({
        searchText,
        limit,
        offset,
        archived: activeTabId === 'archived',
        section,
    });
    const deletePromocode = useDeletePromocode({
        section,
        codeTypeShared: PROMOCODE_TYPE_SHARED,
        onSuccess: () => setOffset(0),
    });
    const { generatePromocodes, isSubmitting, error } = useGeneratePromocodes({
        onSuccess: () => navigate(ROUTES.EXPORTS),
    });
    const handleOpenModal = useCallback((card) => {
        setSelectedToExportPromocode(card);
    }, []);
    const handleCloseModal = useCallback(() => {
        setSelectedToExportPromocode(null);
    }, []);
    const handleChangeActiveTab = useCallback((tabId) => {
        setActiveTabId(tabId);
        handleCurrentTab(tabId);
        setOffset(0);
    }, []);
    const handleApplySearch = useCallback((searchText) => {
        setOffset(0);
        setSearchText(searchText);
    }, []);
    const promocodeLabel = section === 'common' ? t('Промокод') : t('Код');
    const createButtonLabel = section === 'common' ? t('Создать промокод') : t('Создать код');
    const searchPlaceholder = section === 'common' ? t('Название промокода') : t('Название кода');
    const { purchaseUrl, openPurchaseFilter } = useOpenPurchaseFilter(PROMOCODE_TYPE_FRIEND);
    if (!brand)
        return null;
    return (_jsxs(Container, { notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { leftElement: _jsx(SearchPanel, { name: 'searchText', placeholder: searchPlaceholder, hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch }) }), children: [_jsx(PromocodesPanel, { activeCount: count === null || count === void 0 ? void 0 : count.active, archivedCount: count === null || count === void 0 ? void 0 : count.archived, promocodes: data === null || data === void 0 ? void 0 : data.promocodes, total: data === null || data === void 0 ? void 0 : data.total, promocodeLabel: promocodeLabel, codeTypeShared: PROMOCODE_TYPE_SHARED, codeTypePersonal: PROMOCODE_TYPE_PERSONAL, shouldDisplayProfit: brand.brand.showProfit, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort, timeZone: brand.brand.defaultTimezone, isLoading: isLoading, isFetching: isFetching, createButtonLabel: createButtonLabel, activeTabId: activeTabId, purchaseUrl: purchaseUrl, onChangeActiveTab: handleChangeActiveTab, onGenerateBatch: handleOpenModal, onDeletePromocode: deletePromocode, onOpenPurchaseFilter: openPurchaseFilter, onGoToCreateForm: () => {
                    const route = isFriend
                        ? ROUTES.PROMOCODES_FRIEND_CREATE
                        : ROUTES.PROMOCODES_CREATE;
                    navigate(route);
                }, onGoToEditForm: (promocodeId) => {
                    const route = isFriend
                        ? ROUTES.PROMOCODES_FRIEND_EDIT
                        : ROUTES.PROMOCODES_EDIT;
                    const path = generatePath(route, {
                        promocodeId: promocodeId.toString(),
                    });
                    navigate(path);
                }, 
                //
                count: activeTabId === 'active' ? count === null || count === void 0 ? void 0 : count.active : count === null || count === void 0 ? void 0 : count.archived, limit: limit, offset: offset, onChangeOffset: (offset) => {
                    setOffset(offset);
                    handleCleanData();
                } }), !!selectedToExportPromocode && (_jsx(FormPromocodeGenerateModal, { isOpen: !!selectedToExportPromocode, onClose: handleCloseModal, promocode: selectedToExportPromocode, onSubmit: (payload) => generatePromocodes(Object.assign({ id: selectedToExportPromocode.id }, payload)), isSubmitting: isSubmitting, error: error }))] }));
};
