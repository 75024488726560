import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Panel } from '@/components/Panel2/Panel';
import { StepButtons } from '@/components/StepButtons/StepButtons';
import { WalletCardContainer } from '@/components/wallet-cards/WalletCardContainer/WalletCardContainer';
import styles from './styles.module.scss';
export const WalletCardFormLayout = ({ title, content, card, cardType = 'apple', onChangeCardType, cardFooter, onGoBack, onGoPrev, onGoNext, onDone, }) => {
    const { t } = useTranslation();
    const footer = onGoPrev || onGoNext || onDone ? (_jsx("div", { className: styles.footer, children: _jsx(StepButtons, { hasPrevButton: Boolean(onGoPrev), onGoPrev: onGoPrev, hasNextButton: Boolean(onGoNext), onGoNext: onGoNext, doneButton: onDone ? (_jsx(Button, { type: 'button', theme: 'primary', size: 'large', onClick: onDone, children: t('Готово') })) : null }) })) : null;
    return (_jsx(WalletCardContainer, { cardType: cardType, onChangeCardType: onChangeCardType, card: card, cardFooter: cardFooter, children: _jsx(Panel, { className: styles.panel, title: title, onClose: onGoBack, size: 'full', footer: footer, children: content }) }));
};
