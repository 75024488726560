import { bonus0 } from './bonus0';
import { bonus1 } from './bonus1';
import { bonus2 } from './bonus2';
import { bonus3 } from './bonus3';
import { bonus4 } from './bonus4';
import { bonus5 } from './bonus5';
import { bonus6 } from './bonus6';
import { bonus7 } from './bonus7';
import { bonus8 } from './bonus8';
import { bonus9 } from './bonus9';
export const bonus = (args) => {
    const { event } = args;
    switch (event.eventType) {
        // бонусы списаны в покупку
        case '0': {
            return bonus0(args);
        }
        // бонусы начислены с покупки
        case '1': {
            return bonus1(args);
        }
        // бонусы сгорели
        case '2': {
            return bonus2(args);
        }
        // отменены начисленные бонусы за возврат
        case '3': {
            return bonus3(args);
        }
        // начислены поощрительные бонусы
        case '4': {
            return bonus4(args);
        }
        // отменены поощрительные бонусы
        case '5': {
            return bonus5(args);
        }
        // компенсированы списанные бонусы в связи с возвратом
        case '6': {
            return bonus6(args);
        }
        // отменены начисленные бонусы из-за отмены покупки
        case '7': {
            return bonus7(args);
        }
        // компенсированы списанные бонусы из-зв отмены покупки
        case '8': {
            return bonus8(args);
        }
        // начислены бонусы за покупку друга
        case '9': {
            return bonus9(args);
        }
        default: {
            return {};
        }
    }
};
