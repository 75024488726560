import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Fragment, memo } from 'react';
import { useState } from 'react';
import { MinusFilled } from '@/icons/MinusFilled';
import { PlusFilled } from '@/icons/PlusFilled';
import styles from './styles.module.scss';
const Icon = ({ expanded }) => expanded ? _jsx(PlusFilled, {}) : _jsx(MinusFilled, {});
export const AccordionTableRow = memo(({ row, headItems }) => {
    const [isOpenedItem, setIsOpenedItem] = useState(false);
    return (_jsxs("div", { className: classNames(styles.wrapper, {
            [styles.open]: isOpenedItem && !row.notExpand,
            [styles.wrapperExpand]: !row.notExpand,
        }), children: [_jsxs("div", { className: styles.row, onClick: (event) => {
                    event.stopPropagation();
                    if (!row.notExpand)
                        setIsOpenedItem((prevValue) => !prevValue);
                }, children: [_jsx("div", { className: classNames(styles.expandingButton, styles.cell), children: !row.notExpand && _jsx(Icon, { expanded: isOpenedItem }) }), row.cells.map((cell, index) => {
                        var _a, _b, _c;
                        return (_jsx("div", { style: {
                                width: (_a = headItems[index]) === null || _a === void 0 ? void 0 : _a.width,
                                textAlign: (_b = headItems[index]) === null || _b === void 0 ? void 0 : _b.align,
                            }, className: classNames(styles.cell, cell === null || cell === void 0 ? void 0 : cell.className, (_c = headItems[index]) === null || _c === void 0 ? void 0 : _c.className), children: cell.title }, 'cell' + index));
                    })] }), row.cells.map((cell, index) => (_jsx(Fragment, { children: (cell === null || cell === void 0 ? void 0 : cell.content) && (_jsx("div", { className: classNames(styles.cellContent, {
                        [styles.expanded]: isOpenedItem,
                    }), children: cell === null || cell === void 0 ? void 0 : cell.content })) }, 'content' + index)))] }, row.id));
});
