import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '~/src/components/Autocomplete/Autocomplete';
import { Checkbox } from '~/src/components/Checkbox2/Checkbox';
import { FormFieldRow } from '~/src/components/FormFieldRow2/FormFieldRow';
import { Input } from '~/src/components/Input/Input';
import { DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT, } from '~/src/forms/FormOffer/constants';
import { BonusMovementSmallAlt } from '~/src/icons/BonusMovementSmallAlt';
import styles from './styles.module.scss';
export const FormArbitrationPriorityBans = memo(({ currency, labelWidth }) => {
    const { t } = useTranslation();
    const { setValue, watch, formState: { errors }, control, trigger, clearErrors, } = useFormContext();
    const allowBonusApply = watch('allowBonusApply');
    const bonusApplyMaxType = watch('bonusApplyMaxType');
    const bonusApplyMaxTypeOptions = [
        {
            value: DISCOUNT_TYPE_FIXED,
            label: currency,
        },
        {
            value: DISCOUNT_TYPE_PERCENT,
            label: t('% от суммы'),
        },
    ];
    const revalidateBonusApplyMaxAmount = () => {
        setTimeout(() => {
            trigger('bonusApplyMaxAmount');
        }, 10);
    };
    return (_jsxs(FormFieldRow, { label: _jsxs(_Fragment, { children: [t('Запреты'), _jsx("div", { children: t('Только на товары\nс этим вознаграждением') })] }), labelWidth: labelWidth, controlClassName: styles.bans, labelClassName: styles.bansFieldRowLabel, children: [_jsx(Controller, { name: 'allowBonusCollect', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: !field.value ? 'checked' : '', checked: !field.value, onChange: (e) => {
                        setValue('allowBonusCollect', !e.target.checked);
                    }, className: styles.checkbox, children: _jsxs("div", { className: styles.checkboxTitle, children: [_jsx(BonusMovementSmallAlt, {}), t('не начислять бонусы ПЛ')] }) }))) }), _jsx(Controller, { name: 'allowBonusApply', control: control, render: ({ field }) => (_jsx(Checkbox, Object.assign({}, field, { value: !field.value ? 'checked' : '', checked: !field.value, onChange: (e) => {
                        setValue('allowBonusApply', !e.target.checked);
                        if (!e.target.checked) {
                            clearErrors('bonusApplyMaxAmount');
                            setValue('allowBonusApplyNotMore', false);
                            setValue('bonusApplyMaxAmount', null);
                        }
                    }, className: styles.allowBonusApply, children: _jsxs("div", { className: styles.checkboxTitleRed, children: [_jsx(BonusMovementSmallAlt, {}), t('не списывать бонусы ПЛ')] }) }))) }), !!allowBonusApply && (_jsx(Controller, { name: 'allowBonusApplyNotMore', control: control, render: ({ field }) => (_jsxs(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                        setValue('allowBonusApplyNotMore', e.target.checked);
                    }, preventInnerClicks: true, children: [t('списывать не более'), _jsx("div", { className: styles.constraintValueInput, children: _jsx(Controller, { name: 'bonusApplyMaxAmount', control: control, rules: {
                                    required: !!watch('allowBonusApplyNotMore'),
                                    max: bonusApplyMaxType === 1
                                        ? {
                                            value: 100,
                                            message: t('MAX_ERROR_MESSAGE', {
                                                amount: 100,
                                            }),
                                        }
                                        : undefined,
                                }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { type: 'number', inputMode: 'numeric', preventChars: ['-', '+', '.', ',', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.bonusApplyMaxAmount }))) }) }), _jsx(Autocomplete, { value: bonusApplyMaxTypeOptions.find((item) => item.value === bonusApplyMaxType) || null, onChange: (constraintType) => {
                                if (constraintType)
                                    setValue('bonusApplyMaxType', constraintType.value);
                                revalidateBonusApplyMaxAmount();
                            }, options: bonusApplyMaxTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, isInline: true })] }))) }))] }));
});
