import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { FiltersTreeView, } from '@/components/FiltersTreeView/FiltersTreeView';
import { Input } from '@/components/Input/Input';
import { Chevron } from '@/icons/Chevron';
import { findLeafByCode } from '@/services/filters/findLeafByCode';
import styles from './FiltersTreeSelect.module.scss';
export const FiltersTreeSelect = ({ filtersTree, selectedCode, disabledCodes, hiddenCodes, onChange, scrollToSelectedNode = false, isDisabled = false, }) => {
    var _a;
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
    const selectedLeaf = findLeafByCode(filtersTree, selectedCode);
    const input = useRef(null);
    const handleStopClicks = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const handleChange = useCallback((code, item) => {
        onChange(code, item);
        handleClose();
    }, [onChange, handleClose]);
    const treeView = (_jsx("div", { className: styles.tree, onClick: handleStopClicks, style: { width: (_a = input.current) === null || _a === void 0 ? void 0 : _a.clientWidth }, children: _jsx(FiltersTreeView, { filtersTree: filtersTree, selectedCode: selectedCode, disabledCodes: disabledCodes, hiddenCodes: hiddenCodes, onChange: handleChange, scrollToSelectedNode: scrollToSelectedNode }) }));
    const icon = (_jsx(Chevron, { className: classNames(styles.chevron, {
            [styles.enabled]: !isDisabled,
            [styles.flipped]: isOpen,
        }), fill: 'currentColor' }));
    const popperModifiers = [
        {
            name: 'offset',
            options: { offset: [0, -7] },
        },
    ];
    return (_jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(Tooltip, { open: isOpen, PopperProps: {
                className: styles.popup,
                modifiers: popperModifiers,
                onTouchEnd: (e) => e.stopPropagation(),
            }, title: treeView, disableFocusListener: true, disableHoverListener: true, onOpen: handleOpen, TransitionProps: { timeout: 0 }, placement: 'bottom-start', onKeyDown: (e) => {
                if (e.code === 'Escape')
                    handleClose();
            }, children: _jsxs("div", { className: styles.chevronWrapper, children: [_jsx(Input, { ref: input, className: styles.input, type: 'button', value: (selectedLeaf === null || selectedLeaf === void 0 ? void 0 : selectedLeaf.filterTitle) || (selectedLeaf === null || selectedLeaf === void 0 ? void 0 : selectedLeaf.label) || '', onClick: handleToggle, disabled: isDisabled }), icon] }) }) }));
};
