import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import styles from '../styles.module.scss';
import { ImageLoader } from './ImageLoader';
export const ImageHelp = ({ className, format = '.png', dimensions = '', help = '', rejectReason = '', isLoading = false, isFloating = false, isShort = false, isError = false, }) => {
    const { t } = useTranslation();
    const errorMessage = rejectReason
        ? rejectUploadReason(rejectReason, format, dimensions, true) || ''
        : '';
    if (!isLoading && !errorMessage && !help)
        return null;
    return (_jsx("div", { className: classNames(styles.description, {
            [styles.error]: !!errorMessage || isError,
            [styles.floating]: isFloating,
            [styles.short]: isShort,
        }, className), children: isLoading ? _jsx(ImageLoader, {}) : t(errorMessage) || help }));
};
