import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Table } from '@/components/Table/Table';
import { CheckCircle } from '@/icons/CheckCircle';
import { Plus } from '@/icons/Plus';
import { Draft } from '@/icons/status/Draft';
import { Pause } from '@/icons/status/Pause';
import { Planned } from '@/icons/status/Planned';
import { isItemActive } from '@/utils/isItemPlanned';
import { Tooltip } from '../Tooltip/Tooltip';
import { GeopushToggle } from './GeopushToggle';
import { ListEmpty } from './ListEmpty';
import styles from './styles.module.scss';
export const GeopushList = memo(({ items, onAdd, onEdit, onMakeCopy, onDelete, onToggle, isLoading, onItemEnter, onItemLeave, activeItemId, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasWalletCardsManage = userHasAccessTo('WALLET_CARDS_MANAGE');
    const columns = [
        {
            id: 'geopoint',
            title: t('Геопуш'),
            className: styles.geopointHeader,
        },
        { id: 'message', title: t('Сообщение') },
        { id: 'actions' },
    ];
    const rows = items.map(({ id, name, message, isActive, hasChanges, activeFrom, activeTo }) => {
        let statusIcon = _jsx(CheckCircle, {});
        if (hasChanges)
            statusIcon = _jsx(Draft, {});
        else if (activeTo && !moment(activeTo).isAfter())
            statusIcon = _jsx(Pause, {});
        else if (!isItemActive({ activeFrom, activeTo }))
            statusIcon = _jsx(Planned, {});
        const actions = [
            {
                id: 'edit',
                label: t('Редактировать'),
                onClick: () => onEdit(id),
                isDisabled: !userHasWalletCardsManage,
                tooltip: !userHasWalletCardsManage && t('Недоступно для вашей роли'),
            },
            {
                id: 'copie',
                label: t('Сделать копию'),
                onClick: () => onMakeCopy(id),
                shouldDisplay: items.length < 10,
                isDisabled: !userHasWalletCardsManage,
                tooltip: !userHasWalletCardsManage && t('Недоступно для вашей роли'),
            },
            {
                id: 'delete',
                label: t('Удалить'),
                onClick: () => onDelete(id),
                isDanger: true,
                isDisabled: !userHasWalletCardsManage,
                tooltip: !userHasWalletCardsManage && t('Недоступно для вашей роли'),
            },
        ];
        return {
            id,
            cells: [
                _jsx(GeopushToggle, { id: id, label: name, checked: isActive, onToggle: onToggle }, name + id),
                _jsxs("div", { className: styles.message, children: [statusIcon, _jsx("span", { className: styles.messageText, children: message })] }, message + id),
            ],
            isActive: activeItemId === id,
            actions,
        };
    });
    const handleRowClick = useCallback((row) => onEdit(Number(row.id)), [onEdit]);
    return items.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Table, { className: styles.table, columns: columns, rows: rows, isLoading: isLoading, onRowEnter: onItemEnter, onRowLeave: onItemLeave, onRowClick: handleRowClick }), _jsx("div", { className: styles.footer, children: items.length >= 10 ? (_jsx("div", { className: styles.description, children: t('Можно активировать не более 10 геоточек') })) : (_jsx(Tooltip, { title: !userHasWalletCardsManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsxs(Button, { disabled: !userHasWalletCardsManage, type: 'button', size: 'extra-large', onClick: onAdd, children: [_jsx(Plus, { className: styles.plus }), " ", t('Геопуш')] }) })) })] })) : (_jsx(ListEmpty, { onAdd: onAdd }));
});
