import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { Chevron } from '@/icons/Chevron';
import styles from './styles.module.scss';
export const ReportMetricModalSection = memo(({ title, children, circles }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);
    const chevron = (_jsx("div", { className: classNames(styles.chevron, {
            [styles.flipped]: isOpen,
        }), children: _jsx(Chevron, { fill: 'currentColor' }) }));
    return (_jsxs("div", { className: styles.section, children: [_jsxs("div", { className: styles.sectionTitle, onClick: toggle, children: [title, chevron, !isOpen && circles && (_jsx("div", { className: styles.circles, children: circles.map((circle) => (_jsx("div", { className: classNames(styles.circle, {
                                [styles.black]: circle === 'single',
                            }), style: circle !== 'single'
                                ? {
                                    '--circle-bg': circle,
                                }
                                : undefined }, circle))) }))] }), isOpen ? children : null] }));
});
