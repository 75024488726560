import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { SegmentBadgeContainer } from '@/components/SegmentBadge/SegmentBadgeContainer';
import { User } from '@/icons/User';
import styles from '../../styles.module.scss';
// вошел в сегмент
export const loyaltySegmentJoin = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleEntityLink } = entityHandlers;
    const { segment } = event.params || {};
    const link = handleEntityLink('segment', segment === null || segment === void 0 ? void 0 : segment.id);
    const segmentBadge = segment ? (_jsx(SegmentBadgeContainer, { className: styles.segmentBadge, segment: segment, children: segment === null || segment === void 0 ? void 0 : segment.name })) : (_jsxs(_Fragment, { children: [" ", t('сегмент')] }));
    const state = {
        segmentId: segment === null || segment === void 0 ? void 0 : segment.id,
        conditions: segment === null || segment === void 0 ? void 0 : segment.conditions,
    };
    return {
        Icon: _jsx(User, { size: 20 }),
        title: (_jsxs(_Fragment, { children: [t('Попал в'), link ? (_jsx(Link, { to: link, state: state, children: segmentBadge })) : (segmentBadge)] })),
    };
};
