import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from '../../styles.module.scss';
export const getCounterContent = ({ event, translate: t, }) => {
    const { params } = event;
    const { incrementedCounter, incrementedAndUsedCounter, usedCounter, incrementedCoupon, incrementedAndUsedCoupon, usedCoupon, counter = {}, } = params || {};
    const leftItems = [];
    const rightItems = [];
    // Left Items ----------------------------------------------------------------
    const counterPlus = Number(incrementedCounter) + Number(incrementedAndUsedCounter);
    if (counterPlus)
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Прибавилось к счетчику'), _jsx("span", { className: styles.contentValue, children: counterPlus })] }, 'counterPlus'));
    const counterMinus = Number(usedCounter);
    if (counterMinus)
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Счетчик уменьшился на'), _jsx("span", { className: styles.contentValue, children: counterMinus })] }, 'counterMinus'));
    const rewardsPlus = Number(incrementedCoupon) + Number(incrementedAndUsedCoupon);
    if (rewardsPlus)
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Вознаграждений выдано'), _jsx("span", { className: styles.contentValue, children: rewardsPlus })] }, 'rewardsPlus'));
    const rewardsMinus = Number(usedCoupon);
    if (rewardsMinus)
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Вознаграждений потрачено'), _jsx("span", { className: styles.contentValue, children: rewardsMinus })] }, 'rewardsMinus'));
    // Right Items ---------------------------------------------------------------
    if (counter.name)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Счетчик'), _jsx("span", { className: styles.contentValue, children: counter.name })] }, 'counter.name'));
    if (counter.applyOn === 'product' || counter.applyOn === 'purchase')
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Действует на'), _jsx("span", { className: styles.contentValue, children: counter.applyOn === 'product' ? t('товары') : t('покупки') })] }, 'counter.applyOn'));
    if (counter.requiredValue) {
        const times = counter.requiredValue +
            ' ' +
            t('TIMES', { count: Number(counter.requiredValue) });
        let period = counter.periodUnit === 'month'
            ? counter.periodValue +
                ' ' +
                t('IN_MONTHS', { count: Number(counter.periodValue) })
            : counter.periodValue +
                ' ' +
                t('IN_DAYS', { count: Number(counter.periodValue) });
        if (!counter.periodUnit || !counter.periodValue)
            period = t('неограниченный период');
        rightItems.push(_jsx("li", { className: styles.contentItem, children: _jsx("span", { className: styles.contentValue, children: `${t('Необходимо купить')} ${times} ${t('за')} ${period}` }) }, 'counter.requiredValue'));
    }
    // ---------------------------------------------------------------------------
    if (!leftItems.length && !rightItems.length)
        return undefined;
    return (_jsx("div", { children: _jsxs("section", { className: styles.expandedContent, children: [!!leftItems.length && _jsx("ul", { children: leftItems }), !!rightItems.length && _jsx("ul", { children: rightItems })] }) }));
};
