import { jsx as _jsx } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const ChannelNodeStatsLoader = ({ id, }) => {
    const flow = useReactFlow();
    const { isEditMode, stats } = useInteractiveFlow();
    const thisNode = flow.getNode(id);
    const thisNodeStats = thisNode ? stats === null || stats === void 0 ? void 0 : stats[thisNode.uuid] : undefined;
    if (!isEditMode && !thisNodeStats)
        return (_jsx("div", { className: styles.statsLoader, children: _jsx(Skeleton, {}) }));
    return null;
};
