import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@/components/Button2/Button';
import styles from './styles.module.scss';
export const AlertPopupLayout = ({ title, description, agreeLabel, cancelLabel, onAgree, onCancel, }) => {
    const handleAgree = (e) => {
        e.stopPropagation();
        onAgree === null || onAgree === void 0 ? void 0 : onAgree();
    };
    const handleCancel = (e) => {
        e.stopPropagation();
        onCancel === null || onCancel === void 0 ? void 0 : onCancel();
    };
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx("div", { className: styles.arrow }), _jsxs("div", { className: styles.info, children: [_jsx("span", { className: styles.title, children: title }), _jsx("span", { className: styles.text, children: description })] }), _jsxs("div", { className: styles.actions, children: [_jsx(Button, { theme: 'danger', size: 'full-width', onClick: handleCancel, children: cancelLabel }), _jsx(Button, { theme: 'default', size: 'full-width', onClick: handleAgree, children: agreeLabel })] })] }));
};
