import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { usePurchasesPage } from './context/usePurchasesPage';
import styles from './styles.module.scss';
export const PurchasesSearch = () => {
    const { t } = useTranslation();
    const { filters, search } = usePurchasesPage();
    return (_jsx(DatePeriodSelectContainer, { label: t('Показать покупки за'), leftElement: _jsxs("div", { className: styles.searchPanel, children: [_jsx(FiltersButton, { className: styles.filterButton, filtersTree: filters.filtersTree, value: filters.filters, onUpdate: filters.update }), _jsx(SearchPanel, { className: styles.search, name: 'searchText', placeholder: t('Номер телефона, ФИО, email или отсканируйте карту'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: search.apply })] }) }));
};
