import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const AccordionContent = ({ specificationsList, }) => {
    const { t } = useTranslation();
    // Сортируем характеристики по длинее описания начиная с самых коротких
    const { longValues, middleValues, shortValues } = specificationsList
        .filter(({ value }) => value)
        .sort((a, b) => a.value.length - b.value.length)
        .reduce((acc, item) => {
        if (item.value.length < 25)
            acc.shortValues.push(item);
        if (item.value.length >= 25 && item.value.length <= 150)
            acc.middleValues.push(item);
        if (item.value.length > 150)
            acc.longValues.push(item);
        return acc;
    }, {
        shortValues: [],
        middleValues: [],
        longValues: [],
    });
    if (specificationsList === null || specificationsList === void 0 ? void 0 : specificationsList.length)
        return (_jsxs("div", { className: styles.accordionContent, children: [!!shortValues.length && (_jsx("ul", { className: styles.accordionContentColumn, children: shortValues.map(({ title, value }, index) => (_jsxs("li", { className: styles.accordionContentItem, children: [_jsx("span", { className: styles.accordionContentItemTitle, children: title }), _jsx("div", { className: styles.accordionContentItemLine }), _jsx("span", { className: styles.accordionContentValue, children: value })] }, index))) })), !!middleValues.length && (_jsx("ul", { className: styles.accordionContentColumn, children: middleValues.map(({ title, value }, index) => (_jsxs("li", { className: styles.accordionLongValueItem, children: [_jsxs("div", { className: styles.accordionLongValueItemHead, children: [_jsx("span", { className: styles.accordionContentItemTitle, children: title }), _jsx("div", { className: styles.accordionContentItemLine })] }), _jsx("span", { className: styles.accordionContentValue, children: value })] }, index))) })), !!longValues.length && (_jsx("ul", { className: styles.accordionContentColumn, children: longValues.map(({ title, value }, index) => (_jsxs("li", { className: styles.accordionLongValueItem, children: [_jsxs("div", { className: styles.accordionLongValueItemHead, children: [_jsx("span", { className: styles.accordionContentItemTitle, children: title }), _jsx("div", { className: styles.accordionContentItemLine })] }), _jsx("span", { className: styles.accordionContentValue, children: value })] }, index))) }))] }));
    return (_jsx("div", { className: styles.accordionContentPlaceholder, children: t('нет характеристик') }));
};
