import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { capitalize } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { COLOR_MAP } from '~/src/components/arbitration/ArbitrationGroup/helpers/COLOR_MAP';
import { DESCRIPTION_SHORT_TITLE_MAP } from '~/src/components/arbitration/ArbitrationGroup/helpers/DESCRIPTION_MAP';
import { ArbitrationGroupPanel } from '~/src/components/arbitration/ArbitrationGroupPanel/ArbitrationGroupPanel';
import { getSelectedGroup, } from '~/src/components/arbitration/FormArbitrationPriority/utils';
import { Autocomplete } from '~/src/components/Autocomplete/Autocomplete';
import { ListItem } from './ListItem';
import styles from './styles.module.scss';
export const ChoosePosition = ({ isPersonalPromocode, isOfferForm, newEntity, isGroupsExist, currency, entityTitle, groupChildrenList, priority, currentGroup, arbitrationPriorityGuid, groupSelectOptions, setIsEntityInsideList, setGroupChildrenList, setCurrentGroup, }) => {
    const { t } = useTranslation();
    const { setValue, watch } = useFormContext();
    const handleChangeList = ({ isFirstPosition, guid, edge, }) => {
        if (isFirstPosition) {
            setGroupChildrenList([newEntity]);
            setValue('arbitrationPriorityAfter', null);
            setValue('arbitrationGroupGuid', currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.guid);
            return;
        }
        setGroupChildrenList((prevList) => {
            var _a;
            const newList = prevList.filter((item) => item.guid !== (newEntity === null || newEntity === void 0 ? void 0 : newEntity.guid));
            const dropItemIndex = newList.findIndex((item) => item.guid === guid);
            const insertionIndex = edge === 'top' ? dropItemIndex : dropItemIndex + 1;
            setValue('arbitrationGroupGuid', currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.guid);
            setValue('arbitrationPriorityAfterError', false);
            if (insertionIndex !== -1)
                newList.splice(insertionIndex, 0, newEntity);
            const prevIndex = newList.findIndex((item) => item.guid === (newEntity === null || newEntity === void 0 ? void 0 : newEntity.guid)) - 1;
            setValue('arbitrationPriorityAfter', ((_a = newList[prevIndex]) === null || _a === void 0 ? void 0 : _a.guid) || null, {
                shouldValidate: false,
                shouldDirty: true,
            });
            return newList;
        });
        setIsEntityInsideList(true);
    };
    const handleSelectGroup = (value) => {
        const { currentGroup, childrenList } = getSelectedGroup(priority, value === null || value === void 0 ? void 0 : value.id);
        if (arbitrationPriorityGuid)
            setGroupChildrenList(childrenList.filter((i) => i.guid !== arbitrationPriorityGuid));
        else
            setGroupChildrenList(childrenList);
        setCurrentGroup(currentGroup);
        setIsEntityInsideList(false);
        setValue('arbitrationPriorityAfter', undefined, {
            shouldValidate: false,
            shouldDirty: true,
        });
    };
    const [overEmptyList, setOverEmptyList] = useState(false);
    const listRef = useRef(null);
    const newItemOffsetTop = watch('newItemOffsetTop');
    useEffect(() => {
        const listElement = listRef.current;
        listElement.scrollTop = newItemOffsetTop - listElement.offsetTop - 96;
        if (!listElement)
            return;
        let dropTargetCleanup = () => { };
        if ((groupChildrenList === null || groupChildrenList === void 0 ? void 0 : groupChildrenList.length) === 0)
            dropTargetCleanup = dropTargetForElements({
                element: listElement,
                onDrag: () => {
                    setOverEmptyList(true);
                },
                onDragLeave: () => {
                    setOverEmptyList(false);
                },
                onDrop() {
                    setOverEmptyList(false);
                    setValue('newItemOffsetTop', 0);
                    handleChangeList({
                        isFirstPosition: true,
                    });
                    setIsEntityInsideList(true);
                },
            });
        const autoScrollCleanup = autoScrollForElements({
            element: listElement,
            getConfiguration() {
                return {
                    maxScrollSpeed: 'fast',
                };
            },
        });
        return combine(autoScrollCleanup, dropTargetCleanup);
    }, [groupChildrenList]);
    const description = DESCRIPTION_SHORT_TITLE_MAP[(currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.type) || 'max'];
    const descriptionColor = COLOR_MAP[(currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.type) || 'max'];
    return (_jsxs(ArbitrationGroupPanel, { children: [_jsxs("div", { className: styles.chooseGroupSelect, children: [_jsx("span", { className: styles.chooseGroupSelectTitle, children: t('Выберите группу') }), _jsxs("div", { className: styles.chooseGroupSelectContainer, children: [isGroupsExist && (_jsx(Autocomplete, { className: styles.chooseGroupSelectInput, value: groupSelectOptions.find((i) => i.id === (currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.guid)) ||
                                    groupSelectOptions[0], onChange: handleSelectGroup, options: groupSelectOptions, getOptionKey: (option) => option === null || option === void 0 ? void 0 : option.id, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.title) === 'Исключающая остальные'
                                    ? capitalize('единственная в чеке')
                                    : option === null || option === void 0 ? void 0 : option.title, asSelect: true })), currentGroup && (_jsx("div", { className: styles.description, style: { color: descriptionColor }, children: !currentGroup.children.length
                                    ? t('Пустая группа')
                                    : capitalize(t(description)) }))] })] }), _jsx("ul", { ref: listRef, className: classNames(styles.groupChildrenList, {
                    [styles.emptyGroupPlaceholder]: !(groupChildrenList === null || groupChildrenList === void 0 ? void 0 : groupChildrenList.length) && overEmptyList,
                }), children: groupChildrenList === null || groupChildrenList === void 0 ? void 0 : groupChildrenList.map((group, index) => (_jsx(ListItem, { isPersonalPromocode: isPersonalPromocode, isOfferForm: isOfferForm, arbitrationPriorityGuid: arbitrationPriorityGuid, entityTitle: entityTitle, currency: currency, parentType: currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.type, group: group, index: index, isLastItem: groupChildrenList.length - 1 === index, handleChangeList: handleChangeList }, group.guid))) })] }));
};
