import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/Modal/Modal';
import * as triggers from '@/const/mailing-triggers';
import { TRIGGER_TYPES_MAP } from '@/const/mailing-triggers';
import { Birthday } from '@/icons/flow/triggers/Birthday';
import { Bonuses } from '@/icons/flow/triggers/Bonuses';
import { Calendar } from '@/icons/flow/triggers/Calendar';
import { Card } from '@/icons/flow/triggers/Card';
import { Cart } from '@/icons/flow/triggers/Cart';
import { Cursor } from '@/icons/flow/triggers/Cursor';
import { Loyalty } from '@/icons/flow/triggers/Loyalty';
import { Mail } from '@/icons/flow/triggers/Mail';
import { Segments } from '@/icons/flow/triggers/Segments';
import { Selected } from '@/icons/flow/triggers/Selected';
import { Shop } from '@/icons/flow/triggers/Shop';
import styles from './styles.module.scss';
export const FlowTriggerModal = ({ isOpen, onClose, onSelectTrigger, }) => {
    const { t } = useTranslation();
    const [selectedGroup, setSelectedGroup] = useState('all');
    const groups = [
        {
            id: 'segments',
            label: t('Сегменты'),
            triggers: [
                {
                    id: triggers.TRIGGER_TYPE_SEGMENT_JOIN,
                    label: t('Клиент попал в сегмент'),
                    icon: _jsx(Segments, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_SEGMENT_LEAVE,
                    label: t('Клиент вышел из сегмента'),
                    icon: _jsx(Segments, {}),
                },
            ],
        },
        {
            id: 'loyalty',
            label: t('Программа лояльности'),
            shortLabel: t('Лояльность'),
            triggers: [
                {
                    id: triggers.TRIGGER_TYPE_REGISTER_CLIENT,
                    label: t('Клиент зарегистрировался в Программе Лояльности'),
                    icon: _jsx(Loyalty, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_LEVEL_UP,
                    label: t('Клиент перешел на новый уровень'),
                    icon: _jsx(Loyalty, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_REGISTER_WALLET_CARD,
                    label: t('Клиент установил карту Wallet'),
                    icon: _jsx(Card, {}),
                },
            ],
        },
        {
            id: 'purchases',
            label: t('Покупки'),
            triggers: [
                {
                    id: triggers.TRIGGER_TYPE_PURCHASE,
                    label: t('Клиент совершил покупку'),
                    icon: _jsx(Shop, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_RETURN,
                    label: t('Клиент сделал возврат'),
                    icon: _jsx(Shop, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_BONUS_ACTIVATE,
                    label: t('Активировались бонусы'),
                    icon: _jsx(Bonuses, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_BONUS_EXPIRING,
                    label: t('Сгорают бонусы'),
                    icon: _jsx(Bonuses, {}),
                },
            ],
        },
        {
            id: 'website',
            label: t('События сайта'),
            triggers: [
                {
                    id: triggers.TRIGGER_TYPE_ABANDONED_CART,
                    label: t('Клиент бросил корзину'),
                    icon: _jsx(Cart, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_PRODUCT_VIEW,
                    label: t('Брошенный просмотр каталога'),
                    icon: _jsx(Cursor, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_PRICE_IN_CART_DROPPED,
                    label: t('Снизилась цена в брошеной корзине'),
                    icon: _jsx(Cart, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_PRICE_IN_FAVORITE_DROPPED,
                    label: t('Снизилась цена в избранном'),
                    icon: _jsx(Selected, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_SUBSCRIBE_EMAIL,
                    label: t('Клиент подписался на Email-рассылку'),
                    icon: _jsx(Mail, {}),
                },
            ],
        },
        {
            id: 'other',
            label: t('Другое'),
            triggers: [
                {
                    id: triggers.TRIGGER_TYPE_BIRTHDAY,
                    label: t('Наступает День рождения'),
                    icon: _jsx(Birthday, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_CHILD_BIRTHDAY,
                    label: t('Наступает День рождения ребенка'),
                    icon: _jsx(Birthday, {}),
                },
                {
                    id: triggers.TRIGGER_TYPE_SCHEDULE,
                    label: t('По расписанию'),
                    icon: _jsx(Calendar, {}),
                },
            ],
        },
    ];
    const filteredGroups = groups.filter((group) => selectedGroup === 'all' || group.id === selectedGroup);
    const renderNavItem = (id, label) => (_jsx("li", { className: classNames(styles.navItem, {
            [styles.active]: id === selectedGroup,
        }), onClick: () => setSelectedGroup(id), children: label }, id));
    const renderGroup = (id, label, triggers) => (_jsxs("div", { children: [_jsx("div", { className: styles.groupTitle, children: label }), _jsx("div", { className: styles.groupBody, children: triggers.map((trigger) => (_jsx("button", { type: 'button', className: classNames(styles.trigger, styles[TRIGGER_TYPES_MAP[trigger.id]]), onClick: () => onSelectTrigger === null || onSelectTrigger === void 0 ? void 0 : onSelectTrigger(trigger.id, trigger.label), children: _jsxs("span", { className: styles.triggerContent, children: [_jsx("span", { children: trigger.icon }), _jsx("span", { children: trigger.label })] }) }, trigger.id))) })] }, id));
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, bodyClassName: styles.modalBody, title: t('Цепочка стартует, когда...'), size: '900', hasNoPadding: true, hasNoOverflow: true, children: _jsxs("div", { className: styles.body, children: [_jsxs("ul", { className: styles.nav, children: [renderNavItem('all', t('Все')), groups.map((group) => renderNavItem(group.id, group.shortLabel || group.label))] }), _jsx("div", { className: styles.groupsWrapper, children: filteredGroups.map((group) => renderGroup(group.id, group.label, group.triggers)) })] }) }));
};
