import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { MailingRewardsList } from '@/components/MailingRewardsList/MailingRewardsList';
import { MailingRewardsMenu } from '@/components/MailingRewardsMenu/MailingRewardsMenu';
import { MailingRewardsPanels, } from '@/components/MailingRewardsPanels/MailingRewardsPanels';
import { MailingRewardsPopup } from '@/components/MailingRewardsPopup/MailingRewardsPopup';
import { PROMOCODE_TYPE_FRIEND } from '@/const/promocode';
import { Plus } from '@/icons/Plus';
export const MailingRewardsInput = memo(({ value, onChange, offers = [], promocodes: allPromocodes = [], giftCards = [], shouldRenderPanels = true, currency, buttonRef, }) => {
    var _a;
    const { t } = useTranslation();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const promocodes = allPromocodes.filter((i) => i.codeType !== PROMOCODE_TYPE_FRIEND);
    const friendPromocodes = allPromocodes.filter((i) => i.codeType === PROMOCODE_TYPE_FRIEND);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
        setActiveMenuItem('');
    };
    const handleChangeActiveMenuItem = (item) => {
        if (item === 'bonuses') {
            onChange(Object.assign(Object.assign({}, value), { bonusesAmount: '', bonusesLifetime: '' }));
            togglePopup();
            return;
        }
        setActiveMenuItem(item);
    };
    const handleSelectListItem = (itemId) => {
        if (activeMenuItem === 'offers')
            onChange(Object.assign(Object.assign({}, value), { offers: [...(value.offers || []), { id: itemId, expireInDays: '' }] }));
        if (activeMenuItem === 'promocodes' ||
            activeMenuItem === 'friendPromocodes')
            onChange(Object.assign(Object.assign({}, value), { promocodeId: itemId }));
        if (activeMenuItem === 'giftCards')
            onChange(Object.assign(Object.assign({}, value), { giftCardId: itemId }));
        togglePopup();
    };
    let listTitle = '';
    let listItems = [];
    let disabledItemIds = [];
    if (activeMenuItem === 'offers') {
        listTitle = 'Выберите акцию';
        listItems = offers;
        disabledItemIds = ((_a = value.offers) === null || _a === void 0 ? void 0 : _a.map((o) => o.id)) || [];
    }
    if (activeMenuItem === 'promocodes' ||
        activeMenuItem === 'friendPromocodes') {
        listTitle = 'Выберите промокод';
        listItems =
            activeMenuItem === 'promocodes' ? promocodes : friendPromocodes;
        disabledItemIds =
            typeof value.promocodeId !== 'undefined' ? [value.promocodeId] : [];
    }
    if (activeMenuItem === 'giftCards') {
        listTitle = 'Выберите карту';
        listItems = giftCards;
        disabledItemIds =
            typeof value.giftCardId !== 'undefined' ? [value.giftCardId] : [];
    }
    return (_jsxs(_Fragment, { children: [shouldRenderPanels && (_jsx(MailingRewardsPanels, { value: value, onChange: onChange, offers: offers, promocodes: allPromocodes, giftCards: giftCards, currency: currency })), _jsx("span", { ref: triggerRef, onClick: togglePopup, children: _jsx(Button, { ref: buttonRef, type: 'button', theme: 'primary', icon: _jsx(Plus, {}), isActive: popupIsOpen }) }), _jsx(MailingRewardsPopup, { isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, menu: _jsx(MailingRewardsMenu, { activeItem: activeMenuItem, onChangeActiveItem: handleChangeActiveMenuItem, offersCount: offers.length, promocodesCount: promocodes.length, friendPromocodesCount: friendPromocodes.length, giftCardsCount: giftCards.length, hasBonusesApplied: typeof value.bonusesAmount !== 'undefined' }), list: activeMenuItem ? (_jsx(MailingRewardsList, { title: t(listTitle), items: listItems, onItemClick: handleSelectListItem, disabledItemIds: disabledItemIds, currency: currency }, activeMenuItem)) : null })] }));
});
