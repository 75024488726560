import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useChart } from '@/hooks/useChart';
import { getBaseConfig, } from './getBaseChartConfig';
import styles from './styles.module.scss';
export const AnalyticsChart = memo(({ labels, datasets, interval }) => {
    const canvas = useChart(Object.assign(Object.assign({}, getBaseConfig({
        interval,
        crosshair: {
            line: { color: '#9B9B9B' },
            snap: { enabled: true },
            zoom: {
                zoomboxBackgroundColor: 'rgba(230,225,216,0.2)',
                zoomboxBorderColor: '#e6e1d8',
                zoomButtonClass: 'reset-zoom-btn',
            },
        },
    })), { data: {
            labels,
            datasets: datasets.map((dataset) => ({
                label: dataset.label,
                data: dataset.data,
                borderColor: dataset.color,
                backgroundColor: dataset.color,
                yAxisID: dataset.scale,
            })),
        } }));
    return (_jsx("div", { className: styles.wrapper, children: _jsx("canvas", { ref: canvas }) }));
});
