import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FiltersPopup } from '@/components/FiltersPopup/FiltersPopup';
import { Filter } from '@/icons/filters/Filter';
import { branchIsHidden } from '@/services/filters/branchIsHidden';
import { convertLeafToSavedFilter } from '@/services/filters/convertLeafToSavedFilter';
import { findLeafByCode } from '@/services/filters/findLeafByCode';
import styles from './styles.module.scss';
export const FiltersButton = ({ className, filtersTree, value, onUpdate, }) => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const disabledCodes = value.map((item) => item.id || item.code);
    const hiddenCodes = filtersTree
        .filter((i) => branchIsHidden(i, disabledCodes))
        .map((i) => i.id);
    const foundLeaves = disabledCodes
        .map((code) => findLeafByCode(filtersTree, code))
        .filter((leaf) => leaf !== null);
    const hasValue = foundLeaves.length > 0;
    const handleAddFilter = (leaf) => {
        const newValue = [...value, convertLeafToSavedFilter(leaf)];
        onUpdate(newValue);
    };
    const button = (_jsx(Button, { className: classNames({
            [styles.cursorDefault]: hasValue,
        }, className), icon: _jsx(Filter, {}), isActive: isActive || hasValue, children: t('Фильтры') }));
    return hasValue ? (button) : (_jsx(FiltersPopup, { trigger: button, filtersTree: filtersTree, disabledCodes: disabledCodes, hiddenCodes: hiddenCodes, onToggle: setIsActive, onApply: handleAddFilter }));
};
