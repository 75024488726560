import { purchaseChanged } from './purchaseChanged';
import { purchaseExpired } from './purchaseExpired';
import { purchaseFriendPurchase } from './purchaseFriendPurchase';
import { purchaseOrder } from './purchaseOrder';
import { purchasePurchase } from './purchasePurchase';
import { purchasePurchaseReturn } from './purchasePurchaseReturn';
import { purchasePurchaseRevert } from './purchasePurchaseRevert';
export const purchase = (args) => {
    const { event } = args;
    switch (event.eventType) {
        // создан заказ
        case 'order': {
            return purchaseOrder(args);
        }
        // продажа
        case 'purchase': {
            return purchasePurchase(args);
        }
        // удаление покупки
        case 'purchase_revert': {
            return purchasePurchaseRevert(args);
        }
        // покупка друга
        case 'friend_purchase': {
            return purchaseFriendPurchase(args);
        }
        // возврат
        case 'purchase_return': {
            return purchasePurchaseReturn(args);
        }
        // изменение счетчика
        case 'changed': {
            return purchaseChanged(args);
        }
        // истек срок счетчика
        case 'expired': {
            return purchaseExpired(args);
        }
        default: {
            return {};
        }
    }
};
