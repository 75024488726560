import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nEn } from './en';
import { i18nRu } from './ru';
export const resources = {
    ru: { translation: i18nRu },
    en: { translation: i18nEn },
};
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources,
    nsSeparator: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: 'ru',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});
document.documentElement.lang = i18n.language;
i18n.on('languageChanged', (language) => {
    document.documentElement.lang = language;
});
export default i18n;
