var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { canvasToBlob } from './canvasToBlob';
import { getImageDataURL } from './getImageDataURL';
import { getImageDimensions } from './getImageDimensions';
const renderImageOnCanvas = (image, color, width, height) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, width, height);
        ctx.drawImage(image, 0, 0, width, height);
    }
    return canvas;
};
export const fillImageWithBackground = (file_1, ...args_1) => __awaiter(void 0, [file_1, ...args_1], void 0, function* (file, color = '#fff') {
    const dataURL = yield getImageDataURL(file);
    const [image, width, height] = yield getImageDimensions(dataURL);
    const canvas = renderImageOnCanvas(image, color, width, height);
    const result = yield canvasToBlob(canvas);
    return result;
});
