import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { LogoLarge as LogoApple } from '@/icons/wallet-card/apple/LogoLarge';
import { LogoLarge as LogoGoogle } from '@/icons/wallet-card/google/LogoLarge';
import styles from './styles.module.scss';
export const WalletCardFormSection = ({ apple, google, top, children, hasLargeGap, }) => {
    return (_jsxs("div", { className: classNames({
            [styles.largeGap]: hasLargeGap,
        }), children: [top && (_jsx("div", { className: classNames(styles.sectionWrapper, styles.top), children: _jsx("div", { className: styles.section, children: _jsx("div", { className: styles.content, children: top }) }) })), apple && (_jsx("div", { className: classNames(styles.sectionWrapper, styles.apple), children: _jsxs("div", { className: styles.section, children: [_jsx("div", { className: styles.logo, children: _jsx(LogoApple, {}) }), _jsx("div", { className: styles.content, children: apple })] }) })), google && (_jsx("div", { className: classNames(styles.sectionWrapper, styles.google), children: _jsxs("div", { className: styles.section, children: [_jsx("div", { className: styles.logo, children: _jsx(LogoGoogle, {}) }), _jsx("div", { className: styles.content, children: google })] }) })), children && (_jsx("div", { className: styles.sectionWrapper, children: _jsx("div", { className: styles.section, children: _jsx("div", { className: styles.content, children: children }) }) }))] }));
};
