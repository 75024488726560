import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MailingChannel as MailingChannelComponent } from './MailingChannel';
import { MailingChannelArrow } from './MailingChannelArrow';
import styles from './styles.module.scss';
export const MailingChannelsInput = memo(({ activeChannels, enabledChannels, onChangeActiveChannels }) => {
    const { t } = useTranslation();
    const isPushEnabled = enabledChannels.includes('push');
    const isViberEnabled = enabledChannels.includes('viber');
    const isSMSEnabled = enabledChannels.includes('sms');
    const isEmailEnabled = enabledChannels.includes('email');
    const isTelegramEnabled = enabledChannels.includes('telegram');
    const isPushActive = isPushEnabled && activeChannels.includes('push');
    const isViberActive = isViberEnabled && activeChannels.includes('viber');
    const isSMSActive = isSMSEnabled && activeChannels.includes('sms');
    const isEmailActive = isEmailEnabled && activeChannels.includes('email');
    const shouldRenderFirstArrow = (isPushActive && isViberActive) || (isPushActive && isSMSActive);
    const shouldRenderSecondArrow = (isViberActive && isSMSActive) ||
        (isViberEnabled && isPushActive && isSMSActive);
    const toggle = (channel) => {
        onChangeActiveChannels(activeChannels.includes(channel)
            ? activeChannels.filter((c) => c !== channel)
            : [...activeChannels, channel]);
    };
    const renderActiveChannel = (channel, label) => (_jsx(MailingChannelComponent, { className: styles[channel], label: label, isActive: activeChannels.includes(channel), onChange: () => toggle(channel) }));
    const renderDisabledChannel = (channel, label) => (_jsx(MailingChannelComponent, { className: styles[channel], label: label, isDisabled: true }));
    const emailHelp = isEmailActive ? (_jsxs(_Fragment, { children: [_jsx("br", {}), t('Email отправится всем')] })) : null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.wrapper, children: [isPushEnabled && renderActiveChannel('push', 'Push'), shouldRenderFirstArrow && _jsx(MailingChannelArrow, {}), isViberEnabled && renderActiveChannel('viber', 'Viber'), shouldRenderSecondArrow && _jsx(MailingChannelArrow, {}), isSMSEnabled && renderActiveChannel('sms', 'SMS'), isEmailEnabled && renderActiveChannel('email', 'Email'), isTelegramEnabled && renderActiveChannel('telegram', 'Telegram'), !isPushEnabled && renderDisabledChannel('push', 'Push'), !isViberEnabled && renderDisabledChannel('viber', 'Viber'), !isSMSEnabled && renderDisabledChannel('sms', 'SMS'), !isEmailEnabled && renderDisabledChannel('email', 'Email'), !isTelegramEnabled && renderDisabledChannel('telegram', 'Telegram')] }), isPushActive && isViberActive && isSMSActive && (_jsxs("div", { className: styles.help, children: [t('Viber отправится только тем, кому не дойдет Push'), _jsx("br", {}), t('SMS отправится только тем, кому не дойдет Push и Viber'), emailHelp] })), isPushActive && !isViberActive && isSMSActive && (_jsxs("div", { className: styles.help, children: [t('SMS отправится только тем, кому не дойдет Push'), emailHelp] })), isPushActive && isViberActive && !isSMSActive && (_jsxs("div", { className: styles.help, children: [t('Viber отправится только тем, кому не дойдет Push'), emailHelp] })), !isPushActive && isViberActive && isSMSActive && (_jsxs("div", { className: styles.help, children: [t('SMS отправится только тем, кому не дойдет Viber'), emailHelp] }))] }));
});
