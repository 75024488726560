import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { capitalize } from '@/utils/capitalize';
import styles from './styles.module.scss';
export const MonthDropdown = memo(({ monthDate, onChange }) => {
    const { i18n } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    const selectedMonth = moment(monthDate).get('month');
    const months = new Array(12).fill(null).map((_, monthNumber) => ({
        id: monthNumber,
        label: capitalize(moment().locale(i18n.language).set('month', monthNumber).format('MMMM')),
        isSelected: monthNumber === selectedMonth,
        onClick: () => onChange(monthNumber),
    }));
    return (_jsx(Dropdown, { items: months, onOpen: () => setIsActive(true), onClose: () => setIsActive(false), listMaxHeight: 330, placement: 'bottom', offset: [0, -10], disablePortal: true, children: _jsx("span", { className: classNames(styles.month, {
                [styles.active]: isActive,
            }), children: moment(monthDate).locale(i18n.language).format('MMMM') }) }));
});
