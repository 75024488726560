import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FilterListInput } from '@/components/FilterListInput/FilterListInput';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { findEdgesFromNode, findNodeByUUID } from '../../nodes/utils';
import commonStyles from '../styles.module.scss';
import { FiltersWarning } from '../Warnings/FiltersWarning';
import styles from './styles.module.scss';
const noop = () => undefined;
export const ScheduleForm = () => {
    var _a;
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const handleUpdate = (value) => {
        selectedNode.update({
            configuration: value,
            conditions: value.map((savedFilter) => savedFilter.rawConditions.schedule),
        });
    };
    const scheduleTypeOptions = [
        { value: 'daily', label: t('каждый день') },
        { value: 'weekly', label: t('каждую неделю') },
        { value: 'monthly', label: t('каждый месяц') },
        { value: 'yearly', label: t('каждый год') },
    ];
    const scheduleType = scheduleTypeOptions.find((item) => item.value === (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.selectedFilterCode)) || null;
    const thisNode = findNodeByUUID(flow, selectedNode.uuid);
    const edgesFromThisNode = findEdgesFromNode(flow, thisNode);
    const nonDeletableIdx = edgesFromThisNode
        .map((edge) => {
        var _a;
        const sourceHandleIdx = (_a = edge.sourceHandle) === null || _a === void 0 ? void 0 : _a.split('-')[1];
        return sourceHandleIdx ? Number(sourceHandleIdx) : null;
    })
        .filter(notNull);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.selectWrapper, children: _jsx(Autocomplete, { value: scheduleType, onChange: noop, options: scheduleTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, disabled: true }) }), _jsx(FilterListInput, { isSchedule: true, selectedCode: (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.selectedFilterCode) || '', value: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.configuration, onChange: handleUpdate, defaultOpenIdx: selectedNode.handleIdx, nonDeletableIdx: nonDeletableIdx, isDisabled: !isEditMode }), (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.configuration) === null || _a === void 0 ? void 0 : _a.length) || 0) > 1 && _jsx(FiltersWarning, {}), !!(errors === null || errors === void 0 ? void 0 : errors.configuration) && (_jsx("div", { className: classNames(commonStyles.error, commonStyles.mt12), children: errors.configuration }))] }));
};
