import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { Pencil } from '@/icons/Pencil';
import styles from './styles.module.scss';
export const TableEditRowButton = memo(({ onClick }) => {
    const handleClick = useCallback((e) => {
        e.stopPropagation();
        onClick();
    }, [onClick]);
    return (_jsx("button", { className: styles.button, onClick: handleClick, children: _jsx(Pencil, {}) }));
});
