import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { FormPromocode } from '@/forms/FormPromocode/FormPromocode';
import { useDeletePromocode } from '../Promocodes/useDeletePromocode';
import { ROUTES } from '../routes';
import { useInitialData } from './useInitialData';
import { useSavePromocode } from './useSavePromocode';
import { useSetPromocodeActive } from './useSetPromocodeActive';
export const PromocodesForm = ({ isFriend }) => {
    var _a, _b, _c;
    const params = useParams();
    const navigate = useNavigate();
    const promocodeId = params.promocodeId
        ? Number(params.promocodeId)
        : undefined;
    const section = isFriend ? 'friend' : 'common';
    const onGoToList = () => isFriend ? navigate(ROUTES.PROMOCODES_FRIEND) : navigate(ROUTES.PROMOCODES);
    const { brand } = useCurrentUser();
    const tz = (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.defaultTimezone;
    const { selectedPromocode, defaultValues, isLoading } = useInitialData(promocodeId, tz);
    const deletePromocode = useDeletePromocode({
        section,
        codeTypeShared: 0,
        onSuccess: onGoToList,
    });
    const { savePromocode, isSubmitting, error } = useSavePromocode({
        id: promocodeId,
        section,
        onSuccess: onGoToList,
    });
    const setPromocodeActive = useSetPromocodeActive(promocodeId);
    if (!brand)
        return null;
    return isLoading ? null : (_jsx(Container, { hidePageTitle: true, children: _jsx(FormPromocode, { onSubmit: savePromocode, error: error, isSubmitting: isSubmitting, defaultValues: defaultValues, currency: brand.brand.currency.symbol || brand.brand.currency.unitShort, isEdit: !!promocodeId, onDelete: () => {
                if (promocodeId && selectedPromocode)
                    deletePromocode(selectedPromocode);
            }, isFriend: section === 'friend', bonusesPendingInterval: (_c = (_b = brand.brand) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.bonusesPendingInterval, setActive: setPromocodeActive }) }));
};
