import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { AppleDetailsButton } from '@/components/wallet-cards/AppleDetailsButton/AppleDetailsButton';
import { GoogleDetailsButton } from '@/components/wallet-cards/GoogleDetailsButton/GoogleDetailsButton';
import { fgColor } from '@/utils/colors';
import { AppleWalletCardBackside } from './AppleWalletCardBackside';
import { GoogleWalletCardBackside } from './GoogleWalletCardBackside';
import styles from './styles.module.scss';
export const WalletCardPhone = ({ cardId, type = 'apple', children, backside, flipped: defaultFlipped = false, onChangeFlipped, background, color, }) => {
    const [flipped, setFlipped] = useState(defaultFlipped);
    useEffect(() => setFlipped(defaultFlipped), [defaultFlipped]);
    const toggleBackside = useCallback(() => {
        setFlipped((flipped) => !flipped);
        if (onChangeFlipped)
            onChangeFlipped(!flipped);
    }, [flipped, onChangeFlipped]);
    const cardStyles = {
        backgroundColor: background || '#fff',
        color: color || (background ? fgColor(background) : '#3a3a3a'),
        '--clickable-outline': background ? fgColor(background) : '#000',
    };
    if (type === 'notify')
        return (_jsx("div", { className: classNames(styles.wrapper, styles[type]), children: children }));
    return (_jsx("div", { className: classNames(styles.wrapper, styles[type]), children: _jsx("div", { className: classNames(styles.scrollContainer), children: _jsxs("div", { className: styles.cardWrapper, children: [type === 'apple' && (_jsx("div", { className: styles.backsideButton, children: _jsx(AppleDetailsButton, { onClick: toggleBackside }) })), type === 'apple' && (_jsx(AppleWalletCardBackside, { flipped: flipped, onClose: toggleBackside, children: flipped ? backside : null })), _jsx("div", { className: styles.card, id: cardId, style: cardStyles, children: children }), type === 'google' && (_jsx("div", { className: styles.backsideButton, children: _jsx(GoogleDetailsButton, { onClick: toggleBackside }) })), type === 'google' && (_jsx(GoogleWalletCardBackside, { flipped: flipped, onClose: toggleBackside, children: backside }))] }) }) }));
};
