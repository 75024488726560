import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
export const FormUsersName = () => {
    var _a;
    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(FormFieldRow, { label: t('Имя'), labelFor: 'name', controlSize: '250', help: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.name, children: _jsx(Controller, { name: 'name', control: control, rules: {
                maxLength: {
                    value: 200,
                    message: t('FIELD_MAX_LENGTH_ERROR', {
                        amount: numberRound(200),
                        count: 200,
                    }),
                },
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('введите имя пользователя'), hasError: !!errors.name }))) }) }));
};
