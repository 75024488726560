import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { FormArbitrationGroupPopup } from '@/forms/FormArbitrationGroup/FormArbitrationGroupPopup';
import { PromocodeSmall } from '@/icons/PromocodeSmall';
import { Draft } from '@/icons/status/Draft';
import { Description } from './helpers/Description';
import { GroupSettingsButton } from './helpers/GroupSettingsButton';
import { MaxDiscountBadge } from './helpers/MaxDiscountBadge';
import { Title } from './helpers/Title';
import { TitleRow } from './helpers/TitleRow';
import styles from './styles.module.scss';
export const ArbitrationGroupTitle = ({ serialNumber, group, newlyCreated = false, subtitle, isExpanded = false, onToggle, onEdit, onDelete, onAddSubGroup, groupCanBeDeleted, isDraft = false, isReadonly = false, currency = '', levelGap = 0, }) => {
    const { t } = useTranslation();
    const [actionsAreOpen, setActionsAreOpen] = useState(false);
    const [editFormIsOpen, setEditFormIsOpen] = useState(false);
    const isUnordered = group.guid === 'unordered';
    useEffect(() => {
        if (newlyCreated)
            setTimeout(() => setEditFormIsOpen(true), 10);
    }, [newlyCreated]);
    const actions = [
        {
            id: 'add-subgroup',
            label: t('Добавить подгруппу'),
            onClick: () => onAddSubGroup === null || onAddSubGroup === void 0 ? void 0 : onAddSubGroup(group.guid),
            shouldDisplay: group.mode !== 'exclusive' && group.children.length > 0,
        },
        {
            id: 'edit',
            label: t('Редактировать'),
            onClick: () => {
                setActionsAreOpen(true);
                setEditFormIsOpen(true);
            },
        },
        {
            id: 'delete',
            label: t('Удалить'),
            isDanger: true,
            onClick: () => onDelete === null || onDelete === void 0 ? void 0 : onDelete(group.guid),
            shouldDisplay: group.mode !== 'exclusive' && (groupCanBeDeleted === null || groupCanBeDeleted === void 0 ? void 0 : groupCanBeDeleted(group.guid)),
        },
    ];
    const actionsDropdown = (_jsx(Dropdown, { items: actions, onOpen: () => setActionsAreOpen(true), onClose: () => setActionsAreOpen(false), placement: 'bottom-start', offset: [0, -10], children: _jsx(GroupSettingsButton, { className: styles.groupSettingsButton, isActive: actionsAreOpen, isVisible: !editFormIsOpen }) }));
    const editForm = (_jsx(FormArbitrationGroupPopup, { trigger: _jsx(GroupSettingsButton, { className: styles.groupSettingsButton, isActive: editFormIsOpen, isVisible: editFormIsOpen }), defaultValues: {
            title: group.name,
            operator: group.type,
            hasConstraint: group.maxDiscountEnabled,
            constraintValue: group.maxDiscountAmount,
            constraintType: group.maxDiscountType == 0 ? 'currency' : 'percent',
        }, currency: currency, onSubmit: (values) => onEdit === null || onEdit === void 0 ? void 0 : onEdit(group.guid, values), onDelete: () => onDelete === null || onDelete === void 0 ? void 0 : onDelete(group.guid), isOpen: editFormIsOpen, onOpen: () => setEditFormIsOpen(true), onClose: () => setEditFormIsOpen(false), hideTitleInput: group.mode === 'exclusive', hideConstraintInput: group.mode === 'exclusive', hideDeleteButton: group.mode === 'exclusive' || !(groupCanBeDeleted === null || groupCanBeDeleted === void 0 ? void 0 : groupCanBeDeleted(group.guid)), disabledOperators: group.mode === 'exclusive' ? ['sum'] : undefined }));
    return (_jsxs(TitleRow, { className: classNames(styles.titleRow, {
            [styles.unordered]: isUnordered,
        }), group: group, subtitle: subtitle, isActive: actionsAreOpen || editFormIsOpen, isExpanded: isExpanded, levelGap: levelGap, children: [isUnordered ? (_jsx(PromocodeSmall, {})) : (_jsx(Title, { serialNumber: serialNumber, group: group })), _jsx(Description, { group: group, isExpanded: isExpanded, onToggle: onToggle }), _jsx(MaxDiscountBadge, { group: group, currency: currency }), !isReadonly && actionsDropdown, !isReadonly && editForm, isDraft && _jsx(Draft, { className: styles.draftIcon })] }));
};
