import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import styles from '../styles.module.scss';
export const BonusesAmountForm = ({ type = 'add', bonusesAmount: defaultBonusesAmount, bonusesLifetime: defaultBonusesLifetime, onChange, }) => {
    const { t } = useTranslation();
    const [bonusesAmount, setBonusesAmount] = useState(defaultBonusesAmount);
    const [bonusesLifetime, setBonusesLifetime] = useState(defaultBonusesLifetime);
    useEffect(() => {
        setBonusesAmount(defaultBonusesAmount);
        setBonusesLifetime(defaultBonusesLifetime);
    }, [defaultBonusesAmount, defaultBonusesLifetime]);
    const isButtonDisabled = !bonusesAmount || !bonusesLifetime;
    const handleApply = () => {
        const bonusesLifetimeNumber = Number(bonusesLifetime);
        if (!isButtonDisabled)
            onChange(bonusesAmount, bonusesLifetimeNumber > 999 ? '999' : bonusesLifetime);
    };
    return (_jsxs("div", { className: styles.formWrapper, children: [_jsxs("div", { className: styles.form, children: [_jsxs("div", { children: [_jsx("label", { className: styles.label, htmlFor: 'mailing-rewards-bonuses-amount', children: t('Количество бонусов') }), _jsx("div", { className: styles.bonusesAmountInput, children: _jsx(Input, { id: 'mailing-rewards-bonuses-amount', type: 'number', inputMode: 'numeric', preventChars: ['-', '+', '.', ',', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, value: bonusesAmount, onChange: (e) => setBonusesAmount(e.target.value), onKeyUp: (e) => {
                                        if (e.code === 'Enter')
                                            handleApply();
                                    }, autoFocus: true }) })] }), _jsxs("div", { children: [_jsx("label", { className: styles.label, htmlFor: 'mailing-rewards-bonuses-lifetime', children: t('Сгорают через') }), _jsx("div", { className: styles.bonusesLifetimeInput, children: _jsx(Input, { id: 'mailing-rewards-bonuses-lifetime', type: 'number', inputMode: 'numeric', preventChars: ['-', '+', '.', ',', 'e'], preventNegativeNumbers: true, shouldHideSpinButtons: true, value: bonusesLifetime, onChange: (e) => setBonusesLifetime(e.target.value), onBlur: (e) => {
                                        const value = Number(e.target.value);
                                        if (value > 999)
                                            setBonusesLifetime('999');
                                    }, onKeyUp: (e) => {
                                        if (e.code === 'Enter')
                                            handleApply();
                                    }, rightText: t('DAYS_EXPIRING', {
                                        count: Number(bonusesLifetime) || 0,
                                    }) }) })] })] }), _jsx("div", { className: styles.formFooter, children: _jsx(Button, { type: 'button', theme: 'primary', size: 'full-width', disabled: isButtonDisabled, onClick: handleApply, children: type === 'add' ? t('Добавить') : t('Изменить') }) })] }));
};
