import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { draggable, dropTargetForElements, } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import { attachClosestEdge, extractClosestEdge, } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ArbitrationGroupItemIcons } from '~/src/components/arbitration/ArbitrationGroup/ArbitrationGroupItemIcons';
import { DragPreview } from '~/src/components/arbitration/ArbitrationGroup/dnd/DragPreview';
import { Line } from '~/src/components/arbitration/ArbitrationGroup/helpers/Line';
import { Operator } from '~/src/components/arbitration/ArbitrationGroup/helpers/Operator';
import stylesArbitrationGroup from '~/src/components/arbitration/ArbitrationGroup/styles.module.scss';
import { Promocode } from '~/src/components/Promocode/Promocode';
import { TableRowIcon } from '~/src/components/TableRowIcon/TableRowIcon';
import { PROMOCODE_TYPE_SHARED } from '~/src/const/promocode';
import { Drag } from '~/src/icons/Drag';
import { COLOR_MAP } from '../../ArbitrationGroup/helpers/COLOR_MAP';
import styles from './styles.module.scss';
const idle = { type: 'idle' };
export const ListItem = ({ isPersonalPromocode, isOfferForm, isLastItem, entityTitle, arbitrationPriorityGuid, group, index, parentType, currency, handleChangeList, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { setValue } = useFormContext();
    const ref = useRef(null);
    const lastClosestEdge = useRef(null);
    const [closestEdge, setClosestEdge] = useState(null);
    const isCanDrag = (group === null || group === void 0 ? void 0 : group.guid) === arbitrationPriorityGuid || !!group.newEntity;
    const [draggableState, setDraggableState] = useState(idle);
    useEffect(() => {
        const element = ref.current;
        if (!element)
            return;
        if (isCanDrag)
            setValue('newItemOffsetTop', element === null || element === void 0 ? void 0 : element.offsetTop);
        const draggableCleanup = draggable({
            element,
            getInitialData() {
                return { group: Object.assign(Object.assign({}, group), { entityTitle }), index };
            },
            canDrag() {
                return isCanDrag;
            },
            onDrop() {
                setDraggableState(idle);
            },
            onGenerateDragPreview({ nativeSetDragImage }) {
                disableNativeDragPreview({ nativeSetDragImage });
            },
            onDrag(args) {
                const { location } = args;
                setDraggableState({
                    type: 'is-dragging',
                    x: location.current.input.pageX,
                    y: location.current.input.pageY,
                });
            },
        });
        const dropTargetCleanup = dropTargetForElements({
            element,
            getData({ input }) {
                return Object.assign({}, attachClosestEdge({ group, index }, {
                    element,
                    input,
                    allowedEdges: ['top', 'bottom'],
                }));
            },
            onDrag: ({ self, source }) => {
                const isSource = source.element === element;
                if (isSource) {
                    setClosestEdge(null);
                    return;
                }
                const closestEdge = extractClosestEdge(self.data);
                setClosestEdge(closestEdge);
                lastClosestEdge.current = closestEdge;
            },
            onDragLeave: () => {
                setClosestEdge(null);
            },
            onDrop({ self }) {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                setClosestEdge(null);
                if (
                // @ts-ignore
                ((_b = (_a = self.data) === null || _a === void 0 ? void 0 : _a.group) === null || _b === void 0 ? void 0 : _b.guid) === arbitrationPriorityGuid ||
                    (
                    // @ts-ignore
                    (_d = (_c = self.data) === null || _c === void 0 ? void 0 : _c.group) === null || _d === void 0 ? void 0 : _d.newEntity))
                    return;
                const offsetTop = (_e = ref.current) === null || _e === void 0 ? void 0 : _e.offsetTop;
                setValue('newItemOffsetTop', offsetTop);
                // @ts-ignore
                if (((_g = (_f = self.data) === null || _f === void 0 ? void 0 : _f.group) === null || _g === void 0 ? void 0 : _g.guid) && lastClosestEdge.current)
                    handleChangeList({
                        // @ts-ignores
                        guid: (_j = (_h = self.data) === null || _h === void 0 ? void 0 : _h.group) === null || _j === void 0 ? void 0 : _j.guid,
                        edge: lastClosestEdge.current,
                    });
                lastClosestEdge.current = null;
            },
        });
        return () => {
            draggableCleanup();
            dropTargetCleanup();
        };
    }, [parent]);
    const title = 
    // если группа
    group.kind === 'group' ? (_jsx("span", { className: styles.description, style: {
            color: COLOR_MAP[((_a = group.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.type) || 'max'],
        }, children: (_b = group.arbitrationGroup) === null || _b === void 0 ? void 0 : _b.name })) : // если промокод текущий или из списка
        (!isOfferForm && isCanDrag) || group.kind === 'promocode' ? (_jsx(Promocode, { code: group.promocodeCode || entityTitle || '', isPersonal: isCanDrag
                ? isPersonalPromocode
                : group.promocodeType !== PROMOCODE_TYPE_SHARED })) : // если акция текущая или из списка
            isCanDrag ? (entityTitle) : (group.offerTitle);
    return (_jsxs(_Fragment, { children: [_jsxs("li", { ref: ref, className: classNames(stylesArbitrationGroup.item, stylesArbitrationGroup.itemGroupWrapper, styles.listItem, {
                    [styles.itemHighlightTop]: closestEdge === 'top',
                    [styles.itemHighlightBottom]: closestEdge === 'bottom',
                    [styles.newListItemInList]: isCanDrag,
                }), style: {
                    cursor: 'auto',
                }, children: [isCanDrag && _jsx(Drag, {}), _jsxs("span", { className: styles.entityTitle, children: [_jsx(ArbitrationGroupItemIcons, { priority: group, currency: currency }), title, group.isActive === false && (_jsx(TableRowIcon, { title: t('TOOLTIP_OFFER_INACTIVE'), icon: 'pause' }))] }), !isLastItem && _jsx(Line, { initialGap: 18 }), parentType && !isLastItem && (_jsx(Operator, { levelGap: -24, operator: parentType }))] }), draggableState.type === 'is-dragging'
                ? createPortal(_jsxs(DragPreview, { className: classNames(styles.newListItem, styles.listItemPreview), x: draggableState.x, y: draggableState.y, children: [_jsx(Drag, {}), isOfferForm ? (_jsx("span", { children: entityTitle })) : (_jsx(Promocode, { code: entityTitle, isPersonal: isCanDrag
                                ? isPersonalPromocode
                                : group.promocodeType !== PROMOCODE_TYPE_SHARED }))] }), document.body)
                : null] }));
};
