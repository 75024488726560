import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
export const FormUsersAvailableTo = () => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, formState } = useFormContext();
    const { errors } = formState;
    return (_jsx(FormFieldRow, { label: t('Доступ открыт до'), labelFor: 'availableTo', controlSize: '250', help: (_a = errors.availableTo) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.availableTo, children: _jsx(Controller, { name: 'availableTo', control: control, render: ({ field }) => {
                var _a;
                return (_jsx(DateInput, { id: 'availableTo', placeholderText: t('дд.мм.гггг'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                        setValue('availableTo', date ? moment(date).format() : '', {
                            shouldValidate: true,
                        });
                    }, hasError: !!((_a = errors.availableTo) === null || _a === void 0 ? void 0 : _a.message) }));
            } }) }));
};
