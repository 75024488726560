import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { highlightText } from '@/utils/highlightText';
import styles from './styles.module.scss';
export const HighlightText = memo(({ text, search, style }) => {
    const searchWords = (Array.isArray(search) ? search : [search || ''])
        .map((w) => w.trim())
        .filter(Boolean);
    if (!searchWords.length)
        return _jsx("span", { style: style, children: text });
    const highlightedText = highlightText(text, searchWords);
    return (_jsx("span", { className: styles.highlight, style: style, dangerouslySetInnerHTML: { __html: highlightedText } }));
});
