import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { StatsPanel } from '@/components/StatsPanel/StatsPanel';
import { Table } from '@/components/Table/Table';
import { TableSkeleton } from '@/components/TableSkeleton/TableSkeleton';
import { useDashboardMetrics } from '@/hooks/useDashboardMetrics';
import { Chevron } from '@/icons/Chevron';
import { Folder } from '@/icons/Folder';
import { Sending } from '@/icons/status/Sending';
import { money, numberRound } from '@/utils/numbers';
import { getNodeLabel } from './getNodeLabel';
import styles from './styles.module.scss';
export const AnalyticsTable = memo(({ selectedMetricKeys, data, dimensions, currency, isLoadingRoot, onToggleNode, onGroupingClick, onSegmentClick, }) => {
    const { t, i18n } = useTranslation();
    const metrics = useDashboardMetrics();
    const userHasAccessTo = useUserPermissions();
    const isSegmentLinksEnabled = userHasAccessTo([
        'CLIENTS_LIST_OWN',
        'CLIENTS_LIST',
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const nestingLevels = dimensions.length;
    const getMetricLabel = (metricKey) => {
        var _a;
        return ((_a = metrics[metricKey]) === null || _a === void 0 ? void 0 : _a.label) || '';
    };
    const getMetricValue = (metricKey, values) => {
        var _a, _b;
        if (typeof (values === null || values === void 0 ? void 0 : values[metricKey]) === 'undefined' ||
            values[metricKey] === null)
            return '-';
        const value = values[metricKey];
        const scale = (_a = metrics[metricKey]) === null || _a === void 0 ? void 0 : _a.scale;
        const valueFormatted = scale === 'money'
            ? money({ input: value, currency })
            : numberRound(value, (_b = metrics[metricKey]) === null || _b === void 0 ? void 0 : _b.precision);
        return scale === 'percent' ? `${valueFormatted}%` : valueFormatted;
    };
    const getMetricColor = (metricKey) => {
        var _a;
        return (_a = metrics[metricKey]) === null || _a === void 0 ? void 0 : _a.tableColor;
    };
    const columns = [
        {
            id: 'button',
            title: (_jsx("div", { className: styles.grouping, children: _jsxs(Button, { type: 'button', className: styles.groupingButton, onClick: onGroupingClick, children: [_jsx(Folder, { className: styles.groupingButtonIcon }), t('Группировка')] }) })),
        },
        ...selectedMetricKeys.map((metricKey) => ({
            id: metricKey,
            title: getMetricLabel(metricKey),
            align: 'right',
        })),
    ];
    const rows = data.map((item, i) => ({
        id: `${item.node.label}-${i}`,
        cells: [
            _jsxs("div", { className: styles.label, style: { paddingLeft: (item.level - 1) * 30 }, children: [item.level > 0 && nestingLevels > item.level && (_jsx(_Fragment, { children: item.node.loading ? (_jsx(Sending, { className: styles.loader })) : (_jsx("button", { className: styles.expandButton, onClick: () => onToggleNode(item.node), children: _jsx(Chevron, { className: classNames(styles.chevron, {
                                    [styles.flipped]: item.node.expand,
                                }) }) })) })), _jsx("span", { children: getNodeLabel({
                            isSegmentLinksEnabled,
                            item,
                            dimensions,
                            lang: i18n.language,
                            onSegmentClick,
                            t,
                        }) })] }, 'label'),
            ...selectedMetricKeys.map((metricKey) => (_jsx("span", { style: { color: getMetricColor(metricKey) }, children: getMetricValue(metricKey, item.node.values) }, metricKey))),
        ],
    }));
    return (_jsx(StatsPanel, { className: styles.wrapper, hasBorders: false, children: isLoadingRoot ? (_jsx(TableSkeleton, { count: 4 })) : (_jsx(Table, { needAdaptive: false, className: styles.table, columns: columns, rows: rows })) }));
});
