import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from '@/components/DateInput/DateInput';
import { InputInfo } from '@/components/InputInfo/InputInfo';
import styles from './DateRange.module.scss';
import { Wrapper } from './Wrapper';
const parseDate = (date) => date ? moment(date, 'DD.MM.YYYY').toDate() : null;
const formatDate = (date) => date ? moment(date).format('DD.MM.YYYY') : undefined;
export const DateRange = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t, i18n } = useTranslation();
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeDateFrom = (dateFrom) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { dateFrom: formatDate(dateFrom) }) }));
    };
    const handleChangeDateTo = (dateTo) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { dateTo: formatDate(dateTo) }) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = ((_a = draft.value) === null || _a === void 0 ? void 0 : _a.dateFrom) || ((_b = draft.value) === null || _b === void 0 ? void 0 : _b.dateTo);
    const startDate = parseDate((_c = draft.value) === null || _c === void 0 ? void 0 : _c.dateFrom);
    const endDate = parseDate((_d = draft.value) === null || _d === void 0 ? void 0 : _d.dateTo);
    return (_jsxs(Wrapper, { onBack: onBack, label: item.label, operator: ((_e = draft.value) === null || _e === void 0 ? void 0 : _e.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, children: [_jsxs("div", { className: styles.item, children: [_jsx("label", { className: classNames(styles.label, styles[i18n.language]), htmlFor: `${item.id}-from`, children: t('с') }), _jsx("div", { className: styles.input, children: _jsx(DateInput, { id: `${item.id}-from`, selected: startDate, onChange: handleChangeDateFrom, selectsStart: true, startDate: startDate, endDate: endDate, maxDate: endDate }) })] }), _jsxs("div", { className: styles.item, children: [_jsx("label", { className: classNames(styles.label, styles[i18n.language]), htmlFor: `${item.id}-to`, children: t('до') }), _jsx("div", { className: styles.input, children: _jsx(DateInput, { id: `${item.id}-to`, selected: parseDate((_f = draft.value) === null || _f === void 0 ? void 0 : _f.dateTo), onChange: handleChangeDateTo, selectsEnd: true, startDate: startDate, endDate: endDate, minDate: startDate }) })] }), _jsx(InputInfo, { children: t('Укажите хотя бы одно значение') })] }));
});
