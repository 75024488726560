import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Back } from '@/icons/Back';
import { Eye } from '@/icons/Eye';
import { Draft } from '@/icons/status/Draft';
import { Pause } from '@/icons/status/Pause';
import { useDeleteFlow } from '../../Flows/useDeleteFlow';
import styles from './styles.module.scss';
export const FlowTitle = ({ flow, onRenameClick, hasStatusIcon = false, hasSetEditModeOffButton = false, onSetEditModeOff, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    const handleGoBack = () => {
        navigate(ROUTES.FLOWS);
    };
    const { deleteFlow, isDeletingFlow } = useDeleteFlow(handleGoBack);
    return (_jsxs("div", { className: styles.titleWrapper, children: [_jsx("button", { className: styles.back, type: 'button', onClick: handleGoBack, children: _jsx(Back, {}) }), _jsx("div", { className: styles.title, children: flow.title }), userHasFlowsManage && (_jsx(Dropdown, { triggerClassName: styles.dropdown, placement: 'bottom-start', items: [
                    {
                        id: 'rename',
                        label: t('Переименовать'),
                        onClick: () => onRenameClick(),
                        isDisabled: isDeletingFlow,
                    },
                    {
                        id: 'delete',
                        label: t('Удалить'),
                        onClick: () => deleteFlow(flow),
                        isDanger: true,
                        isDisabled: isDeletingFlow,
                    },
                ] })), hasStatusIcon && flow.status === 'draft' && (_jsxs("div", { className: styles.status, children: [_jsx(Draft, {}), t('Черновик')] })), hasStatusIcon && flow.status === 'pause' && (_jsxs("div", { className: styles.status, children: [_jsx(Pause, {}), t('На паузе')] })), hasSetEditModeOffButton && (_jsxs("button", { className: styles.linkButton, type: 'button', onClick: onSetEditModeOff, children: [flow.status === 'active' && _jsx(Eye, {}), flow.status === 'pause' && _jsx(Pause, {}), flow.status === 'active'
                        ? t('К запущенной версии')
                        : t('К версии на паузе'), flow.status === 'pause' && _jsx(Eye, {})] }))] }));
};
