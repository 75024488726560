import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { getPriorityId } from '../ArbitrationEditor/utils';
import { ArbitrationGroupItem } from './ArbitrationGroupItem';
import { ArbitrationGroupTitle } from './ArbitrationGroupTitle';
import { EmptyGroupPlaceholder } from './helpers/EmptyGroupPlaceholder';
import { Parenthesis } from './helpers/Parenthesis';
import styles from './styles.module.scss';
const DISPLAY_SERIAL_NUMBERS = false;
export const ArbitrationGroup = forwardRef(({ serialNumber, group, subtitle, onEdit, onDelete, onAddSubGroup, isExpanded, onToggle, groupCanBeDeleted, newlyCreatedGuid, draftGuids, isReadonly = false, currency, level = 0, }, ref) => {
    const LEVEL_GAP = 32;
    const levelGap = LEVEL_GAP * level;
    return (_jsxs("div", { ref: ref, "data-priority-expanded": isExpanded === null || isExpanded === void 0 ? void 0 : isExpanded(group), className: classNames(styles.group, {
            [styles.empty]: !group.children.length,
            [styles.collapsed]: !(isExpanded === null || isExpanded === void 0 ? void 0 : isExpanded(group)),
            [styles.unordered]: group.guid === 'unordered',
        }), children: [_jsx(ArbitrationGroupTitle, { serialNumber: serialNumber, group: group, newlyCreated: group.guid === newlyCreatedGuid, subtitle: subtitle, isExpanded: isExpanded === null || isExpanded === void 0 ? void 0 : isExpanded(group), onToggle: () => onToggle === null || onToggle === void 0 ? void 0 : onToggle(group), onEdit: onEdit, onDelete: onDelete, onAddSubGroup: onAddSubGroup, groupCanBeDeleted: groupCanBeDeleted, isDraft: draftGuids === null || draftGuids === void 0 ? void 0 : draftGuids.includes(group.guid), isReadonly: isReadonly, currency: currency, levelGap: levelGap }), group.children.length > 0 ? (_jsxs("div", { className: styles.items, children: [_jsx(Parenthesis, { group: group, levelGap: levelGap }), group.children.map((child, i) => {
                        const serialNumber = group.type !== 'max' && group.guid !== 'unordered'
                            ? i + 1
                            : undefined;
                        return (_jsx(ArbitrationGroupItem, { serialNumber: DISPLAY_SERIAL_NUMBERS ? serialNumber : undefined, priority: child, parent: group, renderGroup: (group) => (_jsx(ArbitrationGroup, { serialNumber: DISPLAY_SERIAL_NUMBERS ? serialNumber : undefined, group: group, onEdit: onEdit, onDelete: onDelete, groupCanBeDeleted: groupCanBeDeleted, onAddSubGroup: onAddSubGroup, isExpanded: isExpanded, onToggle: onToggle, newlyCreatedGuid: newlyCreatedGuid, draftGuids: draftGuids, currency: currency, level: level + 1 })), isLast: i === group.children.length - 1, level: level, levelGap: levelGap, currency: currency }, `${child.kind}:${getPriorityId(child)}:${i}`));
                    })] })) : (_jsx(EmptyGroupPlaceholder, { className: styles.emptyGroupPlaceholderWrapper, placeholderClassName: styles.emptyGroupPlaceholder, levelGap: levelGap }))] }));
});
