import moment from 'moment';
export const formatDate = (date, comma = false) => {
    return date
        ? moment(date).format(comma ? 'DD.MM.YYYY, HH:mm' : 'DD.MM.YYYY HH:mm')
        : '';
};
export const formatDateShort = (date) => {
    return date ? moment(date).format('DD.MM.YYYY') : '';
};
export const formatDateTimeFirst = (date) => {
    return date ? moment(date).format('HH:mm DD.MM.YYYY') : '';
};
export const formatDay = (day, locale, isShort) => {
    return moment()
        .locale(locale)
        .set('day', day)
        .format(isShort ? 'dd' : 'dddd')
        .toLowerCase();
};
export const formatFromDateToNow = (locale, date) => {
    return moment().locale(locale).from(moment(date), true);
};
export const diffDays = (dateA, dateB) => {
    return Math.abs(moment(dateA).diff(moment(dateB), 'days'));
};
