var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { EmailEditor, } from '@/components/EmailEditor/EmailEditor';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { FormMailingEmailAbandonedCard } from './FormMailingEmailAbandonedCard';
import { FormMailingEmailDroppedPrice } from './FormMailingEmailDroppedPrice';
import { FormMailingEmailRecommendations } from './FormMailingEmailRecommendations';
export const FormMailingEmailBody = ({ type, unlayerUrl, unlayerProjectId, brand, templatesData, uploadImage, refreshEmailPreview, labelWidth, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { control, setValue, watch, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const [, setIsLoadingPreview] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const channels = watch('channels');
    const isRequired = channels === null || channels === void 0 ? void 0 : channels.includes('email');
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const validateUnsubscribeLink = (value) => {
        if (!(channels === null || channels === void 0 ? void 0 : channels.includes('email')))
            return true;
        if (!value)
            return true;
        const link = /{{\s*brand\.settings\.landingBase\s*}}\/m\/unsub\/{{\s*msgid\s*}}/;
        const variable = '!' + t('Отписаться');
        if (!link.test(value) && value.indexOf(variable) === -1)
            return t('MAILING.CREATE.MISSING_UNSUB_LINK');
    };
    const editorContext = {
        channel: 'email',
        brandName: brand === null || brand === void 0 ? void 0 : brand.name,
        brandWebsite: (_a = brand === null || brand === void 0 ? void 0 : brand.settings) === null || _a === void 0 ? void 0 : _a.website,
        brandHotlinePhone: (_b = brand === null || brand === void 0 ? void 0 : brand.settings) === null || _b === void 0 ? void 0 : _b.hotlinePhone,
        hasWalletFeature: brandHasWalletFeature(brand),
        triggerType: watch('triggerType'),
        rewards: watch('rewards'),
        includeRecommendations: watch('includeRecommendations'),
        recommendationType: watch('recommendationType'),
    };
    const mailingId = watch('id');
    const mailingName = watch('name');
    const previewUrl = watch('emailPreviewImageUrl');
    return (_jsxs(FormFieldRow, { label: t('Содержимое'), labelFor: 'emailDesign', labelWidth: labelWidth, controlSize: '450', hasError: !!errors.emailDesign, help: (_c = errors.emailDesign) === null || _c === void 0 ? void 0 : _c.message, helpSize: '300', isRequired: isRequired, children: [_jsx(FormMailingEmailDroppedPrice, {}), _jsx(FormMailingEmailAbandonedCard, {}), _jsx(FormMailingEmailRecommendations, { hasRecommendationsFeature: (_d = brand === null || brand === void 0 ? void 0 : brand.features) === null || _d === void 0 ? void 0 : _d.recommendations }), _jsx(Controller, { name: 'emailDesign', control: control, rules: {
                    validate: {
                        required: validateTrimValue,
                        hasUnsubscribeLink: validateUnsubscribeLink,
                    },
                }, render: ({ field }) => (_jsx(EmailEditor, { unlayerUrl: unlayerUrl, unlayerProjectId: unlayerProjectId, initialDesign: field.value || '', previewUrl: previewUrl || '', 
                    // isLoadingPreview={isLoadingPreview}
                    onChange: (data) => {
                        setValue('emailDesign', (data === null || data === void 0 ? void 0 : data.design) || null, {
                            shouldValidate: isSubmitted,
                            shouldDirty: true,
                        });
                        setValue('emailBody', (data === null || data === void 0 ? void 0 : data.html) || null);
                        if (data === null)
                            setValue('emailPreviewImageUrl', null);
                        else if (refreshEmailPreview) {
                            setIsLoadingPreview(true);
                            refreshEmailPreview(data.html)
                                .then((url) => setValue('emailPreviewImageUrl', url))
                                .finally(() => setIsLoadingPreview(false));
                        }
                    }, hasError: !!errors.emailDesign, templatesData: templatesData, mailingName: mailingName, mailingId: mailingId, brandGlobalKey: brand === null || brand === void 0 ? void 0 : brand.globalKey, context: editorContext, uploadImage: (file) => __awaiter(void 0, void 0, void 0, function* () {
                        if (!uploadImage)
                            return '';
                        const result = yield uploadImage({ file, forEmail: true });
                        return result.url;
                    }), isDisabled: (type === 'auto' && !userHasAccessTo('AUTO_MAILINGS_MANAGE')) ||
                        (type === 'manual' && !userHasAccessTo('MANUAL_MAILINGS_MANAGE')) })) })] }));
};
