import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { LogoLarge } from '@/icons/wallet-card/google/LogoLarge';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { ImageHelp } from '../../helpers/ImageHelp';
import styles from '../../styles.module.scss';
export const BannerGoogle = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setGoogleCardIsFlipped, markReadyToSave, uploadImage, setIsElementClick, googleBanner, setGoogleBanner, setAppleBanner, } = useWalletCardForm();
    const [applyBothBanner, setApplyBothBanner] = useState(false);
    const handleFocus = () => {
        setActiveCardType('google');
        setGoogleCardIsFlipped(false);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (banner) => {
        setGoogleBanner((googleBanner) => (Object.assign(Object.assign({}, googleBanner), banner)));
    };
    const handleChangeAppleBanner = (image, response) => {
        if (applyBothBanner)
            setAppleBanner((banner) => (Object.assign(Object.assign({}, banner), { image, imageUploadResponse: response, imageRejectReason: '' })));
        setApplyBothBanner(false);
    };
    const handleApplyImage = (image) => {
        handleChange({ image, imageLoading: !!image, imageRejectReason: '' });
        if (!image) {
            markReadyToSave();
            return;
        }
        uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(image).then((response) => {
            handleChangeAppleBanner(image, response);
            handleChange({ imageUploadResponse: response });
        }).finally(() => {
            handleChange({ imageLoading: false });
            markReadyToSave();
        });
    };
    return (_jsxs("div", { className: classNames(styles.control, styles.smallGap), children: [_jsx("label", { className: styles.label, htmlFor: 'google-banner', children: t('Баннер') }), _jsxs("div", { className: styles.uploadWrapper, children: [_jsx(ImageCropInput, { id: 'google-banner', value: googleBanner.image, onChange: handleApplyImage, onReject: (reason) => {
                            handleChange({ image: null, imageRejectReason: reason || '' });
                        }, previewWidth: 280, previewHeight: 106, constraints: {
                            mimeType: 'image/png',
                            extensions: ['.png'],
                            maxSize: 10000000,
                            dimensions: { width: 1125, height: 433 },
                        }, outputMimeType: 'png', description: 'PNG, ' + t('от') + ' ' + t('1125×433, до 10мб'), hasError: !!googleBanner.imageRejectReason, cropModalIcon: _jsx(LogoLarge, {}), cropModalTitle: t('Баннер для Google'), cropModalFooter: _jsx(Checkbox, { checked: applyBothBanner, onChange: (e) => setApplyBothBanner(e.target.checked), theme: 'dark', children: t('и для Apple') }), onFocus: handleFocus }), _jsx(ImageHelp, { dimensions: '1125\u00D7433', rejectReason: googleBanner.imageRejectReason, isLoading: googleBanner.imageLoading })] })] }));
};
