import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import styles from '../styles.module.scss';
export const BillingSections = memo(({ isView, exportsListItems, shortListItems, handleSetValue }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, formState: { errors }, } = useFormContext();
    return (_jsx("div", { id: 'billing', children: _jsxs(FormFieldset, { fieldsetClassName: styles.fieldset, titleClassName: styles.fieldsetTitle, title: t('Биллинг'), children: [_jsx(FormFieldRow, { label: t('Детализация'), labelFor: 'BILLING_VIEW', help: (_a = errors.BILLING_VIEW) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.BILLING_VIEW, controlSize: '154', children: _jsx(Controller, { name: 'BILLING_VIEW', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'BILLING_VIEW', value: shortListItems.find((item) => item.value === field.value) ||
                                shortListItems[0], onChange: (item) => {
                                handleSetValue('BILLING_VIEW', item);
                            }, options: shortListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) }), _jsx(FormFieldRow, { label: t('Счета'), labelFor: 'BILLING_INVOICES', help: (_b = errors.BILLING_INVOICES) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.BILLING_INVOICES, controlSize: '154', children: _jsx(Controller, { name: 'BILLING_INVOICES', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'BILLING_INVOICES', value: exportsListItems.find((item) => item.value === field.value) || exportsListItems[0], onChange: (item) => {
                                handleSetValue('BILLING_INVOICES', item);
                            }, options: exportsListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })] }) }));
});
