import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Purchase } from '@/icons/Purchase';
import styles from '../../styles.module.scss';
import { getPurchaseContent } from './purchaseContent';
// удаление покупки
export const purchasePurchaseRevert = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handlePurchase } = entityHandlers;
    const { purchaseId, purchaseDeleted } = event.params || {};
    const pId = !purchaseDeleted ? purchaseId : undefined;
    return {
        color: 'red',
        Icon: _jsx(Purchase, {}),
        title: (_jsxs(_Fragment, { children: [t('Отменена (или удалена)'), "\u00A0", _jsx("span", { className: pId && handlePurchase ? styles.underline : '', onClick: () => {
                        if (pId)
                            handlePurchase === null || handlePurchase === void 0 ? void 0 : handlePurchase(pId);
                    }, children: t('покупка') })] })),
        content: getPurchaseContent(args),
    };
};
