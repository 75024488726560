import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@/app/layout/AppLayout/PageTitle';
import { useCurrentUser } from '@/app/useCurrentUser';
import { DataCompletenessPanelContainer } from '@/components/DataCompletenessPanel/DataCompletenessPanelContainer';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { DemographicsPanelContainer } from '@/components/DemographicsPanel/DemographicsPanelContainer';
import { DependencePanelContainer } from '@/components/DependencePanel/DependencePanelContainer';
import { PointsStatusPanelContainer } from '@/components/PointsStatusPanel/PointsStatusPanelContainer';
import { SegmentStatsPanelContainer } from '@/components/SegmentStatsPanel/SegmentStatsPanelContainer';
import { StatsMetricsContainer } from '@/components/StatsMetric/StatsMetricsContainer';
import { ROUTES } from '../routes';
import styles from './styles.module.scss';
export const Dashboard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand } = useCurrentUser();
    const isProfitVisible = brand === null || brand === void 0 ? void 0 : brand.brand.showProfit;
    const currency = (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || (brand === null || brand === void 0 ? void 0 : brand.brand.currency.unitShort) || '';
    if (!brand)
        return null;
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx(PageTitle, {}), _jsx(DatePeriodSelectContainer, { leftElement: _jsx("div", { className: styles.description, children: t('Данные сравниваются с предыдущим периодом') }) }), _jsx("div", { className: classNames(styles.grid, styles.cols4), children: _jsx(StatsMetricsContainer, { isProfitVisible: isProfitVisible, currency: currency }) }), _jsxs("div", { className: classNames(styles.grid, styles.cols2), children: [_jsx(DependencePanelContainer, { currency: currency }), _jsx(SegmentStatsPanelContainer, { isProfitVisible: isProfitVisible, currency: currency, onSegmentClick: (segmentId) => {
                            navigate(ROUTES.CLIENTS, { state: { segmentId } });
                        } })] }), _jsxs("div", { className: classNames(styles.grid, styles.cols3), children: [_jsx(PointsStatusPanelContainer, {}), _jsx(DataCompletenessPanelContainer, {}), _jsx(DemographicsPanelContainer, {})] })] }));
};
