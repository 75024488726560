import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormPurchaseWithDetailsAmont } from '@/forms/FormPurchaseWithDetails/FormPurchaseWithDetailsAmont';
import { FormPurchaseWithDetailsTabs } from '@/forms/FormPurchaseWithDetails/FormPurchaseWithDetailsTabs';
import { buildClientFullName } from '@/utils/getClientFullName';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import { UserSolid } from '~/src/icons/UserSolid';
import styles from '../FormPurchaseWithDetails/styles.module.scss';
import { FormOrderWithDetailsWarning } from './FormOrderWithDetailsWarning';
import { useOrderDetails } from './useOrderDetails';
const noop = () => { };
export const FormOrderWithDetails = memo(({ shops = [], currency, selectedOrder = null, selectedClient = null, handleOpenClientModal, isFetching = false, }) => {
    var _a;
    const { t } = useTranslation();
    const { data, isLoading } = useOrderDetails(selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.id);
    const client = data ? data.order.client : selectedClient;
    const clientFullName = buildClientFullName(client);
    const isList = !!(data === null || data === void 0 ? void 0 : data.order.items.length);
    return (_jsxs("div", { children: [_jsxs("div", { className: styles.formContent, children: [client && (_jsxs("div", { className: styles.clientInfo, children: [_jsx("span", { children: t('Клиент') }), _jsxs("span", { className: styles.client, children: [_jsx(UserSolid, {}), clientFullName ? `${clientFullName}, ` : '', client.phoneNumber
                                        ? formatPhoneNumber(client.phoneNumber)
                                        : '', !client.isDeleted && (handleOpenClientModal === null || handleOpenClientModal === void 0 ? void 0 : handleOpenClientModal(client.id))] })] })), _jsxs("div", { className: styles.controls, children: [_jsx(FormFieldRow, { label: t('Дата заказа'), labelFor: 'executedAt', controlSize: '150', labelClassName: styles.fieldRowLabel, className: styles.fieldRow, hasNoMargin: true, children: _jsx(DateInput, { id: 'executedAt', selected: (data === null || data === void 0 ? void 0 : data.order.executedAt)
                                        ? moment(data.order.executedAt).toDate()
                                        : null, onChange: noop, endYear: 2040, disabled: true }) }), _jsx(FormFieldRow, { label: t('Место покупки'), labelFor: 'shopId', isRequired: true, labelClassName: styles.fieldRowLabel, className: styles.fieldRow, controlClassName: styles.shopIdField, hasNoMargin: true, children: _jsx(Autocomplete, { id: 'shopId', placeholder: t('выберите место покупки'), value: shops.find((shop) => shop.id === (data === null || data === void 0 ? void 0 : data.order.shop.id)) || null, onChange: noop, options: shops, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasResetButton: false, disabled: true }) })] })] }), _jsx(FormPurchaseWithDetailsTabs, { isLoading: isFetching, data: data === null || data === void 0 ? void 0 : data.order, currency: currency }), !isList && !isFetching && _jsx("div", { className: styles.divider }), !isLoading && !isFetching && (_jsxs(_Fragment, { children: [_jsx(FormOrderWithDetailsWarning, {}), _jsx(FormPurchaseWithDetailsAmont, { currency: currency, promocodeAmountSum: (data === null || data === void 0 ? void 0 : data.order.promocodeDiscount) || undefined, totalDiscount: (data === null || data === void 0 ? void 0 : data.order.totalDiscount) || undefined, giftCardDiscount: ((_a = data === null || data === void 0 ? void 0 : data.order) === null || _a === void 0 ? void 0 : _a.giftCardDiscount) || undefined, totalAmount: (data === null || data === void 0 ? void 0 : data.order.totalAmount) || undefined, bonusesApplied: (data === null || data === void 0 ? void 0 : data.order.bonusesApplied) || 0, paidAmount: (data === null || data === void 0 ? void 0 : data.order.remainingAmount) || undefined, bonusesCollected: (data === null || data === void 0 ? void 0 : data.order.bonusesCollected) || 0 })] }))] }));
});
