import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { InputInfo } from '@/components/InputInfo/InputInfo';
import { getMonthName } from '@/utils/getMonthName';
import styles from './styles.module.scss';
export const DateSelectInput = memo(({ value, onChange, startYear = 1900, hasHelpText = false, onKeyDown }) => {
    const { t } = useTranslation();
    const days = useMemo(() => {
        return new Array(32).fill(null).map((_, i) => ({
            id: i ? i.toString() : '',
            label: i ? i.toString() : '',
            highlight: i ? i.toString() : '–',
        }));
    }, []);
    const months = useMemo(() => {
        return new Array(13).fill(null).map((_, i) => ({
            id: i ? i.toString() : '',
            label: t(getMonthName(i.toString())),
            highlight: t(getMonthName(i.toString())) || '–',
        }));
    }, [t]);
    const years = useMemo(() => {
        const currentYear = new Date().getFullYear();
        return new Array(currentYear - startYear + 1).fill(null).map((_, i) => ({
            id: i ? (currentYear - i).toString() : '',
            label: i ? (currentYear - i).toString() : '',
            highlight: i ? (currentYear - i).toString() : '–',
        }));
    }, []);
    const handleChangeDay = useCallback((day) => {
        onChange(Object.assign(Object.assign({}, value), { day: Number(day === null || day === void 0 ? void 0 : day.id) || undefined }));
    }, [value, onChange]);
    const handleChangeMonth = useCallback((month) => {
        onChange(Object.assign(Object.assign({}, value), { month: (month === null || month === void 0 ? void 0 : month.id) || undefined }));
    }, [value, onChange]);
    const handleChangeYear = useCallback((year) => {
        onChange(Object.assign(Object.assign({}, value), { year: Number(year === null || year === void 0 ? void 0 : year.id) || undefined }));
    }, [value, onChange]);
    return (_jsxs("div", { children: [_jsxs("div", { className: styles.wrapper, children: [_jsx(Autocomplete, { className: styles.day, placeholder: t('день'), options: days, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, getOptionHighlight: (option) => option.highlight, value: days.find((d) => { var _a; return d.id === ((_a = value.day) === null || _a === void 0 ? void 0 : _a.toString()); }) || null, onChange: handleChangeDay, hasResetButton: false, asSelect: true, onKeyDown: onKeyDown, disablePortal: true }), _jsx(Autocomplete, { className: styles.month, placeholder: t('месяц'), options: months, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, getOptionHighlight: (option) => option.highlight, value: months.find((m) => m.id === value.month) || null, onChange: handleChangeMonth, hasResetButton: false, asSelect: true, onKeyDown: onKeyDown, disablePortal: true }), _jsx(Autocomplete, { className: styles.year, placeholder: t('год'), options: years, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.label, getOptionHighlight: (option) => option.highlight, value: years.find((y) => { var _a; return y.id === ((_a = value.year) === null || _a === void 0 ? void 0 : _a.toString()); }) || null, onChange: handleChangeYear, hasResetButton: false, asSelect: true, onKeyDown: onKeyDown, disablePortal: true })] }), hasHelpText && (_jsx(InputInfo, { children: t('Укажите хотя бы одно значение') }))] }));
});
