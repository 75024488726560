import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { PageTitle } from '@/app/layout/AppLayout/PageTitle';
import styles from './styles.module.scss';
export const Container = memo(({ hidePageTitle = false, notMobileRightPadding = false, className, topElementClassName, topElement, children, }) => {
    return (_jsxs("div", { className: classNames(styles.container, className, {
            [styles.notMobileRightPadding]: notMobileRightPadding,
        }), children: [!hidePageTitle && _jsx(PageTitle, {}), topElement && (_jsx("div", { className: classNames(styles.top, topElementClassName), children: topElement })), children] }));
});
