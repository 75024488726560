import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import styles from './styles.module.scss';
export const GoogleWalletCardBackside = ({ children, onClose, flipped }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const stopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const handleWheel = useCallback((e) => {
        const element = e.currentTarget;
        const isTop = element.scrollTop <= 0;
        if (e.deltaY > 0)
            setIsExpanded(true);
        if (e.deltaY < 0 && isTop)
            setIsExpanded(false);
    }, []);
    return (_jsx("div", { className: classNames(styles.backsideWrapper, {
            [styles.flipped]: flipped,
        }), onClick: onClose, children: _jsx("div", { className: classNames(styles.backside, {
                [styles.expanded]: isExpanded,
            }), onClick: stopPropagation, onWheel: handleWheel, children: children }) }));
};
