import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
export const FormMailingTelegramButton = ({ labelWidth }) => {
    var _a;
    const { t } = useTranslation();
    const { control, watch, trigger, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const telegramUrl = watch('telegramUrl');
    const isRequired = (channels === null || channels === void 0 ? void 0 : channels.includes('telegram')) && !!telegramUrl;
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const maxLength = 20;
    useEffect(() => {
        if (isSubmitted)
            trigger('telegramButton');
    }, [isSubmitted, telegramUrl]);
    return (_jsx(FormFieldRow, { label: t('Кнопка'), labelFor: 'telegramButton', labelWidth: labelWidth, controlSize: '200', help: (_a = errors.telegramButton) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.telegramButton, isRequired: isRequired, children: _jsx(Controller, { name: 'telegramButton', control: control, rules: {
                validate: validateTrimValue,
                maxLength: (channels === null || channels === void 0 ? void 0 : channels.includes('telegram'))
                    ? {
                        value: maxLength,
                        message: t('FIELD_MAX_LENGTH_ERROR', {
                            amount: numberRound(maxLength),
                            count: maxLength,
                        }),
                    }
                    : undefined,
            }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'telegramButton', placeholder: t('введите текст кнопки'), hasError: !!errors.telegramButton, autoFocus: true }))) }) }));
};
