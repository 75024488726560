import { jsx as _jsx } from "react/jsx-runtime";
import { User } from '@/icons/User';
import { getClientUpdateContent } from './clientUpdateContent';
import { ClientUpdateTitle } from './clientUpdateTitle';
// разблокирован
export const clientUpdate5 = (args) => {
    const { event, translate: t } = args;
    const { operatorName } = event.params || {};
    return {
        color: 'red',
        Icon: _jsx(User, { size: 20, fill: '#DA5845' }),
        title: (_jsx(ClientUpdateTitle, Object.assign({}, args, { children: operatorName
                ? t('UNBLOCKED_BY_EMPLOYEE', { operatorName })
                : t('Разблокирован сотрудником') }))),
        content: getClientUpdateContent(Object.assign(Object.assign({}, args), { hiddenParams: ['operator'] })),
    };
};
