import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import styles from '../styles.module.scss';
export const AnalyticsSection = memo(({ isView, longListItems, shortListItems, exportsListItems, handleSetValue, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const { control, formState: { errors }, } = useFormContext();
    return (_jsx("div", { id: 'analytics', children: _jsxs(FormFieldset, { fieldsetClassName: styles.fieldset, titleClassName: styles.fieldsetTitle, title: t('Аналитика'), children: [_jsx(FormFieldRow, { label: t('Сводка'), labelFor: 'DASHBOARD_VIEW', help: (_a = errors.DASHBOARD_VIEW) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.DASHBOARD_VIEW, controlSize: '154', children: _jsx(Controller, { name: 'DASHBOARD_VIEW', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'DASHBOARD_VIEW', value: shortListItems.find((item) => item.value === field.value) ||
                                shortListItems[0], onChange: (item) => {
                                handleSetValue('DASHBOARD_VIEW', item);
                            }, options: shortListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) }), _jsx(FormFieldRow, { label: t('Smart RFM'), labelFor: 'SMARTRFM_VIEW', help: (_b = errors.SMARTRFM_VIEW) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.SMARTRFM_VIEW, controlSize: '154', children: _jsx(Controller, { name: 'SMARTRFM_VIEW', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'SMARTRFM_VIEW', value: shortListItems.find((item) => item.value === field.value) ||
                                shortListItems[0], onChange: (item) => {
                                handleSetValue('SMARTRFM_VIEW', item);
                            }, options: shortListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) }), _jsx(FormFieldRow, { label: t('Отчеты'), labelFor: 'reports', help: (_c = errors.reports) === null || _c === void 0 ? void 0 : _c.message, hasError: !!errors.reports, controlSize: '154', children: _jsx(Controller, { name: 'reports', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'reports', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('reports', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) }), _jsx(FormFieldRow, { label: t('Выгрузка'), labelFor: 'EXPORTS', help: (_d = errors.EXPORTS) === null || _d === void 0 ? void 0 : _d.message, hasError: !!errors.EXPORTS, controlSize: '154', children: _jsx(Controller, { name: 'EXPORTS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'EXPORTS', value: exportsListItems.find((item) => item.value === field.value) || exportsListItems[0], onChange: (item) => {
                                handleSetValue('EXPORTS', item);
                            }, options: exportsListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })] }) }));
});
