import { jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { capitalize } from '@/utils/capitalize';
import { numberRound } from '@/utils/numbers';
import { BACKGROUND_COLOR_MAP, COLOR_MAP } from './COLOR_MAP';
import styles from './MaxDiscountBadge.module.scss';
export const MaxDiscountBadge = ({ group, currency, }) => {
    const { t } = useTranslation();
    if (group.mode === 'exclusive')
        return null;
    if (!group.maxDiscountEnabled)
        return null;
    if (!group.maxDiscountAmount)
        return null;
    return (_jsxs("div", { className: styles.badge, style: {
            color: COLOR_MAP[group.type],
            backgroundColor: BACKGROUND_COLOR_MAP[group.type],
        }, children: [capitalize(t('не более')), " ", numberRound(group.maxDiscountAmount), group.maxDiscountType === 0 ? currency : '%'] }));
};
