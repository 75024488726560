import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useClientFilters } from '@/services/filters/data/useClientFilters';
export const FormMailingRecipients = ({ extraFields = [], counters = [], labelWidth, }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, getValues, watch, trigger } = useFormContext();
    const { errors, isSubmitting, isSubmitted } = formState;
    const recipientAllClients = watch('recipientAllClients');
    const recipientFilters = watch('recipientFilters');
    const handleEnableAllClients = () => {
        setValue('recipientAllClients', true, { shouldDirty: true });
    };
    const handleDisableAllClients = () => {
        setValue('recipientAllClients', false, { shouldDirty: true });
    };
    const handleUpdateRecipientFilters = (value) => {
        setValue('recipientFilters', value, { shouldValidate: true });
    };
    const filtersTree = useClientFilters(extraFields, counters, [
        {
            type: 'custom',
            id: 'all',
            label: t('Все клиенты'),
            disabled: recipientAllClients,
            onClick: handleEnableAllClients,
        },
    ], recipientFilters);
    const customBadges = [];
    if (recipientAllClients)
        customBadges.push({ id: 'all', label: t('Все клиенты') });
    const validate = (value) => {
        const { recipientAllClients } = getValues();
        const isValid = recipientAllClients ? true : value && value.length > 0;
        if ((isSubmitting || isSubmitted) && !isValid)
            return 'error';
        return true;
    };
    useEffect(() => {
        trigger('recipientFilters');
    }, [recipientAllClients]);
    return (_jsx(FormFieldRow, { labelWidth: labelWidth, help: errors.recipientFilters ? t('Задайте получателей рассылки') : undefined, hasError: !!errors.recipientFilters, children: _jsx(Controller, { name: 'recipientFilters', control: control, rules: { validate }, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: filtersTree, value: field.value || [], onUpdate: handleUpdateRecipientFilters, customBadges: customBadges, onRemoveCustomBadge: handleDisableAllClients, hasHangingOperator: false, shouldDisplayActions: !recipientAllClients, shouldDisplayFilterBadges: !recipientAllClients, buttonRef: field.ref })) }) }));
};
