import { jsx as _jsx } from "react/jsx-runtime";
import { Edit } from '@/icons/Edit';
import { getClientUpdateContent } from './clientUpdateContent';
import { ClientUpdateTitle } from './clientUpdateTitle';
// изменен клиент
export const clientUpdate1 = (args) => {
    const { event, translate: t } = args;
    const { operatorName } = event.params || {};
    return {
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        title: (_jsx(ClientUpdateTitle, Object.assign({}, args, { children: operatorName
                ? t('UPDATED_BY_EMPLOYEE', { operatorName })
                : t('Изменены данные сотрудником') }))),
        content: getClientUpdateContent(Object.assign(Object.assign({}, args), { hiddenParams: ['operator'] })),
    };
};
