import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
export const FormMailingSMSSender = ({ brand, labelWidth, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const smsSenders = ((_a = brand === null || brand === void 0 ? void 0 : brand.mailing) === null || _a === void 0 ? void 0 : _a.smsSenders) || [];
    const shouldDisplaySelect = smsSenders.length > 1;
    const senderSelect = (_jsx(Controller, { name: 'smsSender', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'smsSender', value: smsSenders.find((item) => item.id === field.value) || null, onChange: (sender) => {
                setValue('smsSender', sender === null || sender === void 0 ? void 0 : sender.id, { shouldDirty: true });
            }, options: smsSenders, getOptionKey: (option) => option.id, getOptionLabel: (option) => option.name, hasResetButton: false, asSelect: true }))) }));
    return shouldDisplaySelect ? (_jsx(FormFieldRow, { label: t('От кого'), labelFor: 'smsSender', labelWidth: labelWidth, controlSize: '300', children: senderSelect })) : null;
};
