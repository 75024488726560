var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddOperatorRoleMutation, useDeleteOperatorMutation, useDeleteOperatorRoleMutation, useGetOperatorListQuery, useGetOperatorRoleListQuery, useUpdateOperatorRoleMutation, } from '@/api/query/operator';
import { useGetUserQuery } from '@/api/query/user';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { Container } from '@/components/Container/Container';
import { useToastContext } from '@/components/Toast/ToastContext';
import { UsersTable } from './UsersTable/UsersTable';
export const Users = () => {
    var _a, _b;
    const [selectedUser, changeSelectedUser] = useState();
    const [selectRole, setSelectRole] = useState(null);
    const [userModalIsOpen, toggleUserModal] = useState(false);
    const [roleModalIsOpen, toggleRoleModal] = useState(false);
    const { t } = useTranslation();
    const addToast = useToastContext();
    const operatorListQuery = useGetOperatorListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const userQuery = useGetUserQuery();
    const operatorRoleList = useGetOperatorRoleListQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const [addOperator] = useAddOperatorRoleMutation();
    const [updateOperator] = useUpdateOperatorRoleMutation();
    const [deleteOperatorRole] = useDeleteOperatorRoleMutation();
    // Хендлеры для ролей ********
    const handleSubmitRole = useCallback((payload_1, ...args_1) => __awaiter(void 0, [payload_1, ...args_1], void 0, function* (payload, isEdit = false, isView = false) {
        const result = !isEdit || isView
            ? yield addOperator(payload)
            : yield updateOperator(payload);
        if ('error' in result)
            return;
        isEdit
            ? addToast({ type: 'success', message: t('Роль редактирована') })
            : addToast({ type: 'success', message: t('Роль добавлена') });
        toggleRoleModal(false);
        setSelectRole(null);
    }), [addOperator]);
    const handleDeleteRole = useCallback((id_1, name_1, ...args_1) => __awaiter(void 0, [id_1, name_1, ...args_1], void 0, function* (id, name, withoutOperators = false) {
        if (withoutOperators) {
            yield confirm({
                confirmation: t('Можно удалить только такую роль, которая не назначена ни на одного пользователя'),
                agreeButtonSize: 'extra-large',
                showDisagreeLabel: false,
            });
            return;
        }
        const ok = yield confirm({
            confirmation: t('Удалить роль {{name}}?', { name }),
            agreeLabel: t('Удалить роль'),
            disagreeLabel: t('Отмена'),
            isDanger: true,
        });
        if (!ok)
            return;
        const result = yield deleteOperatorRole({ id });
        if ('error' in result)
            return;
        toggleRoleModal(false);
        setSelectRole(null);
        addToast({ type: 'success', message: t('Роль удалена') });
    }), [addOperator]);
    const [deleteUser] = useDeleteOperatorMutation();
    // Хендлеры для пользователй ********
    const handleDeleteUser = useCallback((user) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const name = ((_a = user.name) === null || _a === void 0 ? void 0 : _a.trim()) || '';
        const ok = yield confirm({
            confirmation: name
                ? t('Удалить пользователя {{name}}?', { name })
                : t('Удалить пользователя?'),
            agreeLabel: t('Удалить пользователя'),
            disagreeLabel: t('Отмена'),
            isDanger: true,
        });
        if (!ok)
            return;
        const result = yield deleteUser({ id: user.id });
        if ('error' in result)
            return;
        addToast({ message: t('Пользователь удален'), type: 'success' });
    }), [deleteUser, t]);
    const handleEditUser = useCallback((user) => {
        changeSelectedUser(user);
        toggleUserModal(true);
    }, []);
    // Тоглы для модалок ********
    const handleToggleRoleModal = useCallback(() => {
        toggleRoleModal((prevValue) => !prevValue);
    }, []);
    const handleToggleUserModal = useCallback(() => {
        if (!userModalIsOpen)
            changeSelectedUser(undefined);
        toggleUserModal(!userModalIsOpen);
    }, [userModalIsOpen]);
    const isLoading = operatorListQuery.isLoading ||
        operatorRoleList.isLoading ||
        userQuery.isLoading;
    const isFetching = operatorListQuery.isFetching ||
        operatorRoleList.isFetching ||
        userQuery.isFetching;
    return (_jsx(Container, { notMobileRightPadding: true, children: _jsx(UsersTable, { isLoading: isLoading, isFetching: isFetching, 
            // флаги и тоглеры модальных окон
            userModalIsOpen: userModalIsOpen, roleModalIsOpen: roleModalIsOpen, onToggleUserModal: handleToggleUserModal, onToggleRoleModal: handleToggleRoleModal, 
            // списки для таблицы
            users: operatorListQuery.data, roles: operatorRoleList.data, 
            // методы создания/редактирования/удаления
            onDeleteUser: handleDeleteUser, onDeleteRole: handleDeleteRole, onEditUser: handleEditUser, onSubmitUser: handleToggleUserModal, onSubmitRole: handleSubmitRole, 
            // выбранный пользователь
            sessionUserId: (_b = (_a = userQuery.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.id, selectedUser: selectedUser, 
            // выбранная роль
            setSelectRole: setSelectRole, selectRole: selectRole }) }));
};
