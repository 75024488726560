import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import './App.scss';
import './normalize.scss';
import './filter-operators.scss';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { Login } from '@/app/pages/Login/Login';
import { Protect } from '@/app/pages/Protect';
import { Redirect } from '@/app/pages/Redirect/Redirect';
import { ROUTES } from '@/app/pages/routes';
import { useChatSupport } from '@/hooks/useChatSupport/useChatSupport';
import { useSentry } from '@/sentry/useSentry';
import { AppHttpErrors } from '../layout/AppHttpErrors/AppHttpErrors';
import { Layout } from '../layout/AppLayout/Layout';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import { useRouteName } from '../pages/useRouteName';
import { useCurrentUser } from '../useCurrentUser';
import { useUserPermissions } from '../useUserPermissions';
import { routesList } from './routesList';
export const App = () => {
    const { t } = useTranslation();
    const routeName = useRouteName();
    const { brand } = useCurrentUser();
    useChatSupport();
    useSentry();
    const userHasAccessTo = useUserPermissions();
    const appRoutes = routesList(!!(brand === null || brand === void 0 ? void 0 : brand.brand.settings.inn), userHasAccessTo).map(({ path, element, iaAvailable }) => {
        if (!iaAvailable)
            return null;
        return _jsx(Route, { path: path, element: element }, path);
    });
    return (_jsxs(_Fragment, { children: [!!routeName && (_jsx(Helmet, { children: _jsxs("title", { children: ["MAXMA - ", t(routeName)] }) })), _jsx(AppHttpErrors, {}), _jsxs(Routes, { children: [_jsxs(Route, { element: _jsx(Protect, { from: 'guest' }), children: [_jsx(Route, { path: ROUTES.ROOT, element: _jsx(Redirect, {}) }), _jsx(Route, { element: _jsx(Layout, {}), children: appRoutes }), _jsx(Route, { path: '*', element: _jsx(Redirect, {}) })] }), _jsx(Route, { element: _jsx(Protect, { from: 'user' }), children: _jsx(Route, { path: ROUTES.LOGIN, element: _jsx(Login, {}) }) }), _jsx(Route, { path: ROUTES.PASSWORD_RESET, element: _jsx(ResetPassword, {}) })] })] }));
};
