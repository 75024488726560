import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import styles from './styles.module.scss';
export const ToogleNodeButton = memo(({ isExpanded, isDisabled, onClick }) => {
    return (_jsx("button", { className: classNames(styles.toggle, {
            [styles.expanded]: isExpanded,
            [styles.disabled]: isDisabled,
        }), onClick: onClick, type: 'button', disabled: isDisabled, children: isExpanded ? minus : plus }));
});
const plus = (_jsxs("svg", { width: '10', height: '10', viewBox: '0 0 10 10', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: [_jsx("path", { d: 'M5.70005 1.03333C5.70005 0.646729 5.38665 0.333328 5.00005 0.333328C4.61345 0.333328 4.30005 0.646729 4.30005 1.03333V3.29988C4.30005 3.85217 3.85233 4.29988 3.30005 4.29988H1.0335C0.646897 4.29988 0.333496 4.61328 0.333496 4.99988C0.333496 5.38648 0.646897 5.69988 1.0335 5.69988H3.30005C3.85233 5.69988 4.30005 6.1476 4.30005 6.69988V8.96666C4.30005 9.35326 4.61345 9.66666 5.00005 9.66666C5.38665 9.66666 5.70005 9.35326 5.70005 8.96666V6.69988C5.70005 6.1476 6.14776 5.69988 6.70005 5.69988H8.96683C9.35343 5.69988 9.66683 5.38648 9.66683 4.99988C9.66683 4.61328 9.35343 4.29988 8.96683 4.29988H6.70005C6.14776 4.29988 5.70005 3.85217 5.70005 3.29988V1.03333Z', fill: 'currentColor' }), _jsx("path", { d: 'M5.70005 1.03333C5.70005 0.646729 5.38665 0.333328 5.00005 0.333328C4.61345 0.333328 4.30005 0.646729 4.30005 1.03333V3.29988C4.30005 3.85217 3.85233 4.29988 3.30005 4.29988H1.0335C0.646897 4.29988 0.333496 4.61328 0.333496 4.99988C0.333496 5.38648 0.646897 5.69988 1.0335 5.69988H3.30005C3.85233 5.69988 4.30005 6.1476 4.30005 6.69988V8.96666C4.30005 9.35326 4.61345 9.66666 5.00005 9.66666C5.38665 9.66666 5.70005 9.35326 5.70005 8.96666V6.69988C5.70005 6.1476 6.14776 5.69988 6.70005 5.69988H8.96683C9.35343 5.69988 9.66683 5.38648 9.66683 4.99988C9.66683 4.61328 9.35343 4.29988 8.96683 4.29988H6.70005C6.14776 4.29988 5.70005 3.85217 5.70005 3.29988V1.03333Z', fill: 'currentColor' })] }));
const minus = (_jsx("svg", { width: '10', height: '2', viewBox: '0 0 10 2', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', children: _jsx("path", { d: 'M4.29956 0.299927H1.03301C0.646409 0.299927 0.333008 0.613327 0.333008 0.999927C0.333008 1.38653 0.646409 1.69993 1.03301 1.69993H4.29956H5.69956H8.96634C9.35294 1.69993 9.66634 1.38653 9.66634 0.999927C9.66634 0.613328 9.35294 0.299927 8.96634 0.299927H5.69956L4.29956 0.299927Z', fill: '#fff' }) }));
