import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { rejectUploadReason } from '@/components/FileUploadInput/rejectUploadReason';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import styles from '../styles.module.scss';
export const FormMailingTelegramImage = ({ uploadImage, labelWidth }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, setError, formState } = useFormContext();
    const { errors } = formState;
    const width = 300;
    const height = 160;
    return (_jsx(FormFieldRow, { className: styles.imageRow, label: t('Картинка'), labelFor: 'telegramImage', labelWidth: labelWidth, help: (_a = errors.telegramImage) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.telegramImage, children: _jsx(Controller, { name: 'telegramImage', control: control, render: ({ field }) => (_jsx(ImageCropInput, { id: 'telegramImage', className: styles.telegramImage, value: field.value || null, onChange: (file) => {
                    setValue('telegramImage', file, {
                        shouldValidate: true,
                        shouldDirty: true,
                    });
                    if (file && uploadImage)
                        uploadImage({ file }).then((payload) => {
                            setValue('telegramImageData', payload);
                        });
                }, onReject: (reason) => {
                    const message = rejectUploadReason(reason);
                    if (message)
                        setError('telegramImage', { message: t(message) });
                    setValue('telegramImage', null);
                }, previewWidth: width, previewHeight: height, maintainPreviewAspectRatio: 'height', description: 'JPEG, ' + t('до 1 Мб'), constraints: {
                    mimeType: 'image/jpeg',
                    extensions: ['.jpg', '.jpeg'],
                    maxSize: 1000000, // 1 MB
                    shape: 'full',
                }, outputMimeType: 'jpeg', cropModalTitle: t('Картинка'), theme: 'outlined', hasError: !!errors.telegramImage })) }) }));
};
