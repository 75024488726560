var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage } from '@/api/query';
import { useResetPasswordMutation } from '@/api/query/operator';
import { useGetUserQuery, useLoginMutation } from '@/api/query/user';
import { useToastContext } from '@/components/Toast/ToastContext';
import { ROUTES } from '../routes';
export const useResetPassword = () => {
    const [formError, setFormError] = useState('');
    const { token = '', expirationDate } = useParams();
    const addToast = useToastContext();
    const { t } = useTranslation();
    const isExpired = new Date(Number(expirationDate) * 1000) < new Date();
    const [resetPassword] = useResetPasswordMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const [sendCredentials] = useLoginMutation();
    const { refetch: loadUser } = useGetUserQuery(undefined, { skip: true });
    const handleSubmit = (_a) => __awaiter(void 0, [_a], void 0, function* ({ password }) {
        var _b;
        const result = yield resetPassword({
            newPassword: password,
            token,
        });
        if ('error' in result)
            setFormError(getErrorMessage(result.error));
        else {
            addToast({ type: 'success', message: t('Пароль успешно изменен') });
            yield sendCredentials({
                username: result.data.username,
                password: password,
            });
            loadUser();
            navigate(ROUTES.ROOT, {
                replace: true,
                state: { from: (_b = location.state) === null || _b === void 0 ? void 0 : _b.from },
            });
        }
    });
    return {
        isExpired,
        formError,
        handleSubmit,
    };
};
