var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useRef } from 'react';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Lock } from '@/icons/Lock';
import { CheckMark } from './CheckMark';
import styles from './styles.module.scss';
export const Checkbox = memo((_a) => {
    var { tooltip, isIndeterminate = false, hasError = false, preventScrollToInput = false, preventInnerClicks = false, hasContentLeft = false, isTransparent = false, isLocked = false, align = 'baseline', tooltipClassName, tooltipPlacement = 'top', theme, children, className, disabled } = _a, props = __rest(_a, ["tooltip", "isIndeterminate", "hasError", "preventScrollToInput", "preventInnerClicks", "hasContentLeft", "isTransparent", "isLocked", "align", "tooltipClassName", "tooltipPlacement", "theme", "children", "className", "disabled"]);
    const input = useRef(null);
    const label = useRef(null);
    const handleLabelClick = useCallback((e) => {
        var _a;
        if (preventScrollToInput) {
            (_a = input.current) === null || _a === void 0 ? void 0 : _a.click();
            e.preventDefault();
        }
        if (preventInnerClicks)
            if (e.target !== input.current && e.target !== label.current)
                e.preventDefault();
    }, [preventScrollToInput, preventInnerClicks]);
    const content = tooltip ? (_jsx(HelpTooltip, { tooltipClassName: tooltipClassName, placement: tooltipPlacement, title: tooltip, isSmall: true, children: children })) : (children);
    return (_jsxs("label", { ref: label, className: classNames(styles.label, {
            [styles.empty]: !children,
            [styles.disabled]: disabled,
            [styles.contentLeft]: hasContentLeft,
            [styles.transparent]: isTransparent,
            [styles.dark]: theme === 'dark',
        }, styles[align], className), onClick: handleLabelClick, children: [_jsx("input", Object.assign({ ref: input, className: classNames(styles.input, {
                    [styles.indeterminate]: isIndeterminate,
                }), type: 'checkbox', disabled: disabled || isLocked }, props)), hasContentLeft && content, isLocked ? (_jsx("span", { className: styles.lock, children: _jsx(Lock, {}) })) : (_jsx("span", { className: classNames(styles.icon, {
                    [styles.error]: hasError,
                    [styles.noPointerEvents]: preventInnerClicks,
                }), children: isIndeterminate ? (_jsx("div", { className: classNames(styles.check, styles.dot) })) : (_jsx(CheckMark, { className: styles.check })) })), !hasContentLeft && content] }));
});
