var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useDeleteMailingMutation } from '@/api/query/mailings';
import { confirm } from '@/components/ConfirmDialog/ConfirmDialog';
import { useToastContext } from '@/components/Toast/ToastContext';
import { getEmailDraftKey } from '@/utils/getEmailDraftKey';
import { removeFromLS } from '@/utils/LS';
import styles from './styles.module.scss';
export const useDeleteMailing = (brandGlobalKey, { onSuccess } = {}) => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [deleteMailing] = useDeleteMailingMutation();
    return (mailing) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmAgreeLabel = t('Удалить рассылку');
        const successMessage = t('Рассылка удалена');
        const yes = yield confirm({
            confirmation: (_jsxs(Trans, { i18nKey: 'DELETE_MAILING_TITLE', values: mailing, children: ["\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0443", ' ', _jsx("span", { className: styles.deleteTitle, children: mailing.name }), "?"] })),
            text: mailing.flowId || mailing.flow ? (_jsx("div", { className: styles.deleteDescription, children: _jsx(Trans, { i18nKey: 'DELETE_MAILING_DESCRIPTION', children: "\u0420\u0430\u0441\u0441\u044B\u043B\u043A\u0430 \u0431\u0443\u0434\u0435\u0442 \u0443\u0434\u0430\u043B\u0435\u043D\u0430 \u0438 \u0438\u0437 \u0410\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0447\u0435\u0441\u043A\u0438\u0445 \u0440\u0430\u0441\u0441\u044B\u043B\u043E\u043A, \u0438 \u0438\u0437 \u0426\u0435\u043F\u043E\u0447\u0435\u043A" }) })) : undefined,
            agreeLabel: confirmAgreeLabel,
            isDanger: true,
        });
        if (!yes)
            return;
        const result = yield deleteMailing(mailing.id);
        if ('error' in result)
            addToast({ type: 'error', message: getErrorMessage(result.error) });
        else {
            removeFromLS(getEmailDraftKey(mailing.id, brandGlobalKey));
            addToast({ type: 'success', message: successMessage });
            if (onSuccess)
                onSuccess();
        }
    });
};
