import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HighlightText } from '@/components/HighlightText/HighlightText';
import { SearchInput } from '@/components/SearchInput/SearchInput';
import { CheckCircle } from '@/icons/CheckCircle';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const MailingRewardsList = memo(({ title, items, onItemClick, selectedItemId, disabledItemIds = [], hasSearch = true, currency, }) => {
    const { t } = useTranslation();
    const [searchText, setSearchText] = useState('');
    const getItems = () => {
        if (searchText)
            return items.filter((item) => {
                const label = item.title || item.name || item.code || '';
                return label.toLowerCase().includes(searchText.toLowerCase());
            });
        return items;
    };
    const getItemLabel = (item) => {
        let label = item.title || item.name || item.code || '';
        if (item.amount)
            label = `${label} (${money({ input: item.amount, currency })})`;
        if (searchText)
            return _jsx(HighlightText, { text: label, search: searchText });
        return label;
    };
    return items.length > 0 ? (_jsxs("div", { className: styles.listContainer, children: [!!title && _jsx("div", { className: styles.listTitle, children: title }), hasSearch && (_jsx("div", { className: styles.searchWrapper, children: _jsx(SearchInput, { placeholder: t('Найти'), value: searchText, onChange: setSearchText, debounceDelay: 0 }) })), _jsx("ul", { className: styles.list, children: getItems().map((item) => {
                    const isDisabled = disabledItemIds.includes(item.id);
                    return (_jsx("li", { className: classNames(styles.listItem, {
                            [styles.disabled]: isDisabled,
                            [styles.selected]: item.id === selectedItemId,
                        }), onClick: () => {
                            if (!isDisabled)
                                onItemClick(item.id);
                        }, children: _jsxs("span", { className: styles.listItemLabel, children: [getItemLabel(item), isDisabled && _jsx(CheckCircle, { className: styles.checkIcon })] }) }, item.id));
                }) })] })) : null;
});
