import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import '@/charts/register';
import 'moment/locale/ru';
import './sentry/initSentry';
import './utils/fixGoogleTranslateIssue';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { App } from '@/app/App/App';
import { ToastContextProvider } from '@/components/Toast/ToastContext';
import i18n from '@/i18n';
import { AppModals } from '@/modals/AppModals';
import { store } from '@/store';
import { BreadcrumbsContextProvider } from './app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
window.ENV = process.env.NODE_ENV || 'production';
const entryBlock = document.getElementById('app');
const renderFunction = (entryBlock === null || entryBlock === void 0 ? void 0 : entryBlock.hasChildNodes())
    ? ReactDOM.hydrate
    : ReactDOM.render;
renderFunction(_jsx(React.StrictMode, { children: _jsx(Provider, { store: store, children: _jsx(ToastContextProvider, { children: _jsx(BreadcrumbsContextProvider, { children: _jsx(I18nextProvider, { i18n: i18n, children: _jsxs(HashRouter, { children: [_jsx(App, {}), _jsx(AppModals, {})] }) }) }) }) }) }), entryBlock);
