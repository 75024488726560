export const DESCRIPTION_MAP = {
    max: 'та, что выгоднее клиенту из',
    priority: 'та, что выше в очереди из',
    sum: 'каждая по очереди из',
};
export const DESCRIPTION_SHORT_TITLE_MAP = {
    max: 'та, что выгоднее клиенту',
    priority: 'та, что выше в очереди',
    sum: 'каждая по очереди',
};
