import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { AccordionSublistTable } from '@/components/AccordionSublistTable/AccordionSublistTable';
import { Button } from '@/components/Button2/Button';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Modal } from '@/components/Modal/Modal';
import styles from '../styles.module.scss';
import { useRowsForSublistTable } from './useRowsForSublistTable';
export const MailingFlowsModal = memo(({ isOpen, onClose, from = '', mailings = [] }) => {
    var _a, _b;
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const columnsModal = [
        {
            id: 'trigger',
            title: t('Триггеры'),
            width: 439,
            align: 'left',
        },
        {
            id: 'channels',
            title: t('Каналы'),
            align: 'left',
            width: 120,
        },
        {
            id: 'date',
            title: t('Дата'),
            align: 'left',
            width: 173,
        },
    ];
    const [checkedItems, setChecked] = useState(((_a = location.state) === null || _a === void 0 ? void 0 : _a.mailingIds) || []);
    const currentTriggerType = useRef((_b = location.state) === null || _b === void 0 ? void 0 : _b.trigger);
    const itemActionOnChange = (id, triggerType) => {
        if (!checkedItems.length)
            currentTriggerType.current = triggerType;
        if (currentTriggerType.current !== triggerType) {
            setChecked([id]);
            currentTriggerType.current = triggerType;
        }
        else {
            if (checkedItems.includes(id))
                return setChecked([...checkedItems.filter((item) => id !== item)]);
            setChecked([...checkedItems, id]);
        }
    };
    const sublistItemAction = (id, triggerType, label) => (_jsx(Checkbox, { className: styles.sublistItemAction, align: 'center', checked: checkedItems.includes(id), onChange: () => itemActionOnChange(id, triggerType), children: label }));
    const rows = useRowsForSublistTable(mailings, checkedItems, sublistItemAction);
    return (_jsx(Modal, { isOnMountFocus: true, isOpen: isOpen, onClose: () => {
            setChecked([]);
            onClose();
        }, title: _jsxs("div", { children: [_jsx("div", { className: styles.flowsTitle, children: t('Соединение в цепочку') }), _jsx("div", { className: styles.flowsDescription, children: t('Выберите рассылки с одним триггером, чтобы увидеть их на холсте') })] }), size: '770', hasNoPadding: true, className: styles.modal, titleClassName: styles.modalTitle, bodyClassName: styles.modalBody, footer: _jsxs("div", { className: styles.modalFooter, children: [_jsx("span", { children: t('MAILINGS_SELECTED', { count: checkedItems.length }) }), _jsx(Button, { theme: 'primary', size: 'extra-large', onClick: () => {
                        if (typeof currentTriggerType.current === 'undefined' ||
                            !checkedItems.length)
                            return;
                        navigate(ROUTES.FLOWS_CREATE, {
                            state: {
                                from,
                                trigger: currentTriggerType.current,
                                mailingIds: checkedItems,
                            },
                        });
                    }, children: t('Соединить в цепочку') })] }), children: _jsx(AccordionSublistTable, { rows: rows, headItems: columnsModal, sublistItemLeftOffset: 26 }) }));
});
