import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Input } from '@/components/Input/Input';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import { TShirt } from '@/icons/TShirt';
import styles from '../styles.module.scss';
export const FormMailingEmailRecommendations = ({ hasRecommendationsFeature }) => {
    const { t, i18n } = useTranslation();
    const { control, setValue, watch, formState } = useFormContext();
    const { errors } = formState;
    const channels = watch('channels');
    const includeRecommendations = watch('includeRecommendations');
    const recommendationCount = watch('recommendationCount') || 0;
    const recommendationTypeOptions = [
        {
            value: 1,
            label: t('NEW_PRODUCTS', { count: recommendationCount }),
        },
        {
            value: 2,
            label: t('LAST_VIEWED_PRODUCTS', { count: recommendationCount }),
        },
        {
            value: 3,
            label: t('BESTSELLERS', { count: recommendationCount }),
        },
    ];
    const lockIcon = (_jsx(Tooltip, { title: _jsx("span", { className: styles.tooltip, children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') }), placement: 'right', children: _jsx("span", { className: styles.lock, children: _jsx(Lock, {}) }) }));
    return (_jsxs("div", { className: styles.emailBlockPanel, children: [_jsxs(Checkbox, { className: classNames(styles.recommendationCheckbox, {
                    [styles.disabled]: !hasRecommendationsFeature,
                }), align: 'center', checked: hasRecommendationsFeature ? includeRecommendations : false, disabled: !hasRecommendationsFeature, onChange: (e) => {
                    if (hasRecommendationsFeature)
                        setValue('includeRecommendations', e.target.checked);
                }, children: [_jsx(TShirt, { className: styles.icon }), t('Блок с товарными рекомендациями'), _jsx(HelpTooltip, { className: styles.emailBlockTooltip, title: t('EMAIL_INCLUDE_ITEMS_LIST'), placement: 'right' }), !hasRecommendationsFeature && lockIcon] }), hasRecommendationsFeature && includeRecommendations && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.emailBlockTitle, children: t('Показывать в блоке') }), _jsxs("div", { className: styles.recommendations, children: [_jsx(Controller, { name: 'recommendationCount', control: control, rules: {
                                    required: (channels === null || channels === void 0 ? void 0 : channels.includes('email'))
                                        ? { value: true, message: t('FIELD_REQUIRED_ERROR') }
                                        : undefined,
                                    min: (channels === null || channels === void 0 ? void 0 : channels.includes('email'))
                                        ? { value: 1, message: t('MIN_ERROR_MESSAGE', { amount: 1 }) }
                                        : undefined,
                                    max: (channels === null || channels === void 0 ? void 0 : channels.includes('email'))
                                        ? {
                                            value: 20,
                                            message: t('MAX_ERROR_MESSAGE', { amount: 20 }),
                                        }
                                        : undefined,
                                }, render: ({ field }) => (_jsx("div", { className: styles.recommendationCountInput, children: _jsx(Input, Object.assign({}, field, { id: 'recommendationCount', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.recommendationCount, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true })) })) }), _jsx(Controller, { name: 'recommendationType', control: control, render: ({ field }) => (_jsx("div", { className: styles.recommendationTypeInput, children: _createElement(Autocomplete, Object.assign({}, field, { id: 'recommendationType', key: `recommendationType-${i18n.language}`, value: recommendationTypeOptions.find((item) => item.value === field.value) || null, onChange: (value) => {
                                            setValue('recommendationType', value === null || value === void 0 ? void 0 : value.value);
                                        }, options: recommendationTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true })) })) })] }), _jsx("div", { className: styles.emailBlockHelp, children: t('Добавьте блок в тело письма в редакторе') })] }))] }));
};
