import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Chevron } from '@/icons/Chevron';
import styles from './styles.module.scss';
export const DropdownChevron = ({ className, isActive, }) => {
    const chevron = (_jsx(Chevron, { className: classNames(styles.chevron, {
            [styles.flipped]: isActive,
        }) }));
    return className ? _jsx("span", { className: className, children: chevron }) : chevron;
};
