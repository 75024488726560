import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Movements } from '@/icons/rfm/Movements';
import { Search } from '@/icons/Search';
import styles from './styles.module.scss';
export const RFMMovementsPanelEmpty = ({ type, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.empty, children: [_jsx("div", { className: styles.emptyIcon, children: type === 'no-search-results' ? _jsx(Search, { size: 24 }) : _jsx(Movements, {}) }), _jsx("div", { className: styles.emptyTitle, children: type === 'no-search-results'
                    ? t('Ничего не найдено')
                    : t('Недостаточно данных') }), _jsx("div", { className: styles.emptyDescription, children: type === 'no-search-results'
                    ? t('Попробуйте изменить фильтры')
                    : t('MAXMA накапливает историю сегментов и скоро выведет график') })] }));
};
