import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// начислены бонусы за покупку друга
export const bonus9 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handlePurchase } = entityHandlers;
    const { amount, referralPurchaseId, referralPurchaseDeleted } = event.params || {};
    const hasPurchaseLink = !!referralPurchaseId && !referralPurchaseDeleted && !!handlePurchase;
    return {
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
        title: (_jsxs(BonusTitle, Object.assign({}, args, { children: [amount ? t('BONUSES_FOR', { count: amount }) : t('Бонусы за'), "\u00A0", _jsx("span", { className: hasPurchaseLink ? styles.underline : '', onClick: () => {
                        if (hasPurchaseLink)
                            handlePurchase === null || handlePurchase === void 0 ? void 0 : handlePurchase(referralPurchaseId);
                    }, children: t('покупку друга') })] }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['referralPurchase'] })),
    };
};
