import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import styles from './styles.module.scss';
export const TableHead = ({ columns, needAdaptive, }) => {
    return (_jsx("thead", { children: _jsx("tr", { children: columns.map((col, i) => (_jsxs("th", { className: classNames(col.className, {
                    [styles.center]: col.align === 'center',
                    [styles.right]: col.align === 'right',
                    [styles.withTooltip]: !!col.tooltip,
                    [styles.theadNeedAdaptive]: needAdaptive,
                }), colSpan: col.span, children: [col.tooltip ? (_jsx(HelpTooltip, { title: col.tooltip, placement: 'top', children: col.title })) : (col.title), i === 0 && needAdaptive && _jsx("div", { className: styles.gradientLeft })] }, col.id))) }) }));
};
