import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { HighlightText } from '@/components/HighlightText/HighlightText';
import { LoadMoreDataButton } from '@/components/LoadMoreDataButton/LoadMoreDataButton';
import { SearchInput } from '@/components/SearchInput/SearchInput';
import styles from './styles.module.scss';
export const CheckboxSearchList = memo(({ items: providedItems, selectedItemIds, disabledItemIds = [], onChange, hasUndefinedItem = false, undefinedItemId = '0', hasMoreData = false, isLoadingMoreData = false, onLoadMoreData, search = '', onChangeSearch, searchDebounceDelay, hasSearch = false, hasSelectAll = hasSearch, }) => {
    const { t } = useTranslation();
    const items = hasUndefinedItem
        ? [{ id: undefinedItemId, label: t('Не задано') }, ...providedItems]
        : providedItems;
    const handleChangeSelectedItems = useCallback((itemId) => {
        if (selectedItemIds.includes(itemId))
            onChange(selectedItemIds.filter((id) => id !== itemId));
        else
            onChange([...selectedItemIds, itemId]);
    }, [onChange, selectedItemIds]);
    const handleLoadMoreData = useCallback(() => {
        if (onLoadMoreData)
            onLoadMoreData();
    }, [onLoadMoreData]);
    const filteredItems = items.filter((i) => selectedItemIds.includes(i.id) ||
        i.label.toLowerCase().includes(search.trim().toLowerCase()));
    const enabledItems = filteredItems.filter((i) => !disabledItemIds.includes(i.id));
    const allSelected = enabledItems.length > 0 && selectedItemIds.length === enabledItems.length;
    const indeterminate = selectedItemIds.length > 0 && selectedItemIds.length < enabledItems.length;
    const handleChangeAll = useCallback((e) => {
        const checked = e.target.checked;
        if (checked)
            onChange(enabledItems.map((i) => i.id));
        else
            onChange(selectedItemIds.filter((id) => !enabledItems.map((i) => i.id).includes(id)));
    }, [onChange, selectedItemIds, enabledItems]);
    return (_jsxs("div", { children: [hasSearch && onChangeSearch && (_jsx("div", { className: styles.search, children: _jsx(SearchInput, { placeholder: t('Найти'), value: search, onChange: onChangeSearch, debounceDelay: searchDebounceDelay }) })), _jsxs("div", { className: styles.list, children: [hasSelectAll && (_jsx("div", { className: styles.item, children: _jsx(Checkbox, { checked: allSelected, isIndeterminate: indeterminate, onChange: handleChangeAll, disabled: items.length === disabledItemIds.length, preventScrollToInput: true, children: t('(Выделить все)') }) }, 'select-all')), filteredItems.map((item) => (_jsx("div", { className: classNames(styles.item, {
                            [styles.itemWithIcon]: !!item.icon,
                        }), children: _jsx(Checkbox, { checked: selectedItemIds.includes(item.id), onChange: () => handleChangeSelectedItems(item.id), disabled: disabledItemIds.includes(item.id), preventScrollToInput: true, children: item.view || (_jsxs("span", { className: classNames({
                                    [styles.labelWrapper]: !!item.icon,
                                }), children: [!!item.icon && (_jsx("span", { className: styles.itemIcon, children: item.icon })), _jsx(HighlightText, { text: item.label, search: search })] })) }) }, item.id)))] }), hasMoreData && (_jsx(LoadMoreDataButton, { className: styles.loadMoreButton, onClick: handleLoadMoreData, isLoading: isLoadingMoreData }))] }));
});
