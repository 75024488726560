import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, useRef } from 'react';
import { Popup } from '@/components/Popup/Popup';
import { FormArbitrationGroup } from './FormArbitrationGroup';
export const FormArbitrationGroupPopup = ({ trigger, defaultValues, currency, onSubmit, onDelete, hideTitleInput = false, hideConstraintInput = false, hideDeleteButton = false, disabledOperators, isOpen = false, onOpen, onClose, }) => {
    const triggerRef = useRef(null);
    const handleOpenPopup = () => {
        onOpen === null || onOpen === void 0 ? void 0 : onOpen();
    };
    const handleClosePopup = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    const handleTogglePopup = () => {
        if (isOpen)
            handleClosePopup();
        else
            handleOpenPopup();
    };
    const handleSubmit = (values) => {
        onSubmit(values);
        handleClosePopup();
    };
    return (_jsxs(_Fragment, { children: [cloneElement(trigger, {
                ref: triggerRef,
                onClick: handleTogglePopup,
            }), _jsx(Popup, { isOpen: isOpen, onClose: handleClosePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, placement: 'bottom', offset: [0, 10], hasTail: true, isRounded: true, children: _jsx(FormArbitrationGroup, { defaultValues: defaultValues, currency: currency, onSubmit: handleSubmit, onDelete: onDelete, hideTitleInput: hideTitleInput, hideConstraintInput: hideConstraintInput, hideDeleteButton: hideDeleteButton, disabledOperators: disabledOperators }) })] }));
};
