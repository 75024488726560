import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '@/components/Button2/ButtonLink';
import { Table } from '@/components/Table/Table';
import { Download } from '@/icons/Download';
import { Error } from '@/icons/status/Error';
import { formatDate } from '@/utils/dates';
import { numberRound } from '@/utils/numbers';
import { importTypes } from './importTypes';
import { ImprotsTableStatusIcon } from './ImprotsTableStatusIcon';
import styles from './styles.module.scss';
export const ImportsTable = memo(({ items, isLoading, onEdit, onDelete }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'createdAt',
            title: t('Дата загрузки'),
        },
        {
            id: 'title',
            title: t('Название'),
        },
        {
            id: 'type',
            title: t('Данные'),
        },
        {
            id: 'linesTotal',
            title: t('Всего строк'),
        },
        {
            id: 'linesOk',
            title: t('Загружено'),
        },
        {
            id: 'linesError',
            title: t('С ошибкой'),
        },
        {
            id: 'actions',
            align: 'right',
        },
    ], [t]);
    const rows = useMemo(() => items.map((item) => {
        const errorContent = item.resultUrl ? (_jsx("a", { className: styles.errorText, title: t('Скачать файл с результатом'), href: item.resultUrl, download: true, children: numberRound(item.linesError || 0) })) : (_jsx("span", { className: styles.errorText, children: numberRound(item.linesError || 0) }));
        return {
            id: item.id,
            cells: [
                formatDate(item.createdAt),
                _jsxs("div", { className: styles.title, children: [_jsx(ImprotsTableStatusIcon, { status: item.status }), item.title] }, 'title'),
                t(importTypes[item.type]),
                item.linesTotal ? numberRound(item.linesTotal) : '–',
                item.linesOk ? numberRound(item.linesOk) : '–',
                item.linesError ? (_jsxs("div", { className: styles.error, children: [_jsx("span", { className: styles.icon, children: _jsx(Error, {}) }), errorContent] })) : ('–'),
                item.status === 2 && !!item.resultUrl ? (_jsxs(ButtonLink, { className: styles.download, href: item.resultUrl, download: true, isOutlined: true, children: ["XLS ", _jsx(Download, {})] })) : item.status === 5 ? null : (_jsx("span", {})),
            ].filter(Boolean),
            actions: item.status === 5
                ? [
                    {
                        id: 'edit',
                        label: t('Редактировать'),
                        onClick: () => onEdit === null || onEdit === void 0 ? void 0 : onEdit(item),
                    },
                    {
                        id: 'delete',
                        label: t('Удалить'),
                        onClick: () => onDelete === null || onDelete === void 0 ? void 0 : onDelete(item),
                        isDanger: true,
                    },
                ]
                : undefined,
        };
    }), [t, items]);
    return (_jsx(Table, { className: styles.table, columns: columns, rows: rows, isLoading: isLoading, hasEmptyState: true }));
});
