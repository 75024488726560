import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { ButtonLink } from '@/components/Button2/ButtonLink';
import { CloseCircle } from '@/icons/CloseCircle';
import { Download } from '@/icons/Download';
import { Sending } from '@/icons/status/Sending';
import styles from './styles.module.scss';
export const ExportStatus = memo(({ data }) => {
    if (data.status === 0)
        return _jsx(Sending, { className: styles.loader });
    if (data.status === 3)
        return (_jsx("div", { className: styles.error, children: _jsx(CloseCircle, {}) }));
    if (data.status === 2 && data.getLink)
        return (_jsxs(ButtonLink, { className: styles.link, href: data.getLink, download: true, isOutlined: true, children: ["XLS ", _jsx(Download, {})] }));
    return _jsx("span", { children: "\u2013" });
});
