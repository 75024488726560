var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Switch } from '@/components/Switch/Switch';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './styles.module.scss';
export const GeopushToggle = ({ id, label, checked, onToggle, }) => {
    const [isToggling, setIsToggling] = useState(false);
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasWalletCardsManage = userHasAccessTo('WALLET_CARDS_MANAGE');
    const handleChange = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsToggling(true);
        try {
            yield onToggle(id, checked);
        }
        catch (_a) {
            // do nothing
        }
        finally {
            setIsToggling(false);
        }
    });
    const stopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);
    return (_jsx(Tooltip, { title: !userHasWalletCardsManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx("div", { onClick: stopPropagation, children: _jsx(Switch, { labelClassName: styles.switchLabel, label: label, checked: checked, onChange: handleChange, disabled: !userHasWalletCardsManage || isToggling, isLoading: isToggling }) }) }));
};
