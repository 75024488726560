import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormOrderWithDetailsModal } from '@/forms/FormOrderWithDetails/FormOrderWithDetailsModal';
import { useClientModal } from '../ClientEditModal/useClientModal';
import styles from '../styles.module.scss';
import { useOrderModal } from './useOrderModal';
export const OrderModal = () => {
    var _a;
    const { t } = useTranslation();
    const { currency } = useCurrentUser();
    const orderModal = useOrderModal();
    const clientModal = useClientModal();
    const selectedOrder = orderModal.orderList && orderModal.currentOrderIndex !== null
        ? orderModal.orderList[orderModal.currentOrderIndex]
        : null;
    const isPaginationEnbled = orderModal.orderList
        ? orderModal.orderList.length > 0
        : false;
    const handleOpenClientModal = (clientId) => (_jsx("button", { type: 'button', className: styles.clientModalButton, onClick: () => {
            orderModal.setVisibility(false);
            clientModal.open({
                clientId,
                closeHandler: () => {
                    orderModal.setVisibility(true);
                },
            });
        }, children: t('Перейти в профиль') }));
    return (_jsx(FormOrderWithDetailsModal, { handleOpenClientModal: handleOpenClientModal, isOpen: orderModal.isOpen, onClose: orderModal.close, currency: currency, selectedOrder: selectedOrder, selectedOrderIndex: orderModal.currentOrderIndex, setSelectedOrderIndex: orderModal.setOrderIndex, ordersListLength: ((_a = orderModal.orderList) === null || _a === void 0 ? void 0 : _a.length) || 0, isPaginationEnbled: isPaginationEnbled }));
};
