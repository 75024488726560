import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { Info } from '@/icons/Info';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormGiftCardGenerate = memo(({ id, generatedCount, onSubmit, onChangeGenerateType }) => {
    var _a;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors }, watch, setValue, } = useForm({
        defaultValues: {
            generate: generatedCount > 0 ? '0' : '1',
            num: 1000,
        },
    });
    const generateType = watch('generate');
    useEffect(() => {
        if (onChangeGenerateType)
            onChangeGenerateType(generateType);
    }, [generateType]);
    return (_jsxs("form", { className: styles.wrapper, id: id, onSubmit: handleSubmit(onSubmit), children: [generatedCount > 0 && (_jsx(RadioGroup, { className: styles.radios, name: 'generate', value: generateType, onChange: (value) => {
                    setValue('generate', value);
                }, items: [
                    {
                        value: '0',
                        label: t('GIFT_CARD_GENERATE_MODAL_OPTION_GENERATE', {
                            amount: numberRound(generatedCount),
                        }),
                    },
                    {
                        value: '1',
                        label: t('Сгенерировать и выгрузить дополнительные карты'),
                    },
                ], align: 'vertical' })), generateType === '1' && (_jsx(FormFieldRow, { className: classNames(styles.numRow, {
                    [styles.transparent]: !generatedCount,
                }), label: t('Количество карт'), labelFor: 'num', labelClassName: styles.numRowLabel, controlSize: '200', controlClassName: styles.numRowControl, help: ((_a = errors.num) === null || _a === void 0 ? void 0 : _a.message) ||
                    t('GIFT_CARD_GENERATE_HELP', {
                        from: 1,
                        to: numberRound(1000000),
                    }), helpClassName: !errors.num ? styles.numRowHelp : undefined, hasError: !!errors.num, isRequired: true, children: _jsx(Controller, { name: 'num', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        min: {
                            value: 1,
                            message: t('MIN_ERROR_MESSAGE', { amount: 1 }),
                        },
                        max: {
                            value: 1000000,
                            message: t('MAX_ERROR_MESSAGE', {
                                amount: numberRound(1000000),
                            }),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'num', type: 'number', inputMode: 'numeric', placeholder: t('введите количество карт'), preventChars: [',', '.'], preventNegativeNumbers: true, shouldHideSpinButtons: true, hasError: !!errors.num, autoFocus: true }))) }) })), _jsxs("div", { className: classNames(styles.alert, {
                    [styles.hasLargeMargin]: generateType === '0',
                }), children: [_jsx("div", { className: styles.alertIcon, children: _jsx(Info, {}) }), t('PROMOCODE_GENERATE_REPORT_NOTICE')] })] }));
});
