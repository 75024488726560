import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextArea } from '@/components/Input/TextArea';
import { InputInfo } from '@/components/InputInfo/InputInfo';
import { Wrapper } from './Wrapper';
export const TextField = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (e) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { value: e.target.value }) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = !!((_b = (_a = draft.value) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.trim());
    return (_jsxs(Wrapper, { onBack: onBack, label: item.label, operator: ((_c = draft.value) === null || _c === void 0 ? void 0 : _c.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, children: [_jsx(TextArea, { placeholder: t('введите список'), value: ((_d = draft.value) === null || _d === void 0 ? void 0 : _d.value) || '', onChange: handleChangeValue, rows: 6 }), _jsx(InputInfo, { children: t('Можно указать несколько вариантов, по одному на строку') })] }));
});
