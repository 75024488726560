import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { PROMOCODE_TYPE_PERSONAL } from '@/const/promocode';
import { diffDays, formatDate } from '@/utils/dates';
import styles from '../../styles.module.scss';
export const getBonusContent = ({ event, entityHandlers, translate: t, hiddenParams = [], }) => {
    const { params } = event;
    const { openOverview, handlePurchase, handleReturn, handleEntityLink } = entityHandlers;
    const { operatorName, comment, mailingBrandId, mailingBrandType, mailingBrandName, mailingBrandDeleted, purchaseId, purchaseDeleted, purchaseReturnId, referralPurchaseId, referralPurchaseDeleted, referralPurchaseClientId, referralPurchaseClientName, referralPurchaseClientDeleted, offerId, offerTitle, offerDeleted, promocodeId, promocodeType, promocodeCode, promocodeDeleted, issuedAt, availableAt, expiredAt, } = params || {};
    const linkMailing = !mailingBrandDeleted
        ? handleEntityLink('mailing', mailingBrandId, mailingBrandType)
        : '';
    const mailingContent = linkMailing ? (_jsx(Link, { className: classNames(styles.contentValue, styles.underline), to: linkMailing, target: '_blank', children: mailingBrandName || t('Перейти к рассылке') })) : mailingBrandName ? (_jsx("span", { className: styles.contentValue, children: mailingBrandName })) : null;
    const linkOffer = !offerDeleted ? handleEntityLink('offer', offerId) : '';
    const offerContent = linkOffer ? (_jsx(Link, { className: classNames(styles.contentValue, styles.underline), to: linkOffer, target: '_blank', children: offerTitle || t('Перейти к акции') })) : offerTitle ? (_jsx("span", { className: styles.contentValue, children: offerTitle })) : null;
    const linkPromocode = !promocodeDeleted
        ? handleEntityLink('promocode', promocodeId, undefined, promocodeType)
        : '';
    const formatPromocode = (code) => !code
        ? ''
        : promocodeType === PROMOCODE_TYPE_PERSONAL
            ? `${code}-XXX-XXX`
            : code;
    const promocodeContent = linkPromocode ? (_jsx(Link, { className: classNames(styles.contentValue, styles.underline), to: linkPromocode, target: '_blank', children: formatPromocode(promocodeCode) || t('Перейти к промокоду') })) : promocodeCode ? (_jsx("span", { className: styles.contentValue, children: formatPromocode(promocodeCode) })) : null;
    const leftItems = [];
    const centerItems = [];
    const rightItems = [];
    // Left items ----------------------------------------------------------------
    if (issuedAt && !hiddenParams.includes('issuedAt'))
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(issuedAt, true), _jsx("span", { className: styles.contentValue, children: t('Начисление') })] }, 'issuedAt'));
    if (availableAt && !hiddenParams.includes('availableAt'))
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(availableAt, true), _jsx("span", { className: styles.contentValue, children: t('Активация') })] }, 'availableAt'));
    if (expiredAt && !hiddenParams.includes('expiredAt'))
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(expiredAt, true), _jsxs("span", { className: styles.contentValue, children: [t('Сгорание'), !!availableAt &&
                            ' (' +
                                diffDays(availableAt, expiredAt) +
                                ' ' +
                                t('DAYS_EXPIRING', {
                                    count: diffDays(availableAt, expiredAt),
                                }) +
                                ')'] })] }, 'expiredAt'));
    // Center Items --------------------------------------------------------------
    if (purchaseId &&
        !purchaseDeleted &&
        handlePurchase &&
        !hiddenParams.includes('purchase'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Покупка'), _jsx("span", { className: classNames(styles.contentValue, styles.underline), onClick: () => handlePurchase(purchaseId), children: t('Перейти к покупке') })] }, 'purchase'));
    if (purchaseReturnId &&
        purchaseId &&
        !purchaseDeleted &&
        handleReturn &&
        !hiddenParams.includes('purchaseReturn'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Возврат'), _jsx("span", { className: classNames(styles.contentValue, styles.underline), onClick: () => handleReturn(purchaseId), children: t('Перейти к возврату') })] }, 'purchaseReturn'));
    if (referralPurchaseId &&
        !referralPurchaseDeleted &&
        handlePurchase &&
        !hiddenParams.includes('referralPurchase'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Покупка друга'), _jsx("span", { className: classNames(styles.contentValue, styles.underline), onClick: () => handlePurchase(referralPurchaseId), children: t('Перейти к покупке друга') })] }, 'referralPurchase'));
    if (((referralPurchaseClientId && !referralPurchaseClientDeleted) ||
        referralPurchaseClientName) &&
        openOverview &&
        !hiddenParams.includes('referralPurchaseClient'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Друг'), _jsx("span", { className: classNames(styles.contentValue, {
                        [styles.underline]: !!referralPurchaseClientId && !referralPurchaseClientDeleted,
                    }), onClick: () => {
                        if (referralPurchaseClientId && !referralPurchaseClientDeleted)
                            openOverview(referralPurchaseClientId);
                    }, children: referralPurchaseClientName || t('Перейти к другу') })] }, 'referralPurchaseClient'));
    if (mailingContent && !hiddenParams.includes('mailing'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Рассылка'), mailingContent] }, 'mailingBrand'));
    if (offerContent && !hiddenParams.includes('offer'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Акция'), offerContent] }, 'offer'));
    if (promocodeContent && !hiddenParams.includes('promocode'))
        centerItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Промокод'), promocodeContent] }, 'promocode'));
    // Right Items ---------------------------------------------------------------
    if (operatorName && !hiddenParams.includes('operator'))
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Сотрудник'), _jsx("span", { className: styles.contentValue, children: operatorName })] }, 'operator'));
    if (comment && !hiddenParams.includes('comment'))
        rightItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column, styles.limited), children: [t('Комментарий'), _jsx("span", { className: styles.contentValue, children: comment })] }, 'comment'));
    // ---------------------------------------------------------------------------
    if (!leftItems.length && !centerItems.length && !rightItems.length)
        return undefined;
    return (_jsx("div", { children: _jsxs("section", { className: styles.expandedContent, children: [(!!leftItems.length || !!centerItems.length) && (_jsxs("div", { className: styles.expandedContentGroup, children: [!!leftItems.length && _jsx("ul", { children: [...leftItems].reverse() }), !!centerItems.length && _jsx("ul", { children: centerItems })] })), !!rightItems.length && _jsx("ul", { children: rightItems })] }) }));
};
