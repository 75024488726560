import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT, } from '@/forms/FormOffer/constants';
import { BonusMovementSmallAlt } from '@/icons/BonusMovementSmallAlt';
import { CounterSmall } from '@/icons/CounterSmall';
import { PercentAlt } from '@/icons/PercentAlt';
import { notNull } from '@/utils/notNull';
import styles from './styles.module.scss';
export const ArbitrationGroupItemIcons = ({ priority, currency = '' }) => {
    const { t } = useTranslation();
    const counterIcon = priority.offerHasCounter ? _jsx(CounterSmall, {}) : null;
    const bonusIcon = priority.offerType === 'bonus' ? _jsx(BonusMovementSmallAlt, {}) : null;
    const currencyIcon = priority.offerType !== 'bonus' &&
        priority.offerDiscountType === DISCOUNT_TYPE_FIXED
        ? currency
        : null;
    const percentIcon = priority.offerType !== 'bonus' &&
        priority.offerDiscountType === DISCOUNT_TYPE_PERCENT ? (_jsx(PercentAlt, {})) : null;
    const icons = [counterIcon, bonusIcon, currencyIcon, percentIcon].filter(notNull);
    if (!icons.length)
        return null;
    return (_jsxs("div", { className: styles.iconsWrapper, children: [!!counterIcon && _jsx("div", { children: counterIcon }), !!bonusIcon && (_jsx(Tooltip, { className: styles.tooltip, title: t('Бонусы'), placement: 'top', children: _jsx("div", { children: bonusIcon }) })), !!currencyIcon && (_jsx(Tooltip, { className: styles.tooltip, title: t('Фиксированная скидка'), placement: 'top', children: _jsx("div", { children: currencyIcon }) })), !!percentIcon && (_jsx(Tooltip, { className: styles.tooltip, title: t('Процентная скидка'), placement: 'top', children: _jsx("div", { children: percentIcon }) }))] }));
};
