import moment from 'moment-timezone';
import { useGetPromocodeQuery } from '@/api/query/promocodes';
import { PROMOCODE_TYPE_FRIEND, PROMOCODE_TYPE_PERSONAL, PROMOCODE_TYPE_SHARED, } from '@/const/promocode';
export const useInitialData = (promocodeId, tz) => {
    var _a, _b, _c, _d;
    const { data: selectedPromocode, isLoading } = useGetPromocodeQuery({ id: promocodeId || 0 }, { skip: !promocodeId });
    let codeType;
    let availableFrom = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.availableFrom;
    let availableTo = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.availableTo;
    if ((selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.codeType) === PROMOCODE_TYPE_SHARED)
        codeType = 'shared';
    if ((selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.codeType) === PROMOCODE_TYPE_PERSONAL)
        codeType = 'personal';
    if ((selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.codeType) === PROMOCODE_TYPE_FRIEND)
        codeType = 'friend';
    if ((availableFrom === null || availableFrom === void 0 ? void 0 : availableFrom.includes(':')) && tz)
        availableFrom = moment(availableFrom).tz(tz).format('YYYY-MM-DD');
    if ((availableTo === null || availableTo === void 0 ? void 0 : availableTo.includes(':')) && tz)
        availableTo = moment(availableTo).tz(tz).format('YYYY-MM-DD');
    const defaultValues = {
        allowBonusApply: true,
        allowBonusCollect: true,
    };
    return {
        selectedPromocode,
        defaultValues: promocodeId
            ? Object.assign(Object.assign({}, selectedPromocode), { arbitrationPriority: (_a = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.arbitrationPriority) === null || _a === void 0 ? void 0 : _a.guid, arbitrationGroupGuid: (_b = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.arbitrationGroup) === null || _b === void 0 ? void 0 : _b.guid, codeType, allowedCategories: ((_c = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.allowedCategories) === null || _c === void 0 ? void 0 : _c.configuration) || [], conditions: ((_d = selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.conditions) === null || _d === void 0 ? void 0 : _d.configuration) || [], availableFrom,
                availableTo }) : defaultValues,
        isLoading: promocodeId ? isLoading : false,
    };
};
