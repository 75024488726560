var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '@/api/query';
import { useSetWalletCardMutation } from '@/api/query/wallet-cards';
import { useToastContext } from '@/components/Toast/ToastContext';
export const useCloneWalletCard = () => {
    const { t } = useTranslation();
    const addToast = useToastContext();
    const [setCard, { isLoading }] = useSetWalletCardMutation();
    const cloneCard = (levelId_1, copyFromLevelId_1, ...args_1) => __awaiter(void 0, [levelId_1, copyFromLevelId_1, ...args_1], void 0, function* (levelId, copyFromLevelId, walletCards = []) {
        const card = walletCards.find((card) => card.level === copyFromLevelId);
        if (!card || !card.configuration) {
            addToast({
                type: 'error',
                message: t('Не удалось скопировать дизайн карты'),
            });
            return null;
        }
        const result = yield setCard({
            level: levelId,
            configuration: card.configuration,
        });
        if ('error' in result) {
            const { status } = result.error;
            if (status === 200)
                addToast({ type: 'error', message: getErrorMessage(result.error) });
            return null;
        }
        return result.data;
    });
    return { cloneCard, isLoading };
};
