import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Switch } from '@/components/Switch/Switch';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import styles from './styles.module.scss';
export const TopAlertPostponedPaymentButton = ({ brand, onClick }) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasBillingView = userHasAccessTo(['BILLING_VIEW']);
    const userHasBillingManage = userHasAccessTo(['BILLING_INVOICES_MANAGE']);
    const handleClick = useCallback(() => {
        setChecked(true);
        if (!checked)
            onClick();
    }, [onClick]);
    if (!userHasBillingView ||
        !brand.settings.allowPostponedPayment ||
        brand.paymentPostponedUntil)
        return null;
    return (_jsxs("span", { className: styles.buttonWrapper, children: [_jsx("span", { className: styles.buttonLabel, children: t('Активировать опцию «Обещанный платеж» на 48 часов') }), _jsx(Tooltip, { title: !userHasBillingManage && t('Недоступно для вашей роли'), placement: 'left', children: _jsx("span", { children: _jsx(Switch, { checked: checked, onChange: handleClick, disabled: !userHasBillingManage || checked }) }) })] }));
};
