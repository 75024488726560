import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import { AutocompleteInput, } from '../variants/AutocompleteInput';
export const AutocompleteCashiers = memo((props) => {
    const { t } = useTranslation();
    const getOptionKey = (option) => (option === null || option === void 0 ? void 0 : option.code) || 0;
    const getOptionLabel = (option) => `${(option === null || option === void 0 ? void 0 : option.name) || (option === null || option === void 0 ? void 0 : option.code)}`;
    const renderOption = (option) => {
        if (option)
            return (_jsxs("div", { className: styles.client, children: [_jsx("span", { dangerouslySetInnerHTML: { __html: option.name || t('Без ФИО') } }), option.name && (_jsx("small", { dangerouslySetInnerHTML: { __html: option.code } }))] }));
        return null;
    };
    return (_jsx(AutocompleteInput, Object.assign({}, props, { getOptionKey: getOptionKey, getOptionLabel: getOptionLabel, renderOption: renderOption, placeholder: t('введите имя кассира'), isLarge: true })));
});
