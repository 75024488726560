import { createContext } from 'react';
const noop = () => { };
const noopPromise = () => Promise.resolve();
export const PurchasesContext = createContext({
    tabs: {
        activeTab: 'purchases',
        setActiveTab: noop,
    },
    pagination: {
        purchases: {
            limit: 100,
            offset: 0,
            setOffset: noop,
            resetOffset: noop,
        },
        orders: {
            limit: 100,
            offset: 0,
            setOffset: noop,
            resetOffset: noop,
        },
        ordersCancelled: {
            limit: 100,
            offset: 0,
            setOffset: noop,
            resetOffset: noop,
        },
    },
    search: {
        searchText: '',
        apply: noop,
    },
    filters: {
        filtersTree: [],
        filters: [],
        update: noop,
        reset: noop,
    },
    data: {
        count: {
            purchase: 0,
            order: 0,
            orderCancelled: 0,
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
        purchases: {
            items: [],
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
        orders: {
            items: [],
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
        ordersCancelled: {
            items: [],
            isLoading: false,
            isFetching: false,
            refetch: noop,
        },
    },
    exportData: {
        exportPurchases: noopPromise,
    },
});
