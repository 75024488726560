import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/components/Switch/Switch';
import { PushCircle } from '@/icons/flow/PushCircle';
import { SMSCircle } from '@/icons/flow/SMSCircle';
import { ViberCircle } from '@/icons/flow/ViberCircle';
import formStyles from '../styles.module.scss';
import styles from './styles.module.scss';
export const CascadeFormTab = ({ channel, activeTab, channels, onClick, onToggle, isDisabled, }) => {
    const { t } = useTranslation();
    let icon = (_jsx("div", { className: styles.smsIcon, children: _jsx(SMSCircle, { isDisabled: !channels.includes('sms') }) }));
    let title = 'SMS';
    if (channel === 'push') {
        icon = _jsx(PushCircle, { isDisabled: !channels.includes('push') });
        title = 'Push';
    }
    if (channel === 'viber') {
        icon = _jsx(ViberCircle, { isDisabled: !channels.includes('viber') });
        title = 'Viber';
    }
    return (_jsxs("div", { className: classNames(styles.tab, {
            [styles.active]: activeTab === channel,
            [styles.smsTab]: channel === 'sms',
            [styles.viberTab]: channel === 'viber',
        }), onClick: () => onClick(channel), children: [_jsxs("div", { className: styles.tabTitle, children: [icon, _jsx("span", { children: title })] }), _jsxs("div", { className: styles.tabToggle, children: [_jsx("span", { className: classNames(formStyles.label, formStyles.light), children: channels.includes(channel) ? t('Включен') : t('Выключен') }), _jsx(Switch, { checked: channels.includes(channel), onChange: () => onToggle(channel), disabled: isDisabled })] })] }));
};
