import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColorRadio, COLORS } from '@/components/ColorRadio/ColorRadio';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { SegmentBadge } from '@/components/SegmentBadge/SegmentBadge';
import styles from './styles.module.scss';
export const FormSegment = memo(({ id, defaultValues = { color: COLORS[0] }, onSubmit, setHasFormChanged, submitButton, asPopup, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, handleSubmit, formState: { errors, isSubmitting, dirtyFields }, setValue, watch, } = useForm({ defaultValues });
    useEffect(() => {
        if (setHasFormChanged)
            setHasFormChanged(!isSubmitting && !!Object.keys(dirtyFields).length);
    }, [dirtyFields, isSubmitting, setHasFormChanged]);
    const name = watch('name');
    const color = watch('color');
    const hasName = name === null || name === void 0 ? void 0 : name.trim();
    const labelWidth = 25;
    return (_jsxs("form", { className: classNames({
            [styles.asPopup]: asPopup,
        }), id: id, onSubmit: handleSubmit(onSubmit), children: [!asPopup && (_jsx(FormFieldRow, { labelWidth: labelWidth, children: _jsx("div", { className: styles.title, children: t('Редактирование сегмента') }) })), _jsx(FormFieldRow, { className: styles.preview, label: t('Превью'), labelWidth: labelWidth, hasLabelHidden: asPopup, hasNoMargin: asPopup, alignItems: 'center', children: _jsx(SegmentBadge, { segment: { color: hasName ? color : '#fff' }, isFullSize: true, className: styles.segmentName, children: hasName ? name : '–' }) }), _jsx(FormFieldRow, { className: styles.name, label: t('Название'), labelFor: 'name', labelWidth: labelWidth, hasLabelHidden: asPopup, hasNoMargin: asPopup, controlSize: '250', help: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.name, isRequired: true, children: _jsx(Controller, { name: 'name', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                    }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', placeholder: t('введите название сегмента'), hasError: !!errors.name, autoComplete: 'off', autoFocus: true }))) }) }), _jsx(FormFieldRow, { className: styles.color, label: t('Цвет сегмента'), labelWidth: labelWidth, hasLabelHidden: asPopup, hasNoMargin: asPopup, controlSize: '250', children: _jsx(Controller, { name: 'color', control: control, render: ({ field }) => (_jsx(ColorRadio, { value: field.value, onChange: (value) => setValue('color', value, {
                            shouldDirty: true,
                        }) })) }) }), submitButton && (_jsx("div", { className: styles.submitButtonWrapper, children: submitButton }))] }));
});
