import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WalletCardPhone } from '@/components/wallet-cards/WalletCardPhone/WalletCardPhone';
import styles from './styles.module.scss';
import { WalletCardNotifyButtons } from './WalletCardNotifyButtons';
import { WalletCardNotifyClock } from './WalletCardNotifyClock';
import { WalletCardNotifyHeader } from './WalletCardNotifyHeader';
import { WalletCardNotifyMessage } from './WalletCardNotifyMessage';
export const WalletCardNotify = ({ logoImageUrl = '', logoBackground, messageTitle = '', messageText = '', onElementClick, clicksDisabled = false, }) => {
    return (_jsx(WalletCardPhone, { type: 'notify', children: _jsxs("div", { className: styles.wrapper, style: logoBackground
                ? {
                    '--card-notify-logo-background': logoBackground,
                }
                : undefined, children: [_jsxs("div", { children: [_jsx(WalletCardNotifyHeader, {}), _jsx(WalletCardNotifyClock, {})] }), _jsxs("div", { children: [_jsx(WalletCardNotifyMessage, { logoImageUrl: logoImageUrl, title: messageTitle, message: messageText, onElementClick: onElementClick, clicksDisabled: clicksDisabled }), _jsx(WalletCardNotifyButtons, {}), _jsx("div", { className: styles.line })] })] }) }));
};
