import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { AccentLink } from '@/components/AccentText/AccentLink';
import { SegmentBadgeContainer } from '@/components/SegmentBadge/SegmentBadgeContainer';
import { Table } from '@/components/Table/Table';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { money, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const SegmentsTable = memo(({ segments = [], total, shouldDisplayProfit, currency, isLoading, hasActions = true, onSegmentClick, onEditSegment, onDeleteSegment, onDeleteSegmentClients, onGoToAllClients, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const isSegmentLinksEnabled = userHasAccessTo([
        'CLIENTS_LIST_OWN',
        'CLIENTS_LIST',
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const columns = [
        {
            id: 'segment',
            title: t('Сегмент'),
            tooltip: t('TOOLTIP_SEGMENTS'),
        },
        {
            id: 'clients',
            title: t('Клиентов в сегменте'),
            align: 'right',
        },
        {
            id: 'cr',
            title: t('CR'),
            tooltip: t('TOOLTIP_MAILING_CR'),
            align: 'right',
        },
        {
            id: 'avg',
            title: t('Средний чек'),
            align: 'right',
        },
        {
            id: 'profitOrRevenue',
            title: t(shouldDisplayProfit ? 'Прибыль' : 'Выручка'),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма затрат на программу лояльности для сегмента за выбранный период. Учитываются затраты на платные рассылки, скидки и списание бонусов клиентами'),
            align: 'right',
        },
        {
            id: 'avgExpense',
            title: t('Ст-ть покупателя'),
            tooltip: t('Средняя сумма затрат на получение одной покупки с применением программы лояльности для данного сегмента. Учитываются затраты на рассылки, скидки и списание бонусов'),
            align: 'right',
        },
    ];
    if (hasActions)
        columns.push({ id: 'actions' });
    const rows = segments.map((segment, i) => ({
        id: segment.id || segment.code || `segment-${i}`,
        cells: [
            _jsx(SegmentBadgeContainer, { onClick: isSegmentLinksEnabled ? () => onSegmentClick(segment) : undefined, segment: segment, isFullSize: true, children: segment.name }, segment.id),
            numberRound(segment.clients),
            numberRound(Number(segment.cr), 2) + '%',
            money({ input: segment.avgAmount, currency }),
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: segment.profit, revenue: segment.revenue, currency: currency }, 'profit-or-revenue'),
            _jsx(Expenses, { expenses: segment.expenses, currency: currency }, 'expenses'),
            money({ input: segment.avgExpense, currency }),
        ],
        actions: hasActions
            ? [
                {
                    id: 'edit',
                    label: t('Переименовать'),
                    onClick: () => onEditSegment(segment),
                    isDisabled: !userHasAccessTo(['CLIENTS_SEGMENTS_MANAGE']),
                    tooltip: !userHasAccessTo(['CLIENTS_SEGMENTS_MANAGE']) &&
                        t('Недоступно для вашей роли'),
                },
                {
                    id: 'changeFilters',
                    label: t('Изменить фильтры'),
                    onClick: () => onSegmentClick(segment),
                    isDisabled: !userHasAccessTo(['CLIENTS_SEGMENTS_MANAGE']),
                    tooltip: !userHasAccessTo(['CLIENTS_SEGMENTS_MANAGE']) &&
                        t('Недоступно для вашей роли'),
                },
                {
                    id: 'deleteSegment',
                    label: t('Удалить сегмент'),
                    onClick: () => onDeleteSegment(segment),
                    isDanger: true,
                    isDisabled: !userHasAccessTo(['CLIENTS_SEGMENTS_MANAGE']),
                    tooltip: !userHasAccessTo(['CLIENTS_SEGMENTS_MANAGE']) &&
                        t('Недоступно для вашей роли'),
                },
                {
                    id: 'deleteSegmentClients',
                    label: t('Удалить клиентов'),
                    onClick: () => onDeleteSegmentClients(segment),
                    isDanger: true,
                    isDisabled: !userHasAccessTo(['CLIENTS_MANAGE']),
                    tooltip: !userHasAccessTo(['CLIENTS_MANAGE']) &&
                        t('Недоступно для вашей роли'),
                },
            ]
            : undefined,
    }));
    const totalRow = !total
        ? undefined
        : [
            {
                id: 'total',
                isTotal: true,
                cells: [
                    isSegmentLinksEnabled ? (_jsx(AccentLink, { href: '/#/app/clients', onClick: (e) => {
                            if (!e.ctrlKey) {
                                e.preventDefault();
                                onGoToAllClients();
                            }
                        }, children: t('Все клиенты') }, 'segments-total')) : null,
                    numberRound(total.clients),
                    numberRound(Number(total.cr), 2) + '%',
                    money({ input: total.avgAmount, currency }),
                    _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: total.profit, revenue: total.revenue, currency: currency }, 'profit-or-revenue'),
                    _jsx(Expenses, { expenses: total.expenses, currency: currency }, 'expenses'),
                    money({ input: total.avgAmount, currency }),
                ],
            },
        ];
    if (hasActions)
        totalRow === null || totalRow === void 0 ? void 0 : totalRow[0].cells.push(' ');
    return (_jsx(Table, { needAdaptive: false, className: styles.table, isLoading: isLoading, columns: columns, rows: rows, total: totalRow, hasEmptyState: true }));
});
