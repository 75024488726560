import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import styles from '../../styles.module.scss';
export const getMailingTitle = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleEntityLink } = entityHandlers;
    const { mailingBrandId, mailingBrandType, mailingBrandName, mailingBrandDeleted, } = event.params || {};
    const link = !mailingBrandDeleted
        ? handleEntityLink('mailing', mailingBrandId, mailingBrandType)
        : '';
    const linkElement = link ? (_jsxs(_Fragment, { children: ["\u00A0", _jsx(Link, { className: styles.underline, to: link, target: '_blank', children: mailingBrandName || t('рассылка') })] })) : null;
    const mailingNameElement = (_jsxs(_Fragment, { children: ["\u00A0", mailingBrandName ? `"${mailingBrandName}"` : t('рассылка')] }));
    if (event.eventType === 'mailing_event')
        return (_jsxs(_Fragment, { children: [!mailingBrandName ? t('Запланирована') : t('Запланирована рассылка'), linkElement || mailingNameElement] }));
    if (event.eventType === 'mailing_sending')
        return (_jsxs(_Fragment, { children: [!mailingBrandName ? t('Сформирована') : t('Сформирована рассылка'), linkElement || mailingNameElement] }));
    if ([
        'sending_push',
        'sending_webpush',
        'sending_viber',
        'sending_sms',
        'sending_email',
        'sending_chat',
    ].includes(event.eventType)) {
        const titles = {
            sending_push: 'Push',
            sending_webpush: 'Push',
            sending_viber: 'Viber',
            sending_sms: 'SMS',
            sending_email: 'Email',
            sending_chat: 'Telegram',
        };
        return (_jsxs(_Fragment, { children: [titles[event.eventType], linkElement || mailingNameElement] }));
    }
};
