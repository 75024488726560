import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { ChannelCircles } from '@/components/ChannelCircles/ChannelCircles';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { MailingStats } from '@/icons/MailingStats';
import { cr } from '@/utils/cr';
import { numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import styles from './styles.module.scss';
export const convertMailingToRowTable = ({ mailing, shouldDisplayProfit, currency, purchaseUrl, openPurchaseFilter, openMailingModal, }) => {
    return {
        id: mailing.id,
        cells: [
            mailing.name,
            _jsx(ChannelCircles, { channels: mailing.channels }, 'channels'),
            numberRound(roi(shouldDisplayProfit ? mailing.profit : mailing.revenue, mailing.expenses)) + '%',
            numberRound(cr(mailing.goals, mailing.sendings), 2) + '%',
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: Number(mailing.profit), revenue: Number(mailing.revenue), currency: currency, href: purchaseUrl, onClick: () => openPurchaseFilter([mailing]) }, 'profitOrRevenue'),
            _jsx(Expenses, { expenses: Number(mailing.expenses), currency: currency }, 'expenses'),
            moment(mailing.createdAt).toDate(),
            _jsx("button", { className: styles.modalButton, type: 'button', onClick: (e) => {
                    e.stopPropagation();
                    openMailingModal(mailing);
                }, children: _jsx(MailingStats, {}) }, 'modal-button'),
        ],
    };
};
