import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Popover from '@mui/material/Popover';
import { cloneElement, useEffect, useRef } from 'react';
import { CloseModal } from '@/icons/CloseModal';
import styles from './styles.module.scss';
export const CloseButton = ({ isOnMountFocus = false, tooltipOpen, notificationOfClosure, onClose, setTooltipOpen, handleClose, }) => {
    const anchor = useRef(null);
    const focusOnModal = () => {
        var _a;
        // сами ставим фокус для корректной работы с escape
        (_a = anchor.current) === null || _a === void 0 ? void 0 : _a.focus({
            preventScroll: true,
        });
    };
    const handleCloseTooltip = () => {
        setTooltipOpen(false);
        focusOnModal();
    };
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape')
                handleCloseTooltip();
        };
        addEventListener('keydown', handleEscape);
        return () => removeEventListener('keydown', handleEscape);
    }, []);
    useEffect(() => {
        if (isOnMountFocus)
            focusOnModal();
    }, [isOnMountFocus]);
    if (notificationOfClosure) {
        const tooltipTitle = cloneElement(notificationOfClosure, {
            handleCancel: () => {
                if (onClose)
                    onClose();
                setTooltipOpen(false);
            },
            handleAgree: () => setTooltipOpen(false),
        });
        return (_jsxs(_Fragment, { children: [_jsx("button", { ref: anchor, className: styles.close, onClick: handleClose, children: _jsx(CloseModal, {}) }), _jsx(Popover, { open: tooltipOpen, anchorEl: anchor.current, onClose: handleCloseTooltip, anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }, className: styles.notificationOfClosure, transitionDuration: 200, disableAutoFocus: true, disableEnforceFocus: true, disableRestoreFocus: true, children: tooltipTitle })] }));
    }
    return (_jsx("button", { ref: anchor, className: styles.close, onClick: onClose, children: _jsx(CloseModal, {}) }));
};
