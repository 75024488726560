import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SoonBadge } from '@/components/SoonBadge/SoonBadge';
import { Switch } from '@/components/Switch/Switch';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import styles from './styles.module.scss';
export const MailingChannelsTab = ({ className, buttonClassName, label, onChange, onClick, isActive, isLocked, isSoon, }) => {
    const { t } = useTranslation();
    const isDisabled = !isActive || isLocked || isSoon;
    let leftContent = (_jsx(Switch, { className: className, checked: isActive, onChange: onChange }));
    if (isLocked)
        leftContent = (_jsx("div", { className: styles.lock, children: _jsx(Tooltip, { title: _jsx("span", { className: styles.tooltip, children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') }), placement: 'left', children: _jsx("div", { children: _jsx(Lock, {}) }) }) }));
    if (isSoon)
        leftContent = (_jsx("div", { className: styles.soon, children: _jsx(SoonBadge, {}) }));
    return (_jsxs("div", { className: classNames(styles.item, styles.switch), children: [leftContent, _jsx("button", { className: buttonClassName, type: 'button', onClick: !isDisabled ? onClick : undefined, disabled: isDisabled, children: label })] }));
};
