import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import styles from './styles.module.scss';
export const CheckboxGroup = memo(({ items, selectedItems, onChange }) => {
    const { t } = useTranslation();
    return (_jsx("div", { className: styles.wrapper, children: items.map((item) => {
            const lockedLabel = (_jsxs("span", { className: styles.nowrap, children: [_jsx("span", { className: styles.wrap, children: item.label }), "\u00A0", _jsx(Tooltip, { title: _jsx("span", { className: styles.tooltip, children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') }), placement: 'right', children: _jsx("span", { className: styles.lockIcon, children: _jsx(Lock, {}) }) })] }));
            return (_jsx(Checkbox, { className: styles.item, checked: selectedItems.includes(item.id), onChange: () => {
                    if (selectedItems.includes(item.id))
                        onChange(selectedItems.filter((id) => id !== item.id));
                    else
                        onChange([...selectedItems, item.id]);
                }, disabled: item.locked, children: item.locked ? lockedLabel : item.label }, item.id));
        }) }));
});
