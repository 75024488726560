import { jsx as _jsx } from "react/jsx-runtime";
import { Edit } from '@/icons/Edit';
import { getClientUpdateContent } from './clientUpdateContent';
import { ClientUpdateTitle } from './clientUpdateTitle';
// изменен клиент (по API)
export const clientUpdate2 = (args) => {
    const { translate: t } = args;
    return {
        Icon: _jsx(Edit, { fill: '#3A3A3A' }),
        title: (_jsx(ClientUpdateTitle, Object.assign({}, args, { children: t('API_DATA_CHANGED') }))),
        content: getClientUpdateContent(Object.assign(Object.assign({}, args), { hiddenParams: [] })),
    };
};
