import { jsx as _jsx } from "react/jsx-runtime";
import { CloseCircle } from '@/icons/CloseCircle';
import { useAutocomplete } from '../context/useAutocomplete';
import styles from '../styles.module.scss';
export const AutocompleteResetButton = () => {
    const { value, hasResetButton, getClearProps } = useAutocomplete();
    if (!hasResetButton || !value)
        return null;
    return (_jsx("button", Object.assign({ type: 'button', className: styles.reset }, getClearProps === null || getClearProps === void 0 ? void 0 : getClearProps(), { children: _jsx(CloseCircle, {}) })));
};
