import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { PROMOCODE_TYPE_PERSONAL } from '@/const/promocode';
import { Shop } from '@/icons/Shop';
import { Cancelled } from '@/icons/status/Cancelled';
import { Done } from '@/icons/status/Done';
import { Planned } from '@/icons/status/Planned';
import { money } from '@/utils/numbers';
import styles from '../../styles.module.scss';
export const getPurchaseContent = (args) => {
    const { event, currency, translate: t, entityHandlers, hiddenParams = [], } = args;
    const { handleEntityLink, handlePurchase, openOverview } = entityHandlers;
    const eventType = event.eventType;
    const { status, totalAmount, shopName, purchaseId, purchaseDeleted, referralPurchaseClientId, referralPurchaseClientName, referralPurchaseClientDeleted, promocodeId, promocodeType, promocodeCode, promocodeDeleted, } = event.params || {};
    // Status --------------------------------------------------------------------
    let statusIcon = null;
    let statusText = '';
    if (eventType === 'order' && status === 0) {
        statusIcon = _jsx(Planned, {});
        statusText = 'В работе';
    }
    if (eventType === 'order' && status === 1) {
        statusIcon = _jsx(Done, { type: 'single' });
        statusText = 'Завершён';
    }
    if (eventType === 'order' && status === 2) {
        statusIcon = _jsx(Cancelled, {});
        statusText = 'Отменён';
    }
    const statusElement = !!statusIcon && !hiddenParams.includes('status') ? (_jsxs("div", { className: styles.contentRow, children: [t('Статус'), "\u00A0", _jsxs("span", { className: classNames(styles.contentValue, styles.flex), children: [statusIcon, t(statusText)] })] })) : null;
    // Purchase ------------------------------------------------------------------
    const purchaseElement = eventType === 'order' &&
        purchaseId &&
        !purchaseDeleted &&
        handlePurchase ? (_jsxs("div", { className: styles.contentRow, children: [t('Покупка'), "\u00A0", _jsx("span", { className: classNames(styles.contentValue, styles.underline), onClick: () => handlePurchase(purchaseId), children: t('Перейти к покупке') })] })) : null;
    // Total Amount --------------------------------------------------------------
    const totalAmountElement = totalAmount && !hiddenParams.includes('totalAmount') ? (_jsxs("div", { className: styles.contentRow, children: [t('Сумма'), "\u00A0", _jsx("span", { className: styles.contentValue, children: money({ input: totalAmount, currency }) })] })) : null;
    // Shop ----------------------------------------------------------------------
    const shopElement = shopName && !hiddenParams.includes('shopName') ? (_jsxs("div", { className: styles.shopRow, children: [_jsx(Shop, { size: 14 }), shopName] })) : null;
    // Promocode -----------------------------------------------------------------
    const linkPromocode = !promocodeDeleted
        ? handleEntityLink('promocode', promocodeId, undefined, promocodeType)
        : '';
    const formatPromocode = (code) => !code
        ? ''
        : promocodeType === PROMOCODE_TYPE_PERSONAL
            ? `${code}-XXX-XXX`
            : code;
    const promocodeContent = linkPromocode ? (_jsx(Link, { className: classNames(styles.contentValue, styles.underline), to: linkPromocode, target: '_blank', children: formatPromocode(promocodeCode) || t('Перейти к промокоду') })) : promocodeCode ? (_jsx("span", { className: styles.contentValue, children: formatPromocode(promocodeCode) })) : null;
    const promocodeElement = promocodeContent && !hiddenParams.includes('promocode') ? (_jsxs("div", { className: styles.contentRow, children: [t('Промокод'), "\u00A0", promocodeContent] })) : null;
    // Friend --------------------------------------------------------------------
    const friendContent = (_jsx("span", { className: classNames(styles.contentValue, {
            [styles.underline]: !!referralPurchaseClientId &&
                !referralPurchaseClientDeleted &&
                openOverview,
        }), onClick: () => {
            if (referralPurchaseClientId &&
                !referralPurchaseClientDeleted &&
                openOverview)
                openOverview(referralPurchaseClientId);
        }, children: referralPurchaseClientName || t('Перейти к другу') }));
    const friendElement = ((!!referralPurchaseClientId && !referralPurchaseClientDeleted) ||
        referralPurchaseClientName) &&
        !hiddenParams.includes('referralPurchaseClient') ? (_jsxs("div", { className: styles.contentRow, children: [t('Друг'), "\u00A0", friendContent] })) : null;
    // ---------------------------------------------------------------------------
    if (!statusElement &&
        !totalAmountElement &&
        !shopElement &&
        !promocodeElement &&
        !friendElement)
        return undefined;
    return (_jsx("div", { children: _jsx("section", { className: styles.expandedMailingContent, children: _jsxs("div", { children: [statusElement, purchaseElement, totalAmountElement, shopElement, promocodeElement, friendElement] }) }) }));
};
