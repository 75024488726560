import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { useAppSelector } from '@/hooks/store';
import { Plus } from '@/icons/Plus';
import { LogoSmall } from '@/icons/wallet-card/apple/LogoSmall';
import { publishButtonClickedSelector } from '@/store/selectors/wallet';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import styles from '../../styles.module.scss';
import { BacksideContacts } from './BacksideContacts';
import { BacksideDetails } from './BacksideDetails';
export const Backside = () => {
    const { t } = useTranslation();
    const { setActiveCardType, setAppleCardIsFlipped, markReadyToSave, backside, setBackside, isElementClick, setIsElementClick, } = useWalletCardForm();
    const shouldDisplayErrors = useAppSelector(publishButtonClickedSelector);
    const hasTitleError = shouldDisplayErrors && !backside.appleTitle.trim();
    const addDetailsBlock = () => {
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { details: [...backside.details, { title: '', content: '' }] })));
        markReadyToSave();
        setTimeout(() => {
            const i = backside.details.length;
            const id = `backside-item-${i}-title`;
            const node = document.getElementById(id);
            node === null || node === void 0 ? void 0 : node.focus();
        }, 0);
    };
    const addContact = () => {
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { contacts: [...backside.contacts, { title: '', content: '' }] })));
        markReadyToSave();
        setTimeout(() => {
            const i = backside.contacts.length;
            const id = `backside-contact-${i}-title`;
            const node = document.getElementById(id);
            node === null || node === void 0 ? void 0 : node.focus();
        }, 0);
    };
    const handleFocusAppleTitle = () => {
        setActiveCardType('apple');
        setAppleCardIsFlipped(true);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChangeAppleTitle = (appleTitle) => {
        setBackside((backside) => (Object.assign(Object.assign({}, backside), { appleTitle })));
    };
    return (_jsxs("div", { className: styles.backsideWrapper, children: [_jsxs("div", { className: classNames(styles.control, styles.appleTitleInput), children: [_jsxs("label", { className: classNames(styles.label, styles.appleLabel), htmlFor: 'apple-details-title', children: [t('Название карты для Apple') + '*', _jsx(LogoSmall, {})] }), _jsx(Input, { id: 'apple-details-title', placeholder: t('введите название'), value: backside.appleTitle, onChange: (e) => {
                            handleChangeAppleTitle(e.target.value);
                        }, onFocus: handleFocusAppleTitle, onBlur: markReadyToSave, autoFocus: !isElementClick, hasError: hasTitleError })] }), _jsxs("div", { className: styles.detailsWrapper, children: [_jsx(BacksideDetails, {}), _jsx("div", { children: _jsx(Button, { type: 'button', icon: _jsx(Plus, {}), onClick: addDetailsBlock, isOutlined: true, children: t('Добавить блок') }) })] }), _jsx("div", { className: styles.separator }), _jsx("div", { className: styles.contactsTitle, children: t('Ссылки') }), _jsx(BacksideContacts, {}), _jsx("div", { children: _jsx(Button, { type: 'button', icon: _jsx(Plus, {}), onClick: addContact, isOutlined: true, children: t('Добавить ссылку') }) })] }));
};
