import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Promocode } from '@/components/Promocode/Promocode';
import { money, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const FormPurchaseModalInfo = memo(({ selectedPurchase, currency, showItems, calculationLoading, totalAmount, discountAmount, remainingAmount, bonusesCollected, labelWidth, }) => {
    const { t } = useTranslation();
    const isEdit = !!selectedPurchase;
    return isEdit ? (_jsxs("div", { className: styles.info, children: [selectedPurchase.promocode && (_jsx(FormFieldRow, { label: t('Промокод'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx(Promocode, { code: selectedPurchase.promocode, isDark: true }) })), _jsx(FormFieldRow, { label: t('Сумма со скидкой'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: money({
                        input: selectedPurchase.totalAmount,
                        withFraction: true,
                        removeFractionZeros: false,
                        currency,
                    }) }) }), _jsx(FormFieldRow, { label: t('Бонусов списано'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: numberRound(selectedPurchase.bonusesApplied) }) }), _jsx(FormFieldRow, { label: t('Бонусов начислено'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: numberRound(selectedPurchase.bonusesCollected) }) }), _jsx(FormFieldRow, { label: t('Оплачено'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: money({
                        input: selectedPurchase.paidAmount,
                        withFraction: true,
                        removeFractionZeros: false,
                        currency,
                    }) }) })] })) : (_jsxs("div", { className: classNames(styles.info, {
            [styles.loading]: calculationLoading,
        }), children: [showItems && (_jsx(FormFieldRow, { label: t('Итого'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: money({
                        input: totalAmount,
                        withFraction: true,
                        removeFractionZeros: false,
                        currency,
                    }) }) })), discountAmount > 0 && (_jsx(FormFieldRow, { label: t('Скидка'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: money({
                        input: discountAmount,
                        withFraction: true,
                        removeFractionZeros: false,
                        currency,
                    }) }) })), _jsx(FormFieldRow, { label: t('К оплате'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsx("span", { className: styles.text, children: money({
                        input: remainingAmount,
                        withFraction: true,
                        removeFractionZeros: false,
                        currency,
                    }) }) }), _jsx(FormFieldRow, { label: t('Будет начислено'), labelWidth: labelWidth, labelClassName: styles.label, alignItems: 'center', children: _jsxs("span", { className: styles.text, children: [bonusesCollected > 0 ? '+' : '', t('PURCHASE_TO_EARN_AMOUNT', {
                            amount: numberRound(bonusesCollected),
                            count: bonusesCollected,
                        })] }) })] }));
});
