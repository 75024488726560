import { jsx as _jsx } from "react/jsx-runtime";
import { Info } from '@/icons/Info';
import { Enter } from '@/icons/status/Enter';
import { ErrorTriangle } from '@/icons/status/ErrorTriangle';
const defaultHttpErrorMessage = 'Ошибка при обращении к серверу, повторите попытку позже';
const httpErrorMessageMap = {
    400: 'В запросе содержатся некорректные данные',
    401: 'Время действия сессии истекло, пожалуйста, войдите снова',
    403: 'Недостаточно прав для доступа к ресурсу',
    404: 'Ресурс не найден',
    FETCH_ERROR: 'Вы офлайн',
};
const httpErrorIconMap = {
    400: _jsx(Info, {}),
    401: _jsx(Enter, {}),
    403: _jsx(Info, {}),
    404: _jsx(Info, {}),
    FETCH_ERROR: _jsx(Info, {}),
};
const defaultHttpErrorLevel = 'error';
const httpErrorLevelMap = {
    400: 'warning',
    401: 'warning',
    403: 'warning',
    404: 'warning',
    FETCH_ERROR: 'warning',
};
export const getHttpErrorIcon = (status = -1) => httpErrorIconMap[status] || _jsx(ErrorTriangle, {});
export const getHttpErrorMessage = (status = -1) => {
    if (httpErrorMessageMap[status])
        return httpErrorMessageMap[status];
    return defaultHttpErrorMessage;
};
export const getHttpErrorLevel = (status = -1) => {
    if (httpErrorLevelMap[status])
        return httpErrorLevelMap[status];
    return defaultHttpErrorLevel;
};
