import { jsx as _jsx } from "react/jsx-runtime";
import { User } from '@/icons/User';
import { getClientUpdateContent } from './clientUpdateContent';
import { ClientUpdateTitle } from './clientUpdateTitle';
// создан клиент
export const clientUpdate0 = (args) => {
    const { event, translate: t } = args;
    const { operatorName } = event.params || {};
    return {
        Icon: _jsx(User, { size: 20 }),
        title: (_jsx(ClientUpdateTitle, Object.assign({}, args, { children: operatorName
                ? t('ADDED_BY_EMPLOYEE', { operatorName })
                : t('Добавлен сотрудником') }))),
        content: getClientUpdateContent(Object.assign(Object.assign({}, args), { hiddenParams: ['operator'] })),
    };
};
