import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { AreaChart } from '@/icons/AreaChart';
import { HorizontalBars } from '@/icons/HorizontalBars';
import { Search } from '@/icons/Search';
import styles from './styles.module.scss';
export const RFMChartEmpty = ({ type, chartType, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.empty, children: [_jsx("div", { className: styles.emptyIcon, children: type === 'no-search-results' ? (_jsx(Search, { size: 24 })) : chartType === 'area' ? (_jsx(AreaChart, {})) : (_jsx(HorizontalBars, {})) }), _jsx("div", { className: styles.emptyTitle, children: type === 'no-search-results'
                    ? t('Ничего не найдено')
                    : t('Недостаточно данных') }), _jsx("div", { className: styles.emptyDescription, children: type === 'no-search-results'
                    ? t('Попробуйте изменить фильтры')
                    : chartType === 'area'
                        ? t('MAXMA накапливает историю сегментов и скоро выведет график')
                        : t('Загрузите данные клиентов, чтобы увидеть распределение по сегментам') })] }));
};
