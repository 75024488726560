import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { addClientModalSelector } from '@/store/selectors/modals';
import { updateAddClientModal, } from '@/store/slice/modals';
export const useClientAddModal = () => {
    const dispatch = useAppDispatch();
    const addClientModalState = useAppSelector(addClientModalSelector);
    const open = useCallback((payload = {}) => {
        dispatch(updateAddClientModal(Object.assign({ isOpen: true }, payload)));
    }, []);
    const close = useCallback((result) => {
        var _a;
        (_a = addClientModalState.closeHandler) === null || _a === void 0 ? void 0 : _a.call(addClientModalState, result);
        dispatch(updateAddClientModal({
            isOpen: false,
            closeHandler: undefined,
        }));
    }, [addClientModalState.closeHandler]);
    const setVisibility = useCallback((isVisible) => {
        dispatch(updateAddClientModal({ isOpen: isVisible }));
    }, []);
    return {
        isOpen: addClientModalState.isOpen,
        open,
        close,
        setVisibility,
    };
};
