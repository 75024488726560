import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd';
import { Cancel } from '@/icons/Cancel';
import { Drag } from '@/icons/Drag';
import { remove, reorder } from '@/utils/arrays';
import styles from '../styles.module.scss';
export const DragDrop = (props) => {
    const { draggableClassName, items, getItemKey, renderItem, createItem, onReorder, } = props;
    const handleDragStart = useCallback(() => {
        var _a;
        const element = document.activeElement;
        (_a = element === null || element === void 0 ? void 0 : element.blur) === null || _a === void 0 ? void 0 : _a.call(element);
    }, []);
    const handleDragEnd = useCallback((result) => {
        if (result.destination) {
            const reordered = reorder(items, result.source.index, result.destination.index);
            onReorder(reordered);
        }
    }, [items, onReorder]);
    const handleRemoveItem = (i) => {
        const newItems = remove(items, i);
        if (!newItems.length)
            newItems.push(createItem());
        onReorder(newItems);
    };
    return (_jsx(DragDropContext, { onDragStart: handleDragStart, onDragEnd: handleDragEnd, children: _jsx(Droppable, { droppableId: 'droppable', children: ({ innerRef: droppableRef, droppableProps, placeholder }, { isDraggingOver }) => {
                return (_jsxs("div", Object.assign({ className: classNames({
                        [styles.draggingOver]: isDraggingOver,
                    }), ref: droppableRef }, droppableProps, { children: [items.map((item, i) => {
                            return (_jsx(Draggable, { draggableId: getItemKey(item, i), index: i, children: ({ innerRef, draggableProps, dragHandleProps }, { isDragging, isDropAnimating }) => {
                                    const rendered = renderItem(item, i);
                                    return (_jsxs("div", Object.assign({ ref: innerRef, className: classNames(draggableClassName, styles.draggable, {
                                            [styles.dragging]: isDragging,
                                            [styles.animating]: isDropAnimating,
                                        }) }, draggableProps, { children: [rendered, _jsxs("div", { className: styles.dragControls, children: [_jsx("div", Object.assign({ className: styles.drag }, dragHandleProps, { tabIndex: -1, children: _jsx(Drag, {}) })), _jsx("button", { type: 'button', className: styles.cancel, tabIndex: -1, onClick: () => handleRemoveItem(i), children: _jsx(Cancel, {}) })] })] })));
                                } }, getItemKey(item, i)));
                        }), placeholder] })));
            } }) }));
};
