import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { WalletCardFormSection } from '@/components/wallet-cards/WalletCardFormSection/WalletCardFormSection';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import styles from '../../styles.module.scss';
import { BarcodeItem } from './BarcodeItem';
export const Barcode = () => {
    const { setAppleCardIsFlipped, setGoogleCardIsFlipped } = useWalletCardForm();
    useEffect(() => {
        setAppleCardIsFlipped(false);
        setGoogleCardIsFlipped(false);
    }, []);
    return (_jsxs(WalletCardFormSection, { children: [_jsxs("div", { className: styles.barcodeRow, children: [_jsx(BarcodeItem, { code: 'code128' }), _jsx(BarcodeItem, { code: 'pdf417' })] }), _jsxs("div", { className: styles.barcodeRow, children: [_jsx(BarcodeItem, { code: 'qr-code' }), _jsx(BarcodeItem, { code: 'aztec' })] }), _jsx("div", { className: styles.barcodeRow, children: _jsx(BarcodeItem, { code: '' }) })] }));
};
