import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { TRIGGER_TYPE_BIRTHDAY, TRIGGER_TYPE_BONUS_EXPIRING, TRIGGER_TYPE_CHILD_BIRTHDAY, TRIGGER_TYPE_SCHEDULE, } from '@/const/mailing-triggers';
import { FormMailingChannels } from './controls/FormMailingChannels';
import { FormMailingGoalDaysTo } from './controls/FormMailingGoalDaysTo';
import { FormMailingGoalFilters } from './controls/FormMailingGoalFilters';
import { FormMailingGoalType } from './controls/FormMailingGoalType';
import { FormMailingName } from './controls/FormMailingName';
import { FormMailingOffset } from './controls/FormMailingOffset';
import { FormMailingOffsetHelp } from './controls/FormMailingOffsetHelp';
import { FormMailingRecipients } from './controls/FormMailingRecipients';
import { FormMailingRepeat } from './controls/FormMailingRepeat';
import { FormMailingRewards } from './controls/FormMailingRewards';
import { FormMailingSchedule } from './controls/FormMailingSchedule';
import { FormMailingTriggerEmailReceived } from './controls/FormMailingTriggerEmailReceived';
import { FormMailingTriggerFilters } from './controls/FormMailingTriggerFilters';
import { FormMailingTriggerSchedule } from './controls/FormMailingTriggerSchedule';
import { FormMailingTriggerType } from './controls/FormMailingTriggerType';
export const FormMailingStepMain = ({ type, flow, channelsEnabled, channelsInput, clientExtraFields, counters, hasRecommendationsFeature, offers, promocodes, giftCards, levels, emailMailings, currency, timeZone, labelWidth, isEdit, }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext();
    const triggerType = watch('triggerType');
    const isFlow = typeof (flow === null || flow === void 0 ? void 0 : flow.id) === 'number';
    return (_jsxs(_Fragment, { children: [_jsxs(FormFieldset, { children: [_jsx(FormMailingName, { labelWidth: labelWidth }), channelsInput === 'buttons' && (_jsx(FormMailingChannels, { labelWidth: labelWidth, channelsEnabled: channelsEnabled }))] }), _jsx(FormFieldset, { title: t('Получатели') + '*', titleOffset: labelWidth, description: t('Согласные на рассылку'), children: _jsx(FormMailingRecipients, { extraFields: clientExtraFields, counters: counters, labelWidth: labelWidth }) }), type === 'auto' && (_jsxs(FormFieldset, { title: t('Условия отправки'), titleOffset: labelWidth, description: t('Событие, при котором рассылка автоматически отправится'), children: [_jsx(FormMailingOffset, { isDisabled: isFlow && triggerType === TRIGGER_TYPE_SCHEDULE, isDisabledBeforeOption: isFlow &&
                            typeof triggerType !== 'undefined' &&
                            ![
                                TRIGGER_TYPE_BIRTHDAY,
                                TRIGGER_TYPE_BONUS_EXPIRING,
                                TRIGGER_TYPE_CHILD_BIRTHDAY,
                            ].includes(triggerType), isDisabledScheduleOption: isFlow &&
                            typeof triggerType !== 'undefined' &&
                            triggerType !== TRIGGER_TYPE_SCHEDULE, labelWidth: labelWidth }), _jsx(FormMailingTriggerSchedule, { labelWidth: labelWidth }), _jsx(FormMailingTriggerType, { hasRecommendationsFeature: hasRecommendationsFeature, hasLevelsFeature: !!(levels === null || levels === void 0 ? void 0 : levels.length), isDisabled: isFlow, labelWidth: labelWidth }), _jsx(FormMailingTriggerFilters, { labelWidth: labelWidth }), _jsx(FormMailingTriggerEmailReceived, { emailMailings: emailMailings, isEdit: isEdit, labelWidth: labelWidth }), _jsx(FormMailingRepeat, { labelWidth: labelWidth }), _jsx(FormMailingOffsetHelp, { labelWidth: labelWidth })] })), _jsx(FormFieldset, { title: t('Поощрения внутри рассылки'), titleOffset: labelWidth, description: t('Бонусы, акции, промокоды, подарочные карты'), children: _jsx(FormMailingRewards, { offers: offers, promocodes: promocodes, giftCards: giftCards === null || giftCards === void 0 ? void 0 : giftCards.filter((card) => card.isActive), currency: currency, labelWidth: labelWidth }) }), type === 'manual' && (_jsx(FormFieldset, { title: t('Дата отправки'), titleOffset: labelWidth, children: _jsx(FormMailingSchedule, { timeZone: timeZone, labelWidth: labelWidth }) })), _jsxs(FormFieldset, { title: t('Считать достижением цели'), titleOffset: labelWidth, description: t('Отобразится в таблицах как CR (Conversion Rate)'), children: [_jsx(FormMailingGoalType, { labelWidth: labelWidth }), _jsx(FormMailingGoalDaysTo, { labelWidth: labelWidth }), _jsx(FormMailingGoalFilters, { labelWidth: labelWidth })] })] }));
};
