import { mailingChannel } from './mailingChannel';
import { mailingMailingEvent } from './mailingMailingEvent';
import { mailingMailingSending } from './mailingMailingSending';
import { mailingSendingClick } from './mailingSendingClick';
export const mailing = (args) => {
    const { event } = args;
    switch (event.eventType) {
        // запланирована рассылка
        case 'mailing_event': {
            return mailingMailingEvent(args);
        }
        // сформирована рассылка
        case 'mailing_sending': {
            return mailingMailingSending(args);
        }
        // переход по ссылке в сообщении
        case 'sending_click': {
            return mailingSendingClick(args);
        }
        // каналы
        case 'sending_push':
        case 'sending_webpush':
        case 'sending_sms':
        case 'sending_viber':
        case 'sending_email':
        case 'sending_chat': {
            return mailingChannel(args);
        }
        default: {
            return {};
        }
    }
};
