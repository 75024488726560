import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { PushForm } from '../push/PushForm';
import { SMSForm } from '../sms/SMSForm';
import formStyles from '../styles.module.scss';
import { ViberForm } from '../viber/ViberForm';
import { CascadeFormHelp } from './CascadeFormHelp';
import { CascadeFormTabs } from './CascadeFormTabs';
import styles from './styles.module.scss';
export const CascadeForm = () => {
    const { selectedNode, validationErrorMap } = useInteractiveFlow();
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const channelsError = (errors === null || errors === void 0 ? void 0 : errors.channels) ? (_jsx("div", { className: classNames(formStyles.error, styles.channelsError), children: errors.channels })) : null;
    return (_jsx("div", { children: _jsx(CascadeFormTabs, { push: _jsxs("div", { className: formStyles.form, children: [channelsError, _jsx(CascadeFormHelp, {}), _jsx(PushForm, {})] }), viber: _jsxs("div", { className: formStyles.form, children: [channelsError, _jsx(CascadeFormHelp, {}), _jsx(ViberForm, {})] }), sms: _jsxs("div", { className: formStyles.form, children: [channelsError, _jsx(CascadeFormHelp, {}), _jsx(SMSForm, {})] }) }) }));
};
