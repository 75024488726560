import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ArrowAlt } from '@/icons/ArrowAlt';
import { Frown } from '@/icons/rfm/Frown';
import { Smile } from '@/icons/rfm/Smile';
import { numberRound } from '@/utils/numbers';
import { RFMMovementName } from '../RFMMovementName/RFMMovementName';
import { getMovementStatus } from './RFMMovementStatus';
import styles from './styles.module.scss';
export const RFMMovementsTable = ({ className, rfmSegments = [], movements: unsortedMovements = [], isLocked, sortMovements = true, wrapMovementName = false, }) => {
    const { t } = useTranslation();
    const movements = [...unsortedMovements];
    if (sortMovements)
        movements.sort((a, b) => b.count - a.count);
    return (_jsxs("div", { className: classNames(className, styles.wrapper, {
            [styles.locked]: isLocked,
        }), children: [_jsxs("div", { className: classNames(styles.row, styles.header), children: [_jsx("div", { className: styles['column-1'], children: t('Переток') }), _jsx("div", { className: styles['column-2'], children: t('Кол-во') }), _jsx("div", { className: styles['column-3'], children: "%" }), _jsx("div", { className: styles['column-4'] })] }), movements.map((movement, idx) => {
                const key = `${movement.from}-${movement.to}-${movement.percent}-${idx}`;
                const fromSegment = rfmSegments.find((s) => s.code === movement.from);
                const toSegment = rfmSegments.find((s) => s.code === movement.to);
                if (!fromSegment || !toSegment)
                    return null;
                const status = getMovementStatus(fromSegment, toSegment);
                return (_jsxs("div", { className: classNames(styles.row, styles.rowMovement), children: [_jsx("div", { className: styles['column-1'], children: _jsxs("div", { className: classNames(styles.movement, {
                                    [styles.wrap]: wrapMovementName,
                                }), children: [_jsxs("div", { className: styles.movementFirst, children: [_jsx(RFMMovementName, { className: styles.movementName, segment: fromSegment }), _jsx(ArrowAlt, {})] }), _jsx(RFMMovementName, { className: styles.movementName, segment: toSegment, ellipsis: true })] }) }), _jsx("div", { className: styles['column-2'], children: _jsx("div", { className: styles.count, children: numberRound(movement.count) }) }), _jsx("div", { className: styles['column-3'], children: _jsxs("div", { className: styles.percent, children: [numberRound(movement.percent), "%"] }) }), _jsx("div", { className: styles['column-4'], children: !isLocked && status === 'positive' ? (_jsx("div", { className: styles.positive, children: _jsx(Smile, {}) })) : !isLocked && status === 'negative' ? (_jsx("div", { className: styles.negative, children: _jsx(Frown, {}) })) : (_jsx("div", { className: styles.dash, children: "\u2013" })) })] }, key));
            })] }));
};
