import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@/components/Avatar/Avatar';
import { FlagRU } from '@/icons/flags/FlagRU';
import { FlagUS } from '@/icons/flags/FlagUS';
import { AppNavDropdown, } from '../AppNavDropdown/AppNavDropdown';
import styles from './styles.module.scss';
export const AppSidebarUser = memo(({ user, isCollapsed, isHidden, onLogout, onSwitchLanguage, onSwitchBrand, }) => {
    const { t, i18n } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const { isSuperAdmin, isSuperUser } = user;
    const { name, email, brand } = user.user;
    const userName = name || email || '';
    const hasSwitchBrandControl = isSuperAdmin || isSuperUser;
    const handleLanguageSwitch = useCallback(() => {
        onSwitchLanguage(i18n.language === 'ru' ? 'en' : 'ru');
    }, [onSwitchLanguage, i18n]);
    const menu = [
        {
            id: 'switch-lang',
            label: t('LANG_SWITCH'),
            left: i18n.language === 'ru' ? _jsx(FlagUS, {}) : _jsx(FlagRU, {}),
            onClick: handleLanguageSwitch,
        },
        {
            id: 'switch-brand',
            label: t('Переключить бренд'),
            onClick: onSwitchBrand,
            shouldDisplay: hasSwitchBrandControl,
        },
        {
            id: 'logout',
            label: t('LOGOUT'),
            onClick: onLogout,
        },
    ];
    return (_jsx("div", { className: classNames(styles.wrapper, {
            [styles.collapsed]: isCollapsed,
            [styles.hidden]: isHidden,
        }), children: _jsx(AppNavDropdown, { items: menu, placement: 'right-end', offset: [0, 0], preventOverflow: 10, onOpen: () => setHovered(true), onClose: () => setHovered(false), leaveDelay: 200, enterTouchDelay: 0, leaveTouchDelay: 3000, children: _jsxs("button", { className: classNames(styles.button, {
                    [styles.hovered]: hovered,
                }), children: [_jsx(Avatar, { className: styles.avatar, username: userName }), _jsxs("div", { className: styles.info, children: [_jsx("div", { className: styles.name, children: userName }), _jsx("div", { className: styles.brand, children: brand })] }), _jsx("div", { className: styles.shadow })] }) }) }));
});
