import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Popper } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { DateInput } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { Radio } from '@/components/Radio2/Radio';
import { Check } from '@/icons/Check';
import { Chevron } from '@/icons/Chevron';
import { getMonthName } from '@/utils/getMonthName';
import styles from './styles.module.scss';
const addOneDay = (date) => moment(date).add(1, 'day').toDate();
const subtractOneDay = (date) => moment(date).subtract(1, 'day').toDate();
const formateDate = (date) => `${moment(date).date()}
   ${getMonthName(String(moment(date).month() + 1))}
   ${moment(date).year()}`;
export const PopupWithOptions = ({ labelWidth, prefix, postfix, fromDateFieldName, daysNumberFieldName, fromDateFieldValue, daysNumberFieldValue, neutralFieldText, startDate, endDate, }) => {
    const { t } = useTranslation();
    const [openPopup, setOpenPopup] = useState(false);
    const { control, formState: { errors }, setValue, setError, clearErrors, } = useFormContext();
    const initOptionsState = {
        [daysNumberFieldName]: { value: daysNumberFieldValue || 1, checked: false },
        [fromDateFieldName]: {
            value: fromDateFieldValue ||
                (endDate && subtractOneDay(endDate)) ||
                (startDate && addOneDay(startDate)) ||
                moment().toDate(),
            checked: false,
        },
    };
    const [options, setOptions] = useState(initOptionsState);
    const handleClosePopup = useCallback(() => {
        setOpenPopup(false);
        clearErrors([daysNumberFieldName, fromDateFieldName]);
    }, []);
    const handleCheckOptions = (field) => {
        clearErrors(field || [daysNumberFieldName, fromDateFieldName]);
        setOptions((prevValue) => {
            for (const key in prevValue) {
                const currentCheck = prevValue[key];
                if (field !== key)
                    currentCheck.checked = false;
                else
                    currentCheck.checked = true;
            }
            return Object.assign({}, prevValue);
        });
    };
    const handleSelectOptions = (field, value, checked = true) => {
        clearErrors(field);
        setOptions((prevValue) => {
            return Object.assign(Object.assign({}, prevValue), { [field]: Object.assign(Object.assign({}, prevValue[field]), { value: value }) });
        });
        checked && handleCheckOptions(field);
    };
    const handleConfirmChange = () => {
        const [field, data] = Object.entries(options).find(([, { checked }]) => checked) || [];
        if (!field) {
            setValue(fromDateFieldName, undefined);
            setValue(daysNumberFieldName, undefined);
        }
        else if (!(data === null || data === void 0 ? void 0 : data.value)) {
            setError(field, {});
            return;
        }
        if (field === fromDateFieldName) {
            setValue(fromDateFieldName, moment(data === null || data === void 0 ? void 0 : data.value).format());
            setValue(daysNumberFieldName, undefined);
        }
        if (field === daysNumberFieldName) {
            setValue(daysNumberFieldName, (data === null || data === void 0 ? void 0 : data.value) || '');
            setValue(fromDateFieldName, undefined);
        }
        handleClosePopup();
    };
    const minDate = startDate ? addOneDay(startDate) : null;
    const maxDate = endDate ? subtractOneDay(endDate) : null;
    useEffect(() => {
        setOptions(initOptionsState);
        if (fromDateFieldValue) {
            handleCheckOptions(fromDateFieldName);
            handleSelectOptions(fromDateFieldName, fromDateFieldValue);
        }
        if (daysNumberFieldValue) {
            handleCheckOptions(daysNumberFieldName);
            handleSelectOptions(daysNumberFieldName, daysNumberFieldValue);
        }
        if (!fromDateFieldValue && !daysNumberFieldValue)
            handleCheckOptions();
    }, [openPopup]);
    const activatedValueForm = daysNumberFieldValue
        ? `${t('DAYS_EXPIRING_AFTER', {
            count: +daysNumberFieldValue,
        })}`
        : fromDateFieldValue
            ? formateDate(fromDateFieldValue)
            : neutralFieldText;
    const wrapperRef = useRef(null);
    const content = (_jsxs("div", { className: styles.popupContent, children: [_jsx("div", { className: styles.popupContentRow, children: _jsx(Radio, { checked: !options[fromDateFieldName].checked &&
                        !options[daysNumberFieldName].checked, onClick: () => handleCheckOptions(), children: neutralFieldText }) }), _jsxs("div", { className: styles.popupContentRow, children: [_jsx(Radio, { checked: options[daysNumberFieldName].checked, onClick: () => handleCheckOptions(daysNumberFieldName), children: t('через') }), _jsx(Controller, { name: daysNumberFieldName, control: control, render: () => (_jsx(Input, { hasError: !!errors[daysNumberFieldName], wrapperClassName: styles.popupContentInput, id: daysNumberFieldName, type: 'number', inputMode: 'numeric', value: options[daysNumberFieldName].value, step: 'any', preventNegativeNumbers: true, shouldHideSpinButtons: true, rightText: t('DAYS_EXPIRING', {
                                count: Number(options[daysNumberFieldName].value || 1),
                            }), onChange: (event) => {
                                let value = event.target.value;
                                if (event.target.value) {
                                    if (+event.target.value === 0)
                                        value = 1;
                                    if (+event.target.value > 1000)
                                        value = 999;
                                }
                                handleSelectOptions(daysNumberFieldName, value.toString());
                            } })) })] }), _jsxs("div", { className: styles.popupContentRow, children: [_jsx(Radio, { checked: options[fromDateFieldName].checked, onClick: () => handleCheckOptions(fromDateFieldName), children: t('в дату') }), _jsx("div", { className: styles.popupContentDatePicker, children: _jsx(Controller, { name: fromDateFieldName, control: control, render: () => (_jsx(DateInput, { hasError: !!errors[fromDateFieldName], id: fromDateFieldName, placeholderText: t('ДД.ММ.ГГГГ'), selected: options[fromDateFieldName].value
                                    ? moment(options[fromDateFieldName].value).toDate()
                                    : null, onChange: (date) => {
                                    date && handleSelectOptions(fromDateFieldName, date);
                                }, selectsEnd: true, minDate: minDate, maxDate: maxDate })) }) })] }), _jsx("div", { className: styles.popupContentButtonBlock, children: _jsx(Button, { type: 'button', onClick: handleConfirmChange, theme: 'primary', size: 'full-width', children: _jsx(Check, { width: 12, height: 8 }) }) })] }));
    return (_jsx(FormFieldRow, { className: styles.fieldRow, alignItems: 'center', labelWidth: labelWidth, children: _jsx("div", { ref: wrapperRef, children: _jsxs("div", { className: styles.activated, children: [prefix, "\u00A0", _jsx("span", { onClick: () => setOpenPopup((prevValue) => !prevValue), className: styles.selectedValue, children: activatedValueForm }), _jsx("div", { onClick: () => setOpenPopup((prevValue) => !prevValue), className: classNames(styles.chevronWrapper, {
                            [styles.flipped]: openPopup,
                        }), children: _jsx(Chevron, { fill: 'currentColor' }) }), !!daysNumberFieldValue && _jsxs(_Fragment, { children: ["\u00A0", postfix] }), _jsx(Popper, { className: styles.popper, open: openPopup, anchorEl: wrapperRef.current, modifiers: [{ name: 'offset', options: { offset: [-4, 7] } }], placement: 'bottom-start', disablePortal: true, children: _jsx(ClickAwayListener, { onClickAway: handleClosePopup, children: content }) })] }) }) }));
};
