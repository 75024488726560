import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Input } from '@/components/Input/Input';
import { Chevron } from '@/icons/Chevron';
import { CloseCircle } from '@/icons/CloseCircle';
import styles from './styles.module.scss';
export const CheckboxSelect = memo(({ id, text, options, value, onChange, resetItemLabel = '', hasResetButton = true, hasError = false, listWidth, listHeight, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = useCallback(() => setIsOpen(true), []);
    const handleClose = useCallback(() => setIsOpen(false), []);
    const handleToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);
    const handleStopClicks = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const handleChange = useCallback((e) => {
        const item = Number(e.target.value);
        if (value.includes(item))
            onChange(value.filter((i) => i !== item));
        else
            onChange([...value, item]);
    }, [value, onChange]);
    const handleReset = useCallback(() => {
        onChange([]);
    }, [onChange]);
    const handleResetAndClose = useCallback(() => {
        onChange([]);
        setIsOpen(false);
    }, [onChange]);
    const listStyle = {
        '--list-width': listWidth ? `${listWidth}px` : undefined,
        '--list-height': listHeight ? `${listHeight}px` : undefined,
    };
    const list = (_jsxs("div", { className: classNames(styles.list, {
            [styles.hasMaxHeight]: !!listHeight,
        }), onClick: handleStopClicks, style: listStyle, children: [resetItemLabel && (_jsx(Checkbox, { className: styles.item, checked: !value.length, onChange: handleResetAndClose, children: resetItemLabel }, 'reset')), options.map((option) => (_jsx(Checkbox, { className: styles.item, checked: value.includes(option.value), value: option.value, onChange: handleChange, children: option.label }, option.value)))] }));
    const icon = (_jsx(Chevron, { className: classNames(styles.chevron, {
            [styles.flipped]: isOpen,
        }), fill: 'currentColor' }));
    const popperModifiers = [
        {
            name: 'offset',
            options: { offset: [0, -7] },
        },
    ];
    return (_jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(Tooltip, { open: isOpen, PopperProps: {
                className: styles.popup,
                modifiers: popperModifiers,
                onTouchEnd: (e) => e.stopPropagation(),
            }, title: list, disableFocusListener: true, disableHoverListener: true, onOpen: handleOpen, TransitionProps: { timeout: 0 }, placement: 'bottom-start', onKeyDown: (e) => {
                if (e.code === 'Escape')
                    handleClose();
            }, children: _jsxs("div", { className: classNames(styles.resetWrapper, {
                    [styles.hasResetButton]: value.length > 0,
                }), children: [_jsxs("div", { className: styles.chevronWrapper, children: [_jsx(Input, { className: styles.input, id: id, type: 'button', value: text, onClick: handleToggle, hasError: hasError }), icon] }), value.length > 0 && hasResetButton && !resetItemLabel && (_jsx("button", { className: styles.reset, type: 'button', onClick: handleReset, children: _jsx(CloseCircle, {}) }))] }) }) }));
});
