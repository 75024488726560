import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { SearchInput } from '@/components/SearchInput/SearchInput';
import { ShopGroupInputAddButton } from './ShopGroupInputAddButton';
import { ShopGroupInputItem } from './ShopGroupInputItem';
import styles from './styles.module.scss';
export const ShopGroupInputList = ({ shopGroups, selectedShopGroupId, onSelectShopGroup, onSelectShopGroupToChange, onAddGroup, width, height, }) => {
    const [search, setSearch] = useState('');
    const filteredShopGroups = shopGroups.filter((shopGroup) => shopGroup.name.toLowerCase().includes(search.trim().toLowerCase()));
    const addButton = useRef(null);
    const handleResetSearch = useCallback(() => {
        var _a;
        (_a = document.getElementById('shoup-group-input-search')) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    const handleKeyUp = useCallback((e) => {
        var _a;
        if (e.key === 'Enter')
            if (!filteredShopGroups.length && search.trim())
                (_a = addButton.current) === null || _a === void 0 ? void 0 : _a.click();
    }, [filteredShopGroups.length, search]);
    const handleStopClicks = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const listStyle = {
        '--list-width': width ? `${width}px` : undefined,
        '--list-height': height ? `${height}px` : undefined,
    };
    return (_jsxs("div", { className: styles.list, onClick: handleStopClicks, style: listStyle, children: [_jsx("div", { className: styles.searchBox, children: _jsx(SearchInput, { autoComplete: 'off', id: 'shoup-group-input-search', value: search, onChange: setSearch, onReset: handleResetSearch, onKeyUp: handleKeyUp, debounceDelay: 0, hasAutoFocus: true }) }), !!filteredShopGroups.length && (_jsx("div", { className: classNames(styles.listWrapper, {
                    [styles.hasMaxHeight]: !!height,
                }), children: filteredShopGroups.map((shopGroup) => (_jsx(ShopGroupInputItem, { shopGroup: shopGroup, isSelected: shopGroup.id === selectedShopGroupId, onSelect: onSelectShopGroup, onSelectToChange: onSelectShopGroupToChange, search: search }, shopGroup.id))) })), !filteredShopGroups.length && (_jsx("div", { className: styles.listFooter, children: _jsx(ShopGroupInputAddButton, { ref: addButton, onClick: () => onAddGroup(search.trim()), disabled: !search.trim() }) }))] }));
};
