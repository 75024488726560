import { jsx as _jsx } from "react/jsx-runtime";
import Tooltip from '@mui/material/Tooltip';
import { forwardRef } from 'react';
import styles from './styles.module.scss';
export const ShopGroupInputPopup = forwardRef(({ isOpen, onOpen, onClose, content, children }, ref) => {
    const popperModifiers = [
        {
            name: 'offset',
            options: { offset: [0, -7] },
        },
    ];
    return (_jsx(Tooltip, { open: isOpen, onOpen: onOpen, onClose: onClose, PopperProps: {
            className: styles.popup,
            modifiers: popperModifiers,
            onTouchEnd: (e) => e.stopPropagation(),
        }, title: content, disableFocusListener: true, disableHoverListener: true, TransitionProps: { timeout: 0 }, placement: 'bottom-start', ref: ref, children: children }));
});
