import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import { WalletCardLogo } from '@/components/wallet-cards/WalletCardLogo/WalletCardLogo';
import styles from './styles.module.scss';
export const GoogleWalletCardHeader = ({ imageUrl, title, onElementClick, clicksDisabled = false, }) => {
    return (_jsxs("div", { className: styles.header, children: [_jsx(WalletCardClickableElement, { className: styles.headerImageWrapper, type: 'google-logo', onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('logo'), disabled: clicksDisabled, children: _jsx(WalletCardLogo, { className: classNames(styles.headerImage, {
                        [styles.clicksDisabled]: clicksDisabled,
                    }), imageUrl: imageUrl }) }), _jsx("div", { className: styles.headerTitleWrapper, children: _jsx(WalletCardClickableElement, { full: !(title === null || title === void 0 ? void 0 : title.trim()), inline: true, onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('header-title'), disabled: clicksDisabled, children: _jsx("div", { className: styles.headerTitle, children: title }) }) })] }));
};
