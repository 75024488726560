import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertPopup } from '@/components/AlertPopup/AlertPopup';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { Trash } from '@/icons/Trash';
import styles from './styles.module.scss';
export const ShopGroupInputForm = ({ shopGroup, onUpdateShopGroup, onDeleteShopGroup, width, }) => {
    const { t } = useTranslation();
    const [shopGroupName, setShopGroupName] = useState(shopGroup.name);
    const [isLoading, setIsLoading] = useState(false);
    const [deletePopupIsOpen, setDeletePopupIsOpen] = useState(false);
    const deleteButton = useRef(null);
    const handleStopClicks = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const handleUpdateShopGroup = useCallback(() => {
        setIsLoading(true);
        onUpdateShopGroup(shopGroup.id, shopGroupName).finally(() => {
            setIsLoading(false);
        });
    }, [onUpdateShopGroup, shopGroup.id, shopGroupName]);
    const handleDeleteShopGroup = useCallback(() => {
        setIsLoading(true);
        onDeleteShopGroup(shopGroup.id).finally(() => {
            setIsLoading(false);
        });
    }, [onDeleteShopGroup, shopGroup.id]);
    const handleKeyUp = useCallback((e) => {
        if (e.code === 'Enter')
            handleUpdateShopGroup();
    }, [handleUpdateShopGroup]);
    const formStyle = {
        '--form-width': width ? `${width}px` : undefined,
    };
    return (_jsxs("div", { className: styles.form, onClick: handleStopClicks, style: formStyle, children: [_jsx(Input, { value: shopGroupName, onChange: (e) => {
                    setShopGroupName(e.target.value);
                }, disabled: isLoading, onKeyUp: handleKeyUp, autoFocus: true }), _jsxs("div", { className: styles.formFooter, children: [_jsx(Button, { type: 'button', theme: 'primary', onClick: handleUpdateShopGroup, disabled: !shopGroupName.trim() || isLoading, children: t('Сохранить группу') }), _jsx(Button, { type: 'button', icon: _jsx(Trash, {}), onClick: () => {
                            setDeletePopupIsOpen(true);
                        }, disabled: isLoading, ref: deleteButton }), _jsx(AlertPopup, { isOpen: deletePopupIsOpen, onClose: () => {
                            setDeletePopupIsOpen(false);
                        }, anchorEl: deleteButton.current, title: t('Удалить группу?'), description: t('Содержащиеся точки останутся в системе вне групп'), agreeLabel: t('Отмена'), cancelLabel: t('Удалить'), onCancel: handleDeleteShopGroup })] })] }));
};
