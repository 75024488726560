import { clientUpdate0 } from './clientUpdate0';
import { clientUpdate1 } from './clientUpdate1';
import { clientUpdate2 } from './clientUpdate2';
import { clientUpdate3 } from './clientUpdate3';
import { clientUpdate4 } from './clientUpdate4';
import { clientUpdate5 } from './clientUpdate5';
import { clientUpdate6 } from './clientUpdate6';
import { clientUpdate7 } from './clientUpdate7';
export const clientUpdate = (args) => {
    const { event } = args;
    switch (event.eventType) {
        // создан клиент
        case '0': {
            return clientUpdate0(args);
        }
        // изменен клиент
        case '1': {
            return clientUpdate1(args);
        }
        // изменен клиент (по API)
        case '2': {
            return clientUpdate2(args);
        }
        // удален
        case '3': {
            return clientUpdate3(args);
        }
        // заблокирован
        case '4': {
            return clientUpdate4(args);
        }
        // разблокирован
        case '5': {
            return clientUpdate5(args);
        }
        // создан клиент (по API)
        case '6': {
            return clientUpdate6(args);
        }
        // удален клиент (по API)
        case '7': {
            return clientUpdate7(args);
        }
        default: {
            return {};
        }
    }
};
