import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { DateInput } from '@/components/DateInput/DateInput';
import { DatePeriodInputs } from './DatePeriodInputs';
import { DatePeriodPresets } from './DatePeriodPresets';
import { dateValidator } from './dateValidator';
import styles from './styles.module.scss';
export const DatePeriodCalendar = memo(({ start, end, onChange, onApply }) => {
    const { t } = useTranslation();
    const handleChangeCalendar = () => {
        /* do nothing */
    };
    const handleSelectDateFromCalendar = (date) => {
        const oldStart = start;
        const oldEnd = end;
        if (oldStart && !oldEnd) {
            if (moment(oldStart).isAfter(moment(date)))
                onChange(date, oldStart);
            else
                onChange(oldStart, date);
            return;
        }
        if (!oldStart && oldEnd) {
            if (moment(oldEnd).isAfter(moment(date)))
                onChange(date, oldEnd);
            else
                onChange(oldEnd, date);
            return;
        }
        onChange(date, null);
    };
    const handleChangeFromHelpers = (start, end, shouldClose) => {
        onChange(start, end, shouldClose);
    };
    return (_jsxs("div", { className: styles.calendar, children: [_jsx(DatePeriodPresets, { start: start, end: end, onChange: handleChangeFromHelpers }), _jsxs("div", { onKeyDownCapture: (e) => {
                    if (e.code === 'Enter')
                        e.stopPropagation();
                }, children: [_jsx(DatePeriodInputs, { start: start, end: end, onChange: handleChangeFromHelpers }), _jsx(DateInput, { calendarClassName: classNames(styles.dateInputCalendar, styles.inline), selected: start, startDate: start, endDate: end, openToDate: end || undefined, selectsStart: !start, selectsEnd: !!start && !end, onChange: handleChangeCalendar, onSelect: handleSelectDateFromCalendar, inline: true, disabledKeyboardNavigation: true }), _jsx("div", { className: styles.applyButtonWrapper, children: _jsx(Button, { type: 'button', theme: 'primary', size: 'full-width', disabled: !!(dateValidator(start) || dateValidator(end)), onClick: onApply, children: t('Применить') }) })] })] }));
});
