import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// бонусы начислены с покупки
export const bonus1 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handlePurchase } = entityHandlers;
    const { amount, purchaseId, purchaseDeleted } = event.params || {};
    const hasPurchaseLink = !!purchaseId && !purchaseDeleted && !!handlePurchase;
    return {
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
        title: (_jsxs(BonusTitle, Object.assign({}, args, { includeDates: true, children: [amount ? t('BONUSES_FOR', { count: amount }) : t('Бонусы за'), "\u00A0", _jsx("span", { className: hasPurchaseLink ? styles.underline : '', onClick: () => {
                        if (hasPurchaseLink)
                            handlePurchase(purchaseId);
                    }, children: t('покупку') })] }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['purchase', 'availableAt', 'expiredAt', 'issuedAt'] })),
    };
};
