import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { DateSelectInput, } from '@/components/DateSelectInput/DateSelectInput';
import { Wrapper } from './Wrapper';
export const DateControl = memo(({ item, onApply, onBack }) => {
    var _a, _b, _c, _d;
    const [draft, setDraft] = useState(item);
    const handleChangeOperator = (operator) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), { operator }) }));
    };
    const handleChangeValue = (value) => {
        setDraft(Object.assign(Object.assign({}, draft), { value: Object.assign(Object.assign({}, draft.value), value) }));
    };
    const handleApply = () => onApply(draft);
    const isValid = ((_a = draft.value) === null || _a === void 0 ? void 0 : _a.day) || ((_b = draft.value) === null || _b === void 0 ? void 0 : _b.month) || ((_c = draft.value) === null || _c === void 0 ? void 0 : _c.year);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && isValid)
            handleApply();
    };
    return (_jsx(Wrapper, { onBack: onBack, label: item.label, operator: ((_d = draft.value) === null || _d === void 0 ? void 0 : _d.operator) || 'include', onChangeOperator: handleChangeOperator, onApplyClick: handleApply, isApplyButtonDisabled: !isValid, children: _jsx(DateSelectInput, { value: draft.value || {}, onChange: handleChangeValue, hasHelpText: true, onKeyDown: handleKeyDown }) }));
});
