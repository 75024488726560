import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { purchaseModalSelector } from '@/store/selectors/modals';
import { updatePurchaseModal, } from '@/store/slice/modals';
export const usePurchaseModal = () => {
    const dispatch = useAppDispatch();
    const purchaseModalState = useAppSelector(purchaseModalSelector);
    const open = useCallback((payload = {}) => {
        dispatch(updatePurchaseModal(Object.assign({ isOpen: true }, payload)));
    }, []);
    const close = useCallback((payload) => {
        var _a;
        (_a = purchaseModalState.closeHandler) === null || _a === void 0 ? void 0 : _a.call(purchaseModalState, payload);
        dispatch(updatePurchaseModal({
            isOpen: false,
            purchaseList: null,
            currentPurchaseIndex: null,
            isReturnFirst: false,
            closeHandler: undefined,
        }));
    }, [purchaseModalState.closeHandler]);
    const setVisibility = useCallback((isVisible) => {
        dispatch(updatePurchaseModal({ isOpen: isVisible }));
    }, []);
    const setPurchaseIndex = useCallback((index) => {
        dispatch(updatePurchaseModal({ currentPurchaseIndex: index }));
    }, []);
    return {
        isOpen: purchaseModalState.isOpen || false,
        purchaseList: purchaseModalState.purchaseList || null,
        currentPurchaseIndex: typeof purchaseModalState.currentPurchaseIndex === 'number'
            ? purchaseModalState.currentPurchaseIndex
            : null,
        isReturnFirst: purchaseModalState.isReturnFirst,
        open,
        close,
        setVisibility,
        setPurchaseIndex,
    };
};
