import { t } from 'i18next';
import { getMonthName } from '@/utils/getMonthName';
import { findTreeLoaderItem } from './findTreeLoaderItem';
const NOT = '<i class="op-not2">не</i>';
const OR = '<i class="op-or2">или</i>';
const ELLIPSIS = '<i class="op-elipsis"></i>';
const COMMA = '<i class="op-comma">,</i>';
const COUNT = (count) => `<i class="op-count">+${count}</i>`;
const EXCEPT = (count) => `<i class="op-except">кроме <i class="op-count">${count}</i></i>`;
const composeVariants = (variants, addOR = true) => {
    if (variants.length === 1)
        return variants[0].toString();
    const or = addOR ? OR : '';
    return `${variants[0]}${or}${ELLIPSIS} ${COUNT(variants.length - 1)}`;
};
export const composeSavedFilterText = (leaf) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const parts = leaf.hideTitle
        ? []
        : ((_a = leaf.value) === null || _a === void 0 ? void 0 : _a.type) && (leaf === null || leaf === void 0 ? void 0 : leaf.additionalSelectorOptions)
            ? [
                `${(_b = leaf === null || leaf === void 0 ? void 0 : leaf.additionalSelectorOptions.find((item) => { var _a; return item.id === ((_a = leaf.value) === null || _a === void 0 ? void 0 : _a.type); })) === null || _b === void 0 ? void 0 : _b.label}:`,
            ]
            : [`${leaf.filterTitle || leaf.label}:`];
    if (leaf.template !== 'counter')
        if (((_c = leaf.value) === null || _c === void 0 ? void 0 : _c.operator) === 'exclude')
            parts.push(NOT);
    if (leaf.template === 'radios' && ((_d = leaf.value) === null || _d === void 0 ? void 0 : _d.value) !== undefined)
        parts.push(leaf.options[leaf.value.value]);
    if (leaf.template === 'checkboxes-simple' &&
        ((_e = leaf.value) === null || _e === void 0 ? void 0 : _e.value) !== undefined) {
        const variants = leaf.value.value.map((id) => leaf.options ? leaf.options[id] : '');
        parts.push(composeVariants(variants));
    }
    if (leaf.template === 'checkboxes-loader' &&
        ((_f = leaf.value) === null || _f === void 0 ? void 0 : _f.value) !== undefined) {
        const options = leaf.hasUndefinedOption
            ? Object.assign({ [leaf.undefinedOptionValue || '0']: t('Не задано') }, leaf.options) : leaf.options;
        const variants = leaf.value.value.map((id) => (options ? options[id] : ''));
        parts.push(composeVariants(variants));
    }
    if (leaf.template === 'checkboxes-paginated-loader' &&
        ((_g = leaf.value) === null || _g === void 0 ? void 0 : _g.value) !== undefined) {
        const variants = leaf.value.value.map((id) => leaf.options.options ? leaf.options.options[id] : '');
        parts.push(composeVariants(variants));
    }
    if (leaf.template === 'checkboxes-tree-loader' &&
        ((_h = leaf.value) === null || _h === void 0 ? void 0 : _h.value) !== undefined) {
        const branchNames = ((_j = leaf.value.value.branches) === null || _j === void 0 ? void 0 : _j.map((leafId) => {
            const item = findTreeLoaderItem(leaf.options, leafId);
            return item ? item.badgeLabel || item.label : '';
        }).filter(Boolean)) || [];
        const leafNames = ((_k = leaf.value.value.leaves) === null || _k === void 0 ? void 0 : _k.map((leafId) => {
            const item = findTreeLoaderItem(leaf.options, leafId);
            return item ? item.badgeLabel || item.label : '';
        }).filter(Boolean)) || [];
        const variants = [...branchNames, ...leafNames];
        parts.push(composeVariants(variants));
    }
    if (leaf.template === 'checkboxes-segments' && leaf.useBothOperations) {
        const getLabels = (values) => values
            .map((id) => { var _a; return leaf.options ? (_a = leaf.options.find((opt) => opt.id === id)) === null || _a === void 0 ? void 0 : _a.label : ''; })
            .filter(Boolean);
        const include = getLabels(((_l = leaf.value) === null || _l === void 0 ? void 0 : _l.includeValues) || []);
        const exclude = getLabels(((_m = leaf.value) === null || _m === void 0 ? void 0 : _m.excludeValues) || []);
        if (include.length)
            parts.push(composeVariants(include));
        if (!include.length && exclude.length) {
            parts.push(NOT);
            parts.push(composeVariants(exclude, false));
        }
        if (include.length && exclude.length) {
            parts.push(COMMA);
            parts.push(EXCEPT(exclude.length));
        }
    }
    if (leaf.template === 'checkboxes-segments' &&
        !leaf.useBothOperations &&
        ((_o = leaf.value) === null || _o === void 0 ? void 0 : _o.value) !== undefined) {
        const variants = leaf.value.value
            .map((id) => {
            var _a;
            const item = (_a = leaf.options) === null || _a === void 0 ? void 0 : _a.find((i) => i.id === id);
            return (item === null || item === void 0 ? void 0 : item.label) || '';
        })
            .filter(Boolean);
        parts.push(composeVariants(variants));
    }
    if (leaf.template === 'numRange' && leaf.value !== undefined) {
        const min = leaf.value.min ? parseFloat(leaf.value.min) : null;
        const max = leaf.value.max ? parseFloat(leaf.value.max) : null;
        const right = leaf.inputRightText || '';
        const from = leaf.id === 'purchaseAmountUpToNextLevel' ? t('покупка от') : t('от');
        const to = leaf.id === 'purchaseAmountUpToNextLevel' ? t('покупка до') : t('до');
        let range = leaf.id === 'purchaseAmountUpToNextLevel' ? t('покупка') : '';
        if (range)
            range += ' ';
        if (min !== null && max === null)
            parts.push(`${from} ${min}${right}`);
        if (max !== null && min === null)
            parts.push(`${to} ${max}${right}`);
        if (min !== null && max !== null)
            if (leaf.pluralPostfix)
                parts.push(`${from} ${min} ${to} ${max}`);
            else
                parts.push(`${range}${min} – ${max}${right}`);
        if (leaf.inputPostfix && leaf.composePostfixToText)
            if (leaf.pluralPostfix)
                parts.push(t(leaf.inputPostfix, { count: max !== null ? max : min || 0 }));
            else
                parts.push(leaf.inputPostfix);
    }
    if (leaf.template === 'birthdateDays' && leaf.value !== undefined) {
        const before = leaf.value.before ? parseFloat(leaf.value.before) : null;
        const after = leaf.value.after ? parseFloat(leaf.value.after) : null;
        const beforeDays = before !== null
            ? before + ' ' + t('DAYS_MESSAGE', { count: before })
            : null;
        const afterDays = after !== null ? after + ' ' + t('DAYS_MESSAGE', { count: after }) : null;
        if (beforeDays && !afterDays) {
            parts.push(beforeDays);
            parts.push(t('BEFORE'));
        }
        if (!beforeDays && afterDays) {
            parts.push(afterDays);
            parts.push(t('AFTER'));
        }
        if (beforeDays && afterDays) {
            parts.push(beforeDays);
            parts.push(t('BEFORE') + ',');
            parts.push(afterDays);
            parts.push(t('AFTER'));
        }
    }
    if (leaf.template === 'textField' && ((_p = leaf.value) === null || _p === void 0 ? void 0 : _p.value) !== undefined) {
        const variants = leaf.value.value
            .split(/[\r\n]+/)
            .map((line) => line.trim())
            .filter(Boolean);
        parts.push(composeVariants(variants));
    }
    if (leaf.template === 'date' && leaf.value !== undefined)
        parts.push([leaf.value.day, getMonthName(leaf.value.month), leaf.value.year]
            .filter(Boolean)
            .join(' '));
    if (leaf.template === 'dateRange' && leaf.value !== undefined) {
        if (leaf.value.dateFrom)
            parts.push(`от ${leaf.value.dateFrom}`);
        if (leaf.value.dateTo)
            parts.push(`до ${leaf.value.dateTo}`);
    }
    if (leaf.template === 'counter' && leaf.value !== undefined) {
        parts.push(t('Купить'));
        if (leaf.value.applyOn === 'product')
            parts.push(t('товары'));
        parts.push(leaf.value.requiredValue || '');
        parts.push(t('TIMES', { count: Number(leaf.value.requiredValue) }));
    }
    return parts.join(' ');
};
