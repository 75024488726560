import { useGetOrderQuery } from '@/api/query/orders';
export const useOrderDetails = (id, skip) => {
    const { data, isLoading, isFetching, error } = useGetOrderQuery({ id: id || 0 }, { skip: !id || skip });
    return {
        data,
        isLoading,
        isFetching,
        error,
    };
};
