import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Send } from '@/icons/Send';
import styles from '../../styles.module.scss';
import { getMailingContent } from './getMailingContent';
import { getMailingDescription } from './getMailingDescription';
import { getMailingTitle } from './getMailingTitle';
export const mailingMailingSending = (args) => {
    const titleContent = getMailingTitle(args);
    const { color, content: descriptionContent } = getMailingDescription(args);
    return {
        color,
        Icon: _jsx(Send, { size: 20 }),
        title: (_jsxs("div", { className: styles.channelTitle, children: [_jsx("div", { children: titleContent }), descriptionContent] })),
        content: getMailingContent(args),
    };
};
