import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getSegmentName } from './getSegmentName';
import styles from './styles.module.scss';
export const RFMMovementName = ({ className, segment, ellipsis = false, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: classNames(styles.wrapper, {
            [styles.ellipsis]: ellipsis,
        }), children: [_jsx("div", { className: styles.dot, style: { backgroundColor: segment.color } }), _jsx("div", { className: classNames(className, styles.name), children: t(getSegmentName(segment)) })] }));
};
