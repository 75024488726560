import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Input } from '@/components/Input/Input';
import { Promocode } from '@/components/Promocode/Promocode';
import { CloseCircle } from '@/icons/CloseCircle';
import styles from './styles.module.scss';
export const PromocodeInput = memo(({ id, promocode, onChangePromocode, onCancel, hasError, isDisabled: isDisabledProvided, }) => {
    const { t } = useTranslation();
    const input = useRef(null);
    const [value, setValue] = useState('');
    const isDisabled = !value.trim() || isDisabledProvided;
    const handleChangeValue = useCallback((e) => {
        setValue(e.target.value.toUpperCase());
    }, []);
    const handleChangePromocode = useCallback(() => {
        if (isDisabled)
            return;
        const promocode = value.trim();
        if (!promocode)
            return;
        onChangePromocode(promocode);
    }, [isDisabled, value, onChangePromocode]);
    const handleKeyPress = useCallback((e) => {
        if (e.code === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleChangePromocode();
        }
    }, [handleChangePromocode]);
    const handleReset = useCallback(() => {
        var _a;
        setValue('');
        (_a = input.current) === null || _a === void 0 ? void 0 : _a.focus();
        onCancel();
    }, [onCancel]);
    const applyButton = (_jsx(Button, { onClick: handleChangePromocode, disabled: isDisabled, isOutlined: true, children: t('применить') }));
    return promocode ? (_jsxs("div", { className: styles.promocode, children: [_jsx(Promocode, { code: promocode, isDark: true }), _jsx("button", { className: styles.close, onClick: onCancel, children: _jsx(CloseCircle, {}) })] })) : (_jsxs("div", { className: styles.promocode, children: [_jsx(Input, { ref: input, id: id, placeholder: t('введите промокод'), value: value, onChange: handleChangeValue, onKeyPress: handleKeyPress, rightButton: applyButton, hasError: hasError }), hasError && (_jsx("button", { className: styles.close, onClick: handleReset, children: _jsx(CloseCircle, {}) }))] }));
});
