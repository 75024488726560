import { useEffect, useMemo, useState } from 'react';
import { COLOR_MAP } from '../ArbitrationGroup/helpers/COLOR_MAP';
import styles from './styles.module.scss';
export const scrollToError = ({ field, errorFieldsList = [], }) => {
    const firstErrorField = field || errorFieldsList[0];
    const element = document.getElementById(firstErrorField);
    if (element)
        element.scrollIntoView({ block: 'center' });
};
// проходим по всем группам вложенности
// и собираем их в список где родитель будет над его дочерними. Фиксируем глубину вложенности
export function convertGroupsDataToPriorityWithDepth({ depth = 0, priority, }) {
    let result = [];
    priority === null || priority === void 0 ? void 0 : priority.forEach((element) => {
        if (element.kind === 'group') {
            result.push(Object.assign(Object.assign({}, element), { depth }));
            if (element.arbitrationGroup && element.arbitrationGroup.children)
                result = result.concat(convertGroupsDataToPriorityWithDepth({
                    priority: element.arbitrationGroup.children,
                    depth: depth + 1,
                }));
        }
    });
    return result;
}
export const getSelectedGroup = (priority, arbitrationGroupInitGuid) => {
    var _a, _b;
    let currentGroup;
    if (arbitrationGroupInitGuid)
        currentGroup = priority.find((i) => { var _a; return ((_a = i.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.guid) === arbitrationGroupInitGuid; });
    else
        currentGroup = priority[0];
    const selectedGroup = currentGroup &&
        currentGroup.arbitrationGroup && Object.assign(Object.assign({}, currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.arbitrationGroup), { children: currentGroup.arbitrationGroup.children });
    return {
        currentGroup: currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.arbitrationGroup,
        childrenList: (_b = ((selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.children) || ((_a = currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.arbitrationGroup) === null || _a === void 0 ? void 0 : _a.children))) === null || _b === void 0 ? void 0 : _b.filter((item) => !item.isArchived),
    };
};
export const usePriorityFormTabs = ({ priority, arbitrationGroupGuid, arbitrationPriorityGuid, }) => {
    const [groupChildrenList, setGroupChildrenList] = useState([]);
    const [currentGroup, setCurrentGroup] = useState();
    const [isEntityInsideList, setIsEntityInsideList] = useState(!!arbitrationPriorityGuid);
    const priorityList = useMemo(() => convertGroupsDataToPriorityWithDepth({ priority }), [priority]);
    const groupSelectOptions = useMemo(() => priorityList.map(({ depth, arbitrationGroup }) => ({
        shift: depth,
        id: (arbitrationGroup === null || arbitrationGroup === void 0 ? void 0 : arbitrationGroup.guid) || '',
        title: (arbitrationGroup === null || arbitrationGroup === void 0 ? void 0 : arbitrationGroup.name) || '',
        className: styles.selectItem,
        styles: { color: COLOR_MAP[(arbitrationGroup === null || arbitrationGroup === void 0 ? void 0 : arbitrationGroup.type) || 'max'] },
    })), [priorityList]);
    useEffect(() => {
        var _a;
        const { childrenList, currentGroup } = getSelectedGroup(priorityList, arbitrationGroupGuid);
        setGroupChildrenList(childrenList);
        setCurrentGroup(currentGroup || ((_a = priority[0]) === null || _a === void 0 ? void 0 : _a.arbitrationGroup));
    }, [priority]);
    return {
        isEntityInsideList,
        currentGroup,
        priorityList,
        groupSelectOptions,
        groupChildrenList,
        setCurrentGroup,
        setIsEntityInsideList,
        setGroupChildrenList,
    };
};
