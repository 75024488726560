import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InteractiveFlow } from '@/components/flow/InteractiveFlow/InteractiveFlow';
import { InteractiveFlowConsumer } from '@/components/flow/InteractiveFlow/InteractiveFlowConsumer';
import { FlowHeader } from '../interactive-form-header/FlowHeader';
import styles from './styles.module.scss';
import { useComposedConfig } from './useComposedConfig';
import { useFlowStats } from './useFlowStats';
import { useUpdateDraft } from './useUpdateDraft';
export const InteractiveFlowForm = ({ selectedFlow, }) => {
    var _a, _b, _c;
    const location = useLocation();
    const trigger = (_a = location.state) === null || _a === void 0 ? void 0 : _a.trigger;
    const triggerType = (selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.triggerType) || trigger || 0;
    const triggerLabel = (_b = location.state) === null || _b === void 0 ? void 0 : _b.triggerLabel;
    const { updateDraft, changedFlow } = useUpdateDraft();
    const currentFlow = changedFlow || selectedFlow;
    const { composed, isLoadingComposedConfig } = useComposedConfig(!(currentFlow === null || currentFlow === void 0 ? void 0 : currentFlow.config) &&
        (!!((_c = location.state) === null || _c === void 0 ? void 0 : _c.mailingIds) || !!(selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.mailings)), selectedFlow === null || selectedFlow === void 0 ? void 0 : selectedFlow.mailings);
    const [isEditMode, setIsEditMode] = useState(!(currentFlow === null || currentFlow === void 0 ? void 0 : currentFlow.status) || (currentFlow === null || currentFlow === void 0 ? void 0 : currentFlow.status) === 'draft');
    const currentConfig = isEditMode
        ? (currentFlow === null || currentFlow === void 0 ? void 0 : currentFlow.config) || composed
        : (currentFlow === null || currentFlow === void 0 ? void 0 : currentFlow.publishedConfig) || composed;
    const [currentNodes, setCurrentNodes] = useState((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.nodes) || []);
    const [currentEdges, setCurrentEdges] = useState((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.edges) || []);
    useEffect(() => {
        setCurrentNodes((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.nodes) || []);
        setCurrentEdges((currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.edges) || []);
    }, [isEditMode, isLoadingComposedConfig]);
    const { stats } = useFlowStats(selectedFlow, isEditMode);
    if (isLoadingComposedConfig)
        return null;
    return (_jsx(InteractiveFlow, { triggerType: triggerType, triggerLabel: triggerLabel, stats: stats, isEditMode: isEditMode, onChange: (nodes, edges) => {
            setCurrentNodes(nodes);
            setCurrentEdges(edges);
            if (currentFlow && isEditMode)
                updateDraft(currentFlow, nodes, edges);
        }, children: _jsxs("div", { className: classNames(styles.wrapper, {
                [styles.disabled]: !isEditMode,
                [styles.locked]: !!(composed === null || composed === void 0 ? void 0 : composed.nodes.length),
                paused: (currentFlow === null || currentFlow === void 0 ? void 0 : currentFlow.status) === 'pause',
            }), children: [_jsx(FlowHeader, { flow: currentFlow, triggerType: triggerType, currentNodes: currentNodes, currentEdges: currentEdges, isEditMode: isEditMode, onChangeEditMode: setIsEditMode, isConvertMode: !!(composed === null || composed === void 0 ? void 0 : composed.nodes.length) }), _jsx(InteractiveFlowConsumer, { defaultNodes: clearDefaultNodes(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.nodes), defaultEdges: clearDefaultEdges(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.edges) })] }) }, isEditMode.toString()));
};
const clearDefaultNodes = (nodes) => {
    return cloneDeep(nodes === null || nodes === void 0 ? void 0 : nodes.map((node) => (Object.assign(Object.assign({}, node), { style: { opacity: 0 }, measured: undefined, width: undefined, height: undefined }))));
};
const clearDefaultEdges = (edges) => {
    return cloneDeep(edges === null || edges === void 0 ? void 0 : edges.map((edge) => (Object.assign(Object.assign({}, edge), { style: { opacity: 0 } }))));
};
