import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Radio } from '@/components/Radio2/Radio';
import { useDebounce } from '@/hooks/useDebounce';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import styles from '../../styles.module.scss';
export const BarcodeItem = ({ code }) => {
    const { t } = useTranslation();
    const { barcode, setBarcode, markReadyToSave, activeCardType, setActiveCardType, setAppleCardIsFlipped, setGoogleCardIsFlipped, } = useWalletCardForm();
    useDebounce({ value: barcode, delay: 300, callback: markReadyToSave });
    const titleMap = {
        code128: 'CODE 128',
        pdf417: 'PDF 417',
        'qr-code': 'QR code',
        aztec: 'AZTEC',
        '': t('Без кода'),
    };
    const handleFocus = () => {
        if (activeCardType === 'notify')
            setActiveCardType('apple');
        setAppleCardIsFlipped(false);
        setGoogleCardIsFlipped(false);
    };
    return (_jsx(Radio, { iconClassName: styles.barcodeRadio, name: 'barcode', value: code, checked: barcode === code, onChange: () => setBarcode(code), onFocus: handleFocus, children: _jsxs("div", { className: classNames(styles.barcode, styles[code], {
                [styles.active]: barcode === code,
            }), children: [_jsx("div", { className: styles.barcodeIcon, children: _jsx("div", { className: styles.barcodeIconImage }) }), titleMap[code]] }) }));
};
