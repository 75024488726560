var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { TestMessageModal } from '@/forms/FormMailing/modals/TestMessageModal';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { useSendTestMessage } from '../useSendTestMessage';
export const SMSFormTest = ({ smsText }) => {
    const { t } = useTranslation();
    const { selectedNode, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const sendTestMessage = useSendTestMessage();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const openModal = useCallback(() => setModalIsOpen(true), []);
    const closeModal = useCallback(() => setModalIsOpen(false), []);
    const send = (targets) => __awaiter(void 0, void 0, void 0, function* () {
        setError('');
        setIsLoading(true);
        try {
            yield sendTestMessage('sms', targets, selectedNodeData, smsText);
            closeModal();
        }
        catch (e) {
            const error = e;
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    });
    return (_jsxs("div", { children: [_jsx(Button, { type: 'button', theme: 'default', size: 'full-width', onClick: openModal, disabled: !smsText.trim() || !isEditMode, children: t('Отправить тестовое SMS') }), _jsx(TestMessageModal, { isOpen: modalIsOpen, onClose: closeModal, type: 'sms', onSendClick: send, isLoading: isLoading, error: error }, 'sms')] }));
};
