import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { SegmentBadge } from './SegmentBadge';
import { SegmentBadgeTooltip, } from './SegmentBadgeTooltip';
import styles from './styles.module.scss';
export const SegmentBadgeContainer = (props) => {
    const container = useRef(null);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [timer, setTimer] = useState(null);
    const openTooltip = useCallback(() => {
        const timer = setTimeout(() => {
            setTooltipIsOpen(true);
        }, 500);
        setTimer(timer);
    }, []);
    const closeTooltip = useCallback(() => {
        setTooltipIsOpen(false);
        if (timer)
            clearTimeout(timer);
        setTimer(null);
    }, [timer]);
    const BadgeElement = tooltipIsOpen ? SegmentBadgeTooltip : SegmentBadge;
    return (_jsx("span", { ref: container, className: classNames(styles.wrapper, props.className), children: _jsx(BadgeElement, Object.assign({}, props, { onMouseEnter: openTooltip, onMouseLeave: closeTooltip, hasWrapper: false, onClose: closeTooltip, container: container.current })) }));
};
