import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, } from 'react-beautiful-dnd';
import { Cancel } from '@/icons/Cancel';
import { Drag } from '@/icons/Drag';
import { reorder } from '@/utils/arrays';
import styles from './styles.module.scss';
export const SortableList = memo(({ items, onChange, levelIndent = 20 }) => {
    const handleDragEnd = useCallback((result) => {
        if (result.destination) {
            const reordered = reorder(items, result.source.index, result.destination.index);
            onChange(reordered);
        }
    }, [items, onChange]);
    return (_jsx(DragDropContext, { onDragEnd: handleDragEnd, children: _jsx(Droppable, { droppableId: 'droppable', children: ({ innerRef: droppableRef, droppableProps, placeholder }) => (_jsxs("div", Object.assign({ ref: droppableRef, className: styles.list }, droppableProps, { children: [items.map((item, index) => (_jsx(Draggable, { draggableId: item.id, index: index, children: ({ innerRef: draggableRef, draggableProps, dragHandleProps }, { isDragging }) => (_jsxs("div", Object.assign({ ref: draggableRef }, draggableProps, dragHandleProps, { className: classNames(styles.item, {
                                [styles.dragging]: isDragging,
                            }), children: [_jsx("div", { className: styles.drag, children: _jsx(Drag, {}) }), _jsx("div", { className: styles.label, style: {
                                        marginLeft: index * levelIndent,
                                    }, children: item.label }), _jsx("button", { className: styles.close, type: 'button', onClick: (e) => {
                                        e.stopPropagation();
                                        onChange(items.filter((i) => i.id !== item.id));
                                    }, children: _jsx(Cancel, {}) })] }))) }, item.id))), placeholder] }))) }) }));
});
