import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { GiftCard as GiftCardIcon } from '@/icons/GiftCard';
import { Reset } from '@/icons/Reset';
import { money } from '@/utils/numbers';
import { MailingRewardsPillsPopup } from '../MailingRewardsPillsPopup';
import styles from '../styles.module.scss';
export const GiftCard = ({ idx, value, onChange, offers = [], promocodes = [], giftCards = [], isDisabled = false, currency, }) => {
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    const handleReset = () => {
        const newValue = [...value];
        newValue.splice(idx, 1);
        onChange(newValue);
    };
    const selectedItem = value[idx];
    if (selectedItem.type !== 'gift-card')
        return null;
    const selectedGiftCard = giftCards.find((g) => g.id === selectedItem.giftCardId);
    if (!selectedGiftCard)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.buttonWrapper, children: [_jsx("button", { className: classNames(styles.formButton, {
                            [styles.nonInteractive]: isDisabled,
                            [styles.active]: popupIsOpen,
                        }), ref: triggerRef, type: 'button', onClick: togglePopup, children: _jsxs("div", { className: styles.buttonContent, children: [_jsx(GiftCardIcon, {}), `${selectedGiftCard.name} (${money({
                                    input: selectedGiftCard.amount,
                                    currency,
                                })})`] }) }), _jsx("button", { className: styles.resetButton, type: 'button', disabled: isDisabled, onClick: handleReset, children: _jsx(Reset, {}) })] }), popupIsOpen && (_jsx(MailingRewardsPillsPopup, { type: 'edit', activeMenuItem: 'giftCards', selectedItemIdx: idx, isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }))] }));
};
