import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { NewBadge } from '@/components/NewBadge/NewBadge';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import styles from './styles.module.scss';
export const FlowsPageTitle = () => {
    const { t } = useTranslation();
    const tooltipContent = (_jsxs(Trans, { i18nKey: 'FLOWS_NEW_TOOLTIP', children: ["\u041C\u044B \u043E\u0431\u043D\u043E\u0432\u0438\u043B\u0438 \u0446\u0435\u043F\u043E\u0447\u043A\u0438! \u0422\u0435\u043F\u0435\u0440\u044C \u0438\u0445 \u043C\u043E\u0436\u043D\u043E \u0441\u043E\u0437\u0434\u0430\u0432\u0430\u0442\u044C \u0441 \u043D\u0443\u043B\u044F \u0438 \u043F\u0435\u0440\u0435\u043D\u043E\u0441\u0438\u0442\u044C \u0430\u0432\u0442\u043E\u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u0432 \u044D\u0442\u043E\u0442 \u0440\u0430\u0437\u0434\u0435\u043B. \u041F\u0440\u043E \u0426\u0435\u043F\u043E\u0447\u043A\u0438 \u0432", ' ', _jsx("a", { href: 'https://help.maxma.com/p/rvocnw9F4tBqCB/', target: '_blank', rel: 'noreferrer', children: "\u0411\u0430\u0437\u0435 \u0437\u043D\u0430\u043D\u0438\u0439" })] }));
    return (_jsxs("div", { className: styles.pageTitle, children: [_jsx("div", { children: t('Цепочки') }), _jsx(Tooltip, { className: styles.pageTitleTooltip, title: tooltipContent, placement: 'right', children: _jsx("span", { className: styles.newBadgeWrapper, children: _jsx(NewBadge, {}) }) })] }));
};
