import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Comment } from '@/icons/Comment';
import { formatDateShort } from '@/utils/dates';
import styles from '../../styles.module.scss';
export const BonusTitle = ({ event, children, translate: t, includeDates = false, }) => {
    const { params } = event;
    const { amount, availableAt, expiredAt, comment } = params || {};
    const datesContent = (_jsxs(_Fragment, { children: [!!availableAt && (_jsxs("span", { className: styles.small, children: [' ', t('ACTIVATE', { count: amount || 0 }), ' ', formatDateShort(event.params.availableAt)] })), !!availableAt && !!expiredAt && ',', !!expiredAt && (_jsxs("span", { className: styles.small, children: [' ', t('EXPIRE', { count: amount || 0 }), ' ', formatDateShort(event.params.expiredAt)] }))] }));
    return (_jsxs(_Fragment, { children: [children, includeDates && datesContent, !!comment && _jsx(Comment, { className: styles.commentIcon })] }));
};
