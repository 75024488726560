import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ChannelViber } from '@/icons/ChannelViber';
import { FormMailingViberButton } from './controls/FormMailingViberButton';
import { FormMailingViberImage } from './controls/FormMailingViberImage';
import { FormMailingViberTest } from './controls/FormMailingViberTest';
import { FormMailingViberText } from './controls/FormMailingViberText';
import { FormMailingViberURL } from './controls/FormMailingViberURL';
import styles from './styles.module.scss';
export const FormMailingStepViber = ({ textLength, onSendTestViber, uploadImage, hasWalletFeature, currency, labelWidth, }) => {
    return (_jsxs(FormFieldset, { title: _jsxs("div", { className: styles.channelTitle, children: [_jsx("div", { className: classNames(styles.channel, styles.viber), children: _jsx(ChannelViber, {}) }), "Viber"] }), titleOffset: labelWidth, children: [_jsx(FormMailingViberText, { length: textLength, hasWalletFeature: hasWalletFeature, currency: currency, labelWidth: labelWidth }), _jsx(FormMailingViberImage, { uploadImage: uploadImage, labelWidth: labelWidth }), _jsx(FormMailingViberButton, { labelWidth: labelWidth }), _jsx(FormMailingViberURL, { labelWidth: labelWidth }), _jsx(FormMailingViberTest, { onSendTestViber: onSendTestViber, labelWidth: labelWidth })] }));
};
