import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useGetCounterListQuery } from '@/api/query/counters';
import { useGetBrandPromocodesQuery } from '@/api/query/promocodes';
import { AutocompleteCounters } from '../plugins/VariablePlugin/AutocompleteCounters';
import { AutocompleteDateFormats } from '../plugins/VariablePlugin/AutocompleteDateFormats';
import { AutocompletePromocodes } from '../plugins/VariablePlugin/AutocompletePromocodes';
import { acceptCounters, acceptDateFormats, acceptPromocodes, } from '../plugins/VariablePlugin/Variable';
import styles from './styles.module.scss';
export const VarsPanelRightSettings = ({ variables, selectedCounter, onChangeCounter, selectedPromocode, onChangePromocode, selectedDateFormat, onChangeDateFormat, }) => {
    const { t } = useTranslation();
    const hasCounters = variables.every((v) => acceptCounters(v.parameters));
    const hasPromocodes = variables.every((v) => acceptPromocodes(v.parameters));
    const hasDateFormats = variables.every((v) => acceptDateFormats(v.parameters));
    // preload data
    useGetCounterListQuery();
    useGetBrandPromocodesQuery();
    return (_jsxs("div", { children: [hasPromocodes && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.paramDescription, children: t('Выберите промокод') }), _jsx("div", { className: styles.param, children: _jsx(AutocompletePromocodes, { value: (selectedPromocode === null || selectedPromocode === void 0 ? void 0 : selectedPromocode.id.toString()) || '', onChange: onChangePromocode }) })] })), hasCounters && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.paramDescription, children: t('Выберите счетчик') }), _jsx("div", { className: styles.param, children: _jsx(AutocompleteCounters, { value: (selectedCounter === null || selectedCounter === void 0 ? void 0 : selectedCounter.offerId.toString()) || '', onChange: onChangeCounter }) })] })), hasDateFormats && (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.paramDescription, children: t('Выберите формат отображения') }), _jsx("div", { className: styles.param, children: _jsx(AutocompleteDateFormats, { value: (selectedDateFormat === null || selectedDateFormat === void 0 ? void 0 : selectedDateFormat.value) || '', onChange: onChangeDateFormat }) })] }))] }));
};
