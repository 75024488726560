import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterListInputItemButton } from './FilterListInputItemButton';
import styles from './styles.module.scss';
export const FilterListInput = ({ filtersTree = [], isSchedule = false, selectedCode, value = [], onChange, defaultOpenIdx, nonDeletableIdx, isDisabled, }) => {
    const handleAddItem = (savedFilter) => {
        onChange === null || onChange === void 0 ? void 0 : onChange([...value, savedFilter]);
    };
    const handleChangeItem = (i, savedFilter) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value.map((item, idx) => (idx === i ? savedFilter : item)));
    };
    const handleDeleteItem = (i) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value.filter((item, idx) => idx !== i));
    };
    return (_jsxs("div", { className: styles.wrapper, children: [value.map((savedFilter, i) => (_jsx(FilterListInputItemButton, { filtersTree: filtersTree, isSchedule: isSchedule, selectedCode: selectedCode, value: savedFilter, onChange: handleChangeItem.bind(null, i), onReset: handleDeleteItem.bind(null, i), defaultOpen: defaultOpenIdx === i, isResetDisabled: nonDeletableIdx === null || nonDeletableIdx === void 0 ? void 0 : nonDeletableIdx.includes(i), isDisabled: isDisabled }, `${savedFilter.code}-${i}`))), _jsx(FilterListInputItemButton, { filtersTree: filtersTree, isSchedule: isSchedule, selectedCode: selectedCode, onChange: handleAddItem, isDisabled: isDisabled })] }));
};
