import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormPriorityTab } from '~/src/components/arbitration/FormArbitrationPriority/FormPriorityTab/FormPriorityTab';
import { usePriorityFormTabs } from '~/src/components/arbitration/FormArbitrationPriority/utils';
import { FormOfferGeneralTab } from './FormOfferGeneralTab';
export const FormOfferTabs = memo(({ activeTab, currency, title, counters, segments, mailingOffers, clientExtraFields, hasDiscountOfferFeature, priority, getMailingLink, }) => {
    const { watch } = useFormContext();
    const arbitrationGroupGuid = watch('arbitrationGroupGuid');
    const arbitrationPriorityGuid = watch('arbitrationPriority');
    const { currentGroup, priorityList, groupChildrenList, groupSelectOptions, isEntityInsideList, setCurrentGroup, setIsEntityInsideList, setGroupChildrenList, } = usePriorityFormTabs({
        priority,
        arbitrationGroupGuid,
        arbitrationPriorityGuid,
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { style: { display: activeTab === 'general' ? 'block' : 'none' }, children: _jsx(FormOfferGeneralTab, { currency: currency, counters: counters, segments: segments, mailingOffers: mailingOffers, clientExtraFields: clientExtraFields, hasDiscountOfferFeature: hasDiscountOfferFeature, getMailingLink: getMailingLink }) }), activeTab === 'priority' && (_jsx("div", { children: _jsx(FormPriorityTab, { isOfferForm: true, activeTab: activeTab, currency: currency, priority: priorityList, entityTitle: title, arbitrationPriorityGuid: arbitrationPriorityGuid, groupChildrenList: groupChildrenList, isEntityInsideList: isEntityInsideList, currentGroup: currentGroup, groupSelectOptions: groupSelectOptions, setGroupChildrenList: setGroupChildrenList, setCurrentGroup: setCurrentGroup, setIsEntityInsideList: setIsEntityInsideList }) }))] }));
});
