import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const ApplyBonusesInput = memo(({ isChecked, onChange, bonuses, maxBonuses, availableBonuses, onChangeBonuses, isDisabled, hasError, }) => {
    const { t } = useTranslation();
    const [draft, setDraft] = useState((bonuses || maxBonuses).toString());
    useEffect(() => setDraft((bonuses || maxBonuses).toString()), [bonuses, maxBonuses]);
    const handleChangeChecked = useCallback((e) => {
        onChange(e.target.checked);
    }, [onChange]);
    const handleChangeInput = useCallback((e) => {
        setDraft(e.target.value);
    }, []);
    const handleChangeBonuses = useCallback(() => {
        let newBonuses = Number(draft);
        newBonuses = newBonuses < 0 ? 0 : newBonuses;
        const value = !newBonuses || newBonuses > maxBonuses ? maxBonuses : newBonuses;
        onChangeBonuses(value);
        setDraft(value.toString());
    }, [draft, maxBonuses, onChangeBonuses]);
    const bonusesInput = (_jsx("div", { className: styles.input, children: _jsx(Input, { type: 'number', inputMode: 'numeric', value: draft, onChange: handleChangeInput, onBlur: handleChangeBonuses, disabled: isDisabled || !isChecked, hasError: hasError, preventNegativeNumbers: true, shouldHideSpinButtons: true }) }));
    return (_jsx("div", { children: _jsx(Checkbox, { checked: isChecked, onChange: handleChangeChecked, disabled: isDisabled, children: _jsxs(_Fragment, { children: [t('Списать'), bonusesInput, t('PURCHASE_TO_REDEEM_AMOUNT', {
                        count: !isNaN(Number(draft)) ? Number(draft) : 0,
                    }), ' ', t('PURCHASE_FROM_AVAILABLE', {
                        amount: numberRound(availableBonuses),
                        count: availableBonuses,
                    })] }) }) }));
});
