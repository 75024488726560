import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePeriodButton } from './DatePeriodButton';
import { DatePeriodCalendar } from './DatePeriodCalendar';
import styles from './styles.module.scss';
export const DatePeriodSelect = memo(({ className, start: defaultStart, end: defaultEnd, onChange, label, leftElement, }) => {
    const { t } = useTranslation();
    const [start, setStart] = useState(defaultStart);
    const [end, setEnd] = useState(defaultEnd);
    const [isOpen, setIsOpen] = useState(false);
    const handleChange = (providedStart = start, providedEnd = end) => {
        if (isOpen)
            if (providedStart && !providedEnd)
                onChange(providedStart, providedStart);
            else
                onChange(providedStart, providedEnd);
    };
    const handleOpen = () => {
        setStart(defaultStart);
        setEnd(defaultEnd);
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleToggle = () => {
        if (isOpen)
            handleClose();
        else
            handleOpen();
    };
    const labelText = typeof label === 'undefined' ? t('Показать расчеты за') : label;
    const popoverContent = (_jsx("div", { children: _jsx(DatePeriodCalendar, { start: start, end: end, onChange: (start, end, shouldClose) => {
                if (shouldClose) {
                    handleChange(start, end);
                    handleClose();
                }
                else {
                    setStart(start);
                    setEnd(end);
                }
            }, onApply: () => {
                handleChange();
                handleClose();
            } }) }));
    return (_jsxs("div", { className: classNames(styles.wrapper, {
            [styles.between]: !!leftElement,
            [styles.end]: !leftElement,
        }, className), children: [leftElement && _jsx("div", { className: styles.left, children: leftElement }), _jsxs("div", { className: styles.labelWrapper, children: [labelText && _jsx("div", { className: styles.label, children: labelText }), _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(Tooltip, { open: isOpen, onOpen: handleOpen, onClose: handleClose, PopperProps: {
                                className: styles.popup,
                                onClick: (e) => e.stopPropagation(),
                                onTouchEnd: (e) => e.stopPropagation(),
                            }, title: popoverContent, placement: 'bottom-end', disableFocusListener: true, disableHoverListener: true, disableTouchListener: true, children: _jsx(DatePeriodButton, { start: defaultStart, end: defaultEnd, isOpen: isOpen, onClick: handleToggle }) }) })] })] }));
});
