import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { FlowsTableEmptyIcon } from './FlowsTableEmptyIcon';
import styles from './styles.module.scss';
export const FlowsTableEmpty = ({ onComposeFlow, onCreateFlow, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowManage = userHasAccessTo('FLOWS_MANAGE');
    const createButton = (_jsx(Dropdown, { placement: 'bottom', items: [
            {
                id: 'create',
                label: t('Создать цепочку'),
                onClick: onCreateFlow,
                isNew: true,
            },
            {
                id: 'assemble',
                label: t('Собрать из рассылок'),
                onClick: onComposeFlow,
            },
        ], children: _jsx("div", { children: _jsx(Tooltip, { title: !userHasFlowManage && t('Недоступно для вашей роли'), placement: 'bottom', children: _jsx("div", { children: _jsx(Button, { type: 'button', icon: _jsx(Plus, {}), size: 'large', disabled: !userHasFlowManage, children: t('Собрать цепочку') }) }) }) }) }));
    return (_jsxs("div", { className: styles.empty, children: [_jsx("div", { className: styles.emptyIcon, children: _jsx(FlowsTableEmptyIcon, {}) }), _jsx("div", { className: styles.emptyTitle, children: t('Пока не создано ни одной цепочки') }), _jsx("div", { className: styles.emptyDescription, children: t('Соберите рассылки с одним триггером в цепочку, чтобы увидеть их на холсте') }), createButton] }));
};
