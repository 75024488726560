import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { useLastListState } from '~/src/hooks/useLastListState';
import { ROUTES } from '../routes';
import { FlowsPanel } from './FlowsPanel';
import { useData } from './useData';
import { useDeleteFlow } from './useDeleteFlow';
export const Flows = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { brand } = useCurrentUser();
    const [searchText, setSearchText] = useState('');
    const { data, isLoading, isFetching } = useData({ searchText });
    const { deleteFlow } = useDeleteFlow();
    const handleApplySearch = useCallback((searchText) => {
        setSearchText(searchText);
    }, []);
    useLastListState();
    const handleEditFlow = (flow) => {
        const path = generatePath(ROUTES.FLOWS_EDIT, {
            flowId: flow.id.toString(),
        });
        navigate(path);
    };
    if (!brand)
        return null;
    return (_jsx(Container, { topElement: _jsx(DatePeriodSelectContainer, { leftElement: _jsx(SearchPanel, { name: 'searchText', placeholder: t('Название цепочки'), hasAutoFocus: true, hasMaxWidth: true, onApplySearch: handleApplySearch }) }), notMobileRightPadding: true, children: _jsx(FlowsPanel, { flows: data, isLoading: isLoading, isFetching: isFetching, onDeleteFlow: deleteFlow, onEditFlow: handleEditFlow }) }));
};
