import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getHttpErrorIcon, getHttpErrorLevel, getHttpErrorMessage, } from '@/utils/httpErrors';
import styles from './styles.module.scss';
import { useCloseError } from './useCloseError';
export const HttpErrorAlert = ({ id, status, }) => {
    const { t } = useTranslation();
    const closeError = useCloseError();
    const [isOpened, setIsOpened] = useState(false);
    const handleClose = useCallback(() => {
        setIsOpened(false);
        setTimeout(() => {
            if (id)
                closeError(id);
        }, 150);
    }, [id]);
    useEffect(() => {
        const timeout = setTimeout(() => setIsOpened(true), 0);
        return () => clearTimeout(timeout);
    }, []);
    useEffect(() => {
        const timeout = setTimeout(handleClose, 10000);
        return () => clearTimeout(timeout);
    }, [handleClose]);
    const level = getHttpErrorLevel(status);
    const message = getHttpErrorMessage(status);
    const icon = getHttpErrorIcon(status);
    return (_jsxs("div", { className: classNames(styles.alert, styles[level], {
            [styles.opened]: isOpened,
        }), children: [icon, t(message)] }));
};
