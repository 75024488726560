import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Table } from '@/components/Table/Table';
import { TableExpensesCell as Expenses } from '@/components/TableExpensesCell/TableExpensesCell';
import { TableProfitRevenueCell as ProfitRevenue } from '@/components/TableProfitRevenueCell/TableProfitRevenueCell';
import { TableRowIcon } from '@/components/TableRowIcon/TableRowIcon';
import { getAvailabilityRange } from '@/utils/getAvailabilityRange';
import { isItemPlanned } from '@/utils/isItemPlanned';
import { numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import { ROUTES } from '../routes';
import styles from './styles.module.scss';
export const OffersTable = memo(({ offers = [], total, shouldDisplayProfit, currency, timeZone, isLoading, purchaseUrl, onEditOffer, onDeleteOffer, onOpenPurchaseFilter, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasOffersManage = userHasAccessTo('OFFERS_MANAGE');
    const navigate = useNavigate();
    const columns = [
        {
            id: 'title',
            title: t('Название'),
        },
        {
            id: 'roi',
            title: t('ROI'),
            tooltip: t(`TOOLTIP_ROI_${shouldDisplayProfit ? 'PROFIT' : 'REVENUE'}`),
            align: 'right',
        },
        {
            id: 'profitOrRevenue',
            title: t(shouldDisplayProfit ? 'Прибыль' : 'Выручка'),
            tooltip: t(shouldDisplayProfit
                ? 'TOOLTIP_OFFER_PROFIT'
                : 'TOOLTIP_OFFER_REVENUE'),
            align: 'right',
        },
        {
            id: 'expenses',
            title: t('Расходы'),
            tooltip: t('Сумма применённых скидок и списанных бонусов'),
            align: 'right',
        },
        {
            id: 'count',
            title: t('Покупок'),
            tooltip: t('TOOLTIP_OFFER_COUNT'),
            align: 'right',
        },
        {
            id: 'orderCount',
            title: t('Заказов'),
            tooltip: t('TOOLTIP_OFFER_ORDER_COUNT'),
            align: 'right',
        },
        {
            id: 'availabilityRange',
            title: t('Период действия'),
            align: 'right',
        },
        { id: 'actions' },
    ];
    const rows = offers.map((offer) => ({
        id: offer.id,
        cells: [
            _jsxs("span", { className: styles.title, children: [!offer.isActive && (_jsxs(_Fragment, { children: [_jsx(TableRowIcon, { title: t('TOOLTIP_OFFER_INACTIVE'), icon: 'pause' }), _jsx(_Fragment, { children: "\u00A0" })] })), offer.isActive && isItemPlanned(offer) && (_jsxs(_Fragment, { children: [_jsx(TableRowIcon, { title: t('Запланирована'), icon: 'planned' }), _jsx(_Fragment, { children: "\u00A0" })] })), _jsx("span", { className: styles.wrap, children: offer.title })] }, 'title'),
            numberRound(roi(shouldDisplayProfit ? offer.profit : offer.revenue, offer.expenses)) + '%',
            _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: Number(offer.profit), revenue: Number(offer.revenue), currency: currency, href: purchaseUrl, onClick: () => onOpenPurchaseFilter(offer) }, 'profitOrRevenue'),
            _jsx(Expenses, { expenses: Number(offer.expenses), currency: currency }, 'expenses'),
            numberRound(offer.count),
            numberRound(offer.orderCount || 0),
            getAvailabilityRange(offer, timeZone, t('не ограничен')),
        ],
        actions: [
            {
                id: 'edit',
                tooltip: !userHasOffersManage && t('Недоступно для вашей роли'),
                label: t('Редактировать'),
                onClick: () => onEditOffer(offer.id),
                isDisabled: !userHasOffersManage,
            },
            {
                id: 'clone',
                tooltip: !userHasOffersManage && t('Недоступно для вашей роли'),
                label: t('Сделать копию'),
                onClick: () => {
                    navigate(ROUTES.OFFERS_CREATE, {
                        state: {
                            clone: true,
                            offerId: offer.id,
                        },
                    });
                },
                isDisabled: !userHasOffersManage,
            },
            {
                id: 'delete',
                tooltip: !userHasOffersManage && t('Недоступно для вашей роли'),
                label: t('Удалить'),
                onClick: () => onDeleteOffer(offer),
                isDanger: true,
                isDisabled: !userHasOffersManage,
            },
        ],
    }));
    const totalRow = !total
        ? undefined
        : [
            {
                id: 'total',
                isTotal: true,
                cells: [
                    _jsx(Fragment, { children: _jsx("b", { children: t('Итого за период') }) }, 'total'),
                    numberRound(roi(shouldDisplayProfit ? total.profit : total.revenue, total.expenses)) + '%',
                    _jsx(ProfitRevenue, { shouldDisplayProfit: shouldDisplayProfit, profit: total.profit, revenue: total.revenue, currency: currency }, 'profitOrRevenue'),
                    _jsx(Expenses, { expenses: total.expenses, currency: currency }, 'expenses'),
                    numberRound(total.count),
                    '',
                    '',
                ],
            },
        ];
    const handleRowClick = useCallback((row) => onEditOffer(Number(row.id)), [onEditOffer]);
    return (_jsx(Table, { columns: columns, rows: rows, total: totalRow, isLoading: isLoading, hasEmptyState: true, onRowClick: handleRowClick }));
});
