import { jsx as _jsx } from "react/jsx-runtime";
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import { WalletCardField } from '@/components/wallet-cards/WalletCardField/WalletCardField';
import styles from './styles.module.scss';
export const AppleWalletCardFields = ({ fields, labelColor, onElementClick, clicksDisabled = false, }) => {
    const fieldNumbers = [3, 4, 5, 6];
    return (_jsx("div", { className: styles.fieldsWrapper, children: fieldNumbers.map((n) => {
            var _a, _b;
            const key = `field-${n}`;
            const field = fields === null || fields === void 0 ? void 0 : fields[key];
            return (field === null || field === void 0 ? void 0 : field.title) !== undefined || (field === null || field === void 0 ? void 0 : field.value) !== undefined ? (_jsx(WalletCardClickableElement, { onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick(key), disabled: clicksDisabled || (!((_a = field === null || field === void 0 ? void 0 : field.title) === null || _a === void 0 ? void 0 : _a.trim()) && !((_b = field === null || field === void 0 ? void 0 : field.value) === null || _b === void 0 ? void 0 : _b.trim())), children: _jsx(WalletCardField, { title: field.title, titleColor: labelColor, value: field.value }) }, key)) : null;
        }) }));
};
