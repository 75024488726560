import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { CloseCircle } from '@/icons/CloseCircle';
import { ImagePlaceholderAlt } from '@/icons/ImagePlaceholderAlt';
import { Sending } from '@/icons/status/Sending';
import styles from './styles.module.scss';
export const FileUploadInput = ({ id, className, placeholder, placeholderIcon: _placeholderIcon, description, width = 0, height = 0, filename, previewImageUrl, previewAsIcon, previewHasOpacity, constraints = {}, hasError = false, hasResetButton = true, onChange, onReject, onReset, isLoading, isDisabled = false, theme = 'outlined', onFocus, }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length)
                onChange === null || onChange === void 0 ? void 0 : onChange(acceptedFiles[0]);
        },
        onDropRejected: (fileRejections) => {
            const rejection = fileRejections[0];
            onReject === null || onReject === void 0 ? void 0 : onReject(rejection.errors[0].code);
        },
        accept: constraints.mimeType
            ? { [constraints.mimeType]: constraints.extensions || [] }
            : undefined,
        maxSize: constraints.maxSize,
        disabled: isLoading || isDisabled,
    });
    const rootProps = getRootProps();
    const inputProps = getInputProps();
    const hasPreview = !isDragActive && !!previewImageUrl;
    const hasFilename = !isDragActive && !!filename;
    let placeholderIcon = _placeholderIcon === 'image' ? (_jsx(ImagePlaceholderAlt, { hasError: hasError })) : (_placeholderIcon);
    placeholderIcon = isLoading ? null : placeholderIcon;
    const previewStyles = {};
    if (hasPreview) {
        previewStyles.backgroundImage = previewImageUrl;
        if (previewAsIcon)
            previewStyles.backgroundImage =
                'url(/components/file-upload-input/icon-preview-bg-opacity.png)';
    }
    return (_jsxs("div", { className: classNames(styles.wrapper, styles[theme]), children: [_jsxs("div", Object.assign({}, rootProps, { className: classNames(className, styles.root, {
                    [styles.dragging]: isDragActive,
                    [styles.preview]: hasPreview,
                    [styles.previewIcon]: hasPreview && previewAsIcon,
                    [styles.error]: !isDragActive && hasError,
                    [styles.loading]: isLoading,
                    [styles.disabled]: isDisabled,
                }), style: Object.assign(Object.assign(Object.assign({}, rootProps.style), { width,
                    height }), previewStyles), onFocus: onFocus, children: [_jsx("input", Object.assign({}, inputProps, { id: id })), !hasPreview && (_jsxs("div", { children: [_jsxs("div", { className: styles.title, children: [isLoading && _jsx(Sending, { className: styles.loader }), !hasFilename ? placeholder || placeholderIcon : filename] }), !hasFilename && description && (_jsx("div", { className: styles.description, children: description }))] })), hasPreview && previewAsIcon && (_jsx("div", { className: classNames(styles.previewIconElement, {
                            [styles.transparent]: previewHasOpacity,
                        }), style: { backgroundImage: previewImageUrl } }))] })), !!filename && hasResetButton && !isDisabled && (_jsx("button", { className: styles.reset, type: 'button', onClick: () => {
                    onChange === null || onChange === void 0 ? void 0 : onChange(null);
                    onReset === null || onReset === void 0 ? void 0 : onReset();
                }, children: _jsx(CloseCircle, {}) }))] }));
};
