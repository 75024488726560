import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Purchase } from '@/icons/Purchase';
import styles from '../../styles.module.scss';
import { getPurchaseContent } from './purchaseContent';
// возврат
export const purchasePurchaseReturn = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleReturn } = entityHandlers;
    const { purchaseId, purchaseDeleted } = event.params || {};
    return {
        color: 'red',
        Icon: _jsx(Purchase, {}),
        title: (_jsxs(_Fragment, { children: [t('Сделал'), "\u00A0", _jsx("span", { className: purchaseId && !purchaseDeleted && handleReturn
                        ? styles.underline
                        : '', onClick: () => {
                        if (purchaseId && !purchaseDeleted)
                            handleReturn === null || handleReturn === void 0 ? void 0 : handleReturn(purchaseId);
                    }, children: t('возврат') })] })),
        content: getPurchaseContent(args),
    };
};
