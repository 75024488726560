import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '~/src/components/Checkbox2/Checkbox';
import { FormFieldRow } from '~/src/components/FormFieldRow2/FormFieldRow';
import { Input } from '~/src/components/Input/Input';
import styles from './styles.module.scss';
export const BuyerWillReceiveDiscount = ({ labelWidth, currency, }) => {
    const { t } = useTranslation();
    const { setValue, watch, formState: { errors }, control, } = useFormContext();
    const type = watch('type');
    if (type !== 'discount')
        return null;
    return (_jsx(FormFieldRow, { labelWidth: labelWidth, children: _jsx(Controller, { name: 'maxDiscountEnabled', control: control, render: ({ field }) => (_jsxs(Checkbox, Object.assign({}, field, { value: field.value ? 'checked' : '', checked: field.value, onChange: (e) => {
                    setValue('maxDiscountEnabled', e.target.checked);
                }, preventInnerClicks: true, children: [t('не более'), _jsx(Controller, { name: 'maxDiscountAmount', control: control, rules: {
                            required: watch('maxDiscountEnabled'),
                        }, render: ({ field }) => (_jsx("div", { className: styles.noMoreInput, children: _jsx(Input, Object.assign({}, field, { type: 'number', inputMode: 'numeric', preventChars: ['-', '+', '.', ',', 'e'], preventNegativeNumbers: true, rightText: currency, step: 'any', shouldHideSpinButtons: true, hasError: !!errors.maxDiscountAmount })) })) })] }))) }) }));
};
