import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/app/pages/routes';
import appNavButtonStyles from '../AppNavButton/styles.module.scss';
import { AppNavDropdown } from '../AppNavDropdown/AppNavDropdown';
import { AppNavItem } from './AppNavItem';
import { AppNavItemList } from './AppNavItemList';
import { AppNavSectionName } from './AppNavSectionName';
import { ArbitrationButton } from './ArbitrationButton';
import { filterItems } from './filterItems';
import { isItemActive } from './isItemActive';
import styles from './styles.module.scss';
export const AppNavSectionDropdown = memo(({ section, isActive, onClick, currentRoute, onChangeRoute, isCollapsed, }) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);
    const handleNavigate = useCallback(() => {
        section.route && onChangeRoute(section.route);
    }, [section.route]);
    const button = section.id === 'flows' && !isCollapsed ? (_jsx("li", { onClick: handleNavigate, children: _jsx(AppNavSectionName, Object.assign({}, section, { isActive: isItemActive(section, currentRoute), isHovered: hovered, activeClassName: appNavButtonStyles.active, isCollapsed: isCollapsed, externalLink: section.externalLink, onClick: onClick, hasNewsBadge: true })) })) : (_jsxs("li", { children: [_jsxs("div", { className: styles.sectionWrapper, children: [_jsx(AppNavSectionName, Object.assign({}, section, { isActive: isActive, isHovered: hovered, isCollapsed: isCollapsed, onClick: onClick })), !isCollapsed && isActive && (_jsx(ArbitrationButton, { item: section.items.find((item) => item.route === ROUTES.ARBITRATION), currentRoute: currentRoute, onClick: onChangeRoute }))] }), _jsx(AppNavItemList, { isOpened: isActive, children: filterItems(section.items)
                    .filter((item) => item.route !== ROUTES.ARBITRATION)
                    .map((item) => (_jsx("li", { children: _jsx(AppNavItem, Object.assign({}, item, { currentRoute: currentRoute, onClick: onChangeRoute })) }, item.name))) })] }, section.name));
    return isCollapsed ? (_jsx(AppNavDropdown, { title: !section.externalLink ? t(section.name) : undefined, items: filterItems(section.items).map((item) => ({
            id: item.route || item.href,
            label: t(item.name),
            onClick: () => {
                if (item.route)
                    onChangeRoute(item.route);
            },
            href: item.href,
            isExternal: item.isExternal,
            tooltip: item.tooltip,
            isDisabled: !item.displayAsBlocked && !item.route && !!item.tooltip,
            isActive: isItemActive(item, currentRoute),
            displayAsBlocked: item.displayAsBlocked,
        })), onOpen: () => setHovered(true), onClose: () => setHovered(false), enterTouchDelay: 0, children: button })) : (button);
});
