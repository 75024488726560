import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ColorInput } from '@/components/ColorInput/ColorInput';
import { PanelTitle } from '@/components/Panel2/PanelTitle';
import { Colors } from '@/icons/wallet-card/form/Colors';
import styles from './styles.module.scss';
import { WalletCardFormMenuItem } from './WalletCardFormMenuItem';
export const WalletCardFormMenu = ({ title, onSelectSection, background = '#ffffff', onChangeBackground, onBlurBackgroundInput, color = '#3a3a3a', onChangeColor, onBlurColorInput, labelColor = '#3a3a3a', onChangeLabelColor, onBlurLabelColorInput, onClose, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { children: [title && _jsx(PanelTitle, { title: title, onClose: onClose }), _jsx(WalletCardFormMenuItem, { section: 'head', onClick: onSelectSection }), _jsx(WalletCardFormMenuItem, { section: 'banner', onClick: onSelectSection }), _jsx(WalletCardFormMenuItem, { section: 'fields', onClick: onSelectSection }), _jsx(WalletCardFormMenuItem, { section: 'barcode', onClick: onSelectSection }), _jsx(WalletCardFormMenuItem, { section: 'backside', onClick: onSelectSection }), _jsx(WalletCardFormMenuItem, { section: 'notify', onClick: onSelectSection }), _jsxs("div", { className: classNames(styles.item, styles.colorsItem), children: [_jsxs("div", { className: styles.itemTitle, children: [_jsx(Colors, {}), t('Цвета')] }), _jsxs("div", { className: styles.colors, children: [_jsx("div", { className: styles.color, children: _jsx(ColorInput, { type: 'background-color', placeholder: '#FFFFFF', value: background, onChange: onChangeBackground, onBlur: onBlurBackgroundInput }) }), _jsx("div", { className: styles.color, children: _jsx(ColorInput, { placeholder: '#FFFFFF', value: color, onChange: onChangeColor, onBlur: onBlurColorInput, iconSize: 'large' }) }), _jsx("div", { className: styles.color, children: _jsx(ColorInput, { placeholder: '#FFFFFF', value: labelColor, onChange: onChangeLabelColor, onBlur: onBlurLabelColorInput, iconSize: 'small' }) })] })] })] }));
};
