import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { brandHasFlowFeature } from '@/utils/brandHasFlowFeature';
import { ChangesIndicator } from './ChangesIndicator';
import { CreateFlowHeader } from './CreateFlowHeader';
import { EditButton } from './EditButton';
import { FlowTitle } from './FlowTitle';
import { PauseButton } from './PauseButton';
import { ResetButton } from './ResetButton';
import { StartButton } from './StartButton';
import styles from './styles.module.scss';
export const FlowHeader = ({ flow, triggerType, currentNodes = [], currentEdges = [], isEditMode, onChangeEditMode, isConvertMode, }) => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const [isRenameMode, setIsRenameMode] = useState(false);
    const isFlowsEnabled = brandHasFlowFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const enableEditMode = () => {
        onChangeEditMode === null || onChangeEditMode === void 0 ? void 0 : onChangeEditMode(true);
    };
    const disableEditMode = () => {
        onChangeEditMode === null || onChangeEditMode === void 0 ? void 0 : onChangeEditMode(false);
    };
    if (isConvertMode)
        return (_jsx(CreateFlowHeader, { flow: flow, triggerType: triggerType, currentNodes: currentNodes, currentEdges: currentEdges, isConvertMode: true }));
    if (!flow)
        return (_jsx(CreateFlowHeader, { triggerType: triggerType, currentNodes: currentNodes, currentEdges: currentEdges }));
    if (isRenameMode)
        return (_jsx(CreateFlowHeader, { flow: flow, isRenameMode: true, onSetRenameModeOff: () => setIsRenameMode(false) }));
    return (_jsxs("div", { className: classNames(styles.form, styles.edit, {
            [styles.pushed]: !isFlowsEnabled,
        }), children: [_jsx(FlowTitle, { flow: flow, onRenameClick: () => setIsRenameMode(true), hasStatusIcon: flow.status === 'draft' || !isEditMode, hasSetEditModeOffButton: flow.status !== 'draft' && isEditMode, onSetEditModeOff: disableEditMode }), flow.status === 'draft' && (_jsx(StartButton, { flow: flow, label: t('Запустить'), currentNodes: currentNodes, currentEdges: currentEdges, shouldPublish: true })), flow.status !== 'draft' && !isEditMode && (_jsxs("div", { className: styles.buttonGroup, children: [(flow.status === 'active' || flow.status === 'pause') && (_jsx(EditButton, { onClick: enableEditMode, flowHasChanges: flow.hasChanges })), flow.status === 'active' && _jsx(PauseButton, { flow: flow }), flow.status === 'pause' && (_jsx(StartButton, { flow: flow, label: t('Запустить'), currentNodes: currentNodes, currentEdges: currentEdges, shouldStart: true }))] })), flow.status !== 'draft' && isEditMode && flow.hasChanges && (_jsxs("div", { className: styles.buttonGroup, children: [_jsx(ChangesIndicator, {}), _jsx(ResetButton, { flow: flow }), flow.status === 'active' && (_jsx(StartButton, { flow: flow, label: t('Применить'), currentNodes: currentNodes, currentEdges: currentEdges, shouldPublish: true })), flow.status === 'pause' && (_jsx(StartButton, { flow: flow, label: t('Запустить обновления'), currentNodes: currentNodes, currentEdges: currentEdges, shouldPublish: true }))] }))] }));
};
