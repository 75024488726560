import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from '@/components/Popup/Popup';
import { Plus } from '@/icons/Plus';
import { Reset } from '@/icons/Reset';
import styles from './styles.module.scss';
export const YesNoButton = ({ value, onChange, onReset, hasYesOption, hasNoOption, isDisabled, isResetDisabled, }) => {
    const { t } = useTranslation();
    const formTrigger = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const handleTogglePopup = useCallback(() => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    }, []);
    const handleYes = () => {
        onChange === null || onChange === void 0 ? void 0 : onChange('yes');
        setPopupIsOpen(false);
    };
    const handleNo = () => {
        onChange === null || onChange === void 0 ? void 0 : onChange('no');
        setPopupIsOpen(false);
    };
    return (_jsxs("div", { children: [_jsx("div", { className: styles.description, children: t('Если значение совпадает с...') }), _jsxs("div", { className: styles.buttonWrapper, children: [_jsxs("button", { className: classNames(styles.formButton, {
                            [styles.empty]: !value,
                            [styles.active]: popupIsOpen,
                            [styles.no]: value === 'no',
                            [styles.yes]: value === 'yes',
                            [styles.nonInteractive]: !!value,
                            [styles.disabled]: isDisabled,
                        }), ref: formTrigger, type: 'button', onClick: !value ? handleTogglePopup : undefined, children: [value === 'yes' && t('Да'), value === 'no' && t('Нет'), !value && (_jsxs(_Fragment, { children: [_jsx(Plus, { size: 18 }), t('Значение')] }))] }), value && (_jsx("button", { className: styles.resetButton, type: 'button', disabled: isDisabled || isResetDisabled, onClick: onReset, children: _jsx(Reset, {}) }))] }), _jsx(Popup, { className: styles.popup, isOpen: popupIsOpen, anchorEl: formTrigger === null || formTrigger === void 0 ? void 0 : formTrigger.current, onClose: handleTogglePopup, placement: 'bottom', offset: [0, 6], isRounded: true, children: _jsxs("div", { className: styles.optionButtonsWrapper, children: [hasYesOption && (_jsx("button", { className: classNames(styles.optionButton, styles.yes), type: 'button', onClick: handleYes, children: t('Да') })), hasNoOption && (_jsx("button", { className: classNames(styles.optionButton, styles.no), type: 'button', onClick: handleNo, children: t('Нет') }))] }) })] }));
};
