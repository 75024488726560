import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { ACCESS_OPTIONS } from '../constants';
import styles from '../styles.module.scss';
export const SettingsSections = memo(({ isView, longListItems, handleSetValue }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, formState: { errors }, setValue, watch, } = useFormContext();
    const allDataAccessListItems = [
        {
            label: t('Всех, кроме админов'),
            value: ACCESS_OPTIONS.ALL_USERS_EXEPTS_ADMINS,
        },
        {
            label: t('Всех пользователей'),
            value: ACCESS_OPTIONS.ALL_USERS,
        },
    ];
    const operatorsList = watch('operatorsList');
    const operatorsListOwn = watch('operatorsListOwn');
    useEffect(() => {
        if (operatorsList && !operatorsListOwn)
            setValue('operatorsListOwn', allDataAccessListItems[0].value);
    }, [operatorsList]);
    return (_jsx("div", { id: 'settings', children: _jsxs(FormFieldset, { fieldsetClassName: styles.fieldset, titleClassName: styles.fieldsetTitle, title: t('Настройки'), children: [_jsx(FormFieldRow, { label: t('Пользователи'), labelFor: 'operatorsList', controlSize: '320', help: (_a = errors.operatorsList) === null || _a === void 0 ? void 0 : _a.message, hasError: !!errors.operatorsList, children: _jsxs("div", { className: styles.clientList, children: [_jsx(Controller, { name: 'operatorsList', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView, className: styles.autocompleteWrapper }, field, { id: 'operatorsList', value: longListItems.find((item) => item.value === field.value) ||
                                        longListItems[0], onChange: (item) => {
                                        handleSetValue('operatorsList', item);
                                    }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }), operatorsList && (_jsx(Controller, { name: 'operatorsListOwn', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'operatorsListOwn', wrapperClassName: classNames(styles.ownAccess, styles.clientsVariant), value: allDataAccessListItems.find((item) => item.value === field.value) || allDataAccessListItems[0], onChange: (item) => {
                                        handleSetValue('operatorsListOwn', item);
                                    }, options: allDataAccessListItems, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, isInline: true }))) }))] }) }), _jsx(FormFieldRow, { label: t('Точки продажи'), labelFor: 'SHOPS', help: (_b = errors.SHOPS) === null || _b === void 0 ? void 0 : _b.message, hasError: !!errors.SHOPS, controlSize: '154', children: _jsx(Controller, { name: 'SHOPS', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({ disabled: isView }, field, { id: 'SHOPS', value: longListItems.find((item) => item.value === field.value) ||
                                longListItems[0], onChange: (item) => {
                                handleSetValue('SHOPS', item);
                            }, options: longListItems, getOptionKey: (option) => option.label, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) })] }) }));
});
