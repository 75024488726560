import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ColorInput } from '@/components/ColorInput/ColorInput';
import { FontSizeSelect } from '@/components/FontSizeSelect/FontSizeSelect';
import { ImageCropInput } from '@/components/ImageCropInput/ImageCropInput';
import { LogoLarge } from '@/icons/wallet-card/google/LogoLarge';
import { useWalletCardForm } from '../../context/useWalletCardForm';
import { fontSizeOptions } from '../../helpers/FontSizeOptions';
import { ImageHelp } from '../../helpers/ImageHelp';
import { SmartInput } from '../../helpers/SmartInput';
import styles from '../../styles.module.scss';
import { BannerGoogle } from './BannerGoogle';
export const BannerGoogleText = ({ hasTextInputs = false, }) => {
    const { t } = useTranslation();
    const { setActiveCardType, setGoogleCardIsFlipped, markReadyToSave, uploadImage, setIsElementClick, googleBanner, setGoogleBanner, } = useWalletCardForm();
    const handleFocus = (flipCard = false) => () => {
        setActiveCardType('google');
        setGoogleCardIsFlipped(flipCard);
        setIsElementClick === null || setIsElementClick === void 0 ? void 0 : setIsElementClick(false);
    };
    const handleChange = (banner) => {
        setGoogleBanner((googleBanner) => (Object.assign(Object.assign({}, googleBanner), banner)));
    };
    const handleApplyImage = (backsideImage) => {
        handleChange({
            backsideImage,
            backsideImageLoading: !!backsideImage,
            backsideImageRejectReason: '',
        });
        if (!backsideImage) {
            markReadyToSave();
            return;
        }
        uploadImage === null || uploadImage === void 0 ? void 0 : uploadImage(backsideImage).then((response) => {
            handleChange({ backsideImageUploadResponse: response });
        }).finally(() => {
            handleChange({ backsideImageLoading: false });
            markReadyToSave();
        });
    };
    return (_jsxs("div", { className: classNames(styles.google, styles.bannerWrapper), children: [_jsx(BannerGoogle, {}), hasTextInputs && (_jsxs("div", { className: classNames(styles.control, styles.smallGap), children: [_jsx("label", { className: styles.label, htmlFor: 'google-banner-title', children: t('Текст на баннере') }), _jsxs("div", { className: styles.bannerTextInputWrapper, children: [_jsx("div", { className: styles.bannerTextInput, children: _jsx(SmartInput, { id: 'google-banner-title', value: googleBanner.title, vars: googleBanner.titleVars, onChange: (value, vars) => {
                                        handleChange({
                                            title: value,
                                            titleVars: vars,
                                        });
                                    }, placeholder: t('верхняя строка'), onFocus: handleFocus(), onBlur: markReadyToSave }) }), _jsx("div", { className: styles.bannerColorInput, children: _jsx(ColorInput, { id: 'google-banner-title-color', placeholder: '#FFFFFF', value: googleBanner.titleColor, onChange: (titleColor) => {
                                        handleChange({ titleColor });
                                    }, onFocus: handleFocus(), onBlur: markReadyToSave }) }), _jsx(FontSizeSelect, { sizes: fontSizeOptions, value: googleBanner.titleSize, onChange: (size) => {
                                    handleChange({ titleSize: size.label });
                                    markReadyToSave();
                                }, onFocus: handleFocus() })] }), _jsxs("div", { className: styles.bannerTextInputWrapper, children: [_jsx("div", { className: styles.bannerTextInput, children: _jsx(SmartInput, { id: 'google-banner-description', value: googleBanner.description, vars: googleBanner.descriptionVars, onChange: (value, vars) => {
                                        handleChange({
                                            description: value,
                                            descriptionVars: vars,
                                        });
                                    }, placeholder: t('нижняя строка'), onFocus: handleFocus(), onBlur: markReadyToSave }) }), _jsx("div", { className: styles.bannerColorInput, children: _jsx(ColorInput, { id: 'google-banner-description-color', placeholder: '#FFFFFF', value: googleBanner.descriptionColor, onChange: (descriptionColor) => {
                                        handleChange({ descriptionColor });
                                    }, onFocus: handleFocus(), onBlur: markReadyToSave }) }), _jsx(FontSizeSelect, { sizes: fontSizeOptions, value: googleBanner.descriptionSize, onChange: (size) => {
                                    handleChange({ descriptionSize: size.label });
                                    markReadyToSave();
                                }, onFocus: handleFocus() })] })] })), _jsxs("div", { className: classNames(styles.control, styles.smallGap), children: [_jsx("label", { className: styles.label, htmlFor: 'google-backside-banner', children: t('Баннер в сведениях') }), _jsxs("div", { className: styles.uploadWrapper, children: [_jsx(ImageCropInput, { id: 'google-backside-banner', value: googleBanner.backsideImage, onChange: handleApplyImage, onReject: (reason) => {
                                    handleChange({
                                        backsideImage: null,
                                        backsideImageRejectReason: reason || '',
                                    });
                                }, previewWidth: 280, previewHeight: 106, constraints: {
                                    mimeType: 'image/png',
                                    extensions: ['.png'],
                                    maxSize: 10000000,
                                    dimensions: { width: 1125, height: 433 },
                                }, outputMimeType: 'png', description: 'PNG, ' + t('от') + ' ' + t('1125×433, до 10мб'), hasError: !!googleBanner.backsideImageRejectReason, cropModalIcon: _jsx(LogoLarge, {}), cropModalTitle: t('Баннер в сведениях'), onFocus: handleFocus(true) }), _jsx(ImageHelp, { className: styles.bannerHelp, dimensions: '1125\u00D7433', rejectReason: googleBanner.backsideImageRejectReason, isLoading: googleBanner.backsideImageLoading })] })] })] }));
};
