import { jsx as _jsx } from "react/jsx-runtime";
import { User } from '@/icons/User';
import { getClientUpdateContent } from './clientUpdateContent';
import { ClientUpdateTitle } from './clientUpdateTitle';
// удален клиент (по API)
export const clientUpdate7 = (args) => {
    const { translate: t } = args;
    return {
        color: 'red',
        Icon: _jsx(User, { size: 20, fill: '#DA5845' }),
        title: (_jsx(ClientUpdateTitle, Object.assign({}, args, { children: t('Удалён по API') }))),
        content: getClientUpdateContent(Object.assign({}, args)),
    };
};
