import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useClientFilters } from '@/services/filters/data/useClientFilters';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import styles from '../styles.module.scss';
export const FormOfferRecipients = ({ extraFields = [], counters = [], mailingOffers = [], segments = [], getMailingLink, labelWidth, }) => {
    var _a, _b;
    const { t } = useTranslation();
    const { control, formState, setValue, getValues, watch, trigger } = useFormContext();
    const { errors, isSubmitting, isSubmitted } = formState;
    const recipientTags = watch('recipientTags');
    const recipientFilters = watch('recipientFilters');
    const isPresetSelected = Boolean((recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.all) || (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.withCards) || (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.noCards));
    const handleUpdateRecipientTags = (tags) => {
        setValue('recipientTags', Object.assign(Object.assign({}, recipientTags), { all: typeof tags.all !== 'undefined' ? tags.all : false, withCards: typeof tags.withCards !== 'undefined' ? tags.withCards : false, noCards: typeof tags.noCards !== 'undefined' ? tags.noCards : false }), { shouldDirty: true });
    };
    const handleRemoveRecipientTag = (tagId) => {
        var _a, _b;
        if (!tagId) {
            setValue('recipientTags', {});
            return;
        }
        if ((_a = recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.segments) === null || _a === void 0 ? void 0 : _a.includes(Number(tagId)))
            setValue('recipientTags', Object.assign(Object.assign({}, recipientTags), { segments: recipientTags.segments.filter((id) => id !== Number(tagId)) }));
        else if ((_b = recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.phones) === null || _b === void 0 ? void 0 : _b.includes(tagId))
            setValue('recipientTags', Object.assign(Object.assign({}, recipientTags), { phones: recipientTags.phones.filter((phone) => phone !== tagId) }));
        else
            setValue('recipientTags', Object.assign(Object.assign({}, recipientTags), { all: false, withCards: false, noCards: false }));
    };
    const handleUpdateRecipientFilters = (value) => {
        setValue('recipientFilters', value, { shouldValidate: true });
        setValue('recipientTags', Object.assign(Object.assign({}, recipientTags), { all: false, withCards: false, noCards: false }));
    };
    const filtersTree = useClientFilters(extraFields, counters, [
        {
            type: 'custom',
            id: 'all',
            label: t('Все клиенты'),
            disabled: recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.all,
            onClick: () => handleUpdateRecipientTags({ all: true }),
        },
        {
            type: 'custom',
            id: 'withCards',
            label: t('Клиенты с картами'),
            disabled: recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.withCards,
            onClick: () => handleUpdateRecipientTags({ withCards: true }),
        },
        {
            type: 'custom',
            id: 'noCards',
            label: t('Клиенты без карт'),
            disabled: recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.noCards,
            onClick: () => handleUpdateRecipientTags({ noCards: true }),
        },
    ], recipientFilters);
    const customBadges = [];
    mailingOffers.forEach((mo) => {
        customBadges.push({
            id: mo.id.toString(),
            label: (_jsxs(Trans, { i18nKey: 'MAILING_RECIPIENTS', values: { name: mo.mailing.name }, children: ["\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u0438 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438", ' ', _jsxs("a", { className: styles.link, href: (getMailingLink === null || getMailingLink === void 0 ? void 0 : getMailingLink(mo)) || '#', target: '_blank', rel: 'noreferrer', children: ["\u00AB", mo.mailing.name, "\u00BB"] })] })),
            isNonEditable: true,
            isUntruncated: true,
        });
    });
    if (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.all)
        customBadges.push({ id: 'all', label: t('Все клиенты') });
    if (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.withCards)
        customBadges.push({ id: 'withCards', label: t('Клиенты с картами') });
    if (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.noCards)
        customBadges.push({ id: 'noCards', label: t('Клиенты без карт') });
    (_a = recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.segments) === null || _a === void 0 ? void 0 : _a.forEach((segmentId) => {
        const segment = segments.find((s) => s.id === segmentId);
        if (segment)
            customBadges.push({
                id: segment.id.toString(),
                label: segment.name,
            });
    });
    (_b = recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.phones) === null || _b === void 0 ? void 0 : _b.forEach((phone) => customBadges.push({
        id: phone,
        label: formatPhoneNumber(phone),
    }));
    const validate = (value) => {
        const { recipientTags } = getValues();
        const isValid = (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.all) || (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.noCards) || (recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.withCards)
            ? true
            : value && value.length > 0;
        if ((isSubmitting || isSubmitted) && !isValid)
            return 'error';
        return true;
    };
    useEffect(() => {
        trigger('recipientFilters');
    }, [recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.all, recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.noCards, recipientTags === null || recipientTags === void 0 ? void 0 : recipientTags.withCards]);
    return (_jsx(FormFieldRow, { labelWidth: labelWidth, help: errors.recipientFilters
            ? t('Выберите хотя бы одного участника')
            : undefined, hasError: !!errors.recipientFilters, children: _jsx("div", { id: 'recipientFilters', children: _jsx(Controller, { name: 'recipientFilters', control: control, rules: { validate }, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: filtersTree, value: field.value || [], onUpdate: handleUpdateRecipientFilters, customBadges: customBadges, onRemoveCustomBadge: handleRemoveRecipientTag, hasHangingOperator: false, shouldDisplayActions: !isPresetSelected, shouldDisplayFilterBadges: !isPresetSelected, buttonRef: field.ref })) }) }) }));
};
