import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { DropdownPills } from '@/components/DropdownPills/DropdownPills';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { Download } from '@/icons/Download';
import { activeDashboardAnalyticsSelector } from '@/store/selectors/reports';
import { toggleChartMetrics, updateChartMetrics } from '@/store/slice/reports';
import { Tooltip } from '../Tooltip/Tooltip';
import { AnalyticsChartPills } from './AnalyticsChartPills';
import styles from './styles.module.scss';
export const AnalyticsChartPillsContainer = ({ interval, exportReport, onChangeInterval }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const widget = useAppSelector(activeDashboardAnalyticsSelector);
    const userHasAccessTo = useUserPermissions();
    const userHasExportManage = userHasAccessTo('EXPORTS_MANAGE');
    const metrics = (widget === null || widget === void 0 ? void 0 : widget.configuration.metrics) || [];
    const currentMetrics = metrics.map((m) => m.key);
    const hiddenMetrics = metrics.filter((m) => !m.showOnChart).map((m) => m.key);
    return (_jsxs("div", { className: styles.container, children: [_jsx(AnalyticsChartPills, { items: currentMetrics, onChangeItems: (keys) => {
                    dispatch(updateChartMetrics({ keys }));
                }, disabledItems: hiddenMetrics, onChangeDisabledItems: (_, key) => {
                    dispatch(toggleChartMetrics({ key }));
                } }), _jsx(Tooltip, { title: !userHasExportManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsxs(Button, { disabled: !userHasExportManage, onClick: exportReport, className: styles.downloadButton, children: ["XLS ", _jsx(Download, {})] }) }), _jsx(DropdownPills, { items: [
                    { id: 'day', label: t('День') },
                    { id: 'week', label: t('Неделя') },
                    { id: 'month', label: t('Месяц') },
                ], selectedItemId: interval, onChange: onChangeInterval })] }));
};
