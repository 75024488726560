import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Pencil } from '@/icons/Pencil';
import { Draft } from '@/icons/status/Draft';
export const EditButton = ({ onClick, flowHasChanges = false, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasFlowsManage = userHasAccessTo('FLOWS_MANAGE');
    return (_jsx(Tooltip, { title: !userHasFlowsManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { icon: flowHasChanges ? _jsx(Draft, {}) : _jsx(Pencil, {}), onClick: onClick, children: flowHasChanges ? t('В черновик') : t('Редактировать') }) }));
};
