import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { ChannelEmailAlt } from '@/icons/ChannelEmailAlt';
import { ChannelPushAlt } from '@/icons/ChannelPushAlt';
import { ChannelSMSAlt } from '@/icons/ChannelSMSAlt';
import { ChannelTelegramAlt } from '@/icons/ChannelTelegramAlt';
import { ChannelViberAlt } from '@/icons/ChannelViberAlt';
import styles from './styles.module.scss';
export const ChannelCircles = memo(({ channels, center = false, cascade = true }) => {
    return (_jsxs("div", { className: classNames(styles.channels, {
            [styles.cascade]: cascade,
            [styles.center]: center,
        }), children: [channels.push && (_jsx("div", { className: styles.push, children: _jsx(ChannelPushAlt, {}) })), channels.viber && (_jsx("div", { className: styles.viber, children: _jsx(ChannelViberAlt, {}) })), channels.sms && (_jsx("div", { className: styles.sms, children: _jsx(ChannelSMSAlt, {}) })), channels.telegram && (_jsx("div", { className: styles.telegram, children: _jsx(ChannelTelegramAlt, {}) })), channels.email && (_jsx("div", { className: styles.email, children: _jsx(ChannelEmailAlt, {}) }))] }));
});
