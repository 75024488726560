import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import styles from './styles.module.scss';
export const SegmentBadgeInfo = memo(({ configuration, title, color }) => {
    const configurationList = useMemo(() => {
        var _a;
        return (_a = configuration === null || configuration === void 0 ? void 0 : configuration.map(({ text }) => text)) === null || _a === void 0 ? void 0 : _a.filter(Boolean);
    }, [configuration]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.header, children: [_jsx("div", { className: styles.title, style: { display: '-webkit-box' }, children: title }), _jsx("div", { className: styles.mark, style: { backgroundColor: color } })] }), (configurationList === null || configurationList === void 0 ? void 0 : configurationList.length) && (_jsx("div", { className: styles.list, children: configurationList.map((html, index) => (_jsx("div", { className: classNames(styles.row, {
                        [styles.listItem]: configurationList.length > 1,
                    }), dangerouslySetInnerHTML: { __html: html } }, index))) }))] }));
});
