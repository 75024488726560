import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EmailLarge } from '@/icons/flow/EmailLarge';
import { PushLarge } from '@/icons/flow/PushLarge';
import { SMSLarge } from '@/icons/flow/SMSLarge';
import { TelegramLarge } from '@/icons/flow/TelegramLarge';
import { ViberLarge } from '@/icons/flow/ViberLarge';
import { notNull } from '@/utils/notNull';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
import { useChannelFormState } from './useChannelFormState';
export const ChannelFormIcon = () => {
    var _a;
    const { selectedNode } = useInteractiveFlow();
    const data = selectedNode.getData() || {};
    const { channels = [] } = data;
    const { isCascade, isPush, isSMS, isViber, isEmail, isTelegram } = useChannelFormState();
    const cascadeChannels = [
        channels.includes('push') ? 'push' : null,
        channels.includes('viber') ? 'viber' : null,
        channels.includes('sms') ? 'sms' : null,
    ].filter(notNull);
    let icon = _jsx(PushLarge, {});
    if (isPush)
        icon = _jsx(PushLarge, {});
    else if (isSMS)
        icon = _jsx(SMSLarge, {});
    else if (isViber)
        icon = _jsx(ViberLarge, {});
    else if (isEmail)
        icon = _jsx(EmailLarge, {});
    else if (isTelegram)
        icon = _jsx(TelegramLarge, {});
    else if (isCascade)
        icon = (_jsx("div", { className: styles.cascadeIcon, style: {
                marginRight: cascadeChannels.length > 1 ? -8 * (cascadeChannels.length - 1) : 0,
            }, children: cascadeChannels.map((channel, i) => {
                return (_jsxs("div", { style: { left: -8 * i }, children: [channel === 'push' && _jsx(PushLarge, {}, 'push'), channel === 'sms' && _jsx(SMSLarge, {}, 'sms'), channel === 'viber' && _jsx(ViberLarge, {}, 'viber')] }, channel + i));
            }) }));
    if (!((_a = data.channels) === null || _a === void 0 ? void 0 : _a.length))
        return null;
    return icon;
};
