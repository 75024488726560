import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { MessageEditor, } from '@/components/rich-text/MessageEditor/MessageEditor';
export const FormMailingSMSText = ({ length = 0, messages = 1, hasWalletFeature, labelWidth, currency, }) => {
    var _a;
    const { t } = useTranslation();
    const { control, setValue, watch, formState } = useFormContext();
    const { errors, isSubmitted } = formState;
    const channels = watch('channels');
    const isRequired = channels === null || channels === void 0 ? void 0 : channels.includes('sms');
    const validateTrimValue = (value) => isRequired && !(value === null || value === void 0 ? void 0 : value.trim()) ? t('FIELD_REQUIRED_ERROR') : true;
    const validateAlphabetMixed = (value) => {
        if (!(channels === null || channels === void 0 ? void 0 : channels.includes('sms')))
            return true;
        if (!value)
            return true;
        const res = value.match(/(\S*(?:[A-z][А-Яа-яЁё]|[А-Яа-яЁё][A-z])\S*)/g);
        if (res)
            return t('MAILING.CREATE.ALPHABET_MIXED', { words: res.join(', ') });
        return true;
    };
    const editorContext = {
        channel: 'sms',
        triggerType: watch('triggerType'),
        rewards: watch('rewards'),
        hasWalletFeature,
    };
    return (_jsx(FormFieldRow, { label: t('Сообщение'), labelFor: 'smsText', labelWidth: labelWidth, controlSize: '300', help: ((_a = errors.smsText) === null || _a === void 0 ? void 0 : _a.message) ||
            t('Длина:') + ' ' + length + ` (${messages} SMS)`, helpSize: '300', hasError: !!errors.smsText, tooltip: t('Любые символы юникода (кириллица тоже). Рекомендуемая длина < 6 SMS'), tooltipPlacement: 'top', isRequired: isRequired, children: _jsx(Controller, { name: 'smsText', control: control, rules: {
                validate: {
                    required: validateTrimValue,
                    alphabet: validateAlphabetMixed,
                },
            }, render: ({ field }) => (_jsx(MessageEditor, { initialValue: field.value || '', onChange: (text) => {
                    setValue('smsText', text, {
                        shouldValidate: isSubmitted,
                        shouldDirty: true,
                    });
                }, placeholder: t('введите сообщение'), context: editorContext, currency: currency, hasError: !!errors.smsText, hasAutoFocus: true })) }) }));
};
