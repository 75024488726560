import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { CloseBackside } from '@/icons/wallet-card/apple/CloseBackside';
import styles from './styles.module.scss';
export const AppleWalletCardBackside = ({ children, onClose, flipped }) => {
    const [isHeaderActive, setHeaderIsActive] = useState(false);
    const handleScroll = useCallback((e) => {
        setHeaderIsActive(e.currentTarget.scrollTop > 0);
    }, []);
    return (_jsxs("div", { className: classNames(styles.backsideWrapper, {
            [styles.flipped]: flipped,
        }), onScroll: handleScroll, children: [_jsx("div", { className: classNames(styles.backsideHeader, {
                    [styles.active]: isHeaderActive,
                }), children: _jsx("button", { className: styles.backsideCloseButton, type: 'button', onClick: onClose, children: _jsx(CloseBackside, {}) }) }), _jsx("div", { className: styles.backsideContent, children: children })] }));
};
