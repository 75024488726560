import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/components/Table/Table';
import { formatDate } from '@/utils/dates';
import { ExportStatus } from './ExportStatus';
import styles from './styles.module.scss';
export const ExportsTable = memo(({ items, isLoading }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'name',
            title: t('Название'),
        },
        {
            id: 'author',
            title: t('Автор'),
        },
        {
            id: 'createdAt',
            title: t('Дата создания'),
        },
        {
            id: 'status',
            title: t('Статус'),
            align: 'right',
        },
    ], [t]);
    const rows = useMemo(() => items.map((item) => ({
        id: item.id,
        cells: [
            _jsx("span", { dangerouslySetInnerHTML: { __html: item.name } }, 'name'),
            !!item.operator && item.operator.username
                ? item.operator.username
                : '–',
            formatDate(item.createdAt),
            _jsx(ExportStatus, { data: item }, 'status'),
        ],
    })), [t, items]);
    return items.length > 0 ? (_jsx(Table, { needAdaptive: false, isLoading: isLoading, rows: rows, columns: columns })) : (_jsx("span", { className: styles.empty, children: t('Пока нет выгрузок') }));
});
