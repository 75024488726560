import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { FlowLockedAlert } from '@/components/flow/FlowLockedAlert/FlowLockedAlert';
import { RFMLockedAlert } from '@/components/rfm/RFMLockedAlert/RFMLockedAlert';
import { BurgerMenu } from '@/icons/BurgerMenu';
import { brandHasFlowFeature } from '@/utils/brandHasFlowFeature';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { AppHeaderBalance } from './AppHeaderBalance';
import { AppHeaderPlusButton } from './AppHeaderPlusButton';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import styles from './styles.module.scss';
import { useAppHeaderState } from './useAppHeaderState';
export const AppHeader = memo(({ balance, brand, isCollapsed, isHidden, onToggleHidden, onGoToBalance, onAddClient, onAddPurchase, }) => {
    const location = useLocation();
    const { hidden } = useAppHeaderState();
    const isRFMPage = matchPath(ROUTES.RFM, location.pathname);
    const isRFMEnabled = brandHasRFMFeature(brand);
    const isFlowsPage = location.pathname.startsWith(ROUTES.FLOWS);
    const isFlowsEnabled = brandHasFlowFeature(brand);
    return (_jsxs(_Fragment, { children: [((isRFMPage && !isRFMEnabled) || (isFlowsPage && !isFlowsEnabled)) && (_jsxs("div", { className: classNames(styles.alert, {
                    [styles.collapsed]: isCollapsed,
                    [styles.hidden]: isHidden,
                    [styles.floating]: hidden,
                }), children: [!!isRFMPage && _jsx(RFMLockedAlert, {}), !!isFlowsPage && _jsx(FlowLockedAlert, {})] })), _jsxs("header", { id: 'app-header', className: classNames(styles.header, {
                    [styles.collapsed]: isCollapsed,
                    [styles.hidden]: isHidden,
                    [styles['not-visible']]: hidden,
                }), children: [_jsxs("div", { className: styles.left, children: [_jsx("button", { className: styles.toggle, onClick: onToggleHidden, children: _jsx(BurgerMenu, {}) }), _jsx(Breadcrumbs, {})] }), _jsxs("div", { className: classNames(styles.right, {
                            [styles.rightHide]: !isHidden,
                        }), children: [_jsx(AppHeaderBalance, { balance: brand.balance, currency: brand.currency.unitShort, forecastDays: balance.forecastDays, onGoToBalance: onGoToBalance }), _jsx(AppHeaderPlusButton, { onAddClient: onAddClient, onAddPurchase: onAddPurchase })] })] })] }));
});
