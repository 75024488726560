import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position, useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterConditionsAlt } from '@/icons/flow/FilterConditionsAlt';
import { Start } from '@/icons/flow/Start';
import { Plus } from '@/icons/Plus';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import { NodeBody } from '../helpers/NodeBody/NodeBody';
import { NodeDeleteButton } from '../helpers/NodeDeleteButton/NodeDeleteButton';
import { NodeRow } from '../helpers/NodeRow/NodeRow';
import { NodeTitle } from '../helpers/NodeTitle/NodeTitle';
import { NodeWrapper } from '../helpers/NodeWrapper/NodeWrapper';
export const ConditionsNode = ({ id, data, }) => {
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const { type, subheader, conditions, configuration } = data;
    const thisNode = flow.getNode(id);
    const isInvalid = !!(validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[(thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid) || '']);
    const icon = ['trigger', 'schedule'].includes(type) ? (_jsx(Start, {})) : (_jsx(FilterConditionsAlt, {}));
    const header = type === 'trigger' || type === 'schedule' ? 'Условие старта' : 'Фильтр';
    const handleClick = useCallback(() => {
        if (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid)
            selectedNode.selectUUID(thisNode.uuid);
    }, [thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid, selectedNode.selectUUID]);
    return (_jsxs(_Fragment, { children: [_jsxs(NodeWrapper, { gap: '12', onClick: handleClick, isSelected: selectedNode.uuid === (thisNode === null || thisNode === void 0 ? void 0 : thisNode.uuid), isInvalid: isInvalid, isInteractive: true, children: [_jsx(NodeTitle, { icon: icon, hasSmallPadding: true, children: t(header) }), !!subheader && (_jsx(NodeBody, { hasSmallPadding: true, movedDown: true, children: t(subheader) })), _jsxs("div", { children: [configuration === null || configuration === void 0 ? void 0 : configuration.map((savedFilter, i) => {
                                const condition = ((conditions === null || conditions === void 0 ? void 0 : conditions[i]) || '').toString();
                                const handleId = savedFilter
                                    ? `${savedFilter.code}-${i}`
                                    : condition;
                                const handleRowClick = (e) => {
                                    e.stopPropagation();
                                    if (thisNode)
                                        selectedNode.selectUUID(thisNode.uuid, i);
                                };
                                return (_jsx(NodeRow, { nodeId: id, handleId: handleId, onClick: isEditMode ? handleRowClick : undefined, children: _jsx("div", { dangerouslySetInnerHTML: {
                                            __html: type === 'schedule'
                                                ? savedFilter.text
                                                : getConditionText(savedFilter.text),
                                        } }) }, handleId));
                            }), !(configuration === null || configuration === void 0 ? void 0 : configuration.length) && (_jsx(NodeRow, { nodeId: id, handleId: 'add', asIcon: true, hasHandlePlusButton: false, children: _jsx(Plus, {}) }, 'add'))] })] }), _jsx(NodeDeleteButton, { nodeId: id }), _jsx(Handle, { className: classNames('flow-handle', 'flow-handle-target'), type: 'target', position: Position.Left })] }));
};
const getConditionText = (condition) => {
    const colon = condition.indexOf(':');
    return condition.slice(colon + 1).trim();
};
