import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChannelEmailAlt } from '@/icons/ChannelEmailAlt';
import { ChannelPushAlt } from '@/icons/ChannelPushAlt';
import { ChannelSMSAlt } from '@/icons/ChannelSMSAlt';
import { ChannelTelegramAlt } from '@/icons/ChannelTelegramAlt';
import { ChannelViberAlt } from '@/icons/ChannelViberAlt';
import { Send } from '@/icons/Send';
import styles from '../../styles.module.scss';
import { getMailingContent } from './getMailingContent';
import { getMailingDescription } from './getMailingDescription';
import { getMailingTitle } from './getMailingTitle';
export const mailingChannel = (args) => {
    const titleContent = getMailingTitle(args);
    const { color, content: descriptionContent } = getMailingDescription(args);
    return {
        color,
        Icon: getChannelIcon(args),
        title: (_jsxs("div", { className: styles.channelTitle, children: [_jsx("div", { children: titleContent }), descriptionContent] })),
        content: getMailingContent(args),
    };
};
// -----------------------------------------------------------------------------
const getChannelIcon = (args) => {
    const { event } = args;
    const icons = {
        sending_push: _jsx(ChannelPushAlt, {}),
        sending_webpush: _jsx(ChannelPushAlt, {}),
        sending_viber: _jsx(ChannelViberAlt, {}),
        sending_sms: _jsx(ChannelSMSAlt, {}),
        sending_email: _jsx(ChannelEmailAlt, {}),
        sending_chat: _jsx(ChannelTelegramAlt, {}),
    };
    return icons[event.eventType] || _jsx(Send, { size: 20 });
};
