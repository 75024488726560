var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Popup } from '@/components/Popup/Popup';
import { FormSegment } from './FormSegment';
import styles from './styles.module.scss';
export const FormSegmentPopup = memo(({ trigger, id = 'segment-form', error, onSubmit, isSubmitting, isEdit, defaultValues, }) => {
    const { t } = useTranslation();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [cloneElement(trigger, {
                ref: triggerRef,
                onClick: () => setPopupIsOpen(!popupIsOpen),
                isActive: popupIsOpen,
            }), _jsx(Popup, { isOpen: popupIsOpen, onClose: () => setPopupIsOpen(false), anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, placement: 'bottom', offset: [0, 10], hasTail: true, isRounded: true, children: _jsx("div", { className: styles.popupBody, children: _jsx(FormSegment, { id: id, asPopup: true, onSubmit: (values) => __awaiter(void 0, void 0, void 0, function* () {
                            yield onSubmit(values);
                            setPopupIsOpen(false);
                        }), defaultValues: defaultValues, submitButton: _jsxs(_Fragment, { children: [error && (_jsx(FormFieldRow, { asErrorMessage: true, asModalFooter: true, hasLabelHidden: true, children: error })), _jsx(Button, { className: styles.submitButton, type: 'submit', form: id, theme: 'primary', disabled: isSubmitting, children: isEdit ? t('Сохранить сегмент') : t('Создать сегмент') })] }) }) }) })] }));
});
