import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { PanelTitle } from './PanelTitle';
import styles from './styles.module.scss';
export const Panel = memo(({ className, children, title, isCentered = false, size = '740', footer, onClose, }) => {
    return (_jsxs("div", { className: classNames(styles.wrapper, styles[`size-${size}`], {
            [styles.centered]: isCentered,
            [styles.withFooter]: !!footer,
        }, className), children: [_jsx(PanelTitle, { title: title, onClose: onClose }), children, footer && _jsx("div", { className: styles.footer, children: footer })] }));
});
