import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { ChannelCircles } from '@/components/ChannelCircles/ChannelCircles';
import styles from '../styles.module.scss';
export const createCellsList = ({ isChannels = true, hasTitle = false, data, itemPrevTitle, }) => {
    const dataArray = Object.values(data || {});
    return dataArray.map((item) => {
        {
            return {
                id: item.id,
                cells: [
                    {
                        title: (_jsxs("div", { className: styles.modalItemTitle, children: [itemPrevTitle &&
                                    itemPrevTitle(item.id, item.triggerType, item.title || item.name), hasTitle && (item.title || item.name), _jsx("span", { className: styles.count, children: item.count })] }, 'title')),
                    },
                    {
                        title: isChannels ? (_jsx(ChannelCircles, { channels: item.channels }, 'channels')) : null,
                    },
                    {
                        title: item.createdAt
                            ? moment(item.createdAt).format('DD.MM.YYYY, HH:mm')
                            : '',
                    },
                ],
            };
        }
    });
};
