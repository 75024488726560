import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Table } from '@/components/Table/Table';
import styles from './styles.module.scss';
import { titleRenderer } from './titleRenderers/titleRenderer';
export const EventsTable = memo(({ isLoading, eventsData, openOverview, handleOrder, handleReturn, handlePurchase, handleEntityLink, currency, }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'event_date',
            title: t('Дата события'),
        },
        {
            id: 'clients',
            title: t('Клиент'),
        },
        {
            id: 'event-icon',
        },
        {
            id: 'event',
            title: t('Событие'),
        },
    ], [t]);
    const events = eventsData.flatMap(([date, item]) => item.reduce((acc, event, index) => {
        const { title, color, tooltipTitle, Icon, content } = titleRenderer({
            event,
            translate: t,
            entityHandlers: {
                handlePurchase,
                handleEntityLink,
                handleReturn,
                handleOrder,
                openOverview,
            },
            currency,
        }) || {};
        const eventID = `${event.refId}-${index}`;
        const currentEvent = {
            childGroupId: content ? `event-${eventID}-content` : undefined,
            rows: [
                {
                    id: eventID,
                    cells: [
                        _jsxs(_Fragment, { children: [moment(date).format('DD.MM.YYYY'), ' ', moment(event.createdAt).format('HH:mm')] }),
                        _jsx("button", { className: classNames(styles.clientButton, {
                                [styles.disabled]: !openOverview,
                            }), onClick: () => openOverview === null || openOverview === void 0 ? void 0 : openOverview(event.clientId), children: _jsx("span", { className: styles.clientName, children: event.clientName }) }, 'client' + index),
                        _jsx("div", { className: classNames({
                                [styles.negativeEvent]: color === 'red',
                                [styles.positiveEvent]: color === 'green',
                                [styles.neutralEvent]: color === 'gray',
                            }), children: Icon }, index),
                        _jsxs("div", { className: classNames(styles.eventTitle, {
                                [styles.negativeEvent]: color === 'red',
                                [styles.positiveEvent]: color === 'green',
                                [styles.neutralEvent]: color === 'gray',
                            }), children: [title, tooltipTitle && (_jsx(HelpTooltip, { className: styles.tooltipIcon, tooltipClassName: styles.tooltip, title: tooltipTitle, size: 'medium', placement: 'top', hasLightIcon: true }, 'tooltip' + index))] }, index),
                    ],
                },
            ],
        };
        acc[`event-${eventID}`] = currentEvent;
        if (content)
            acc[`event-${eventID}-content`] = {
                type: 'sub-group',
                rows: [
                    {
                        cells: [null, null, null, content],
                        id: index + 11 + event.refId,
                    },
                ],
            };
        return acc;
    }, {}));
    let rows = {};
    events.forEach((item) => {
        rows = Object.assign(Object.assign({}, rows), item);
    });
    return (_jsx(Table, { hideSubGroupsCount: true, subRowClassName: styles.subRow, needWrapper: false, expandButtonCellIndex: 3, className: styles.table, cellClassNames: {
            0: styles.eventsDateCell,
            1: styles.eventTitleCell,
            2: styles.eventIconCell,
            3: styles.eventsTitleCell,
        }, needAdaptive: false, isLoading: isLoading, rows: rows, columns: columns }));
});
