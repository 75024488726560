import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@/components/Badge/Badge';
import { ButtonLink } from '@/components/Button2/ButtonLink';
import { Table } from '@/components/Table/Table';
import { Download } from '@/icons/Download';
import { formatDate } from '@/utils/dates';
import { money } from '@/utils/numbers';
export const InvoicesTable = memo(({ items, currency }) => {
    const { t } = useTranslation();
    const columns = useMemo(() => [
        {
            id: 'number',
            title: t('Номер'),
        },
        {
            id: 'amount',
            title: t('Сумма'),
            align: 'right',
        },
        {
            id: 'status',
            title: t('Статус'),
            align: 'center',
        },
        {
            id: 'createdAt',
            title: t('Дата выставления'),
        },
        {
            id: 'paidAt',
            title: t('Дата оплаты'),
        },
        {
            id: 'download',
        },
    ], [t]);
    const rows = useMemo(() => items.map((item) => {
        let statusType = 'default';
        if (item.status === 0 || item.status === 2)
            statusType = 'warning';
        if (item.status === 1)
            statusType = 'success';
        return {
            id: item.id,
            cells: [
                item.number,
                money({ input: item.amount, currency }),
                _jsx(Badge, { type: statusType, children: t(item.displayStatus) }, 'status'),
                formatDate(item.createdAt),
                formatDate(item.paidAt),
                item.status === 0 || item.status === 2 ? (_jsxs(ButtonLink, { title: t('Скачать счет в PDF'), href: `/billing/download-invoice/${item.id}`, download: true, isOutlined: true, children: ["PDF ", _jsx(Download, {})] }, 'download')) : (''),
            ],
        };
    }), [t, items]);
    return _jsx(Table, { columns: columns, rows: rows });
});
