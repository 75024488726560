import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Offer as OfferIcon } from '@/icons/Offer';
import { Reset } from '@/icons/Reset';
import { MailingRewardsPillsPopup } from '../MailingRewardsPillsPopup';
import styles from '../styles.module.scss';
export const Offer = ({ idx, value, onChange, offers = [], promocodes = [], giftCards = [], isDisabled = false, currency, }) => {
    const { t } = useTranslation();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const togglePopup = () => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    };
    const handleReset = () => {
        const newValue = [...value];
        newValue.splice(idx, 1);
        onChange(newValue);
    };
    const selectedItem = value[idx];
    if (selectedItem.type !== 'offer')
        return null;
    const selectedOffer = offers.find((o) => o.id === selectedItem.id);
    if (!selectedOffer)
        return null;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.buttonWrapper, children: [_jsx("button", { className: classNames(styles.formButton, {
                            [styles.nonInteractive]: isDisabled,
                            [styles.active]: popupIsOpen,
                        }), ref: triggerRef, type: 'button', onClick: togglePopup, children: _jsxs("div", { className: styles.buttonContent, children: [_jsx(OfferIcon, {}), _jsxs("div", { children: [_jsx("div", { children: selectedOffer.title }), !!selectedItem.expireInDays && (_jsxs("div", { className: styles.buttonDescription, children: [t('Действует'), " ", selectedItem.expireInDays, ' ', t('DAYS_EXPIRING', {
                                                    count: Number(selectedItem.expireInDays) || 0,
                                                }), ' ', t('с момента рассылки')] }))] })] }) }), _jsx("button", { className: styles.resetButton, type: 'button', disabled: isDisabled, onClick: handleReset, children: _jsx(Reset, {}) })] }), popupIsOpen && (_jsx(MailingRewardsPillsPopup, { type: 'edit', activeMenuItem: 'offers', selectedItemIdx: idx, isOpen: popupIsOpen, onClose: togglePopup, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, value: value, onChange: onChange, offers: offers, promocodes: promocodes, giftCards: giftCards, currency: currency }))] }));
};
