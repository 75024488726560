import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/components/Modal/Modal';
import { Loader } from '@/icons/Loader';
import { EmailTemplateItem } from './EmailTemplateItem';
import styles from './styles.module.scss';
import { useGetTemplateData } from './useGetTemplateData';
export const EmailTemplateModal = ({ isOpen, templatesData, onClose, onCreateTemplate, onSelectTemplate, }) => {
    const { t } = useTranslation();
    const getTemplateData = useGetTemplateData(onSelectTemplate);
    const { handleIntersection, hasMoreData, isIntersecting, recent, templates } = templatesData;
    const hasRecentTemplates = (recent || []).length > 0;
    const allTemplatesIndex = hasRecentTemplates ? 1 : 0;
    const [activeTabIndex, setActiveTabIndex] = useState(allTemplatesIndex);
    const navItems = getUniqueTags(templates || []);
    navItems.unshift(t('Все'));
    if (hasRecentTemplates)
        navItems.unshift(t('Созданные вами'));
    let visibleTemplates = templates;
    if (activeTabIndex === 0)
        visibleTemplates = hasRecentTemplates ? recent : templates;
    if (activeTabIndex > allTemplatesIndex) {
        const selectedTag = navItems[activeTabIndex];
        visibleTemplates = templates.filter((template) => { var _a; return (_a = template.tags) === null || _a === void 0 ? void 0 : _a.includes(selectedTag); });
    }
    return (_jsx(Modal, { isOpen: isOpen, onClose: onClose, title: t('Выберите шаблон письма'), size: '1105', hasNoPadding: true, bodyClassName: styles.modalBody, modalClassName: styles.modalMain, children: _jsxs("div", { className: styles.body, id: 'scrollArea', children: [_jsx("ul", { className: classNames(styles.nav, {
                        [styles.hasRecentTemplates]: hasRecentTemplates,
                    }), children: navItems.map((item, index) => (_jsx("li", { className: classNames(styles.navItem, {
                            [styles.active]: activeTabIndex === index,
                        }), onClick: () => setActiveTabIndex(index), children: item }, item))) }), _jsxs("div", { className: styles.templates, children: [_jsxs("div", { className: styles.templatesGrid, children: [_jsx(EmailTemplateItem, { onClick: onCreateTemplate }, 'new'), visibleTemplates === null || visibleTemplates === void 0 ? void 0 : visibleTemplates.map((template, i) => (_jsx("div", { children: _jsx(EmailTemplateItem, { name: template.name, previewImageUrl: template.emailPreviewImageUrl, onClick: () => getTemplateData({
                                            id: template.id,
                                            kind: template.kind,
                                        }) }) }, `${template.name}-${i}`)))] }), hasMoreData && activeTabIndex === 0 && (_jsx("div", { className: styles.templatesLoader, ref: handleIntersection, children: _jsx(Loader, { className: classNames(styles.loader, {
                                    [styles.loaderShow]: isIntersecting,
                                }) }) }))] })] }) }));
};
function getUniqueTags(templates) {
    const tags = [];
    templates.forEach((template) => {
        var _a;
        (_a = template.tags) === null || _a === void 0 ? void 0 : _a.forEach((tag) => {
            if (!tags.includes(tag))
                tags.push(tag);
        });
    });
    return tags;
}
