import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Plus } from '@/icons/Plus';
import styles from './styles.module.scss';
export const EmailTemplateItem = ({ name, previewImageUrl, onClick, }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.templateWrapper, onClick: onClick, children: [_jsx("div", { className: styles.template, style: {
                    backgroundImage: previewImageUrl
                        ? `url(${previewImageUrl})`
                        : undefined,
                }, children: !previewImageUrl && _jsx(Plus, {}) }), _jsx("div", { className: styles.templateName, children: name || t('С чистого листа') })] }));
};
