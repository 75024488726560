import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HelpTooltip } from '~/src/components/HelpTooltip2/HelpTooltip';
import { ChoosePosition } from './ChoosePosition';
import { EntitySlot } from './EntitySlot';
import styles from './styles.module.scss';
const NEW_ENTITY_GUID = 'new-entity-guid';
export const FormPriorityTab = ({ isOfferForm = false, currency, entityTitle, groupChildrenList, priority, currentGroup, arbitrationPriorityGuid, isEntityInsideList, groupSelectOptions, setIsEntityInsideList, setGroupChildrenList, setCurrentGroup, }) => {
    const { t } = useTranslation();
    const isGroupsExist = groupSelectOptions.length > 0;
    const { watch } = useFormContext();
    const newEntity = {
        entityTitle,
        newEntity: true,
        guid: arbitrationPriorityGuid || NEW_ENTITY_GUID,
        priority: 0,
    };
    const isPersonalPromocode = watch('codeType') !== 'shared';
    return (_jsx(_Fragment, { children: _jsxs("div", { className: styles.priorityTabContent, children: [_jsx(HelpTooltip, { placement: 'right', html: isOfferForm
                        ? t('TOOLTIP_ARBITRATION_OFFER_HINT')
                        : t('TOOLTIP_ARBITRATION_PROMOCODE_HINT'), children: _jsx("span", { className: styles.priorytyTabTitle, children: isOfferForm
                            ? t('Приоритет акций среди других')
                            : t('Приоритет промокодов среди других') }) }), _jsx(EntitySlot, { isPersonalPromocode: isPersonalPromocode, isOfferForm: isOfferForm, newEntity: newEntity, entityTitle: entityTitle, arbitrationPriorityGuid: arbitrationPriorityGuid, isEntityInsideList: isEntityInsideList, isGroupsExist: isGroupsExist, setIsEntityInsideList: setIsEntityInsideList, setGroupChildrenList: setGroupChildrenList }), _jsx(ChoosePosition, { isPersonalPromocode: isPersonalPromocode, isOfferForm: isOfferForm, newEntity: newEntity, currency: currency, isGroupsExist: isGroupsExist, entityTitle: entityTitle, groupChildrenList: groupChildrenList, priority: priority, currentGroup: currentGroup, arbitrationPriorityGuid: arbitrationPriorityGuid, groupSelectOptions: groupSelectOptions, setIsEntityInsideList: setIsEntityInsideList, setGroupChildrenList: setGroupChildrenList, setCurrentGroup: setCurrentGroup })] }) }));
};
