import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { ChannelEmailAlt } from '@/icons/ChannelEmailAlt';
import { ChannelPushAlt } from '@/icons/ChannelPushAlt';
import { ChannelSMSAlt } from '@/icons/ChannelSMSAlt';
import { ChannelTelegramAlt } from '@/icons/ChannelTelegramAlt';
import { ChannelViberAlt } from '@/icons/ChannelViberAlt';
import { ClickHand } from '@/icons/ClickHand';
import { EmailNotOpened } from '@/icons/flow/EmailNotOpened';
import { Goal } from '@/icons/Goal';
import { Message } from '@/icons/Message';
import { Send } from '@/icons/Send';
import { Cancelled } from '@/icons/status/Cancelled';
import { Done } from '@/icons/status/Done';
import { ErrorTriangle } from '@/icons/status/ErrorTriangle';
import { Planned } from '@/icons/status/Planned';
import { Sending } from '@/icons/status/Sending';
import { getAutomaticMailingOptionsSelector, getCashierOptionsSelector, getClientLevelOptionsSelector, getManualMailingOptionsSelector, getRFMSegmentOptionsSelector, getSegmentOptionsSelector, } from '@/store/selectors/filters';
import { getAutomaticMailingOptionsThunk, getCashierOptionsThunk, getClientLevelOptionsThunk, getManualMailingOptionsThunk, getRFMSegmentOptionsThunk, getSegmentOptionsThunk, } from '@/store/thunk/filters';
import { brandHasRFMFeature } from '@/utils/brandHasRFMFeature';
import { noop } from '@/utils/noop';
export const useClientEventFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { brand } = useCurrentUser();
    const isRFMEnabled = brandHasRFMFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    const extraFields = (brand === null || brand === void 0 ? void 0 : brand.brand.clientExtraFields) || [];
    const segments = useAppSelector(getSegmentOptionsSelector);
    const rfmSegments = useAppSelector(getRFMSegmentOptionsSelector);
    const cashiers = useAppSelector(getCashierOptionsSelector);
    const clientLevels = useAppSelector(getClientLevelOptionsSelector);
    const manualMailings = useAppSelector(getManualMailingOptionsSelector);
    const automaticMailings = useAppSelector(getAutomaticMailingOptionsSelector);
    const clientBranch = {
        type: 'branch',
        id: 'client',
        label: t('Клиент'),
        items: [
            {
                type: 'leaf',
                id: 'clientPhone',
                label: t('Телефон'),
                filterTitle: t('По клиенту с телефоном'),
                template: 'textField',
            },
            {
                type: 'leaf',
                id: 'clientEmail',
                label: t('Email'),
                filterTitle: t('По клиенту с email'),
                template: 'textField',
            },
            {
                type: 'leaf',
                id: 'loyaltyCard',
                label: t('Номер карты'),
                filterTitle: t('По клиенту с картой'),
                template: 'textField',
            },
            {
                type: 'branch',
                id: 'segments',
                label: t('Сегмент'),
                items: [
                    {
                        type: 'leaf',
                        id: 'clientSegments',
                        label: t('REGULAR_SEGMENTS'),
                        filterTitle: t('По клиентам в сегменте'),
                        template: 'checkboxes-segments',
                        loadOptions: () => dispatch(getSegmentOptionsThunk()),
                        options: segments,
                        useBothOperations: true,
                    },
                    {
                        type: 'leaf',
                        id: 'clientRfmSegments',
                        label: 'smartRFM',
                        filterTitle: t('По клиентам в сегменте'),
                        template: 'checkboxes-segments',
                        loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                        options: rfmSegments,
                        useBothOperations: true,
                        locked: !isRFMEnabled,
                    },
                ],
            },
        ],
    };
    const clientUpdateBranch = {
        type: 'branch',
        id: 'client-update',
        label: t('Профиль клиента'),
        items: [
            {
                type: 'leaf',
                id: 'clientUpdateEventType',
                template: 'checkboxes-tree-loader',
                label: t('Тип события'),
                filterTitle: t('Профиль клиента'),
                leafPrefix: '',
                filterInBrowser: true,
                loadTree: noop,
                loadBranch: noop,
                options: {
                    items: [
                        { id: '0', label: t('создан'), items: null },
                        { id: '6', label: t('добавлен по API'), items: null },
                        { id: '1', label: t('изменены данные'), items: null },
                        { id: '2', label: t('изменены данные по API'), items: null },
                        {
                            id: 'wallet_card_created',
                            label: t('установил карту Wallet'),
                            items: null,
                        },
                        {
                            id: 'wallet_card_deleted',
                            label: t('удалил карту Wallet'),
                            items: null,
                        },
                        { id: '3', label: t('удалён'), items: null },
                        { id: '7', label: t('удалён по API'), items: null },
                        { id: '4', label: t('заблокирован'), items: null },
                        { id: '5', label: t('разблокирован'), items: null },
                    ],
                },
            },
            {
                type: 'leaf',
                id: 'clientUpdateEventChanges',
                template: 'checkboxes-tree-loader',
                label: t('Изменены поля'),
                leafPrefix: '',
                filterInBrowser: true,
                loadTree: noop,
                loadBranch: noop,
                options: {
                    items: [
                        { id: 'fullName', label: t('ФИО'), items: null },
                        { id: 'surname', label: t('Фамилия'), items: null },
                        { id: 'name', label: t('Имя'), items: null },
                        { id: 'patronymic', label: t('Отчество'), items: null },
                        { id: 'phoneNumber', label: t('Телефон'), items: null },
                        { id: 'email', label: t('Email'), items: null },
                        { id: 'card', label: t('Номер карты'), items: null },
                        { id: 'level', label: t('Уровень'), items: null },
                        { id: 'gender', label: t('Пол'), items: null },
                        {
                            id: 'isEmailSubscribed',
                            label: t('Подписка на Email-рассылку'),
                            items: null,
                        },
                        {
                            id: 'isPhoneSubscribed',
                            label: t('Подписка на телефон и мессенджеры'),
                            items: null,
                        },
                        { id: 'birthdate', label: t('День рождения'), items: null },
                        { id: 'childrenNum', label: t('Дети'), items: null },
                        ...extraFields.map((ef) => ({
                            id: ef.name,
                            label: ef.title,
                            items: null,
                        })),
                    ],
                },
            },
            {
                type: 'leaf',
                id: 'clientUpdateEventEditor',
                label: t('Сотрудник'),
                filterTitle: t('Работал над профилем'),
                template: 'checkboxes-paginated-loader',
                loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                options: cashiers,
            },
        ],
    };
    const bonusesBranch = {
        type: 'branch',
        id: 'bonuses',
        label: t('Движение бонусов'),
        items: [
            {
                type: 'leaf',
                id: 'loyaltyBonusesEventType',
                template: 'checkboxes-tree-loader',
                label: t('Тип события'),
                filterTitle: t('Движение бонусов'),
                filterInBrowser: true,
                leafPrefix: '',
                loadTree: noop,
                loadBranch: noop,
                options: {
                    items: [
                        {
                            id: 'earned',
                            label: t('Начислено'),
                            hasMoreData: false,
                            color: '#499E5C',
                            items: [
                                {
                                    id: '1',
                                    label: t('за покупку, по ПЛ'),
                                    badgeLabel: t('начислено').toLowerCase() + ' ' + t('за покупку, по ПЛ'),
                                    items: null,
                                },
                                {
                                    id: '9',
                                    label: t('за покупку друга'),
                                    badgeLabel: t('начислено').toLowerCase() + ' ' + t('за покупку друга'),
                                    items: null,
                                },
                                {
                                    id: '4-operator',
                                    label: t('вручную'),
                                    badgeLabel: t('начислено').toLowerCase() + ' ' + t('вручную'),
                                    items: null,
                                },
                                {
                                    id: '4-mailing',
                                    label: t('из рассылки'),
                                    badgeLabel: t('начислено').toLowerCase() + ' ' + t('из рассылки'),
                                    items: null,
                                },
                                {
                                    id: '6',
                                    label: t('с возврата'),
                                    badgeLabel: t('начислено').toLowerCase() + ' ' + t('с возврата'),
                                    items: null,
                                },
                                {
                                    id: '8',
                                    label: t('с отмены покупки'),
                                    badgeLabel: t('начислено').toLowerCase() + ' ' + t('с отмены покупки'),
                                    items: null,
                                },
                            ],
                        },
                        {
                            id: 'redeemed',
                            label: t('Списано'),
                            hasMoreData: false,
                            color: '#DD6B5B',
                            items: [
                                {
                                    id: '0',
                                    label: t('на покупку'),
                                    badgeLabel: t('списано').toLowerCase() + ' ' + t('на покупку'),
                                    items: null,
                                },
                                {
                                    id: '5-operator',
                                    label: t('вручную'),
                                    badgeLabel: t('списано').toLowerCase() + ' ' + t('вручную'),
                                    items: null,
                                },
                                {
                                    id: '3',
                                    label: t('с возврата'),
                                    badgeLabel: t('списано').toLowerCase() + ' ' + t('с возврата'),
                                    items: null,
                                },
                                {
                                    id: '7',
                                    label: t('с отмены покупки'),
                                    badgeLabel: t('списано').toLowerCase() + ' ' + t('с отмены покупки'),
                                    items: null,
                                },
                            ],
                        },
                        {
                            id: 'expired',
                            label: t('Сгорело'),
                            hasMoreData: false,
                            color: '#828282',
                            items: [
                                {
                                    id: '2',
                                    label: t('по ПЛ'),
                                    badgeLabel: t('сгорело') + ' ' + t('по ПЛ'),
                                    items: null,
                                },
                                {
                                    id: '2-mailing',
                                    label: t('из рассылки'),
                                    badgeLabel: t('сгорело') + ' ' + t('из рассылки'),
                                    items: null,
                                },
                            ],
                        },
                    ],
                },
            },
            {
                type: 'leaf',
                id: 'loyaltyBonusesEventAmount',
                label: t('Сумма бонусов'),
                filterTitle: t('Сумма в движении бонусов'),
                template: 'numRange',
            },
            {
                type: 'leaf',
                id: 'loyaltyBonusesEventCashier',
                label: t('Сотрудник'),
                filterTitle: t('Провёл бонусы'),
                template: 'checkboxes-paginated-loader',
                loadOptions: (config) => dispatch(getCashierOptionsThunk(config)),
                options: cashiers,
            },
        ],
    };
    const purchasesBranch = {
        type: 'branch',
        id: 'purchases',
        label: t('Покупки'),
        items: [
            {
                type: 'leaf',
                id: 'purchaseEventType',
                label: t('Тип события'),
                filterTitle: t('Покупки'),
                template: 'checkboxes-simple',
                options: {
                    order: t('Сделал заказ').toLowerCase(),
                    purchase: t('Совершил покупку').toLowerCase(),
                    purchase_revert: t('Отменил покупку').toLowerCase(),
                    purchase_return: t('Сделал возврат').toLowerCase(),
                    friend_purchase: t('Друг сделал покупку').toLowerCase(),
                },
            },
        ],
    };
    const segmentsAndLevelsBranch = {
        type: 'branch',
        id: 'segments-and-levels',
        label: t('Сегменты и уровни'),
        items: [
            {
                type: 'branch',
                id: 'join-segments',
                label: t('Попал в сегмент'),
                items: [
                    {
                        type: 'leaf',
                        id: 'joinSegments',
                        label: t('REGULAR_SEGMENTS'),
                        filterTitle: t('Попал в сегмент'),
                        template: 'checkboxes-segments',
                        loadOptions: () => dispatch(getSegmentOptionsThunk()),
                        options: segments,
                        useBothOperations: false,
                    },
                    {
                        type: 'leaf',
                        id: 'joinRfmSegments',
                        label: 'smartRFM',
                        filterTitle: t('Попал в сегмент'),
                        template: 'checkboxes-segments',
                        loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                        options: rfmSegments,
                        useBothOperations: false,
                        locked: !isRFMEnabled,
                    },
                ],
            },
            {
                type: 'branch',
                id: 'leave-segments',
                label: t('Покинул сегмент'),
                items: [
                    {
                        type: 'leaf',
                        id: 'leaveSegments',
                        label: t('REGULAR_SEGMENTS'),
                        filterTitle: t('Покинул сегмент'),
                        template: 'checkboxes-segments',
                        loadOptions: () => dispatch(getSegmentOptionsThunk()),
                        options: segments,
                        useBothOperations: false,
                    },
                    {
                        type: 'leaf',
                        id: 'leaveRfmSegments',
                        label: 'smartRFM',
                        filterTitle: t('Покинул сегмент'),
                        template: 'checkboxes-segments',
                        loadOptions: () => dispatch(getRFMSegmentOptionsThunk()),
                        options: rfmSegments,
                        useBothOperations: false,
                        locked: !isRFMEnabled,
                    },
                ],
            },
            {
                type: 'leaf',
                id: 'levelUp',
                label: t('Повышен до уровня'),
                template: 'checkboxes-loader',
                loadOptions: () => dispatch(getClientLevelOptionsThunk()),
                options: clientLevels,
            },
        ],
    };
    const mailingsBranch = {
        type: 'branch',
        id: 'mailings',
        label: t('Рассылки'),
        items: [
            {
                type: 'branch',
                id: 'mailing',
                label: t('Название'),
                items: [
                    {
                        type: 'leaf',
                        id: 'mailingManual',
                        label: t('Ручные'),
                        filterTitle: t('Ручная рассылка'),
                        template: 'checkboxes-loader',
                        loadOptions: () => dispatch(getManualMailingOptionsThunk()),
                        options: manualMailings,
                    },
                    {
                        type: 'leaf',
                        id: 'mailingAuto',
                        label: t('Автоматические'),
                        filterTitle: t('Автоматическая рассылка'),
                        template: 'checkboxes-loader',
                        loadOptions: () => dispatch(getAutomaticMailingOptionsThunk()),
                        options: automaticMailings,
                    },
                ],
            },
            {
                type: 'leaf',
                id: 'mailingChannel',
                label: t('Канал рассылки'),
                template: 'checkboxes-simple',
                options: {
                    push: 'Push',
                    viber: 'Viber',
                    sms: 'SMS',
                    email: 'Email',
                    chat: 'Telegram',
                },
                iconMap: {
                    push: _jsx(ChannelPushAlt, {}),
                    viber: _jsx(ChannelViberAlt, {}),
                    sms: _jsx(ChannelSMSAlt, {}),
                    email: _jsx(ChannelEmailAlt, {}),
                    chat: _jsx(ChannelTelegramAlt, {}),
                },
            },
            {
                type: 'leaf',
                id: 'mailingStatus',
                label: t('Статус рассылки'),
                template: 'checkboxes-simple',
                options: {
                    planned: t('запланирована'),
                    scheduled: t('ожидает отправки'),
                    cascade: t('в резерве каскада'),
                    sent: t('отправлено'),
                    delivered: t('доставлено'),
                    finished: t('завершено'),
                    error: t('ошибка отправки'),
                    not_delivered: t('не доставлено'),
                    opened: t('открыл (для email)'),
                    read: t('прочитал (для viber)'),
                    clicked: t('перешел по ссылке'),
                    goal_reached: t('достиг цели'),
                },
                iconMap: {
                    planned: _jsx(Send, { size: 20 }),
                    scheduled: _jsx(Planned, {}),
                    cascade: _jsx(Planned, {}),
                    sent: _jsx(Sending, {}),
                    delivered: _jsx(Done, { type: 'single' }),
                    finished: _jsx(Done, { type: 'double' }),
                    error: _jsx(ErrorTriangle, {}),
                    not_delivered: _jsx(Cancelled, {}),
                    opened: _jsx(EmailNotOpened, {}),
                    read: _jsx(Message, {}),
                    clicked: _jsx(ClickHand, {}),
                    goal_reached: _jsx(Goal, { size: 20 }),
                },
            },
        ],
    };
    return [
        clientBranch,
        {
            type: 'branch',
            id: 'events',
            label: t('События'),
            items: [
                clientUpdateBranch,
                bonusesBranch,
                purchasesBranch,
                segmentsAndLevelsBranch,
                mailingsBranch,
            ],
        },
    ];
};
