import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { ArcLong } from './ArcLong';
import { ArcShort } from './ArcShort';
import styles from './styles.module.scss';
export const MailingChannelsArcs = ({ activeChannels, enabledChannels, soonChannels = [], }) => {
    const { t } = useTranslation();
    const hasChannel = (channel) => enabledChannels.includes(channel) &&
        activeChannels.includes(channel) &&
        !soonChannels.includes(channel);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classNames(styles.pushViberArc, {
                    [styles.visible]: hasChannel('push') && hasChannel('viber'),
                }), children: _jsx(Tooltip, { className: styles.tooltip, title: t('Viber отправится тем, кому не дойдет Push'), placement: 'left', children: _jsx("div", { children: _jsx(ArcShort, {}) }) }) }), _jsx("div", { className: classNames(styles.viberSmsArc, {
                    [styles.visible]: hasChannel('viber') && hasChannel('sms'),
                    [styles.raised]: !enabledChannels.includes('push'),
                }), children: _jsx(Tooltip, { className: styles.tooltip, title: hasChannel('push')
                        ? t('SMS отправится тем, кому не дойдет Push и Viber')
                        : t('SMS отправится тем, кому не дойдет Viber'), placement: 'left', children: _jsx("div", { children: _jsx(ArcShort, {}) }) }) }), _jsx("div", { className: classNames(styles.pushSmsArc, {
                    [styles.visible]: hasChannel('push') && !hasChannel('viber') && hasChannel('sms'),
                }), children: _jsx(Tooltip, { className: styles.tooltip, title: t('SMS отправится тем, кому не дойдет Push'), placement: 'left', children: _jsx("div", { children: enabledChannels.includes('viber') ? _jsx(ArcLong, {}) : _jsx(ArcShort, {}) }) }) })] }));
};
