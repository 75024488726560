import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
export const BreadcrumbsContext = createContext({
    getLastCrumb: () => { },
    lastCrumb: '',
});
export const BreadcrumbsContextProvider = ({ children }) => {
    const [lastCrumb, setLastCrumb] = useState('');
    const getLastCrumb = useCallback((lastCrumb) => {
        setLastCrumb(lastCrumb);
    }, []);
    return (_jsx(BreadcrumbsContext.Provider, { value: { getLastCrumb, lastCrumb }, children: children }));
};
export const useGetLastBreadcrumb = (lastCrumbName) => {
    const { getLastCrumb, lastCrumb } = useContext(BreadcrumbsContext);
    const { t } = useTranslation();
    useEffect(() => {
        getLastCrumb(lastCrumbName || t('Без названия'));
        return () => getLastCrumb('');
    }, [lastCrumbName]);
    return lastCrumb;
};
