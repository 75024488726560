import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Table } from '@/components/Table/Table';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { FormPointOfSaleContainer } from '@/forms/FormPointOfSale/FormPointOfSaleContainer';
import { Plus } from '@/icons/Plus';
import { numberRound } from '@/utils/numbers';
import { useTableData } from './useTableData';
export const ShopsTable = memo(({ shops, shopGroups, refetchShopGroups, isLoading, isFetching }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasShopView = userHasAccessTo(['SHOPS_LIST']);
    const userHasShopManage = userHasAccessTo([
        'SHOPS_OWN_MANAGE',
        'SHOPS_MANAGE',
    ]);
    const [selectedShop, changeSelectedShop] = useState();
    const [shopModalIsOpen, toggleShopModal] = useState(false);
    const handleToggleShopModal = useCallback(() => {
        if (!shopModalIsOpen)
            changeSelectedShop(undefined);
        toggleShopModal((isOpen) => !isOpen);
    }, [shopModalIsOpen]);
    const handleEditShop = useCallback((shop) => {
        changeSelectedShop(shop);
        toggleShopModal(true);
    }, []);
    const handleRowClick = useCallback((row) => {
        const shop = shops.find((s) => s.id === row.id);
        if (shop)
            handleEditShop(shop);
    }, [shops, handleEditShop]);
    const { columns, rows } = useTableData({
        shopGroups,
        shops,
        onEdit: handleEditShop,
    });
    const createButton = (_jsx(Tooltip, { placement: 'top', title: !userHasShopManage && t('Недоступно для вашей роли'), children: _jsx(Button, { icon: _jsx(Plus, {}), onClick: handleToggleShopModal, theme: 'primary', size: 'extra-large', disabled: !userHasShopManage, children: t('Добавить точку продаж') }) }));
    return (_jsxs(_Fragment, { children: [_jsx(TabsPanel, { tabs: [
                    {
                        id: 'shops',
                        label: t('SHOPS_PANEL_HEADER', {
                            amount: numberRound(shops.length),
                            count: shops.length,
                        }),
                    },
                ], rightContent: createButton, isSkeleton: isLoading, children: _jsx(Table, { isOpenSingleGroup: true, columns: columns, rows: rows, isLoading: isFetching, onRowClick: userHasShopView || userHasShopManage ? handleRowClick : undefined, hasClicksOnSubRows: true }) }), (userHasShopView || userHasShopManage) && (_jsx(FormPointOfSaleContainer, { isOpen: shopModalIsOpen, onClose: handleToggleShopModal, value: selectedShop, onAdd: handleToggleShopModal, onDelete: handleToggleShopModal, onEdit: handleToggleShopModal, shopGroups: shopGroups, refetchShopGroups: refetchShopGroups }))] }));
});
