import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import formStyles from '../styles.module.scss';
export const CascadeFormHelp = () => {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation();
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const activeTab = (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.cascadeActiveChannelTab) || 'sms';
    if (((_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) === null || _a === void 0 ? void 0 : _a.includes('push')) &&
        selectedNodeData.channels.includes('viber') &&
        activeTab === 'viber')
        return (_jsx("span", { className: formStyles.description, children: t('Viber отправится тем, кому не дойдет Push') }));
    if (((_b = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) === null || _b === void 0 ? void 0 : _b.includes('push')) &&
        selectedNodeData.channels.includes('viber') &&
        selectedNodeData.channels.includes('sms') &&
        activeTab === 'sms')
        return (_jsx("span", { className: formStyles.description, children: t('SMS отправится тем, кому не дойдет Push и Viber') }));
    if (!((_c = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) === null || _c === void 0 ? void 0 : _c.includes('push')) &&
        ((_d = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) === null || _d === void 0 ? void 0 : _d.includes('viber')) &&
        selectedNodeData.channels.includes('sms') &&
        activeTab === 'sms')
        return (_jsx("span", { className: formStyles.description, children: t('SMS отправится тем, кому не дойдет Viber') }));
    if (((_e = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.channels) === null || _e === void 0 ? void 0 : _e.includes('push')) &&
        !selectedNodeData.channels.includes('viber') &&
        selectedNodeData.channels.includes('sms') &&
        activeTab === 'sms')
        return (_jsx("span", { className: formStyles.description, children: t('SMS отправится тем, кому не дойдет Push') }));
    return null;
};
