import { jsx as _jsx } from "react/jsx-runtime";
import { EmojiPicker, } from '@/components/rich-text/EmojiPicker/EmojiPicker';
import styles from './styles.module.scss';
export const EmojiPopup = ({ set, onSelectEmoji, onClose, }) => {
    const handleSelectEmoji = (emoji, unified) => {
        onSelectEmoji(emoji, unified);
        if (onClose)
            onClose();
    };
    return (_jsx("div", { className: styles.emojiPopupWrapper, onMouseDown: (e) => {
            e.preventDefault();
            e.stopPropagation();
        }, children: _jsx(EmojiPicker, { set: set, onEmojiSelect: handleSelectEmoji }) }));
};
