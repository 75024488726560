import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Star } from '@/icons/Star';
import { COLOR_MAP } from './COLOR_MAP';
import styles from './Title.module.scss';
export const Title = ({ serialNumber, group }) => {
    const color = COLOR_MAP[group.type];
    const title = serialNumber !== undefined ? serialNumber + '. ' + group.name : group.name;
    const isExclusive = group.mode === 'exclusive';
    const isUnordered = group.guid === 'unordered';
    return (_jsxs("div", { className: classNames(styles.title, {
            [styles.unordered]: isUnordered,
        }), style: { '--color': color }, children: [isExclusive && (_jsx("div", { className: styles.starIcon, children: _jsx(Star, {}) })), !isExclusive && title] }));
};
