import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientBonusHistoryListItem as ListItem, } from './ClientBonusHistoryListItem';
import styles from './styles.module.scss';
export const ClientBonusHistory = memo(({ items, clientReservedBonuses = 0, clientJoinedAt }) => {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx("h4", { className: styles.title, children: t('История клиента') }), _jsx("div", { className: styles.body, children: _jsx("div", { className: styles.scroll, children: _jsxs("ul", { className: styles.timeline, children: [clientReservedBonuses > 0 && (_jsx(ListItem, { type: 'warning', date: 'now', title: t('В резерве'), bonuses: clientReservedBonuses, description: t('за заказом или покупкой') })), items.map((item) => {
                                let type;
                                let itemTitle = '';
                                let itemDescription;
                                let itemTooltip = '';
                                if ([1, 4, 9].includes(item.operation))
                                    type = 'success';
                                if ([0, 3, 5].includes(item.operation))
                                    type = 'danger';
                                if (item.operation === 0) {
                                    itemTitle = t('-');
                                    itemDescription = t('потрачено на покупку');
                                    itemTooltip = '';
                                }
                                if (item.operation === 1) {
                                    itemTitle = t('+');
                                    itemDescription = t('за покупку');
                                    itemTooltip = '';
                                }
                                if (item.operation === 2) {
                                    itemTitle = t('-');
                                    itemDescription = t('сгорело');
                                    itemTooltip = '';
                                }
                                if (item.operation === 3) {
                                    itemTitle = t('-');
                                    itemDescription = t('отменено за возврат');
                                    itemTooltip = '';
                                }
                                if (item.operation === 4) {
                                    itemTitle = t('+');
                                    itemDescription = item.operator
                                        ? `${t('начислено оператором')} ${item.operator.username}`
                                        : t('начислено');
                                    itemTooltip = item.comment;
                                    if (item.type === 'client') {
                                        itemTitle = '';
                                        itemDescription = t('Заблокирован');
                                        type = 'danger';
                                    }
                                }
                                if (item.operation === 5) {
                                    itemTitle = t('-');
                                    itemDescription = item.operator
                                        ? `${t('списано оператором')} ${item.operator.username}`
                                        : t('списано');
                                    itemTooltip = item.comment;
                                    if (item.type === 'client') {
                                        itemTitle = '';
                                        itemDescription = t('Разблокирован');
                                        type = 'success';
                                    }
                                }
                                if (item.operation === 6) {
                                    itemTitle = t('+');
                                    itemDescription = t('возвращено');
                                }
                                if (item.operation === 7) {
                                    itemTitle = t('-');
                                    itemDescription = t('отменено');
                                }
                                if (item.operation === 8) {
                                    itemTitle = t('+');
                                    itemDescription = t('возвращено');
                                }
                                if (item.operation === 9) {
                                    itemTitle = t('+');
                                    itemDescription = t('за покупку друга');
                                }
                                return (_jsx(ListItem, { type: type, date: item.createdAt, title: itemTitle, bonuses: item.amount, description: itemDescription, tooltip: itemTooltip }, item.id));
                            }), _jsx(ListItem, { type: 'join', date: clientJoinedAt, description: t('Клиент вступил в Клуб') })] }) }) })] }));
});
