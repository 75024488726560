import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { selectGiftCards, useGetGiftCardQuery } from '@/api/query/giftCard';
import { BreadcrumbsContext } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { useAppSelector } from '@/hooks/store';
import { money } from '@/utils/numbers';
import styles from './styles.module.scss';
export const GiftCardInstancesPageTitle = ({ giftCardId, currency }) => {
    const { t } = useTranslation();
    const { data: giftCards } = useAppSelector(selectGiftCards);
    const giftCardFromList = giftCards === null || giftCards === void 0 ? void 0 : giftCards.cards.find((card) => card.id === giftCardId);
    const { data: giftCard } = useGetGiftCardQuery(giftCardId, {
        skip: !!giftCardFromList,
    });
    const loadedGiftCard = giftCardFromList || giftCard;
    const amount = money({ input: (loadedGiftCard === null || loadedGiftCard === void 0 ? void 0 : loadedGiftCard.amount) || 0, currency });
    const { getLastCrumb } = useContext(BreadcrumbsContext);
    useEffect(() => {
        getLastCrumb(`${t('Карта')} ${loadedGiftCard === null || loadedGiftCard === void 0 ? void 0 : loadedGiftCard.name}`);
        return () => getLastCrumb('');
    }, []);
    return (_jsxs("div", { className: styles.wrapper, children: [t('Карта'), " ", loadedGiftCard === null || loadedGiftCard === void 0 ? void 0 : loadedGiftCard.name, _jsx("div", { className: styles.amount, children: amount })] }));
};
