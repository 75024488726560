import { jsx as _jsx } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// бонусы сгорели
export const bonus2 = (args) => {
    const { event, translate: t } = args;
    const { amount } = event.params || {};
    return {
        color: 'gray',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#828282' }),
        title: (_jsx(BonusTitle, Object.assign({}, args, { children: amount
                ? t('LOYALTY_PROGRAM_BONUSES_BURNED_OUT', { count: amount })
                : t('Часть бонусов сгорело') }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: [] })),
    };
};
