import { jsx as _jsx } from "react/jsx-runtime";
import './styles.cropper.scss';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useCropper } from './useCropper';
export const ImageCrop = ({ src, mimeType, cropWidth = 0, cropHeight = 0, cropShape, isRound = false, onCrop, }) => {
    const { imageRef } = useCropper({
        w: cropWidth,
        h: cropHeight,
        mimeType,
        shape: cropShape,
        isRound,
        onCrop,
    });
    return (_jsx("div", { className: classNames(styles.wrapper, {
            [styles.round]: isRound,
        }), children: _jsx("img", { ref: imageRef, className: styles.image, src: src, crossOrigin: 'anonymous' }) }));
};
