import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiltersInput } from '@/components/FiltersPanel/FiltersInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { useMailingGoalFilters } from '@/services/filters/data/useMailingGoalFilters';
import { GOAL_TYPES } from './FormMailingGoalType';
export const FormMailingGoalFilters = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const filtersTree = useMailingGoalFilters();
    const goalType = watch('goalType');
    if (goalType !== GOAL_TYPES.PURCHASE)
        return null;
    return (_jsx(FormFieldRow, { label: t('если'), labelWidth: labelWidth, children: _jsx(Controller, { name: 'goalFilters', control: control, render: ({ field }) => (_jsx(FiltersInput, { filtersTree: filtersTree, value: field.value || [], onUpdate: field.onChange, hasHangingOperator: false })) }) }));
};
