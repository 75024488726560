import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { money, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const VarSample = ({ item, itemContent, currency, }) => {
    const { t } = useTranslation();
    const tooltipTitle = !item.sample ? null : (_jsxs("div", { children: [_jsxs("div", { className: styles.sampleTooltipHelp, children: [t('Пример отображения'), ":"] }), typeof item.sample === 'object' && item.sampleType === 'date' && (_jsx("div", { children: item.sample.format('DD.MM.YYYY') })), typeof item.sample === 'number' && item.sampleType === 'price' && (_jsx("div", { children: money({ input: item.sample, currency }) })), typeof item.sample === 'number' && item.sampleType !== 'price' && (_jsx("div", { children: numberRound(item.sample) })), item.sampleType === 'link' && (_jsx("div", { className: styles.sampleTooltipLink, children: item.sample })), item.sampleType === 'image' && typeof item.sample === 'string' && (_jsx("div", { className: styles.sampleTooltipImage, children: _jsx("img", { src: item.sample, height: 80 }) })), item.sampleType !== 'date' &&
                typeof item.sample !== 'number' &&
                item.sampleType !== 'price' &&
                item.sampleType !== 'link' &&
                item.sampleType !== 'image' && _jsx("div", { children: item.sample })] }));
    return tooltipTitle ? (_jsx(Tooltip, { popperClassName: styles.sampleTooltip, title: tooltipTitle, placement: 'right', children: itemContent })) : (itemContent);
};
