import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// начислены поощрительные бонусы
export const bonus4 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handleEntityLink } = entityHandlers;
    const { amount, mailingBrandId, mailingBrandType, mailingBrandName, operatorName, } = event.params || {};
    const linkMailing = handleEntityLink('mailing', mailingBrandId, mailingBrandType);
    let title;
    if (amount)
        if (operatorName)
            title = (_jsxs(_Fragment, { children: [t('BONUSES_FOR_RETURN_GOODS_WITH_OPERATOR', { count: amount }), "\u00A0", operatorName] }));
        else if (mailingBrandName)
            title = (_jsxs(_Fragment, { children: [t('ADDED_BONUSES_IN_MAILING', { count: amount }), "\u00A0", linkMailing ? (_jsx(Link, { className: styles.underline, to: linkMailing, target: '_blank', children: mailingBrandName })) : (`"${mailingBrandName}"`)] }));
        else
            title = t('ADDED_BONUSES', { count: amount });
    else
        title = t('Начислены бонусы');
    return {
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
        title: (_jsx(BonusTitle, Object.assign({}, args, { includeDates: true, children: title }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: [
                'availableAt',
                'expiredAt',
                'issuedAt',
                'operator',
                !operatorName ? 'mailing' : null,
            ] })),
    };
};
