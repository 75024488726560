import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Popup } from '@/components/Popup/Popup';
import { Smile } from '@/icons/Smile';
import styles from '../EditorToolbar/styles.module.scss';
import { EmojiPopup } from '../EmojiPopup/EmojiPopup';
export const EmojiButton = ({ set, onSelectEmoji, }) => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const ref = useRef(null);
    const handleOpenPopup = () => setPopupIsOpen(true);
    const handleClosePopup = () => setPopupIsOpen(false);
    return (_jsxs(_Fragment, { children: [_jsx("button", { ref: ref, className: classNames(styles.wrapper, styles.button, styles.rounded, {
                    [styles.active]: popupIsOpen,
                }), type: 'button', onClick: handleOpenPopup, children: _jsx(Smile, {}) }), _jsx(Popup, { isOpen: popupIsOpen, anchorEl: ref.current, onClose: handleClosePopup, placement: 'bottom', offset: [0, 5], isRounded: true, clickAwayMouseEvent: 'onMouseDown', clickAwayTouchEvent: 'onTouchEnd', children: _jsx(EmojiPopup, { set: set, onSelectEmoji: onSelectEmoji, onClose: handleClosePopup }) })] }));
};
