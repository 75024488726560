import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useUserPermissions } from '@/app/useUserPermissions';
import { SegmentBadgeContainer } from '@/components/SegmentBadge/SegmentBadgeContainer';
import styles from './styles.module.scss';
export const SegmentList = memo(({ segments, justify = 'start', onSegmentClick, hasMaxWidth = true }) => {
    const userHasAccessTo = useUserPermissions();
    const isSegmentLinksEnabled = userHasAccessTo([
        'CLIENTS_LIST_OWN',
        'CLIENTS_LIST',
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    return (_jsx("div", { className: classNames(styles.list, {
            [styles.limited]: hasMaxWidth,
            [styles.center]: justify === 'center',
            [styles.end]: justify === 'end',
        }), children: segments.map((segment) => (_jsx(SegmentBadgeContainer, { onClick: isSegmentLinksEnabled
                ? () => onSegmentClick === null || onSegmentClick === void 0 ? void 0 : onSegmentClick(segment)
                : undefined, segment: segment, children: segment.name }, segment.id))) }));
});
