import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// бонусы списаны в покупку
export const bonus0 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handlePurchase } = entityHandlers;
    const { amount, purchaseId, purchaseDeleted } = event.params || {};
    const hasPurchaseLink = !!purchaseId && !purchaseDeleted && !!handlePurchase;
    return {
        color: 'red',
        Icon: _jsx(BonusMovement, {}),
        title: (_jsxs(BonusTitle, Object.assign({}, args, { children: [amount
                    ? t('BONUSES_SPENT_ON', { count: amount })
                    : t('Бонусы потрачены на'), "\u00A0", _jsx("span", { className: hasPurchaseLink ? styles.underline : '', onClick: () => {
                        if (hasPurchaseLink)
                            handlePurchase(purchaseId);
                    }, children: t('покупку') })] }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['purchase'] })),
    };
};
