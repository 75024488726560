import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CascadeForm } from './cascade/CascadeForm';
import { ChannelFormName } from './ChannelFormName';
import { ChannelFormRewards } from './ChannelFormRewards';
import { EmailForm } from './email/EmailForm';
import { PushForm } from './push/PushForm';
import { SMSForm } from './sms/SMSForm';
import styles from './styles.module.scss';
import { TelegramForm } from './telegram/TelegramForm';
import { useChannelFormState } from './useChannelFormState';
import { ViberForm } from './viber/ViberForm';
export const ChannelForm = () => {
    const { isPush, isViber, isSMS, isEmail, isTelegram, isCascade } = useChannelFormState();
    return (_jsxs("div", { className: styles.form, children: [_jsx(ChannelFormName, {}), _jsx(ChannelFormRewards, {}), isPush && _jsx(PushForm, {}), isViber && _jsx(ViberForm, {}), isSMS && _jsx(SMSForm, {}), isEmail && _jsx(EmailForm, {}), isTelegram && _jsx(TelegramForm, {}), isCascade && _jsx(CascadeForm, {})] }));
};
