import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
export const FormOfferProductCounter = ({ count, threshold = 5, type = 'products' }) => {
    const { t } = useTranslation();
    const renderCounter = (count, key) => (_jsx("div", { className: classNames(styles.productCounter, styles.productCounterShadow, styles.productCounterSmall, {
            [styles.productCounterMedium]: count >= 10,
            [styles.productCounterLarge]: count >= 100,
        }), children: count }, key));
    let counter = (_jsxs("div", { className: classNames(styles.productCounterLine, styles.productCounterLineLarge), children: [renderCounter(1), _jsx("div", { className: styles.productCounterMore, children: "..." }), renderCounter(count)] }));
    if (count === 1)
        counter = renderCounter(1);
    if (count <= threshold)
        counter = (_jsx("div", { className: styles.productCounterLine, children: new Array(count)
                .fill(null)
                .map((_, i) => renderCounter(i + 1, `counter-${i + 1}`)) }));
    if (type === 'purchases')
        return (_jsxs("div", { className: styles.productCounterWrapper, children: [t('покупки, содержащие'), counter] }));
    return (_jsxs("div", { className: styles.productCounterWrapper, children: [t('товары'), counter] }));
};
