import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Chevron } from '@/icons/Chevron';
import { useRFMSegments } from '../RFMData/useRFMSegments';
import { RFMRecommendationsItemText } from './RFMRecommendationsItemText';
import styles from './styles.module.scss';
const segmentsWithoutColorBadge = [
    'ultraLargeSales',
    'ultraActiveCustomers',
    'possibleFraud',
    'wholesalers',
];
const segmentsWithoutActions = [
    'ultraflow',
    'ultraLargeSales',
    'ultraActiveCustomers',
    'possibleFraud',
    'wholesalers',
];
export const RFMRecommendationsItem = ({ id, onAction, isOpen = false, onToggle, isLocked = false, }) => {
    const { t } = useTranslation();
    const [actionsActive, setActionsActive] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasClientsManage = userHasAccessTo([
        'CLIENTS_LIST_OWN',
        'CLIENTS_LIST',
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const segments = useRFMSegments();
    const segment = segments[id];
    const segmentActions = [
        {
            id: 'create-manual-mailing',
            label: t('ручную рассылку'),
            onClick: () => onAction(segment.id, 'create-manual-mailing'),
            isDisabled: !userHasAccessTo('MANUAL_MAILINGS_MANAGE'),
            tooltip: !userHasAccessTo('MANUAL_MAILINGS_MANAGE') &&
                t('Недоступно для вашей роли'),
        },
        {
            id: 'create-auto-mailing',
            label: t('авторассылку'),
            onClick: () => onAction(segment.id, 'create-auto-mailing'),
            isDisabled: !userHasAccessTo('AUTO_MAILINGS_MANAGE'),
            tooltip: !userHasAccessTo('AUTO_MAILINGS_MANAGE') &&
                t('Недоступно для вашей роли'),
        },
        {
            id: 'create-offer',
            label: t('акцию'),
            onClick: () => onAction(segment.id, 'create-offer'),
            isDisabled: !userHasAccessTo('OFFERS_MANAGE'),
            tooltip: !userHasAccessTo('OFFERS_MANAGE') && t('Недоступно для вашей роли'),
        },
    ];
    return (_jsxs("div", { className: styles.item, id: `rfm-recommendation-${id}`, children: [_jsxs("div", { className: styles.itemTitle, onClick: () => onToggle === null || onToggle === void 0 ? void 0 : onToggle(id), children: [_jsxs("div", { className: styles.itemLabel, children: [!segmentsWithoutColorBadge.includes(id) && (_jsx("div", { className: classNames(styles.itemColor, {
                                    [styles.ultraflow]: id === 'ultraflow',
                                }), style: { backgroundColor: segment.background } })), segment.label] }), _jsx("div", { className: classNames(styles.chevron, {
                            [styles.flipped]: isOpen,
                        }), children: _jsx(Chevron, {}) })] }), _jsxs("div", { className: classNames(styles.itemBody, {
                    [styles.collapsed]: !isOpen,
                }), children: [_jsxs("div", { className: styles.itemContent, children: [_jsx("p", { children: t(`RFM_SEGMENT_DESCRIPTION_${id}`) }), _jsx(RFMRecommendationsItemText, { id: id })] }), _jsx("div", { className: styles.itemActions, children: !segmentsWithoutActions.includes(segment.id) ? (_jsx(Dropdown, { items: segmentActions, onOpen: () => setActionsActive(true), onClose: () => setActionsActive(false), children: _jsx(Button, { type: 'button', isActive: actionsActive, disabled: isLocked, isOutlined: true, children: t('Создать...') }) })) : (_jsx(Tooltip, { title: !userHasClientsManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { type: 'button', onClick: () => onAction(segment.id, 'view-clients'), disabled: isLocked || !userHasClientsManage, isOutlined: true, children: t('Посмотреть клиентов') }) })) })] })] }));
};
