import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import styles from './styles.module.scss';
export const MailingChannel = memo(({ className, label, isActive = false, onChange, isDisabled = false }) => {
    const { t } = useTranslation();
    const handleChange = useCallback((e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e.target.checked);
    }, [onChange]);
    const checkbox = (_jsx(Checkbox, { className: classNames(styles.channel, {
            [styles.active]: isActive,
        }, className), checked: isActive, onChange: handleChange, hasContentLeft: true, isTransparent: true, align: 'center', isLocked: isDisabled, children: label }));
    return isDisabled ? (_jsx(Tooltip, { title: _jsx("span", { className: styles.tooltip, children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') }), placement: 'right', children: _jsx("div", { children: checkbox }) })) : (checkbox);
});
