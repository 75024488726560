import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Purchase } from '@/icons/Purchase';
import styles from '../../styles.module.scss';
import { getPurchaseContent } from './purchaseContent';
// покупка друга
export const purchaseFriendPurchase = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handlePurchase } = entityHandlers;
    const { purchaseId, purchaseDeleted, referralPurchaseId, referralPurchaseDeleted, } = event.params || {};
    const pId = referralPurchaseId || purchaseId;
    const pDeleted = typeof referralPurchaseId !== 'undefined'
        ? referralPurchaseDeleted
        : purchaseDeleted;
    return {
        Icon: _jsx(Purchase, {}),
        title: (_jsxs(_Fragment, { children: [t('Друг совершил'), "\u00A0", _jsx("span", { className: pId && !pDeleted && handlePurchase ? styles.underline : '', onClick: () => {
                        if (pId && !pDeleted)
                            handlePurchase === null || handlePurchase === void 0 ? void 0 : handlePurchase(pId);
                    }, children: t('покупку') })] })),
        content: getPurchaseContent(args),
    };
};
