import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './styles.module.scss';
import { VarsPanelItem } from './VarsPanelItem';
export const VarsPanelLeft = ({ variables, onSelectVariable, selectedGroupTitle, onSelectGroup, currency, }) => {
    return (_jsx("ul", { children: variables.map((item) => {
            if ('title' in item)
                return (_jsx("li", { className: classNames(styles.item, styles.group, {
                        [styles.selected]: item.title === selectedGroupTitle,
                    }), onClick: () => onSelectGroup(item), children: item.title }, item.title));
            if ('id' in item)
                return (_jsx(VarsPanelItem, { item: item, onClick: () => onSelectVariable(item), currency: currency }, item.id));
        }) }));
};
