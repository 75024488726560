import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Shop } from '@/icons/Shop';
import styles from './styles.module.scss';
export const CurrentUserFranchisePicker = ({ user }) => {
    var _a;
    const { t } = useTranslation();
    const filter = (_a = user.shopConditions) === null || _a === void 0 ? void 0 : _a.configuration[0];
    const filterText = (filter === null || filter === void 0 ? void 0 : filter.text) || t('Все точки');
    return (_jsxs("div", { className: styles.wrapper, children: [_jsx(Shop, { size: 14 }), _jsx("span", { dangerouslySetInnerHTML: { __html: filterText } })] }));
};
