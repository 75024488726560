import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { AppleDummySettings } from '@/components/wallet-cards/AppleDummySettings/AppleDummySettings';
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import { AppleWalletCardPreview } from './AppleWalletCardPreview';
import styles from './styles.module.scss';
export const AppleWalletCardDetails = ({ title, details, contacts, previewKey, onElementClick, clicksDisabled = false, }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(AppleWalletCardPreview, {}, previewKey), _jsx(WalletCardClickableElement, { className: styles.detailsTitleWrapper, onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick('apple-details-title'), disabled: clicksDisabled, children: _jsx("div", { className: styles.detailsTitle, children: title }) }), _jsx("div", { className: styles.detailsDescription, children: "Updated yesterday" }), _jsx(AppleDummySettings, {}), _jsxs("div", { className: styles.detailsWrapper, children: [_jsxs("div", { className: styles.detailsItem, children: [_jsx("div", { className: styles.detailsItemTitle, children: t('Сообщение') }), _jsx("div", { className: styles.detailsItemContent, children: t('WALLET_CARD_MESSAGE_EXAMPLE') })] }, 'last-push-message-item'), details === null || details === void 0 ? void 0 : details.map((item, i) => {
                        if (!item.title.trim() && !item.content.trim())
                            return null;
                        return (_jsx("div", { className: styles.detailsItem, children: _jsxs(WalletCardClickableElement, { onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick(`backside-item-${i}-title`), disabled: clicksDisabled, children: [_jsx("div", { className: styles.detailsItemTitle, children: item.title }), _jsx("div", { className: styles.detailsItemContent, dangerouslySetInnerHTML: {
                                            __html: item.content,
                                        } })] }) }, `${item.title || 'item'}-${i}`));
                    }), contacts === null || contacts === void 0 ? void 0 : contacts.map((contact, i) => {
                        if (!contact.title.trim() && !contact.content.trim())
                            return null;
                        return (_jsx("div", { className: styles.detailsItem, children: _jsxs(WalletCardClickableElement, { onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick(`backside-contact-${i}-title`), disabled: clicksDisabled, children: [_jsx("div", { className: styles.detailsItemTitle, children: contact.title }), _jsx("div", { className: styles.contactItemContent, children: contact.content })] }) }, `${contact.content || 'contact'}-${i}`));
                    }), _jsx("div", { className: styles.detailsItem, children: _jsxs("div", { className: styles.detailsItemContent, children: [t('Выпуск карт'), " \u2014", ' ', _jsx("a", { href: 'https://maxma.com/', target: '_blank', rel: 'noreferrer', children: "MAXMA.com" })] }) }, 'card-issuance-item')] })] }));
};
