import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Help } from '@/icons/Help';
import styles from './styles.module.scss';
export const TopAlertPopup = ({ brand }) => {
    const { t } = useTranslation();
    const { autoSuspendBonuses, autoSuspendMailings, autoSuspendTools } = brand.settings;
    if (!autoSuspendBonuses && !autoSuspendMailings && !autoSuspendTools)
        return null;
    return (_jsx(_Fragment, { children: _jsx(Tooltip, { className: styles.tooltip, title: _jsxs("ul", { className: styles.list, children: [autoSuspendBonuses && (_jsxs("li", { children: [t('Процессинг бонусов (начисление/списание)'), " \u2013", ' ', _jsx("span", { children: t('недоступен') })] })), autoSuspendMailings && (_jsxs("li", { children: [t('Рассылки (ручные/автоматические)'), " \u2013", ' ', _jsx("span", { children: t('недоступны') })] })), autoSuspendTools && (_jsxs("li", { children: [t('Инструменты (Акции, Промокоды, Подарочные карты и пр.)'), " \u2013", ' ', _jsx("span", { children: t('недоступны') })] }))] }), children: _jsx("span", { className: styles.popup, children: _jsx(Help, {}) }) }) }));
};
