var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { EmailEditorModal, } from '@/components/EmailEditorModal/EmailEditorModal';
import { EmailTemplateModal, } from '@/components/EmailTemplateModal/EmailTemplateModal';
import { getEmailDraftKey } from '@/utils/getEmailDraftKey';
import { setModalLoading } from '@/utils/setModalLoading';
import { EmailEditorButton } from './EmailEditorButton';
import { EmailEditorEmpty } from './EmailEditorEmpty';
import { EmailEditorLoading } from './EmailEditorLoading';
import { EmailEditorPreview } from './EmailEditorPreview';
import { EmailEditorReset } from './EmailEditorReset';
import styles from './styles.module.scss';
export const EmailEditor = ({ unlayerUrl, unlayerProjectId, initialDesign, previewUrl, isLoadingPreview = false, onChange, hasError, templatesData, mailingName = '', mailingId = 0, brandGlobalKey = '', context = {}, uploadImage = () => Promise.resolve(''), isDisabled, buttonClassName, }) => {
    const [templateModalIsOpen, setTemplateModalIsOpen] = useState(false);
    const [editorModalIsOpen, setEditorModalIsOpen] = useState(false);
    const [design, setDesign] = useState(initialDesign);
    const empty = !initialDesign ? _jsx(EmailEditorEmpty, {}) : null;
    const preview = previewUrl ? _jsx(EmailEditorPreview, { src: previewUrl }) : null;
    const loading = isLoadingPreview ? _jsx(EmailEditorLoading, {}) : null;
    const reset = initialDesign ? (_jsx(EmailEditorReset, { onReset: () => onChange(null) })) : null;
    const handleOpenEditor = (payload) => {
        setTemplateModalIsOpen(false);
        setDesign(typeof payload === 'string' ? payload : payload === null || payload === void 0 ? void 0 : payload.emailDesign);
        document.body.classList.add(styles.noScroll);
        setEditorModalIsOpen(true);
    };
    const handleCloseEditor = () => {
        document.body.classList.remove(styles.noScroll);
        setEditorModalIsOpen(false);
    };
    const handleOpenTemplateModal = () => __awaiter(void 0, void 0, void 0, function* () {
        setModalLoading(true);
        try {
            yield templatesData.handleGetEmailTemplatesListFirstPage();
        }
        finally {
            setModalLoading(false);
            setTemplateModalIsOpen(true);
        }
    });
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs(EmailEditorButton, { className: buttonClassName, design: initialDesign, hasPreview: !!previewUrl || isLoadingPreview, hasError: !!hasError, mailingId: mailingId, brandGlobalKey: brandGlobalKey, onOpenTemplateModal: handleOpenTemplateModal, onOpenEditorModal: handleOpenEditor, isDisabled: isDisabled, children: [preview, loading, !preview && !isLoadingPreview ? empty : null] }), !isDisabled && reset, _jsx(EmailTemplateModal, { isOpen: templateModalIsOpen, onClose: () => setTemplateModalIsOpen(false), templatesData: templatesData, onCreateTemplate: handleOpenEditor, onSelectTemplate: handleOpenEditor }), editorModalIsOpen && (_jsx(EmailEditorModal, { unlayerUrl: unlayerUrl, unlayerProjectId: unlayerProjectId, title: mailingName, initialDesign: design, onChange: (data) => {
                    onChange(data);
                    handleCloseEditor();
                }, onClose: handleCloseEditor, context: context, draftDesignLSKey: getEmailDraftKey(mailingId, brandGlobalKey), uploadImage: uploadImage }))] }));
};
