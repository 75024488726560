import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { orderModalSelector } from '@/store/selectors/modals';
import { updateOrderModal, } from '@/store/slice/modals';
export const useOrderModal = () => {
    const dispatch = useAppDispatch();
    const orderModalState = useAppSelector(orderModalSelector);
    const open = useCallback((payload = {}) => {
        dispatch(updateOrderModal(Object.assign({ isOpen: true }, payload)));
    }, []);
    const close = useCallback((payload) => {
        var _a;
        (_a = orderModalState.closeHandler) === null || _a === void 0 ? void 0 : _a.call(orderModalState, payload);
        dispatch(updateOrderModal({
            isOpen: false,
            orderList: null,
            currentOrderIndex: null,
            closeHandler: undefined,
        }));
    }, [orderModalState.closeHandler]);
    const setVisibility = useCallback((isVisible) => {
        dispatch(updateOrderModal({ isOpen: isVisible }));
    }, []);
    const setOrderIndex = useCallback((index) => {
        dispatch(updateOrderModal({ currentOrderIndex: index }));
    }, []);
    return {
        isOpen: orderModalState.isOpen || false,
        orderList: orderModalState.orderList || null,
        currentOrderIndex: typeof orderModalState.currentOrderIndex === 'number'
            ? orderModalState.currentOrderIndex
            : null,
        open,
        close,
        setVisibility,
        setOrderIndex,
    };
};
