import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BASIC_END_YEAR, BASIC_START_YEAR, DateInput, } from '@/components/DateInput/DateInput';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import styles from '../styles.module.scss';
export const FormOfferAvailableDates = ({ labelWidth }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const availableFrom = watch('availableFrom');
    const availableTo = watch('availableTo');
    return (_jsx(FormFieldRow, { label: t('Период действия с'), labelFor: 'availableFrom', labelWidth: labelWidth, children: _jsxs("div", { className: styles.dates, children: [_jsx("div", { className: styles.date, children: _jsx(Controller, { name: 'availableFrom', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'availableFrom', placeholderText: t('не ограничен'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                setValue('availableFrom', date ? moment(date).format() : '', {
                                    shouldDirty: true,
                                });
                            }, selectsStart: true, startDate: field.value ? moment(field.value).toDate() : null, endDate: availableTo ? moment(availableTo).toDate() : null, maxDate: availableTo ? moment(availableTo).toDate() : null, portalId: 'form-offer-dates-portal', startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR })) }) }), _jsx("label", { className: styles.datesDivider, htmlFor: 'availableTo', children: t('по') }), _jsx("div", { className: styles.date, children: _jsx(Controller, { name: 'availableTo', control: control, render: ({ field }) => (_jsx(DateInput, { id: 'availableTo', placeholderText: t('не ограничен'), selected: field.value ? moment(field.value).toDate() : null, onChange: (date) => {
                                setValue('availableTo', date ? moment(date).format() : '', {
                                    shouldDirty: true,
                                });
                            }, selectsEnd: true, startDate: availableFrom ? moment(availableFrom).toDate() : null, endDate: field.value ? moment(field.value).toDate() : null, minDate: availableFrom ? moment(availableFrom).toDate() : null, startYear: BASIC_START_YEAR, endYear: BASIC_END_YEAR, portalId: 'form-offer-dates-portal' })) }) })] }) }));
};
