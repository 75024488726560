import { jsx as _jsx } from "react/jsx-runtime";
import { memo, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
export const AppNavItemList = memo(({ children, isOpened }) => {
    const list = useRef(null);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        const element = list.current;
        if (element) {
            const isHidden = element.style.display === 'none';
            element.style.display = '';
            setHeight(element.clientHeight);
            if (isHidden)
                element.style.display = 'none';
        }
    }, []);
    return (_jsx("ul", { ref: list, className: styles.items, style: {
            display: !height && !isOpened ? 'none' : undefined,
            height: !height ? undefined : isOpened ? height : 0,
        }, children: children }));
});
