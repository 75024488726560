import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useCallback, useState } from 'react';
import { HexColorInput } from 'react-colorful';
import styles from './styles.module.scss';
export const HexColor = forwardRef(({ id, color, onChange, placeholder, leftElement, disabled, isActive, hasError, onFocus, }, ref) => {
    const [focused, setFocused] = useState(false);
    const forwardFocusFromLeft = useCallback((e) => {
        const input = e.currentTarget.nextSibling;
        input === null || input === void 0 ? void 0 : input.focus();
    }, []);
    const handleFocus = () => {
        setFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
    };
    const handleBlur = () => {
        setFocused(false);
    };
    return (_jsx("div", { ref: ref, className: classNames(styles.wrapper, {
            [styles.error]: hasError,
        }), children: _jsxs("div", { className: classNames(styles.inputWrapper, {
                [styles.disabled]: disabled,
                [styles.active]: isActive,
            }), children: [!!leftElement && (_jsx("div", { className: styles.left, onClick: forwardFocusFromLeft, children: leftElement })), _jsx(HexColorInput, { className: classNames(styles.input, styles.colorIput), id: id, color: color, onChange: onChange, placeholder: placeholder, prefixed: color ? true : focused, onFocus: handleFocus, onBlur: handleBlur })] }) }));
});
