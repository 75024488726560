import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { AccentText } from '@/components/AccentText/AccentText';
import { Table } from '@/components/Table/Table';
import { Done } from '@/icons/status/Done';
import { usePurchaseModal } from '@/modals/PurchaseEditModal/usePurchaseModal';
import { getClientFullName } from '@/utils/getClientFullName';
import { money } from '@/utils/numbers';
import { formatPhoneNumber } from '@/utils/phoneNumber';
import { usePurchasesPage } from './context/usePurchasesPage';
import styles from './styles.module.scss';
export const PurchasesTable = () => {
    const { t } = useTranslation();
    const { currency } = useCurrentUser();
    const { data, pagination } = usePurchasesPage();
    const purchaseModal = usePurchaseModal();
    const columns = [
        {
            id: 'status',
        },
        {
            id: 'executedAt',
            title: t('Дата операции'),
        },
        {
            id: 'phoneNumber',
            title: t('Телефон'),
        },
        {
            id: 'clientName',
            title: t('Имя клиента'),
        },
        {
            id: 'shop',
            title: t('Точка продажи'),
            tooltip: t('Место, где была совершена покупка'),
        },
        {
            id: 'paidAmount',
            title: t('Сумма покупки'),
            align: 'right',
        },
        {
            id: 'bonusesApplied',
            title: _jsxs("span", { className: styles.noWrap, children: ["\u2013 ", t('Бонусы')] }),
            align: 'right',
        },
        {
            id: 'bonusesCollected',
            title: _jsxs("span", { className: styles.noWrap, children: ["+ ", t('Бонусы')] }),
            align: 'right',
        },
    ];
    const rows = data.purchases.items.map((purchase) => ({
        id: purchase.id,
        cells: [
            _jsx("div", { className: styles.status, children: _jsx(Done, { type: 'single' }) }, 'status'),
            moment(purchase.executedAt).format('DD.MM.YYYY HH:mm'),
            _jsx(Fragment, { children: _jsx("span", { className: styles.nowrap, children: formatPhoneNumber(purchase.client.phoneNumber) }) }, 'phoneNumber'),
            getClientFullName(purchase.client),
            purchase.shop.name,
            money({ input: purchase.paidAmount, currency }),
            _jsx(AccentText, { accent: purchase.bonusesApplied > 0 ? 'danger' : 'default', children: purchase.bonusesApplied > 0 ? -purchase.bonusesApplied : 0 }, 'bonusesApplied'),
            _jsx(AccentText, { accent: purchase.bonusesCollected > 0 && !(purchase.bonus || {}).isPending
                    ? 'success'
                    : (purchase.bonus || {}).isPending
                        ? 'warning'
                        : 'default', children: purchase.bonusesCollected }, 'bonusesCollected'),
        ],
    }));
    const isLoading = data.count.isFetching || data.purchases.isFetching;
    const handleRowClick = useCallback((row, index) => {
        purchaseModal.open({
            purchaseList: data.purchases.items,
            currentPurchaseIndex: index,
            closeHandler: (result) => {
                if ((result === null || result === void 0 ? void 0 : result.added) || (result === null || result === void 0 ? void 0 : result.deleted))
                    if (pagination.purchases.offset !== 0)
                        pagination.purchases.resetOffset();
                    else
                        data.purchases.refetch();
                if (result === null || result === void 0 ? void 0 : result.updated)
                    data.purchases.refetch();
            },
        });
    }, [data]);
    return (_jsx(Table, { className: styles.table, rows: rows, columns: columns, isLoading: isLoading, onRowClick: handleRowClick, hasEmptyState: true }));
};
