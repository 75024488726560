import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { t } from 'i18next';
import { money } from '@/utils/numbers';
import { itemIsOrder } from '../../dataIsOrder';
import { AccordionContent } from '../AccordionContent';
import styles from '../styles.module.scss';
import { createDiscountList } from './createDiscountList';
export const tableRows = (currency, data) => {
    if (data)
        return data === null || data === void 0 ? void 0 : data.items.map((item, index) => {
            var _a;
            const discountList = createDiscountList(item, data, currency);
            return {
                id: index + 1,
                notExpand: !discountList.length,
                cells: [
                    {
                        title: `${index + 1}.`,
                    },
                    {
                        title: (_jsx("div", { className: styles.productTitle, children: _jsxs("span", { children: [" ", ((_a = item === null || item === void 0 ? void 0 : item.product) === null || _a === void 0 ? void 0 : _a.title) || t('без названия')] }) })),
                        content: _jsx(AccordionContent, { discountList: discountList }),
                    },
                    {
                        title: money({
                            input: item.price || '',
                            currency,
                            withFraction: true,
                            removeFractionZeros: true,
                        }),
                    },
                    { title: '×' },
                    { title: item.itemCount },
                    {
                        title: money({
                            input: item.totalDiscount || '',
                            currency,
                            withFraction: true,
                            removeFractionZeros: true,
                        }),
                        className: styles.discountCell,
                    },
                    {
                        title: money({
                            input: itemIsOrder(item)
                                ? item.remainingAmount || ''
                                : item.paidAmount,
                            currency,
                            withFraction: true,
                            removeFractionZeros: true,
                        }),
                        className: styles.totalPrice,
                    },
                    {
                        title: item.bonusesCollected,
                        className: styles.bonusCell,
                    },
                ],
            };
        });
    return [];
};
