var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReactFlow } from '@xyflow/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRefreshEmailPreview } from '@/app/pages/MailingsForm/useRefreshEmailPreview';
import { useTemplatesInfiniteScrollData } from '@/app/pages/MailingsForm/useTemplatesInfiniteScrollData';
import { useCurrentUser } from '@/app/useCurrentUser';
import { EmailEditor, } from '@/components/EmailEditor/EmailEditor';
import { convertToInputValue } from '@/components/MailingRewardsPills/utils';
import { useUploadImage } from '@/hooks/useUploadImage';
import { brandHasWalletFeature } from '@/utils/brandHasWalletFeature';
import { useInteractiveFlow } from '../../../context/useInteractiveFlow';
import { branchHasNode } from '../../../nodes/utils';
import formStyles from '../styles.module.scss';
import { EmailFormAbandonedCard } from './EmailFormAbandonedCard';
import { EmailFormDroppedPrice } from './EmailFormDroppedPrice';
import { EmailFormRecommendations } from './EmailFormRecommendations';
import styles from './styles.module.scss';
export const EmailFormBody = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const { t } = useTranslation();
    const flow = useReactFlow();
    const { triggerType, selectedNode, validationErrorMap, isEditMode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    const selectedNodeNode = flow.getNode(selectedNode.uuid);
    const errors = validationErrorMap === null || validationErrorMap === void 0 ? void 0 : validationErrorMap[selectedNode.uuid];
    const { brand } = useCurrentUser();
    const uploadImage = useUploadImage();
    const templatesData = useTemplatesInfiniteScrollData();
    const refreshEmailPreview = useRefreshEmailPreview();
    const branchHasChannelNodes = !!selectedNodeNode &&
        branchHasNode({
            flow,
            toNode: selectedNodeNode,
            predicate: (node) => node.type === 'channel',
            checkInitialNode: false,
        });
    const updateEmailPreviewImageUrl = (uuid, html, design, data) => {
        refreshEmailPreview(html).then((url) => {
            selectedNode.update({
                email: Object.assign(Object.assign({}, data), { emailBody: html, emailDesign: design, emailPreviewImageUrl: url }),
            }, uuid);
        });
    };
    const handleUpdateEmailBody = (data) => {
        var _a;
        selectedNode.update({
            email: Object.assign(Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email), { emailBody: (data === null || data === void 0 ? void 0 : data.html) || null, emailDesign: (data === null || data === void 0 ? void 0 : data.design) || null, emailPreviewImageUrl: data
                    ? (_a = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _a === void 0 ? void 0 : _a.emailPreviewImageUrl
                    : null }),
        });
        if (data === null || data === void 0 ? void 0 : data.html)
            updateEmailPreviewImageUrl(selectedNode.uuid, data.html, data.design, Object.assign({}, selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email));
    };
    const editorContext = {
        channel: 'email',
        brandName: (_a = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _a === void 0 ? void 0 : _a.name,
        brandWebsite: (_c = (_b = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _b === void 0 ? void 0 : _b.settings) === null || _c === void 0 ? void 0 : _c.website,
        brandHotlinePhone: (_e = (_d = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _d === void 0 ? void 0 : _d.settings) === null || _e === void 0 ? void 0 : _e.hotlinePhone,
        hasWalletFeature: brandHasWalletFeature(brand === null || brand === void 0 ? void 0 : brand.brand),
        triggerType: !branchHasChannelNodes ? triggerType : undefined,
        rewards: convertToInputValue((selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.rewards) || []),
        includeRecommendations: (_f = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _f === void 0 ? void 0 : _f.includeRecommendations,
        recommendationType: (_g = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _g === void 0 ? void 0 : _g.recommendationType,
    };
    return (_jsxs("div", { children: [_jsx("label", { className: classNames(formStyles.label, formStyles.movedUp), htmlFor: 'channel-form-email-body', children: t('Содержимое') }), _jsxs("div", { className: styles.emailBlocks, children: [_jsx(EmailFormRecommendations, {}), _jsx(EmailFormDroppedPrice, {}), _jsx(EmailFormAbandonedCard, {})] }), _jsx(EmailEditor, { buttonClassName: styles.button, unlayerUrl: '/components/unlayer/embed.1.5.30.js', unlayerProjectId: 4335, initialDesign: ((_h = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _h === void 0 ? void 0 : _h.emailDesign) || '', previewUrl: ((_j = selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.email) === null || _j === void 0 ? void 0 : _j.emailPreviewImageUrl) || '', onChange: handleUpdateEmailBody, templatesData: templatesData, mailingName: selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.name, mailingId: selectedNode.uuid, brandGlobalKey: (_k = brand === null || brand === void 0 ? void 0 : brand.brand) === null || _k === void 0 ? void 0 : _k.globalKey, context: editorContext, uploadImage: (file) => __awaiter(void 0, void 0, void 0, function* () {
                    const result = yield uploadImage(file, true);
                    return (result === null || result === void 0 ? void 0 : result.url) || '';
                }), hasError: !!(errors === null || errors === void 0 ? void 0 : errors.emailBody), isDisabled: !isEditMode }), !!(errors === null || errors === void 0 ? void 0 : errors.emailBody) && (_jsx("div", { className: formStyles.error, children: errors.emailBody }))] }));
};
