import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { formatDate } from '@/utils/dates';
import styles from '../../styles.module.scss';
export const getMailingContent = (args) => {
    const { event, translate: t } = args;
    const { openedAt, bouncedAt, deliveredAt, scheduledAt, finishedAt, goalReachedAt, markedSpamAt, unsubscribedAt, pushBody, pushImageUrl, webpushTitle, smsBody, viberBody, viberImageUrl, viberButton, viberUrl, emailSubject, emailPreviewImageUrl, emailSenderName, emailSenderEmail, chatBody, chatImageUrl, chatButton, chatUrl, error, statusLine, } = event.params || {};
    let message = '';
    let button = '';
    let url = '';
    let imageUrl = '';
    if (event.eventType === 'sending_push')
        message = pushBody;
    if (event.eventType === 'sending_webpush')
        message = webpushTitle;
    if (event.eventType === 'sending_sms')
        message = smsBody;
    if (event.eventType === 'sending_viber')
        message = viberBody;
    if (event.eventType === 'sending_chat')
        message = chatBody;
    if (event.eventType === 'sending_viber')
        button = viberButton;
    if (event.eventType === 'sending_chat')
        button = chatButton;
    if (event.eventType === 'sending_viber')
        url = viberUrl;
    if (event.eventType === 'sending_chat')
        url = chatUrl;
    if (event.eventType === 'sending_push')
        imageUrl = pushImageUrl;
    if (event.eventType === 'sending_viber')
        imageUrl = viberImageUrl;
    if (event.eventType === 'sending_chat')
        imageUrl = chatImageUrl;
    const isMailingEvent = event.eventType === 'mailing_event' || event.eventType === 'mailing_sending';
    const leftItems = [];
    const rightItems = [];
    // Left Items ----------------------------------------------------------------
    if (event.eventType === 'sending_email' &&
        (emailSenderName || emailSenderEmail))
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('От кого'), _jsx("span", { className: styles.contentValue, children: emailSenderName && emailSenderEmail
                        ? `${emailSenderName} <${emailSenderEmail}>`
                        : emailSenderName || emailSenderEmail })] }, 'emailSubject'));
    if (event.eventType === 'sending_email' && emailSubject)
        leftItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column, styles.limited), children: [t('Тема'), _jsx("span", { className: styles.contentValue, children: emailSubject })] }, 'emailSubject'));
    if (message)
        leftItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column, styles.limited), children: [t('Сообщение'), _jsx("span", { className: styles.contentValue, children: message })] }, 'message'));
    if (event.eventType === 'sending_email' && emailPreviewImageUrl)
        leftItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column), children: [t('Превью'), _jsx("img", { className: styles.valuePreview, src: emailPreviewImageUrl, alt: t('Превью Email') })] }, 'emailPreviewImageUrl'));
    if (imageUrl)
        leftItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column), children: [t('Картинка'), _jsx("img", { className: styles.valueImage, src: imageUrl, alt: t('Картинка из сообщения') })] }, 'imageUrl'));
    if (button)
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Кнопка'), _jsx("span", { className: styles.contentValue, children: button })] }, 'button'));
    if (url)
        leftItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Ссылка'), _jsx("a", { className: classNames(styles.contentValue, styles.underline, styles.limitedValue), href: url, target: '_blank', rel: 'noreferrer', children: url })] }, 'url'));
    // Right Items ---------------------------------------------------------------
    if (scheduledAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(scheduledAt, true), _jsx("span", { className: styles.contentValue, children: t('Ожидает отправки') })] }, 'scheduledAt'));
    if (finishedAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(finishedAt, true), _jsx("span", { className: styles.contentValue, children: isMailingEvent ? t('Отправлена') : t('Отправлено') })] }, 'finishedAt'));
    if (deliveredAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(deliveredAt, true), _jsx("span", { className: styles.contentValue, children: isMailingEvent ? t('Доставлена') : t('Доставлено') })] }, 'deliveredAt'));
    if (bouncedAt)
        rightItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.danger), children: [formatDate(bouncedAt, true), _jsx("span", { className: styles.contentValue, children: isMailingEvent ? t('Не доставлена') : t('Не доставлено') })] }, 'bouncedAt'));
    if (openedAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(openedAt, true), _jsx("span", { className: styles.contentValue, children: isMailingEvent ? t('Открыта') : t('Открыто') })] }, 'openedAt'));
    if (goalReachedAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(goalReachedAt, true), _jsx("span", { className: styles.contentValue, children: t('Цель достигнута') })] }, 'goalReachedAt'));
    if (markedSpamAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(markedSpamAt, true), _jsx("span", { className: styles.contentValue, children: t('Отправил в спам') })] }, 'markedSpamAt'));
    if (unsubscribedAt)
        rightItems.push(_jsxs("li", { className: styles.contentItem, children: [formatDate(unsubscribedAt, true), _jsx("span", { className: styles.contentValue, children: t('Отписался') })] }, 'unsubscribedAt'));
    if (error || statusLine)
        rightItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column, styles.limited, styles.danger), children: [t('Ошибка отправки'), _jsx("span", { className: styles.contentValue, children: error || statusLine })] }, 'error'));
    // ---------------------------------------------------------------------------
    if (!leftItems.length && !rightItems.length)
        return undefined;
    return (_jsx("div", { children: _jsxs("section", { className: styles.expandedContent, children: [!!leftItems.length && _jsx("ul", { children: leftItems }), !!rightItems.length && _jsx("ul", { children: [...rightItems].reverse() })] }) }));
};
