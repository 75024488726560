import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { MailingTriggerTypeInput } from '@/components/MailingTriggerTypeInput/MailingTriggerTypeInput';
import { OFFSET_SIGN_AFTER, OFFSET_SIGN_AT, OFFSET_SIGN_BEFORE, OFFSET_SIGN_SCHEDULE, } from './FormMailingOffset';
export const FormMailingTriggerType = ({ hasRecommendationsFeature, hasLevelsFeature, labelWidth, isDisabled, }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const offsetSign = watch('offsetSign');
    let label = '';
    if (offsetSign === OFFSET_SIGN_AT)
        label = t('OFFSET_SIGN_AT');
    if (offsetSign === OFFSET_SIGN_BEFORE)
        label = t('OFFSET_SIGN_BEFORE');
    if (offsetSign === OFFSET_SIGN_AFTER)
        label = t('OFFSET_SIGN_AFTER');
    if (offsetSign === OFFSET_SIGN_SCHEDULE)
        return null;
    return (_jsx(FormFieldRow, { label: label, labelFor: 'triggerType', labelWidth: labelWidth, controlSize: '300', children: _jsx(Controller, { name: 'triggerType', control: control, render: ({ field }) => (_jsx(MailingTriggerTypeInput, { id: 'triggerType', value: field.value || 0, onChange: (newTriggerType) => setValue('triggerType', newTriggerType), isBeforeEvents: offsetSign === OFFSET_SIGN_BEFORE, hasRecommendationsFeature: hasRecommendationsFeature, hasLevelsFeature: hasLevelsFeature, isDisabled: isDisabled }, offsetSign)) }) }));
};
