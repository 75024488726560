import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// отменены поощрительные бонусы
export const bonus5 = (args) => {
    const { event, translate: t } = args;
    const { amount, operatorName } = event.params || {};
    return {
        color: 'red',
        Icon: _jsx(BonusMovement, {}),
        title: (_jsx(BonusTitle, Object.assign({}, args, { children: operatorName ? (_jsxs(_Fragment, { children: [t('BONUSES_DEDUCTED_BY_OPERATOR', { count: amount }), "\u00A0", operatorName, ' '] })) : (`- ${amount} ` + t('BONUSES_LABEL', { count: amount })) }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['operator'] })),
    };
};
