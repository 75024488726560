import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// компенсированы списанные бонусы из-зв отмены покупки
export const bonus8 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handlePurchase } = entityHandlers;
    const { amount, purchaseId, purchaseDeleted } = event.params || {};
    const hasPurchaseLink = !!purchaseId && !purchaseDeleted && !!handlePurchase;
    return {
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
        title: (_jsxs(BonusTitle, Object.assign({}, args, { includeDates: true, children: [amount
                    ? t('RETURN_APPLIED_BONUSES_FROM_REVERTED_PURCHASE', {
                        count: amount,
                    })
                    : t('Начисление бонусов с отмененной (или удаленной)'), "\u00A0", _jsx("span", { className: hasPurchaseLink ? styles.underline : '', onClick: () => {
                        if (hasPurchaseLink)
                            handlePurchase === null || handlePurchase === void 0 ? void 0 : handlePurchase(purchaseId);
                    }, children: amount ? t('purchase_range_one') : t('покупки') })] }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['purchase', 'availableAt', 'expiredAt', 'issuedAt'] })),
    };
};
