import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button2/Button';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import styles from './styles.module.scss';
export const WalletCardsTableCreateButton = ({ copyFrom = [], onCreate, onCopy }) => {
    const { t } = useTranslation();
    const [buttonIsActive, setButtonIsActive] = useState(false);
    const button = !copyFrom.length ? (_jsx(Button, { onClick: onCreate, children: t('Создать') })) : (_jsx(Dropdown, { items: [
            {
                id: 'create-from-scratch',
                label: t('Создать с нуля'),
                onClick: onCreate,
            },
            ...copyFrom.map((item) => ({
                id: `copy-from-${item.id}`,
                label: t('COPY_WALLET_CARD', { name: item.name }),
                onClick: () => onCopy === null || onCopy === void 0 ? void 0 : onCopy(item.id),
                isMultiLine: true,
            })),
        ], onOpen: () => setButtonIsActive(true), onClose: () => setButtonIsActive(false), children: _jsx(Button, { isActive: buttonIsActive, children: t('Создать') }) }));
    const stopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);
    return (_jsxs("div", { className: styles.createButtonWrapper, onClick: stopPropagation, children: [_jsx("div", { className: styles.createButtonHelp, children: t('Нет карты') }), button] }));
};
