import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Popup } from '@/components/Popup/Popup';
import { FormClientBlock, } from '@/forms/FormClientBlock/FormClientBlock';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './styles.module.scss';
export const ClientBlockButton = memo(({ onApply, isLoading = false }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasClientManage = userHasAccessTo([
        'CLIENTS_MANAGE_OWN',
        'CLIENTS_MANAGE',
    ]);
    const popupTrigger = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const handleTogglePopup = useCallback(() => setIsPopupOpen(!isPopupOpen), [isPopupOpen]);
    const handleApply = useCallback((data) => {
        setIsPopupOpen(false);
        onApply(data.comment);
    }, [onApply]);
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: !userHasClientManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { ref: popupTrigger, theme: 'danger', onClick: handleTogglePopup, disabled: !userHasClientManage || isLoading, isActive: isPopupOpen, children: t('Заблокировать') }) }), _jsx(Popup, { className: styles.popup, isOpen: isPopupOpen, onClose: handleTogglePopup, anchorEl: popupTrigger === null || popupTrigger === void 0 ? void 0 : popupTrigger.current, placement: 'top', hasTail: true, offset: [0, 13], children: _jsxs("div", { className: styles.form, children: [_jsx("h2", { className: styles.title, children: t('Заблокировать клиента?') }), _jsx("p", { className: styles.description, children: t('Блокировка заморозит все бонусы, скидки, промокоды и рассылку для данного клиента.') }), _jsx(FormClientBlock, { onSubmit: handleApply, onCancel: handleTogglePopup })] }) })] }));
});
