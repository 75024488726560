import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import styles from './styles.module.scss';
export const Pills = ({ items, activeItemId, onChangeActiveItem, asIcons, }) => {
    return (_jsx("div", { className: classNames(styles.wrapper, {
            [styles.text]: !asIcons,
            [styles.icons]: asIcons,
        }), children: items.map((item) => (_jsx("button", { className: classNames(styles.button, {
                [styles.active]: item.id === activeItemId,
            }), type: 'button', onClick: () => onChangeActiveItem(item.id), children: item.content }, item.id))) }));
};
