import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Frown } from '@/icons/rfm/Frown';
import { Smile } from '@/icons/rfm/Smile';
import styles from './styles.module.scss';
export const RFMMovementsChartHeader = ({ type = 'all' }) => {
    const { t } = useTranslation();
    let header1 = t('Сегмент');
    let header2 = t('Ушло');
    let header3 = t('Пришло');
    if (type == 'from')
        header1 = t('В сегмент');
    if (type == 'to')
        header1 = t('Из сегмента');
    if (type == 'from' || type === 'to') {
        header2 = t('Стали более лояльны');
        header3 = t('Стали менее лояльны');
    }
    return (_jsxs("div", { className: classNames(styles.row, styles.header), children: [_jsx("div", { className: styles['column-1'], children: header1 }), _jsxs("div", { className: styles['column-2'], children: [(type == 'from' || type === 'to') && _jsx(Smile, {}), header2] }), _jsxs("div", { className: styles['column-3'], children: [(type == 'from' || type === 'to') && _jsx(Frown, {}), header3] })] }));
};
