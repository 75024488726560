import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Button } from '@/components/Button2/Button';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { EventsTable } from './EventsTable';
import styles from './styles.module.scss';
export const EventsPanel = memo(({ isLoading = false, isPrevButtonDisabled, isNextButtonDisabled, eventsData, activeButtons, openOverview, handleNextPage, handlePrevPage, handlePurchase, handleReturn, handleOrder, handleEntityLink, handleChangeFilters, }) => {
    const { t } = useTranslation();
    const { currency } = useCurrentUser();
    const footer = (_jsxs("div", { className: styles.footerPanel, children: [_jsx(Button, { isOutlined: true, disabled: isPrevButtonDisabled, className: styles.nextPageButton, onClick: handlePrevPage, children: "\u2190" }), _jsx("span", { children: "..." }), _jsx(Button, { isOutlined: true, disabled: isNextButtonDisabled, className: styles.nextPageButton, onClick: handleNextPage, children: "\u2192" })] }));
    return (_jsx(TabsPanel, { headerClassName: styles.headerTabsPanel, footer: footer, rightToolbar: _jsxs(_Fragment, { children: [_jsx(Button, { className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.all,
                    }), isActive: activeButtons.all, onClick: () => !activeButtons.all && handleChangeFilters('all'), children: t('Все') }), _jsx(Button, { className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.bonus,
                    }), isActive: activeButtons.bonus, onClick: () => handleChangeFilters('bonus'), children: t('Бонусы') }), _jsx(Button, { className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.mailing,
                    }), isActive: activeButtons.mailing, onClick: () => handleChangeFilters('mailing'), children: t('Рассылки') }), _jsx(Button, { className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.purchase,
                    }), isActive: activeButtons.purchase, onClick: () => handleChangeFilters('purchase'), children: t('Покупки') }), _jsx(Button, { className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.loyalty,
                    }), isActive: activeButtons.loyalty, onClick: () => handleChangeFilters('loyalty'), children: t('Сегменты') }), _jsx(Button, { className: classNames({
                        [styles.nonActiveFilterButton]: !activeButtons.client_update,
                    }), isActive: activeButtons.client_update, onClick: () => handleChangeFilters('client_update'), children: t('Другое') })] }), isContentSkeleton: isLoading, children: _jsx(EventsTable, { isLoading: isLoading, eventsData: eventsData, openOverview: openOverview, handleOrder: handleOrder, handleReturn: handleReturn, handleEntityLink: handleEntityLink, handlePurchase: handlePurchase, currency: currency }) }));
});
