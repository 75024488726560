import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorTriangle } from '@/icons/status/ErrorTriangle';
import { Button } from '../Button2/Button';
import styles from './styles.module.scss';
export const ClientBlockAlert = memo(({ date, comment, onUnblockClick, isLoading = false }) => {
    const { t, i18n } = useTranslation();
    const dateFormatted = moment(date)
        .locale(i18n.language)
        .format('DD MMMM YYYY');
    return (_jsxs("div", { className: styles.wrapper, children: [_jsxs("div", { className: styles.header, children: [_jsx("div", { className: styles.icon, children: _jsx(ErrorTriangle, {}) }), _jsxs("div", { children: [_jsx("h2", { className: styles.title, children: t('Профиль заблокирован {{date}} г.', { date: dateFormatted }) }), comment && (_jsx("p", { className: styles.comment, children: t('Причина: {{comment}}', { comment }) }))] })] }), _jsx(Button, { className: classNames(styles.button, {
                    [styles.loading]: isLoading,
                }), onClick: onUnblockClick, disabled: isLoading, theme: 'primary', children: t('Разблокировать') })] }));
});
