import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DaysSelect } from '@/components/DaysSelect/DaysSelect';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { TimeInput } from '@/components/TimeInput/TimeInput';
import styles from '../styles.module.scss';
export const FormOfferWeekdays = ({ labelWidth, }) => {
    const { t, i18n } = useTranslation();
    const { control, setValue } = useFormContext();
    return (_jsxs(_Fragment, { children: [_jsx(FormFieldRow, { label: t('Время покупки'), labelFor: 'weekdays', labelWidth: labelWidth, children: _jsx(Controller, { name: 'weekdays', control: control, render: ({ field }) => (_jsx("div", { className: classNames(styles.daysSelect, {
                            [styles.en]: i18n.language === 'en',
                        }), children: _jsx(DaysSelect, { id: 'weekdays', value: field.value || [], onChange: (value) => setValue('weekdays', value, { shouldDirty: true }), listWidth: i18n.language === 'en' ? 243 : 225 }) })) }) }), _jsx(FormFieldRow, { labelFor: 'timeFrom', labelWidth: labelWidth, children: _jsxs("div", { className: styles.timeWrapper, children: [_jsx("label", { className: styles.timeLabel, htmlFor: 'timeFrom', children: t('с') }), _jsx("div", { className: styles.timeInput, children: _jsx(Controller, { name: 'timeFrom', control: control, render: ({ field }) => (_jsx(TimeInput, Object.assign({}, field, { id: 'timeFrom', onComplete: (timeFrom) => {
                                        setValue('timeFrom', timeFrom, { shouldDirty: true });
                                    } }))) }) }), _jsx("label", { className: styles.timeLabel, htmlFor: 'timeTo', children: t('по') }), _jsx("div", { className: styles.timeInput, children: _jsx(Controller, { name: 'timeTo', control: control, render: ({ field }) => (_jsx(TimeInput, Object.assign({}, field, { id: 'timeTo', onComplete: (timeTo) => {
                                        setValue('timeTo', timeTo, { shouldDirty: true });
                                    } }))) }) })] }) })] }));
};
