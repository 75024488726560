import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { money } from '@/utils/numbers';
import { BillingTable } from './BillingTable';
import styles from './styles.module.scss';
import { useStats } from './utils/useStats';
export const Billing = () => {
    var _a;
    const { t } = useTranslation();
    const { stats, isLoading, range } = useStats();
    const { brand } = useCurrentUser();
    if (!brand)
        return null;
    const currency = brand.brand.currency.symbol || brand.brand.currency.unitShort;
    const footer = (_jsxs("div", { className: styles.footer, children: [t('Итого:'), " ", money({ input: ((_a = stats === null || stats === void 0 ? void 0 : stats.total) === null || _a === void 0 ? void 0 : _a.amount) || 0, currency })] }));
    return (_jsx(Container, { notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, {}), children: _jsx(TabsPanel, { tabs: [
                {
                    id: 'billing',
                    label: t('Детализация расходов за:') + range,
                },
            ], footer: footer, children: _jsx(BillingTable, { billing: stats, currency: currency, isLoading: isLoading }) }) }));
};
