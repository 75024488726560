import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { User } from '@/icons/User';
// повысился уровень
export const loyaltyLevelUp = (args) => {
    const { event, translate: t } = args;
    const { oldLevel, oldLevelName, level, levelName } = event.params || {};
    const level1 = oldLevelName ? `"${oldLevelName}"` : oldLevel;
    const level2 = levelName ? `"${levelName}"` : level;
    return {
        Icon: _jsx(User, { size: 20 }),
        title: (_jsxs(_Fragment, { children: [t('LEVEL_UP'), "\u00A0", level1, "\u00A0", t('на'), "\u00A0", level2] })),
    };
};
