import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useCurrentUser } from '@/app/useCurrentUser';
import { FormCounter } from '@/forms/FormCounter/FormCounter';
import styles from './Counter.module.scss';
import { Wrapper } from './Wrapper';
export const Counter = memo(({ item, onApply, onBack }) => {
    const { brand } = useCurrentUser();
    const formId = onBack
        ? 'filter-counter-form-mobile'
        : 'filter-counter-form-desktop';
    const handleChange = (value) => {
        onApply(Object.assign(Object.assign({}, item), { value }));
    };
    return (_jsx(Wrapper, { label: item.label, onBack: onBack, isHeaderVisible: false, applyButtonForm: formId, isFullHeight: true, isOverflow: true, children: _jsx("div", { className: styles.counterWrapper, children: _jsx(FormCounter, { id: formId, value: item.value, onChange: handleChange, conditionsProductCount: item.conditionsProductCount, currency: (brand === null || brand === void 0 ? void 0 : brand.brand.currency.symbol) || '' }) }) }));
});
