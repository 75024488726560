import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from 'react';
import { SparklineAddButton } from '@/components/SparklineButton/SparklineAddButton';
import { SparklineButton } from '@/components/SparklineButton/SparklineButton';
import { useDashboardMetrics, } from '@/hooks/useDashboardMetrics';
import styles from './styles.module.scss';
export const SparklineButtonGroup = memo(({ items, currency, onSelect, onChange, onDelete, onAdd }) => {
    const metrics = useDashboardMetrics();
    const handleChangeMetric = useCallback((newMetricId, index) => {
        const newItems = [...items];
        newItems.splice(index, 1, {
            metric: metrics[newMetricId],
            value: 0,
            data: [],
        });
        onChange(newItems, newMetricId);
    }, [items, onChange]);
    const handleDeleteMetric = useCallback((index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        onDelete(newItems);
    }, [items, onDelete]);
    const handleAddMetric = useCallback((newMetricId) => {
        const newItems = [
            ...items,
            { metric: metrics[newMetricId], value: 0, data: [] },
        ];
        onAdd(newItems, newMetricId);
    }, [items, onAdd]);
    return (_jsxs("div", { className: styles.group, children: [items.map(({ metric, value, data }, index) => (_jsx(SparklineButton, { className: styles.item, metric: metric, value: value, data: data, currency: currency, onClick: () => onSelect(metric.id), onChange: (newMetricId) => handleChangeMetric(newMetricId, index), onDelete: () => handleDeleteMetric(index) }, `${metric.id}-${index}`))), _jsx(SparklineAddButton, { onAdd: handleAddMetric }, 'add')] }));
});
