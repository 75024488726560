var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { StyledPopper } from './styles';
import styles from './styles.module.scss';
export const Popup = React.memo((_a) => {
    var { children, isOpen, onClose, hasTail, offset, className, isRounded = false, isLargeRounded = false, isTransparent = false, anchorEl, arrowClassName, clickAwayMouseEvent, clickAwayTouchEvent } = _a, props = __rest(_a, ["children", "isOpen", "onClose", "hasTail", "offset", "className", "isRounded", "isLargeRounded", "isTransparent", "anchorEl", "arrowClassName", "clickAwayMouseEvent", "clickAwayTouchEvent"]);
    const modifiers = [
        {
            name: 'offset',
            options: { offset },
        },
        {
            name: 'preventOverflow',
            options: { padding: 8, mainAxis: true, altAxis: true },
        },
    ];
    const handleClose = useCallback(() => {
        if (onClose)
            onClose();
    }, [onClose]);
    const handleCloseOnEsc = useCallback((e) => {
        if (e.code === 'Escape' && onClose)
            onClose();
    }, [onClose]);
    return (_jsxs(StyledPopper, Object.assign({ className: classNames(className, {
            [styles.rounded]: isRounded || isLargeRounded,
            [styles.roundedLarge]: isLargeRounded,
            [styles.transparent]: isTransparent,
        }), open: isOpen, modifiers: modifiers, anchorEl: anchorEl, onKeyUp: handleCloseOnEsc }, props, { children: [hasTail && _jsx("div", { className: classNames('arrow', arrowClassName) }), _jsx(ClickAwayListener, { onClickAway: handleClose, mouseEvent: clickAwayMouseEvent, touchEvent: clickAwayTouchEvent, children: _jsx("div", { children: children }) })] })));
});
