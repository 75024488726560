import { jsx as _jsx } from "react/jsx-runtime";
import native from '@emoji-mart/data';
import apple from '@emoji-mart/data/sets/14/apple.json';
import Picker from '@emoji-mart/react';
import { useTranslation } from 'react-i18next';
export const EmojiPicker = ({ set = 'native', onEmojiSelect, }) => {
    const { i18n } = useTranslation();
    const handleSelectEmoji = (emoji) => {
        onEmojiSelect(emoji.native, emoji.unified);
    };
    return (_jsx("div", { className: 'emoji-picker', children: _jsx(Picker, { set: set, data: set === 'apple' ? apple : native, locale: i18n.language, searchPosition: 'none', previewPosition: 'none', onEmojiSelect: handleSelectEmoji }) }));
};
