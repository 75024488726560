import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPresetPeriod, isPresetEqualsToRange, PresetPeriod, } from '@/utils/getPresetPeriod';
import styles from './styles.module.scss';
export const DatePeriodPresets = memo(({ start, end, onChange }) => {
    const { t } = useTranslation();
    const handleSelectPreset = (preset) => {
        const [start, end] = getPresetPeriod(preset);
        onChange(start.toDate(), end.toDate(), true);
    };
    const handleReset = () => {
        onChange(null, null);
    };
    const presets = [
        {
            id: 'custom',
            label: t('Даты'),
            onClick: handleReset,
        },
        {
            id: PresetPeriod.Today,
            label: t('Сегодня'),
            onClick: () => handleSelectPreset(PresetPeriod.Today),
            isActive: isPresetEqualsToRange(PresetPeriod.Today, [start, end]),
        },
        {
            id: PresetPeriod.Yesterday,
            label: t('Вчера'),
            onClick: () => handleSelectPreset(PresetPeriod.Yesterday),
            isActive: isPresetEqualsToRange(PresetPeriod.Yesterday, [start, end]),
        },
        {
            id: PresetPeriod.Week,
            label: t('Неделя'),
            onClick: () => handleSelectPreset(PresetPeriod.Week),
            isActive: isPresetEqualsToRange(PresetPeriod.Week, [start, end]),
        },
        {
            id: PresetPeriod.Month,
            label: t('Месяц'),
            onClick: () => handleSelectPreset(PresetPeriod.Month),
            isActive: isPresetEqualsToRange(PresetPeriod.Month, [start, end]),
        },
        {
            id: PresetPeriod.Quarter,
            label: t('Квартал'),
            onClick: () => handleSelectPreset(PresetPeriod.Quarter),
            isActive: isPresetEqualsToRange(PresetPeriod.Quarter, [start, end]),
        },
        {
            id: PresetPeriod.Year,
            label: t('Год'),
            onClick: () => handleSelectPreset(PresetPeriod.Year),
            isActive: isPresetEqualsToRange(PresetPeriod.Year, [start, end]),
        },
        {
            id: PresetPeriod.AllTime,
            label: t('Всё время'),
            onClick: () => handleSelectPreset(PresetPeriod.AllTime),
            isActive: isPresetEqualsToRange(PresetPeriod.AllTime, [start, end]),
        },
    ];
    return (_jsx("div", { className: styles.presets, children: _jsx("ul", { className: styles.presetsNav, children: presets.map((preset) => {
                const isActive = preset.id === 'custom'
                    ? presets.every((p) => !p.isActive)
                    : preset.isActive;
                return (_jsx("li", { children: _jsx("button", { className: classNames({
                            [styles.active]: isActive,
                        }), type: 'button', onClick: preset.onClick, children: preset.label }) }, preset.id));
            }) }) }));
});
