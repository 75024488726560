import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
export const GOAL_TYPES = {
    SUBSCRIPTION: '1',
    PURCHASE: '3',
    WALLET_CARD: '6',
    LINK_CLICK: '7',
    DISCARD: '0',
};
export const FormMailingGoalType = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, setValue } = useFormContext();
    const options = [
        {
            value: GOAL_TYPES.SUBSCRIPTION,
            label: t('подтверждение подписки (Email)'),
        },
        {
            value: GOAL_TYPES.PURCHASE,
            label: t('совершение покупки'),
        },
        {
            value: GOAL_TYPES.WALLET_CARD,
            label: t('установку карты Wallet'),
        },
        {
            value: GOAL_TYPES.LINK_CLICK,
            label: t('переход по ссылке (Email)'),
        },
        {
            value: GOAL_TYPES.DISCARD,
            label: t('не считать'),
        },
    ];
    return (_jsx(FormFieldRow, { labelFor: 'goalType', labelWidth: labelWidth, controlSize: '300', children: _jsx(Controller, { name: 'goalType', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'goalType', value: options.find((item) => item.value === field.value) || null, onChange: (goalType) => {
                    setValue('goalType', goalType === null || goalType === void 0 ? void 0 : goalType.value, { shouldDirty: true });
                }, options: options, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true }))) }) }));
};
