import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { defined } from '@/utils/defined';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const UserShops = ({ user }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const source = ((_a = user.shopConditions) === null || _a === void 0 ? void 0 : _a.conditions._not) || ((_b = user.shopConditions) === null || _b === void 0 ? void 0 : _b.conditions);
    const shopGroupEq = source === null || source === void 0 ? void 0 : source.shopGroupEq;
    const shopEq = source === null || source === void 0 ? void 0 : source.shopEq;
    const shopGroups = Array.isArray(shopGroupEq)
        ? shopGroupEq
        : [shopGroupEq].filter(defined);
    const shops = Array.isArray(shopEq) ? shopEq : [shopEq].filter(defined);
    const hasNo = !!((_c = user.shopConditions) === null || _c === void 0 ? void 0 : _c.conditions._not);
    if (!shopGroups.length && !shops.length)
        return _jsx(_Fragment, { children: t('Все') });
    const content = (_jsxs(_Fragment, { children: [shopGroups.length > 0 &&
                t('GROUPS_PLURAL', {
                    count: shopGroups.length,
                    amount: numberRound(shopGroups.length),
                }), shopGroups.length > 0 && shops.length > 0 && ', ', shops.length > 0 &&
                t('SHOPS_PLURAL', {
                    count: shops.length,
                    amount: numberRound(shops.length),
                })] }));
    if (hasNo)
        return (_jsxs(_Fragment, { children: [_jsx("div", { children: t('Все') }), _jsxs("div", { className: styles.description, children: [_jsx("span", { className: styles.danger, children: t('кроме') }), " ", content] })] }));
    return content;
};
