import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Lock } from '@/icons/Lock';
import styles from './styles.module.scss';
export const Tabs = memo((_a) => {
    var _b;
    var { className, tabClassName, tabs = [], activeTabId = (_b = tabs[0]) === null || _b === void 0 ? void 0 : _b.id, onChangeActiveTab, } = _a;
    const { t } = useTranslation();
    return (_jsx("ul", { className: classNames(styles.tabs, className), children: tabs.map((tab) => {
            const lockedLabel = (_jsxs(_Fragment, { children: [tab.label, _jsx(Tooltip, { title: _jsx("span", { className: styles.lockTooltip, children: t('Для настройки данного модуля обратитесь к менеджеру MAXMA') }), placement: 'right', children: _jsx("span", { className: styles.lockIcon, children: _jsx(Lock, {}) }) })] }));
            return (_jsxs("li", { className: classNames(styles.tab, {
                    [styles.interactive]: tabs.length > 1 && !!onChangeActiveTab && !tab.isLocked,
                    [styles.locked]: tab.isLocked,
                    [styles.active]: tab.id === activeTabId,
                }, tabClassName), onClick: () => {
                    if (onChangeActiveTab && !tab.isLocked)
                        onChangeActiveTab(tab.id);
                }, children: [tab.isLocked ? lockedLabel : tab.label, ' ', !!tab.count && !tab.isLocked && (_jsx("span", { className: styles.count, children: tab.count }))] }, tab.id));
        }) }));
});
