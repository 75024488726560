import { bonus } from './bonus';
import { clientUpdate } from './client-update';
import { loyalty } from './loyalty';
import { mailing } from './mailing';
import { purchase } from './purchase';
export const titleRenderer = (args) => {
    const { event } = args;
    switch (event.eventGroup) {
        case 'bonus': {
            return bonus(args);
        }
        case 'loyalty': {
            return loyalty(args);
        }
        case 'purchase': {
            return purchase(args);
        }
        case 'mailing': {
            return mailing(args);
        }
        case 'client_update': {
            return clientUpdate(args);
        }
        default: {
            return { title: '' };
        }
    }
};
