import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { TableDropdown } from '@/components/TableDropdown/TableDropdown';
import styles from './styles.module.scss';
export const TableRowActions = ({ row, onOpen, onClose, isSubCell = false, }) => {
    if (!row.actions)
        return null;
    const cellContent = (_jsx(TableDropdown, { items: row.actions, onOpen: () => onOpen(row.id), onClose: () => onClose(row.id) }));
    return (_jsx("td", { className: classNames(styles.actions, styles.right, row.actionsCellClassName, { [styles.subCell]: isSubCell }), children: isSubCell ? (_jsx("div", { children: _jsx("div", { children: cellContent }) })) : (cellContent) }));
};
