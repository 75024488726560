import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { Info } from '@/icons/Info';
import { Warning } from '@/icons/Warning';
import styles from './styles.module.scss';
export const FormFieldRow = memo(({ className, label, labelFor, labelWidth, labelClassName, controlClassName, controlSize, help, helpClassName, helpSize, tooltip, tooltipPlacement = 'top', tooltipPosition = 'label', isRequired = false, hasError = false, alignItems = 'start', asCheckbox = false, asErrorMessage = false, asInfoMessage = false, asModalFooter = false, hasLabelHidden = false, hasNoMargin = false, children, }) => {
    const labelStyle = labelWidth
        ? { '--label-width': `${labelWidth}%` }
        : undefined;
    const labelContent = (_jsxs(_Fragment, { children: [label, isRequired ? '*' : null] }));
    const labelElement = tooltipPosition === 'label' ? (_jsx(HelpTooltip, { title: tooltip, placement: tooltipPlacement, isSmall: true, children: labelContent })) : (labelContent);
    const inputElement = tooltipPosition === 'control' && tooltip ? (_jsxs("div", { className: styles.inputWrapper, children: [_jsx("div", { className: styles.input, children: children }), _jsx(HelpTooltip, { title: tooltip, placement: tooltipPlacement, hasLightIcon: true, isSmall: true })] })) : (_jsx("div", { className: styles.input, children: children }));
    if (asErrorMessage || asInfoMessage)
        return (_jsxs("div", { className: classNames(styles.wrapper, {
                [styles.errorMessage]: asErrorMessage,
                [styles.infoMessage]: asInfoMessage,
                [styles[`control-${controlSize}`]]: !!controlSize,
                [styles.noLabel]: hasLabelHidden,
                [styles.noMargin]: hasNoMargin,
                [styles.modalFooter]: asModalFooter,
            }, className), children: [_jsx("div", { className: styles.label, style: labelStyle }), _jsxs("div", { className: classNames(styles.control, controlClassName), children: [asErrorMessage ? _jsx(Warning, {}) : _jsx(Info, {}), children] })] }));
    return (_jsxs("div", { className: classNames(styles.wrapper, styles[alignItems], {
            [styles.error]: hasError,
            [styles[`control-${controlSize}`]]: !!controlSize,
            [styles[`help-${helpSize}`]]: !!helpSize,
            [styles.checkbox]: asCheckbox,
            [styles.noLabel]: hasLabelHidden,
            [styles.noMargin]: hasNoMargin,
        }, className), children: [_jsx("label", { className: classNames(styles.label, labelClassName), htmlFor: labelFor, style: labelStyle, children: labelElement }), _jsxs("div", { className: classNames(styles.control, controlClassName), children: [inputElement, !!help && (_jsx("div", { className: classNames(styles.help, helpClassName), children: help }))] })] }));
});
