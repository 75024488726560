import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@/components/Dropdown2/Dropdown';
import { ReportMetricModal } from '@/components/ReportMetricModal/ReportMetricModal';
import { SparklineChart } from '@/components/SparklineChart/SparklineChart';
import { largeNumberRound, numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
export const SparklineButton = memo(({ className, metric, value, data, currency, onClick, onChange, onDelete, }) => {
    const { t } = useTranslation();
    const [isOver, setIsOver] = useState(false);
    const handleOver = useCallback(() => setIsOver(true), []);
    const handleLeave = useCallback(() => setIsOver(false), []);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleOpenModal = useCallback(() => setModalIsOpen(true), []);
    const handleCloseModal = useCallback(() => {
        setModalIsOpen(false);
        setIsOver(false);
    }, []);
    const actions = useMemo(() => [
        {
            id: 'change',
            label: t `Изменить метрику`,
            onClick: handleOpenModal,
        },
        {
            id: 'delete',
            label: t `Удалить`,
            isDanger: true,
            onClick: onDelete,
        },
    ], [t, onDelete]);
    const handleChangeMetric = useCallback((newMetricId) => {
        onChange(newMetricId);
        handleCloseModal();
    }, [onChange]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.wrapper, onMouseEnter: handleOver, onMouseMove: handleOver, onMouseLeave: handleLeave, children: [_jsxs("button", { className: classNames(styles.button, className, {
                            [styles.over]: isOver,
                        }), type: 'button', onClick: onClick, children: [_jsx("div", { className: styles.title, style: { color: metric.valueColor }, children: metric.label }), _jsxs("div", { className: styles.value, style: { color: metric.valueColor }, children: [metric.precision
                                        ? numberRound(value, metric.precision)
                                        : largeNumberRound({
                                            value,
                                            million: t('млн'),
                                            thousand: t('тыс'),
                                            currency: metric.scale === 'money' ? currency : undefined,
                                        }), metric.scale === 'percent' && _jsx("span", { children: "%" })] }), _jsx(SparklineChart, { data: data, color: metric.valueColor || (isOver ? '#9b9b9b' : '#dfdfdf') })] }), isOver && (_jsx("div", { className: styles.actions, children: _jsx(Dropdown, { items: actions }) }))] }), _jsx(ReportMetricModal, { isOpen: modalIsOpen, onClose: handleCloseModal, value: metric.id, onApply: handleChangeMetric })] }));
});
