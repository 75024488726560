import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { numberRound } from '@/utils/numbers';
import styles from './styles.module.scss';
const FORECAST_DAYS_THRESHOLD = 15;
export const AppHeaderBalance = memo(({ balance, currency, forecastDays, forecastDaysThreshold = FORECAST_DAYS_THRESHOLD, onGoToBalance, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasBillingView = userHasAccessTo('BILLING_VIEW');
    const isEnoughForecastDays = forecastDays > forecastDaysThreshold;
    const balanceFormatted = `${numberRound(balance)} ${currency === 'руб' ? '₽' : currency}`;
    const handleGoToBalance = useCallback((e) => {
        if (!e.ctrlKey) {
            e.preventDefault();
            onGoToBalance();
        }
    }, [onGoToBalance]);
    return (_jsx(Tooltip, { title: userHasBillingView
            ? t('BALANCE_FORECAST_TOOLTIP', { days: forecastDays })
            : t('Недоступно для вашей роли'), children: _jsxs(Button, { className: classNames(styles.balanceButton, {
                [styles.notClicked]: !userHasBillingView,
            }), onClick: userHasBillingView ? handleGoToBalance : undefined, disabled: !userHasBillingView, theme: !isEnoughForecastDays ? 'danger' : 'default', isOutlined: isEnoughForecastDays, children: [t('Баланс:'), " ", balanceFormatted] }) }));
});
