import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WalletCardClickableElement } from '@/components/wallet-cards/WalletCardClickableElement/WalletCardClickableElement';
import { Email } from '@/icons/wallet-card/google/Email';
import { Globe } from '@/icons/wallet-card/google/Globe';
import { Phone } from '@/icons/wallet-card/google/Phone';
import styles from './styles.module.scss';
export const GoogleWalletCardContacts = ({ contacts = [], onElementClick, clicksDisabled = false }) => {
    if (!contacts.length)
        return null;
    return (_jsx("div", { className: styles.contactsWrapper, children: _jsx("div", { className: styles.contacts, children: contacts.map((contact, i) => {
                var _a, _b;
                if (!((_a = contact.title) === null || _a === void 0 ? void 0 : _a.trim()) && !((_b = contact.content) === null || _b === void 0 ? void 0 : _b.trim()))
                    return null;
                return (_jsxs(WalletCardClickableElement, { className: styles.contact, onClick: () => onElementClick === null || onElementClick === void 0 ? void 0 : onElementClick(`backside-contact-${i}-title`), disabled: clicksDisabled, children: [_jsxs("div", { className: styles.contactIcon, children: [!contact.type && _jsx(Globe, {}), contact.type === 'link' && _jsx(Globe, {}), contact.type === 'email' && _jsx(Email, {}), contact.type === 'phone' && _jsx(Phone, {})] }), _jsx("div", { className: styles.contactContent, children: contact.title || contact.content })] }, `${contact.content || 'contact'}-${i}`));
            }) }) }));
};
