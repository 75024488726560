import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import styles from './styles.module.scss';
export const FormFieldset = memo(({ title, titleOffset, titleClassName, fieldsetClassName, tooltip, tooltipClassName, description, children, }) => {
    const offsetStyle = titleOffset
        ? { '--offset': `${titleOffset}%` }
        : undefined;
    const titleContent = tooltip ? (_jsx(HelpTooltip, { className: tooltipClassName, title: tooltip, placement: 'right', children: title })) : (title);
    return (_jsxs("fieldset", { className: classNames(styles.fieldset, fieldsetClassName, {
            [styles.noTitle]: !titleContent,
        }), children: [!!titleContent && (_jsxs("legend", { className: classNames(styles.titleWrapper, {
                    [styles.withDescription]: !!description,
                }, titleClassName), children: [_jsx("div", { className: styles.title, style: offsetStyle, children: titleContent }), description && (_jsx("div", { className: styles.description, style: offsetStyle, children: description }))] })), children] }));
});
