import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { dataIsOrder } from './dataIsOrder';
import { OffersTab } from './OffersTab/OffersTab';
import { ReturnTab } from './ReturnTab/ReturnTab';
import { SpecificationsTab } from './SpecificationsTab/SpecificationsTab';
import { StructureTab } from './StructureTab/StructureTab';
import styles from './styles.module.scss';
var Tabs;
(function (Tabs) {
    Tabs["STRUCTURE"] = "structure";
    Tabs["SPECIFICATIONS"] = "specifications";
    Tabs["OFFERS"] = "offers";
    Tabs["RETURN"] = "return";
})(Tabs || (Tabs = {}));
export const FormPurchaseWithDetailsTabs = memo(({ isReturnFirst, isLoading, data, currency }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(isReturnFirst ? Tabs.RETURN : Tabs.STRUCTURE);
    const tabs = [
        { id: Tabs.STRUCTURE, label: t('Состав') },
        { id: Tabs.SPECIFICATIONS, label: t('Характеристики') },
        { id: Tabs.OFFERS, label: t('Акции') },
    ];
    if (!dataIsOrder(data) && (data === null || data === void 0 ? void 0 : data.returns.length))
        tabs.push({ id: Tabs.RETURN, label: t('Возврат') });
    if (!isLoading && !(data === null || data === void 0 ? void 0 : data.items.length))
        return null;
    return (_jsxs(TabsPanel, { isContentSkeleton: isLoading, headerClassName: styles.tabsHeader, className: styles.tabs, bodyClassName: styles.tabsBody, tabs: tabs, activeTabId: activeTab, onChangeActiveTab: (tabId) => setActiveTab(tabId), children: [activeTab === Tabs.STRUCTURE && (_jsx(StructureTab, { currency: currency, data: data })), activeTab === Tabs.OFFERS && (_jsx(OffersTab, { currency: currency, data: data })), activeTab === Tabs.SPECIFICATIONS && (_jsx(SpecificationsTab, { data: data, currency: currency })), !dataIsOrder(data) && activeTab === Tabs.RETURN && (_jsx(ReturnTab, { data: data, currency: currency }))] }));
});
