import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { ExternalLink } from '@/icons/nav/ExternalLink';
import styles from './styles.module.scss';
export const AppNavButton = memo(({ className, href, isActive = false, children, onClick, isDisabled = false, }) => {
    const isExternal = href === null || href === void 0 ? void 0 : href.includes('http');
    const classes = classNames(styles.button, {
        [styles.active]: !isDisabled && isActive,
        [styles.disabled]: isDisabled,
    }, className);
    const handleClick = useCallback((e) => {
        if (!e.ctrlKey && onClick) {
            e.preventDefault();
            onClick();
        }
    }, [onClick]);
    return href ? (_jsxs("a", { className: classes, href: href, target: isExternal ? '_blank' : undefined, rel: isExternal ? 'noreferrer' : undefined, onClick: handleClick, children: [children, isExternal && _jsx(ExternalLink, { className: styles.externalLinkIcon })] })) : (_jsx("button", { className: classes, onClick: handleClick, children: children }));
});
