import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { createHref, ROUTES } from '@/app/pages/routes';
import { useUserPermissions } from '@/app/useUserPermissions';
import { AccentLink } from '@/components/AccentText/AccentLink';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { BRAND_STATUS_ACTIVE, BRAND_STATUS_ARCHIVED, BRAND_STATUS_GETTING_READY, BRAND_STATUS_INTEGRATION, BRAND_STATUS_SUSPENDED, } from '@/const/brand';
import { money } from '@/utils/numbers';
import { ErrorTriangle } from '~/src/icons/status/ErrorTriangle';
import { useAppHeaderState } from '../AppHeader/useAppHeaderState';
import { useSidebarState } from '../AppSidebar/useSidebarState';
import styles from './styles.module.scss';
import { TopAlertPopup } from './TopAlertPopup';
import { TopAlertPostponedPaymentButton } from './TopAlertPostponedPaymentButton';
export const TopAlert = ({ brand, balance, onGoToBalance, onPostponePayment, paymentPostponedUntil: paymentPostponedUntilProvided, forecastDaysThreshold = 7, }) => {
    const { t } = useTranslation();
    const { isCollapsed, isHidden } = useSidebarState();
    const { hidden } = useAppHeaderState();
    const userHasAccessTo = useUserPermissions();
    const userHasBillingView = userHasAccessTo(['BILLING_VIEW']);
    const userHasBillingManage = userHasAccessTo(['BILLING_INVOICES_MANAGE']);
    const balanceLink = userHasBillingView
        ? brand.settings.inn && userHasBillingManage
            ? createHref(ROUTES.BILLING_INVOICES)
            : createHref(ROUTES.BILLING)
        : undefined;
    const postponedUntil = brand.paymentPostponedUntil || paymentPostponedUntilProvided;
    const postponedUntilFormatted = postponedUntil && moment(postponedUntil).isAfter(moment())
        ? moment(postponedUntil).format('DD.MM.YYYY HH:mm')
        : null;
    const postponedPaymentIsActive = !!postponedUntilFormatted;
    const handleGoToBalance = useCallback((e) => {
        if (!e.ctrlKey) {
            e.preventDefault();
            onGoToBalance();
        }
    }, [onGoToBalance]);
    const goToBalanceLink = (_jsx(Tooltip, { title: !userHasBillingView && t('Недоступно для вашей роли'), placement: 'bottom', children: _jsx("span", { children: _jsx(AccentLink, { accent: 'danger', href: balanceLink, onClick: userHasBillingView ? handleGoToBalance : undefined, className: classNames({
                    [styles.disabledLink]: !userHasBillingView,
                }), children: postponedPaymentIsActive
                    ? t('Пополните баланс до {{date}}', {
                        date: postponedUntilFormatted,
                    })
                    : t('К пополнению баланса') }) }) }));
    let content = null;
    if (brand.status === BRAND_STATUS_INTEGRATION ||
        brand.status === BRAND_STATUS_GETTING_READY)
        content = t('Сервис на этапе интеграции. Оплата лицензии не списывается, рассылки не срабатывают.');
    if (brand.status == BRAND_STATUS_ACTIVE &&
        brand.balance <= brand.balanceThreshold) {
        const autoSuspend = brand.settings.autoSuspendBonuses ||
            brand.settings.autoSuspendMailings ||
            brand.settings.autoSuspendTools;
        content = (_jsxs("div", { className: styles.content, children: [_jsxs("div", { children: [_jsxs("span", { children: [autoSuspend
                                    ? t('Работа сервиса приостановлена')
                                    : t('Работа сервиса может быть приостановлена'), _jsx(TopAlertPopup, { brand: brand })] }), ' ', userHasBillingView && _jsx("span", { children: goToBalanceLink })] }), !postponedUntil && (_jsx(TopAlertPostponedPaymentButton, { brand: brand, onClick: onPostponePayment }))] }));
    }
    if (brand.status === BRAND_STATUS_ACTIVE &&
        brand.balance > brand.balanceThreshold &&
        balance.forecastDays <= forecastDaysThreshold)
        content = (_jsxs(_Fragment, { children: [_jsxs("span", { children: [t('BALANCE_FORECAST_ALERT', {
                            currentBalance: money({
                                input: brand.balance,
                                currency: brand.currency.unitShort,
                                withFraction: true,
                            }),
                            days: balance.forecastDays,
                        }), "."] }), userHasBillingView && _jsxs("span", { children: [" ", goToBalanceLink] })] }));
    if (brand.status == BRAND_STATUS_SUSPENDED ||
        brand.status == BRAND_STATUS_ARCHIVED)
        content = (_jsxs(_Fragment, { children: [_jsx("span", { children: postponedPaymentIsActive
                        ? t('Работа сервиса активирована с опцией Обещанный платеж.')
                        : t('Работа сервиса приостановлена') + '.' }), ' ', userHasBillingView && _jsx("span", { children: goToBalanceLink })] }));
    return !hidden && content ? (_jsxs("div", { className: classNames(styles.alert, {
            [styles.collapsed]: isCollapsed,
            [styles.hidden]: isHidden,
        }), children: [_jsx(ErrorTriangle, {}), content] })) : null;
};
