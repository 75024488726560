import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { EmailNotClicked } from '@/icons/flow/EmailNotClicked';
import { EmailNotOpened } from '@/icons/flow/EmailNotOpened';
import { EmailNotPurchased } from '@/icons/flow/EmailNotPurchased';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
export const EmailReceivedFormIcon = () => {
    const { selectedNode } = useInteractiveFlow();
    const selectedNodeData = selectedNode.getData();
    let icon = _jsx(_Fragment, {});
    switch (selectedNodeData === null || selectedNodeData === void 0 ? void 0 : selectedNodeData.type) {
        case 'opened': {
            icon = _jsx(EmailNotOpened, {});
            break;
        }
        case 'clicked': {
            icon = _jsx(EmailNotClicked, {});
            break;
        }
        case 'purchased': {
            icon = _jsx(EmailNotPurchased, {});
            break;
        }
    }
    return _jsx("div", { className: styles.icon, children: icon });
};
