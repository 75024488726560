import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { StepButtons } from '@/components/StepButtons/StepButtons';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import { FormMailingActiveToggle } from './controls/FormMailingActiveToggle';
import { goToStepWithError } from './helpers/goToStepWithError';
import styles from './styles.module.scss';
export const FormMailingFooter = ({ type, isEdit, isSubmitting, isFirstStep, isLastStep, isReadOnly, onGoToStep, onGoNext, onGoPrev, onDelete, onSaveDraft, setActive, }) => {
    const { t } = useTranslation();
    const userHasAccessTo = useUserPermissions();
    const userHasMailingManage = userHasAccessTo([
        'MANUAL_MAILINGS_MANAGE',
        'AUTO_MAILINGS_MANAGE',
    ]);
    const { getValues, watch, formState, setFocus } = useFormContext();
    const schedule = watch('schedule');
    const deleteButton = (_jsx(TrashButton, { onClick: onDelete, tooltip: userHasMailingManage
            ? t('Удалить рассылку')
            : t('Недоступно для вашей роли'), disabled: !userHasMailingManage }));
    const toggleButton = (_jsx(Tooltip, { title: !userHasMailingManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx("div", { children: _jsx(FormMailingActiveToggle, { disabled: !userHasMailingManage, setActive: setActive }) }) }));
    const saveDraftButton = (_jsx(Button, { type: 'button', onClick: () => {
            const values = getValues();
            onSaveDraft === null || onSaveDraft === void 0 ? void 0 : onSaveDraft(values);
        }, isOutlined: true, children: t('Сохранить черновик') }));
    const submitButton = (_jsx(Tooltip, { title: !userHasMailingManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { type: 'submit', theme: 'primary', disabled: !userHasMailingManage || isSubmitting, size: 'large', onClick: () => {
                if (!onGoToStep)
                    return;
                setTimeout(() => {
                    goToStepWithError(formState, onGoToStep, setFocus);
                }, 1);
            }, children: type === 'auto'
                ? t('Сохранить')
                : schedule === '1'
                    ? t('Запланировать')
                    : t('Отправить') }) }));
    const hasNextButton = (isFirstStep && !isLastStep) || (!isFirstStep && !isLastStep);
    const hasPrevButton = (!isFirstStep && isLastStep) || (!isFirstStep && !isLastStep);
    return (_jsxs("div", { className: styles.actions, children: [_jsxs("div", { className: styles.toolbar, children: [!isReadOnly && isEdit && deleteButton, !isReadOnly && type === 'auto' && toggleButton, !isReadOnly && type === 'manual' && saveDraftButton] }), _jsx(StepButtons, { hasNextButton: hasNextButton, onGoNext: onGoNext, hasPrevButton: hasPrevButton, onGoPrev: onGoPrev, doneButton: !isReadOnly && isLastStep ? submitButton : null })] }));
};
