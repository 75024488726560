import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { GearsAlt } from '@/icons/GearsAlt';
import styles from '../../styles.module.scss';
import { getCounterContent } from './counterContent';
// истек срок счетчика
export const purchaseExpired = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleEntityLink } = entityHandlers;
    const { offerId, offerName: offerNameDeprecated, offerTitle, offerDeleted, } = event.params || {};
    const link = !offerDeleted ? handleEntityLink('offer', offerId) : '';
    const offerName = offerTitle || offerNameDeprecated;
    return {
        color: 'gray',
        Icon: _jsx(GearsAlt, {}),
        title: (_jsxs(_Fragment, { children: [t('Истек срок счетчика'), "\u00A0", link ? (_jsx(Link, { className: styles.underline, to: link, target: '_blank', children: offerName })) : (_jsx(_Fragment, { children: offerName ? _jsx("span", { children: `"${offerName}"` }) : '' }))] })),
        content: getCounterContent(args),
    };
};
