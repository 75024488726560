import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { GearsAlt } from '@/icons/GearsAlt';
import styles from '../../styles.module.scss';
import { getCounterContent } from './counterContent';
// изменение счетчика
export const purchaseChanged = (args) => {
    const { event, entityHandlers, translate: t } = args;
    const { handleEntityLink } = entityHandlers;
    const { incrementedCounter = 0, incrementedAndUsedCounter = 0, usedCounter = 0, incrementedCoupon = 0, incrementedAndUsedCoupon = 0, usedCoupon = 0, offerId, offerName: offerNameDeprecated, offerTitle, offerDeleted, } = event.params || {};
    const link = !offerDeleted ? handleEntityLink('offer', offerId) : '';
    const offerName = offerTitle || offerNameDeprecated;
    const changeValue = Number(incrementedCounter) +
        Number(incrementedAndUsedCounter) -
        Number(usedCounter);
    const formattedChangeValue = changeValue > 0 ? '+' + changeValue : changeValue;
    const rewards = Number(incrementedCoupon) +
        Number(incrementedAndUsedCoupon) -
        Number(usedCoupon);
    const formattedRewards = rewards > 0 ? '+' + rewards : rewards;
    const rewardsInCounter = rewards !== 0
        ? t('ADD_REWARDS', { count: rewards, rewards: formattedRewards })
        : null;
    return {
        color: changeValue > 0 ? 'green' : changeValue < 0 ? 'red' : 'gray',
        Icon: _jsx(GearsAlt, { fill: 'currentColor' }),
        title: (_jsxs(_Fragment, { children: [changeValue !== 0
                    ? `${formattedChangeValue} ${t('в счетчике')}`
                    : t('Изменение в счетчике'), link ? (_jsxs(_Fragment, { children: ["\u00A0", _jsx(Link, { className: styles.underline, to: link, target: '_blank', children: offerName })] })) : (_jsx(_Fragment, { children: offerName ? _jsxs("span", { children: ["\u00A0", `"${offerName}"`] }) : '' })), rewardsInCounter ? `, ${rewardsInCounter}` : null] })),
        content: getCounterContent(args),
    };
};
