var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetLastBreadcrumb } from '@/app/layout/AppHeader/Breadcrumbs/BreadcrumbsContext';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Switch } from '@/components/Switch/Switch';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TrashButton } from '@/components/TrashButton/TrashButton';
import { useNotificateForUnsavedForm } from '@/hooks/useNotificateForUnsavedForm';
import { useArbitrationList } from '~/src/app/pages/Arbitration/useArbitrationList';
import { FormArbitrationPriorityLayout } from '~/src/components/arbitration/FormArbitrationPriority/FormArbitrationPriorityLayout';
import { scrollToError } from '~/src/components/arbitration/FormArbitrationPriority/utils';
import { DISCOUNT_TYPE_FIXED, DISCOUNT_TYPE_PERCENT, } from '../FormOffer/constants';
import { FormPromocodeTabs } from './FormPromocodeTabs';
import styles from './styles.module.scss';
const labelWidth = 32.52;
export const FormPromocode = memo(({ onSubmit, error, isSubmitting = false, defaultValues, currency, isEdit, onDelete, isFriend = false, bonusesPendingInterval = 0, setActive, }) => {
    const { t } = useTranslation();
    const [isToggleDisabled, setIsToggleDisabled] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasPromocodesManage = userHasAccessTo('PROMOCODES_MANAGE');
    const methods = useForm({
        mode: 'onChange',
        defaultValues: Object.assign({ codeType: isFriend ? 'friend' : 'shared', onPurchase: isFriend ? 1 : 0, discountType: DISCOUNT_TYPE_PERCENT, conditions: [], allowedCategories: [], rewardType: 0, rewardOn: 0, rewardNotification: true, isActive: true, allowBonusCollect: !!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.allowBonusCollect), allowBonusApply: !!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.allowBonusApply), allowBonusApplyNotMore: !!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.allowBonusApplyNotMore), bonusApplyMaxType: (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.bonusApplyMaxType) || DISCOUNT_TYPE_FIXED, bonusApplyMaxAmount: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.bonusApplyMaxAmount, arbitrationGroupGuid: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.arbitrationGroupGuid, arbitrationPriorityAfter: defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.arbitrationPriority, arbitrationPriorityAfterError: false, type: (defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.type) || 'bonus' }, defaultValues),
    });
    const { handleSubmit, formState: { isSubmitted, dirtyFields, isSubmitting: isSubmittingForm, errors, }, setValue, trigger, watch, } = methods;
    const { data } = useArbitrationList();
    const priority = (data === null || data === void 0 ? void 0 : data.priority.length) ? data.priority : [];
    useNotificateForUnsavedForm(!!Object.keys(dirtyFields).length && !isSubmittingForm && !isSubmitting);
    const [activeTab, setActiveTab] = useState('general');
    const errorFieldsList = Object.keys(errors);
    useEffect(() => {
        if (errorFieldsList.length > 0)
            scrollToError({
                errorFieldsList,
            });
    }, [activeTab, error]);
    const discountType = watch('discountType');
    const rewardType = watch('rewardType');
    const isActive = watch('isActive');
    const title = watch('code') || t('Без названия');
    useEffect(() => {
        if (isSubmitted)
            trigger('discountAmount');
    }, [isSubmitted, discountType]);
    useEffect(() => {
        if (isSubmitted)
            trigger('rewardAmount');
    }, [isSubmitted, rewardType]);
    useGetLastBreadcrumb(title);
    const handleFormSubmit = () => {
        if (activeTab === 'general') {
            setActiveTab('priority');
            trigger();
        }
        if (activeTab === 'priority')
            trigger().then((isValidForm) => {
                if (isValidForm)
                    handleSubmit(onSubmit)();
                else
                    setActiveTab('general');
            });
    };
    return (_jsxs(FormArbitrationPriorityLayout, { activeTab: activeTab, setActiveTab: setActiveTab, title: title, methods: methods, children: [_jsx(FormPromocodeTabs, { title: title, priority: priority, activeTab: activeTab, currency: currency, isFriend: isFriend, bonusesPendingInterval: bonusesPendingInterval, setActive: setActive }), error && (_jsx(FormFieldRow, { className: classNames(styles.message, styles.error), labelWidth: labelWidth, asErrorMessage: true, children: error })), _jsxs("div", { className: styles.actions, children: [_jsxs("div", { className: styles.toolbar, children: [isEdit && (_jsx(TrashButton, { disabled: !userHasPromocodesManage, tooltip: userHasPromocodesManage
                                    ? t('Удалить промокод')
                                    : t('Недоступно для вашей роли'), onClick: onDelete })), _jsx(Tooltip, { title: !userHasPromocodesManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx("div", { children: _jsx(Switch, { label: isActive ? t('Включен') : t('Остановлен'), checked: isActive, onChange: (checked) => __awaiter(void 0, void 0, void 0, function* () {
                                            let ok = true;
                                            if (setActive) {
                                                setIsToggleDisabled(true);
                                                ok = yield setActive(checked);
                                                setIsToggleDisabled(false);
                                            }
                                            if (ok)
                                                setValue('isActive', checked);
                                        }), disabled: !userHasPromocodesManage || isToggleDisabled }) }) })] }), _jsx(Tooltip, { title: !userHasPromocodesManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { type: 'button', onClick: handleFormSubmit, theme: 'primary', disabled: !userHasPromocodesManage || isSubmitting, size: 'large', children: activeTab === 'general'
                                ? t('Далее')
                                : isEdit
                                    ? t('Сохранить изменения')
                                    : t('Создать промокод') }) })] })] }));
});
