import { jsx as _jsx } from "react/jsx-runtime";
import moment from 'moment';
import { money } from '@/utils/numbers';
import { AccordionContent } from '../AccordionContent';
import styles from '../styles.module.scss';
export const tableRows = (currency, purchase) => {
    var _a;
    if (purchase)
        return (_a = purchase.returns) === null || _a === void 0 ? void 0 : _a.map((item) => {
            var _a;
            const executedAtDate = moment(item.executedAt).format('DD.MM.YYYY');
            return {
                id: item.id,
                cells: [
                    {
                        title: executedAtDate || '',
                        className: styles.date,
                    },
                    {
                        title: (_jsx("div", { className: styles.productTitle, children: _jsx("span", { children: ((_a = item.shop) === null || _a === void 0 ? void 0 : _a.name) || '' }) })),
                        content: (_jsx(AccordionContent, { offersList: item.items, currency: currency })),
                    },
                    {
                        title: item.cashier || '',
                        className: styles.discountCell,
                    },
                    {
                        title: money({
                            input: String(item.refundAmount),
                            withFraction: true,
                            removeFractionZeros: true,
                            currency,
                        }),
                    },
                    {
                        title: item.bonusesCancelled || '',
                        className: styles.minusBonusCell,
                    },
                    {
                        title: item.bonusesCancelled || '',
                        className: styles.bonusCell,
                    },
                ],
            };
        });
    return [];
};
