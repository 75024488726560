import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BonusMovement } from '@/icons/BonusMovement';
import styles from '../../styles.module.scss';
import { getBonusContent } from './bonusContent';
import { BonusTitle } from './bonusTitle';
// компенсированы списанные бонусы в связи с возвратом
export const bonus6 = (args) => {
    const { event, translate: t, entityHandlers } = args;
    const { handleReturn } = entityHandlers;
    const { amount, purchaseId, purchaseDeleted } = event.params || {};
    const hasPurchaseLink = !!purchaseId && !purchaseDeleted && !!handleReturn;
    return {
        color: 'green',
        Icon: _jsx(BonusMovement, { className: styles.rotateIcon, fill: '#499E5C' }),
        title: (_jsxs(BonusTitle, Object.assign({}, args, { includeDates: true, children: [amount
                    ? t('RECOVERED_BONUSES_SPENT_ON_PURCHASE_IN_CASE_OF_GOODS_RETURNING', {
                        count: amount,
                    })
                    : t('Начисление бонусов с'), "\u00A0", _jsx("span", { className: hasPurchaseLink ? styles.underline : '', onClick: () => {
                        if (hasPurchaseLink)
                            handleReturn === null || handleReturn === void 0 ? void 0 : handleReturn(purchaseId);
                    }, children: t('возврата товаров') })] }))),
        content: getBonusContent(Object.assign(Object.assign({}, args), { hiddenParams: ['purchaseReturn', 'availableAt', 'expiredAt', 'issuedAt'] })),
    };
};
