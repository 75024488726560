import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useCallback, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Popup } from '@/components/Popup/Popup';
import { RadioGroup } from '@/components/RadioGroup2/RadioGroup';
import { Chevron } from '@/icons/Chevron';
import { FormCounterPeriod } from './FormCounterPeriod';
import styles from './styles.module.scss';
export const FormCounterPeriodType = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const triggerRef = useRef(null);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const periodType = watch('periodType');
    const periodValue = watch('periodValue') || '0';
    const periodUnit = watch('periodUnit');
    const periodAnyLabel = t('неограниченный период');
    const periodValueLabel = periodUnit === 'month'
        ? t('IN_MONTHS', { count: Number(periodValue) })
        : t('IN_DAYS', { count: Number(periodValue) });
    const handleTogglePopup = useCallback(() => {
        setPopupIsOpen((popupIsOpen) => !popupIsOpen);
    }, []);
    const handleClosePopup = useCallback(() => {
        if (periodType === 'period' && (!periodValue || !Number(periodValue)))
            return;
        setPopupIsOpen(false);
    }, [periodType, periodValue]);
    const periodTypeOptions = [
        {
            value: 'period',
            label: _jsx(FormCounterPeriod, {}),
        },
        {
            value: 'any',
            label: t('неограниченный период'),
        },
    ];
    return (_jsxs(FormFieldRow, { className: styles.row, label: '', labelFor: 'periodType', labelWidth: labelWidth, labelClassName: styles.label, children: [_jsxs("span", { className: styles.periodPopupTrigger, ref: triggerRef, onClick: handleTogglePopup, children: [t('COUNTER_PERIOD_IN'), _jsx("span", { className: styles.periodLabel, children: periodType === 'any'
                            ? periodAnyLabel
                            : `${periodValue} ${periodValueLabel}` }), _jsx(Chevron, { className: classNames(styles.chevron, {
                            [styles.flipped]: popupIsOpen,
                        }) })] }), _jsx(Popup, { isOpen: popupIsOpen, anchorEl: triggerRef === null || triggerRef === void 0 ? void 0 : triggerRef.current, onClose: handleClosePopup, placement: 'bottom-start', offset: [0, 10], isRounded: true, disablePortal: true, children: _jsx(Controller, { name: 'periodType', control: control, render: ({ field }) => (_jsx("div", { className: styles.periodPopup, children: _jsx(RadioGroup, Object.assign({}, field, { id: 'periodType', value: field.value, onChange: (value) => setValue('periodType', value), items: periodTypeOptions, align: 'vertical', gap: 'medium' })) })) }) })] }));
};
