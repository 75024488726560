import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { Cancelled } from '@/icons/status/Cancelled';
import { Done } from '@/icons/status/Done';
import { formatDateShort } from '@/utils/dates';
import styles from '../../styles.module.scss';
export const getClientUpdateContent = ({ event, translate: t, hiddenParams = [], }) => {
    const params = event.params || {};
    const operatorName = params.operatorName;
    const comment = params.comment;
    const changes = (params.changes || []);
    // Top Content ---------------------------------------------------------------
    const topItems = [];
    if (operatorName && !hiddenParams.includes('operator'))
        topItems.push(_jsxs("li", { className: styles.contentItem, children: [t('Сотрудник'), _jsx("span", { className: styles.contentValue, children: operatorName })] }, 'operator'));
    if (comment && !hiddenParams.includes('comment'))
        topItems.push(_jsxs("li", { className: classNames(styles.contentItem, styles.column), children: [t('Комментарий'), _jsx("span", { className: styles.contentValue, children: comment })] }, 'comment'));
    // Changes -------------------------------------------------------------------
    const changeElements = changes.map((change) => {
        const field = change.field;
        return (_jsxs("li", { className: classNames(styles.changeItem, {
                [styles.changeItemWithOldValue]: !!change.old,
            }), children: [_jsx("span", { children: FIELD_MAP[field] ? t(FIELD_MAP[field]) : field }), _jsx("div", { className: styles.changeItemLine }), renderFieldChangeValue(change, t)] }, field));
    });
    const changeContent = changes.length && !hiddenParams.includes('changes') ? (_jsx("ul", { className: styles.changeItems, children: changeElements })) : null;
    // ---------------------------------------------------------------------------
    if (!topItems.length && !changeContent)
        return undefined;
    return (_jsx("div", { children: _jsxs("section", { className: classNames(styles.expandedContent, styles.expandedContentGroup, styles.clientUpdateContainer), children: [!!topItems.length && _jsx("ul", { children: topItems }), changeContent] }) }));
};
const FIELD_MAP = {
    name: 'Имя',
    surname: 'Фамилия',
    patronymic: 'Отчество',
    gender: 'Пол',
    phoneNumber: 'Телефон',
    email: 'Email',
    birthdate: 'День рождения',
    childrenNum: 'Дети',
    card: 'Карта',
    level: 'Уровень',
    isEmailSubscribed: 'Подписки (Email)',
    isPhoneSubscribed: 'Подписки (SMS, Push и Viber)',
    isChatSubscribed: 'Подписки (Чат-боты)',
};
const renderFieldChangeValue = (change, t) => {
    if (change.field === 'gender')
        return (_jsx("div", { className: styles.changeValues, children: _jsx("span", { className: styles.changeValueNew, children: change.new === 1 ? t('мужской') : t('женский') }) }));
    if (change.field === 'birthdate' && typeof change.new === 'string')
        return (_jsxs("div", { className: styles.changeValues, children: [_jsx("span", { className: styles.changeValueNew, children: formatDateShort(change.new) }), !!change.old && typeof change.old === 'string' && (_jsx("span", { className: styles.changeValueOld, children: formatDateShort(change.old) }))] }));
    if (change.field === 'level' || change.field === 'childrenNum')
        return (_jsxs("div", { className: classNames(styles.changeValues, styles.row), children: [_jsx("span", { className: styles.changeValueNew, children: change.new }), !!change.old && (_jsx("span", { className: styles.changeValueOld, children: change.old }))] }));
    if (typeof change.new === 'boolean')
        return (_jsx("div", { className: styles.changeValues, children: _jsx("span", { className: styles.changeValueNew, children: change.new ? _jsx(Done, { type: 'single' }) : _jsx(Cancelled, {}) }) }));
    return (_jsxs("div", { className: styles.changeValues, children: [_jsx("span", { className: styles.changeValueNew, children: change.new }), !!change.old && (_jsx("span", { className: styles.changeValueOld, children: change.old }))] }));
};
