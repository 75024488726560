import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@/components/Autocomplete/Autocomplete';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { Input } from '@/components/Input/Input';
import { LIMIT_PERIOD_DAY, LIMIT_PERIOD_OFFER, LIMIT_PERIOD_WEEK, LIMIT_TYPE_NO_LIMIT, LIMIT_TYPE_WITH_LIMIT, } from '../constants';
import styles from '../styles.module.scss';
export const FormOfferLimit = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, formState, setValue, watch } = useFormContext();
    const { errors } = formState;
    const limitTypeOptions = [
        { value: LIMIT_TYPE_NO_LIMIT, label: t('неограниченное количество раз') },
        { value: LIMIT_TYPE_WITH_LIMIT, label: t('не более') },
    ];
    const limitPeriodOptions = [
        { value: LIMIT_PERIOD_DAY, label: t('день') },
        { value: LIMIT_PERIOD_WEEK, label: t('неделю') },
        { value: LIMIT_PERIOD_OFFER, label: t('акцию') },
    ];
    const limitType = watch('limitType');
    const limitMax = watch('limitMax');
    const offerHasLimit = limitType === 1;
    return (_jsx(FormFieldRow, { label: t('Клиент участвует'), labelFor: 'limitType', labelWidth: labelWidth, isRequired: offerHasLimit, children: _jsxs("div", { className: styles.limitRow, children: [_jsx(Controller, { name: 'limitType', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'limitType', value: limitTypeOptions.find((item) => item.value === field.value) ||
                            null, onChange: (limitTypeType) => {
                            setValue('limitType', limitTypeType === null || limitTypeType === void 0 ? void 0 : limitTypeType.value, {
                                shouldDirty: true,
                            });
                        }, options: limitTypeOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, minListWidth: 264 }))) }), offerHasLimit && (_jsx(Controller, { name: 'limitMax', control: control, rules: {
                        required: {
                            value: true,
                            message: t('FIELD_REQUIRED_ERROR'),
                        },
                        min: {
                            value: 1,
                            message: t('MIN_ERROR_MESSAGE', {
                                amount: 1,
                            }),
                        },
                    }, render: ({ field }) => (_jsx("div", { className: styles.limitMaxInput, children: _jsx(Input, Object.assign({}, field, { id: 'limitMax', type: 'number', inputMode: 'numeric', step: 'any', hasError: !!errors.limitMax, preventChars: ['+'], preventNegativeNumbers: true, shouldHideSpinButtons: true })) })) })), offerHasLimit && (_jsx("span", { className: styles.limitTimes, children: t('NO_MORE_TIMES', { count: Number(limitMax) || 0 }) })), offerHasLimit && (_jsx(Controller, { name: 'limitPeriod', control: control, render: ({ field }) => (_jsx(Autocomplete, Object.assign({}, field, { id: 'limitPeriod', value: limitPeriodOptions.find((item) => item.value === field.value) || null, onChange: (limitPeriod) => {
                            setValue('limitPeriod', limitPeriod === null || limitPeriod === void 0 ? void 0 : limitPeriod.value);
                        }, options: limitPeriodOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, hasResetButton: false, asSelect: true, minListWidth: 113 }))) }))] }) }));
};
