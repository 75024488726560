import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { Button } from '@/components/Button2/Button';
import { HelpTooltip } from '@/components/HelpTooltip2/HelpTooltip';
import { MailingRevenueProfitHelp } from '@/components/MailingRevenueProfitHelp/MailingRevenueProfitHelp';
import { MailingStatsModal } from '@/components/MailingStatsModal/MailingStatsModal';
import { Error } from '@/icons/status/Error';
import { largeNumberRound, numberRound } from '@/utils/numbers';
import { roi } from '@/utils/roi';
import { useInteractiveFlow } from '../../context/useInteractiveFlow';
import styles from './styles.module.scss';
import { useMailingStatsModal } from './useMailingStatsModal';
export const ChannelFromStats = () => {
    var _a;
    const { t } = useTranslation();
    const { triggerType, selectedNode, isEditMode, stats } = useInteractiveFlow();
    const { currency, shouldDisplayProfit } = useCurrentUser();
    const thisNodeData = selectedNode.getData();
    const thisNodeStats = stats === null || stats === void 0 ? void 0 : stats[selectedNode.uuid];
    const mailingStatsModal = useMailingStatsModal();
    if (!thisNodeStats || !thisNodeData || isEditMode)
        return null;
    const profitOrRevenue = shouldDisplayProfit
        ? thisNodeStats.profit
        : thisNodeStats.revenue;
    const profitOrRevenueFormatted = largeNumberRound({
        value: Number(profitOrRevenue),
        currency,
    });
    const profitOrRevenueLabel = shouldDisplayProfit ? t('Прибыль') : t('Выручка');
    const roiValue = numberRound(roi(profitOrRevenue, thisNodeStats.expenses)) + '%';
    const failed = Number(thisNodeStats.failed || 0);
    const failedFormatted = failed > 0 ? numberRound(failed) : '';
    return (_jsxs("div", { className: styles.statsWrapper, children: [_jsxs("div", { className: styles.statsLine, children: [_jsxs("div", { className: styles.statsItem, children: [_jsx("div", { className: styles.statsTitle, children: _jsx(HelpTooltip, { title: _jsx(MailingRevenueProfitHelp, {}), placement: 'top', children: profitOrRevenueLabel }) }), _jsx("div", { className: styles.statsValue, children: profitOrRevenueFormatted })] }), _jsxs("div", { className: styles.statsItem, children: [_jsx("div", { className: styles.statsTitle, children: "ROI" }), _jsx("div", { className: styles.statsValue, children: roiValue })] }), _jsxs("div", { className: styles.statsItem, children: [_jsx("div", { className: styles.statsTitle, children: t('Ошибки') }), _jsx("div", { className: classNames(styles.statsValue, {
                                    [styles.statsValueError]: !!failedFormatted,
                                }), children: failedFormatted ? (_jsxs(_Fragment, { children: [_jsx(Error, {}), failedFormatted] })) : ('–') })] })] }), _jsx(Button, { type: 'button', size: 'full-width', onClick: () => {
                    mailingStatsModal.openModal(thisNodeStats.mailingId);
                }, disabled: mailingStatsModal.isLoading, children: t('Подробнее') }), !!mailingStatsModal.mailingStats && (_jsx(MailingStatsModal, { isOpen: mailingStatsModal.isOpen, onClose: mailingStatsModal.closeModal, periodStart: mailingStatsModal.periodStart, periodEnd: mailingStatsModal.periodEnd, onChangePeriod: mailingStatsModal.changePeriod(thisNodeStats.mailingId), mailing: {
                    id: thisNodeStats.mailingId,
                    name: thisNodeData.name || '',
                    triggerType,
                    goalType: ((_a = thisNodeData.goal) === null || _a === void 0 ? void 0 : _a.goalType) || 0,
                }, stats: mailingStatsModal.mailingStats, isManual: false, currency: currency, isLoading: mailingStatsModal.isLoading }))] }));
};
