import { createSlice } from '@reduxjs/toolkit';
const modals = createSlice({
    name: 'modals',
    initialState: {
        clientModal: {
            isOpen: false,
            activeTab: 'overview',
        },
        addClientModal: {
            isOpen: false,
        },
        purchaseModal: {
            isOpen: false,
            purchaseList: null,
            currentPurchaseIndex: null,
            isReturnFirst: false,
        },
        addPurchaseModal: {
            isOpen: false,
        },
        orderModal: {
            isOpen: false,
            orderList: null,
            currentOrderIndex: null,
        },
    },
    reducers: {
        updateClientModal: (state, action) => {
            state.clientModal = Object.assign(Object.assign({}, state.clientModal), action.payload);
        },
        updateAddClientModal: (state, action) => {
            state.addClientModal = Object.assign(Object.assign({}, state.addClientModal), action.payload);
        },
        updatePurchaseModal: (state, action) => {
            state.purchaseModal = Object.assign(Object.assign({}, state.purchaseModal), action.payload);
        },
        updateAddPurchaseModal: (state, action) => {
            state.addPurchaseModal = Object.assign(Object.assign({}, state.addPurchaseModal), action.payload);
        },
        updateOrderModal: (state, action) => {
            state.orderModal = Object.assign(Object.assign({}, state.orderModal), action.payload);
        },
    },
});
export default modals.reducer;
export const { updateClientModal, updateAddClientModal, updatePurchaseModal, updateAddPurchaseModal, updateOrderModal, } = modals.actions;
