import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { ROUTES } from '@/app/pages/routes';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Container } from '@/components/Container/Container';
import { DatePeriodSelectContainer } from '@/components/DatePeriodSelect/DatePeriodSelectContainer';
import { FiltersButton } from '@/components/FiltersPanel/FiltersButton';
import { FiltersPanel } from '@/components/FiltersPanel/FiltersPanel';
import { SearchPanel } from '@/components/SearchPanel/SearchPanel';
import { PROMOCODE_TYPE_FRIEND } from '@/const/promocode';
import { useClientModal } from '@/modals/ClientEditModal/useClientModal';
import { useOrderModal } from '@/modals/OrderModal/useOrderModal';
import { usePurchaseModal } from '@/modals/PurchaseEditModal/usePurchaseModal';
import { useClientEventFilters } from '@/services/filters/data/useClientEventFilters';
import { useFiltersState } from '../Clients/useFiltersState';
import { EventsPanel } from './EventsPanel';
import styles from './styles.module.scss';
import { useData } from './useData';
export const createHandleEntityLink = (userHasAccessTo) => (entityType, entityId, mailingType, promocodeType) => {
    // Ссылка на авто-рассылку, но нет прав
    if (entityType === 'mailing' &&
        mailingType === 0 &&
        !userHasAccessTo('AUTO_MAILINGS_MANAGE'))
        return;
    // Ссылка на ручную рассылку, но нет прав
    if (entityType === 'mailing' &&
        mailingType === 1 &&
        !userHasAccessTo('MANUAL_MAILINGS_MANAGE'))
        return;
    // Ссылка на акцию, но нет прав
    if (entityType === 'offer' && !userHasAccessTo('OFFERS_MANAGE'))
        return;
    // Ссылка на промокод, но нет прав
    if (entityType === 'promocode' && !userHasAccessTo('PROMOCODES_MANAGE'))
        return;
    // Ссылка на список клиентов, но нет прав
    if (entityType === 'segment' && !userHasAccessTo('CLIENTS_LIST'))
        return;
    // Не передан ID сущности
    // или попытка построить ссылку на рассылку, но не передан тип рассылки
    if (!entityId ||
        (entityType === 'mailing' && !mailingType && mailingType !== 0))
        return;
    // Строим ссылку
    let url = '';
    if (mailingType === 0)
        url = `${ROUTES.MAILINGS_AUTOMATIC_CREATE}/${entityId}`;
    if (mailingType === 1)
        url = `${ROUTES.MAILINGS_MANUAL_CREATE}/${entityId}`;
    if (entityType === 'offer')
        url = `${ROUTES.OFFERS_CREATE}/${entityId}`;
    if (entityType === 'segment')
        url = ROUTES.CLIENTS;
    if (entityType === 'promocode' && promocodeType !== undefined) {
        const route = promocodeType == PROMOCODE_TYPE_FRIEND
            ? ROUTES.PROMOCODES_FRIEND_EDIT
            : ROUTES.PROMOCODES_EDIT;
        url = generatePath(route, { promocodeId: entityId.toString() });
    }
    return url;
};
export const Events = () => {
    const { t } = useTranslation();
    const { user, currency } = useCurrentUser();
    const userHasAccessTo = useUserPermissions();
    const clientModal = useClientModal();
    const purchaseModal = usePurchaseModal();
    const orderModal = useOrderModal();
    const openOverview = (clientId) => {
        clientModal.open({ clientId, activeTab: 'overview' });
    };
    const handlePurchase = (purchaseId) => {
        purchaseModal.open({
            purchaseList: [{ id: purchaseId }],
            currentPurchaseIndex: 0,
        });
    };
    const handleReturn = (purchaseId) => {
        purchaseModal.open({
            purchaseList: [{ id: purchaseId }],
            currentPurchaseIndex: 0,
            isReturnFirst: true,
        });
    };
    const handleOrder = (orderId) => {
        orderModal.open({
            orderList: [{ id: orderId }],
            currentOrderIndex: 0,
        });
    };
    const { segment, filters, compiledFilters, updateFilters, reset } = useFiltersState({ currency, localStorageKey: 'eventsFilters' });
    const eventsFiltersTree = useClientEventFilters();
    const handleUpdateFilters = (filters) => {
        resetOffset();
        updateFilters(filters);
    };
    const { isLoading, isPrevButtonDisabled, isNextButtonDisabled, clientsEventList, activeDataTypesFilters, resetOffset, handleApplySearch, handleChangeFilters, handleNextPage, handlePrevPage, } = useData({
        filters: (filters === null || filters === void 0 ? void 0 : filters.filters.length) ? compiledFilters : undefined,
    });
    const userHasEventsView = userHasAccessTo([
        'CLIENTS_LIST_OWN',
        'CLIENTS_MANAGE',
    ]);
    if (!user)
        return null;
    return userHasEventsView ? (_jsxs(Container, { notMobileRightPadding: true, topElement: _jsx(DatePeriodSelectContainer, { onChange: resetOffset, leftElement: _jsxs("div", { className: styles.topElement, children: [_jsx(FiltersButton, { filtersTree: eventsFiltersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters }), _jsx(SearchPanel, { className: styles.searchPanel, name: 'searchText', placeholder: '\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430', hasMaxWidth: true, onApplySearch: handleApplySearch })] }), label: t('Показать события за') }), children: [_jsx(FiltersPanel, { filtersTree: eventsFiltersTree, value: (filters === null || filters === void 0 ? void 0 : filters.filters) || [], onUpdate: handleUpdateFilters, hasResetButton: !!(filters === null || filters === void 0 ? void 0 : filters.filters.length), onResetFilters: () => {
                    resetOffset();
                    reset();
                }, segment: segment, isSegmentFiltersTouched: filters === null || filters === void 0 ? void 0 : filters.touched, user: user }), _jsx(EventsPanel, { isLoading: isLoading, eventsData: clientsEventList, activeButtons: activeDataTypesFilters, openOverview: userHasAccessTo('CLIENTS_MANAGE') ? openOverview : undefined, handlePurchase: userHasAccessTo('CLIENTS_PURCHASES_MANAGE')
                    ? handlePurchase
                    : undefined, handleOrder: userHasAccessTo('CLIENTS_PURCHASES_MANAGE') ? handleOrder : undefined, handleReturn: userHasAccessTo('CLIENTS_PURCHASES_MANAGE') ? handleReturn : undefined, handleEntityLink: createHandleEntityLink(userHasAccessTo), handleNextPage: handleNextPage, handlePrevPage: handlePrevPage, isPrevButtonDisabled: isPrevButtonDisabled, isNextButtonDisabled: isNextButtonDisabled, handleChangeFilters: handleChangeFilters })] })) : null;
};
