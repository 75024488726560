var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '@/app/useCurrentUser';
import { useUserPermissions } from '@/app/useUserPermissions';
import { Button } from '@/components/Button2/Button';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Plus } from '@/icons/Plus';
import { Send } from '@/icons/Send';
import { brandHasFlowFeature } from '@/utils/brandHasFlowFeature';
import { MailingFlowsModal } from './MailingFlowsModal/MailingFlowsModal';
import { useFlowCandidates } from './MailingFlowsModal/useFlowCandidates';
import { MailingTable } from './MailingTable';
import styles from './styles.module.scss';
export const MailingPanel = memo(({ mailings = [], total, isManual, shouldDisplayProfit, currency, isLoading, isFetching, purchaseUrl, onMailingStatsClick, onEditMailing, onCloneMailing, onDeleteMailing, onOpenPurchaseFilter, onSegmentClick, onGoToCreateForm, }) => {
    const { t } = useTranslation();
    const { brand } = useCurrentUser();
    const [openFlowsModal, setOpenFlowsModal] = useState(false);
    const userHasAccessTo = useUserPermissions();
    const userHasFlowManage = userHasAccessTo('FLOWS_MANAGE');
    const userHasMailingManage = userHasAccessTo([
        'MANUAL_MAILINGS_MANAGE',
        'AUTO_MAILINGS_MANAGE',
    ]);
    const { mailings: candidates, getFlowCandidates } = useFlowCandidates();
    const handleOpenFlowsModal = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getFlowCandidates();
        setOpenFlowsModal(true);
    });
    const isShowCreateFlowButton = brandHasFlowFeature(brand === null || brand === void 0 ? void 0 : brand.brand) && !isManual;
    return (_jsxs(_Fragment, { children: [_jsx(TabsPanel, { tabs: [
                    {
                        id: 'mailings',
                        label: t('MAILINGS', { count: mailings.length }),
                    },
                ], rightContent: _jsxs("div", { className: styles.rightContentWrapper, children: [isShowCreateFlowButton && (_jsx(Tooltip, { title: !userHasFlowManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { icon: _jsx(Plus, {}), onClick: handleOpenFlowsModal, theme: 'default', size: 'normal', disabled: !userHasFlowManage, children: t('Собрать цепочку') }) })), _jsx(Tooltip, { title: !userHasMailingManage && t('Недоступно для вашей роли'), placement: 'top', children: _jsx(Button, { icon: _jsx(Send, {}), onClick: onGoToCreateForm, theme: 'primary', size: 'extra-large', disabled: !userHasMailingManage, children: t('Добавить рассылку') }) })] }), isSkeleton: isLoading, children: _jsx(MailingTable, { mailings: mailings, total: total, isManual: isManual, shouldDisplayProfit: shouldDisplayProfit, currency: currency, isLoading: isFetching, purchaseUrl: purchaseUrl, onMailingStatsClick: onMailingStatsClick, onEditMailing: onEditMailing, onCloneMailing: onCloneMailing, onDeleteMailing: onDeleteMailing, onOpenPurchaseFilter: onOpenPurchaseFilter, onSegmentClick: onSegmentClick }) }), _jsx(MailingFlowsModal, { isOpen: openFlowsModal, onClose: () => setOpenFlowsModal(false), from: 'auto-mailings-list', mailings: candidates })] }));
});
