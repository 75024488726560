import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import { useCurrentUser } from '@/app/useCurrentUser';
import { brandHasFlowFeature } from '@/utils/brandHasFlowFeature';
import styles from './styles.module.scss';
import { useDrawerContent } from './useDrawerContent';
import { useDrawerFocus } from './useDrawerFocus';
export const NodeFormDrawer = () => {
    const { brand } = useCurrentUser();
    const { selectedNode, isOpen, close, closeOnEsc, icon, title, titleRightElement, children, bottomContent, footer, isLarge, isWide, } = useDrawerContent();
    const { container } = useDrawerFocus(selectedNode.uuid);
    const isFlowsEnabled = brandHasFlowFeature(brand === null || brand === void 0 ? void 0 : brand.brand);
    return (_jsx(ClickAwayListener, { onClickAway: close, children: _jsxs("div", { ref: container, className: classNames(styles.drawer, {
                [styles.open]: isOpen,
                [styles.large]: isLarge,
                [styles.wide]: isWide,
                [styles.pushed]: !isFlowsEnabled,
            }), onKeyDown: closeOnEsc, tabIndex: -1, children: [_jsx("div", { className: styles.header, children: _jsxs("div", { className: styles.headerContent, children: [_jsxs("div", { className: styles.titleContent, children: [icon, title] }), titleRightElement] }) }), _jsxs("div", { className: styles.body, children: [_jsxs("div", { className: classNames(styles.content, {
                                [styles.hasBottomContent]: !!bottomContent,
                            }), children: [children, bottomContent] }, selectedNode.uuid), !!footer && _jsx("div", { className: styles.footer, children: footer })] })] }) }));
};
