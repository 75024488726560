import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AutocompleteButton } from '@/components/Autocomplete/variants/AutocompleteButton';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { APPLY_ON_ALL_UNITS, APPLY_ON_CONDITION_UNITS, APPLY_ON_PURCHASE, APPLY_ON_SOME_UNITS, } from '../constants';
import styles from '../styles.module.scss';
import { FormOfferProductCounter } from './FormOfferProductCounter';
export const FormOfferApplyOn = ({ labelWidth, }) => {
    const { t, i18n } = useTranslation();
    const { control, setValue, watch } = useFormContext();
    const offerType = watch('type');
    const isFixedPrice = offerType === 'fixed_price';
    const isGrowingDiscount = offerType === 'growing_discount';
    const isFreeProduct = offerType === 'free_product';
    const isBonus = offerType === 'bonus';
    const conditionsProduct = watch('conditionsProduct');
    const conditionsProductCount = (conditionsProduct === null || conditionsProduct === void 0 ? void 0 : conditionsProduct.length) || 0;
    const applyOnOptions = [
        {
            value: APPLY_ON_PURCHASE,
            label: t('чек'),
            renderOption: () => t('чек'),
            isActive: !isFixedPrice && !isGrowingDiscount && !isFreeProduct,
        },
        {
            value: APPLY_ON_ALL_UNITS,
            label: t('любой товар'),
            renderOption: () => t('любой товар'),
            isActive: true,
        },
        {
            value: APPLY_ON_SOME_UNITS,
            label: t('указанный товар'),
            renderOption: () => t('указанный товар'),
            isActive: true,
        },
        {
            value: APPLY_ON_CONDITION_UNITS,
            label: t('товары из чека'),
            renderOption: () => (_jsx(FormOfferProductCounter, { count: conditionsProductCount })),
            renderValue: () => (_jsx(FormOfferProductCounter, { count: conditionsProductCount })),
            // isActive: conditionsProductCount > 0,
            isActive: false,
        },
    ].filter((item) => item.isActive);
    return (_jsx(FormFieldRow, { className: styles.applyOn, label: isBonus ? t('начисляются за') : t('действует на'), labelFor: 'applyOn', labelWidth: labelWidth, controlSize: '200', children: _jsx(Controller, { name: 'applyOn', control: control, render: ({ field }) => (_createElement(AutocompleteButton, Object.assign({}, field, { key: i18n.language + offerType + (conditionsProduct === null || conditionsProduct === void 0 ? void 0 : conditionsProduct.length), id: 'applyOn', value: applyOnOptions.find((item) => item.value === field.value) || null, onChange: (applyOn) => {
                    setValue('applyOn', applyOn === null || applyOn === void 0 ? void 0 : applyOn.value, {
                        shouldDirty: true,
                    });
                }, options: applyOnOptions, getOptionKey: (option) => option.value, getOptionLabel: (option) => option.label, renderOption: (option) => option === null || option === void 0 ? void 0 : option.renderOption(), hasResetButton: false, asSelect: true }))) }) }));
};
