import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/components/Checkbox2/Checkbox';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { RepeatOneTime } from '@/icons/flow/RepeatOneTime';
import styles from '../styles.module.scss';
import { OFFSET_SIGN_SCHEDULE } from './FormMailingOffset';
export const FormMailingRepeat = ({ labelWidth, }) => {
    const { t } = useTranslation();
    const { control, watch, setValue } = useFormContext();
    const offsetSign = watch('offsetSign');
    if (offsetSign === OFFSET_SIGN_SCHEDULE)
        return null;
    return (_jsx(FormFieldRow, { labelWidth: labelWidth, asCheckbox: true, children: _jsx(Controller, { name: 'repeat', control: control, render: ({ field }) => (_jsxs(Checkbox, Object.assign({}, field, { className: styles.repeatLabel, value: field.value ? 'checked' : '', checked: !field.value, onChange: (e) => {
                    setValue('repeat', !e.target.checked);
                }, children: [_jsx(RepeatOneTime, {}), t('Отправлять только один раз на клиента')] }))) }) }));
};
