var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { cloneElement, useRef } from 'react';
import { Popup } from '@/components/Popup/Popup';
import styles from './styles.module.scss';
export const EditorToolbarButton = (_a) => {
    var { popupContent, popupIsOpen, onOpen, onClose } = _a, props = __rest(_a, ["popupContent", "popupIsOpen", "onOpen", "onClose"]);
    const ref = useRef(null);
    const handleMouseDown = (e) => {
        e.preventDefault();
        onOpen();
    };
    const handleClosePopup = () => {
        onClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ ref: ref, type: 'button', className: classNames(styles.button, {
                    [styles.active]: popupIsOpen,
                }), onMouseDown: handleMouseDown }, props)), _jsx(Popup, { isOpen: popupIsOpen, anchorEl: ref.current, onClose: handleClosePopup, placement: 'bottom', offset: [0, 5], isRounded: true, clickAwayMouseEvent: 'onMouseDown', clickAwayTouchEvent: 'onTouchEnd', children: cloneElement(popupContent, { onClose: handleClosePopup }) })] }));
};
