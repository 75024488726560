import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportTemplatesTable, } from '@/components/ExportTemplatesTable/ExportTemplatesTable';
import { TabsPanel } from '@/components/TabsPanel/TabsPanel';
import { ExportsTable } from './ExportsTable';
export const ExportsPanel = memo(({ activePanel, onChangeActivePanel, isLoading = false, isFetching = false, items, onExport, onGoToClients, }) => {
    const { t } = useTranslation();
    return (_jsxs(TabsPanel, { tabs: [
            {
                id: 'exports',
                label: t('Выгрузки'),
                count: items.length,
            },
            {
                id: 'templates',
                label: t('Шаблоны'),
            },
        ], activeTabId: activePanel, onChangeActiveTab: onChangeActivePanel, isSkeleton: isLoading, children: [activePanel === 'exports' && (_jsx(ExportsTable, { items: items, isLoading: isFetching })), activePanel === 'templates' && (_jsx(ExportTemplatesTable, { onExport: onExport, onGoToClients: onGoToClients }))] }));
});
