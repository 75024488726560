import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { ClientWalletsLinkButton } from '@/components/ClientWalletsLinkModal/ClientWalletsLinkButton';
import { WalletCardPills } from '@/components/wallet-cards/WalletCardPills/WalletCardPills';
import styles from './styles.module.scss';
export const WalletCardContainer = ({ cardType, onChangeCardType, card, cardFooter, children, isCentered = true, }) => {
    return (_jsxs("div", { className: classNames(styles.wrapper, {
            [styles.centered]: isCentered,
        }), children: [_jsx("div", { className: styles.content, children: children }), _jsx("div", { children: _jsxs("div", { className: styles.right, children: [_jsxs("div", { className: styles.cardWrapper, children: [_jsxs("div", { className: styles.menu, children: [_jsx(WalletCardPills, { value: cardType, onChange: onChangeCardType }), _jsx(ClientWalletsLinkButton, {})] }), _jsx("div", { className: styles.card, children: card })] }), cardFooter] }) })] }));
};
