import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Trash } from '@/icons/Trash';
import styles from './styles.module.scss';
export const TrashButton = memo(({ className, disabled = false, tooltip, disableTooltip, disableTooltipPortal, onClick, }) => {
    const { t } = useTranslation();
    return (_jsx(Tooltip, { title: disableTooltip ? '' : tooltip || t('Удалить'), placement: 'top', PopperProps: { disablePortal: disableTooltipPortal }, children: _jsx("button", { disabled: disabled, className: classNames(styles.button, className, {
                [styles.disabled]: disabled,
            }), type: 'button', onClick: onClick, children: _jsx(Trash, {}) }) }));
});
