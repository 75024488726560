import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { Done } from '@/icons/status/Done';
import { Draft } from '@/icons/status/Draft';
import { Error } from '@/icons/status/Error';
import { Planned } from '@/icons/status/Planned';
import { Sending } from '@/icons/status/Sending';
import { MAILING_STATUS_DONE, MAILING_STATUS_DRAFT, MAILING_STATUS_ERROR, MAILING_STATUS_PLANNED, MAILING_STATUS_SENDING, } from '@/types/mailingStatus';
import styles from './styles.module.scss';
const getIconByStatus = (status) => {
    const ICON_MAP = {
        [MAILING_STATUS_PLANNED]: { icon: _jsx(Planned, {}), tooltip: 'Запланирована' },
        [MAILING_STATUS_SENDING]: { icon: _jsx(Sending, {}), tooltip: 'Отправляется' },
        [MAILING_STATUS_DONE]: { icon: _jsx(Done, {}), tooltip: 'Выполнена' },
        [MAILING_STATUS_ERROR]: { icon: _jsx(Error, {}), tooltip: 'Ошибка' },
        [MAILING_STATUS_DRAFT]: { icon: _jsx(Draft, {}), tooltip: 'Черновик' },
    };
    return status in ICON_MAP ? ICON_MAP[status] : null;
};
export const MailingStatusIcon = memo(({ status }) => {
    const { t } = useTranslation();
    const icon = getIconByStatus(status);
    if (!icon)
        return _jsx("span", { className: styles.empty });
    return (_jsx(Tooltip, { title: t(icon.tooltip), placement: 'top', children: _jsx("span", { className: styles.icon, children: icon.icon }) }));
});
