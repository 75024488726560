import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldRow } from '@/components/FormFieldRow2/FormFieldRow';
import { FormFieldset } from '@/components/FormFieldset2/FormFieldset';
import { Input } from '@/components/Input/Input';
import { numberRound } from '@/utils/numbers';
import { ACCESS_OPTIONS, INIT_SECTIONS_STATE } from './constants';
import { AnalyticsSection } from './sections/AnalyticsSection';
import { BillingSections } from './sections/BillingSections';
import { ClientsSection } from './sections/ClientsSection';
import { SettingsSections } from './sections/SettingsSections';
import { ToolsSections } from './sections/ToolsSections';
import styles from './styles.module.scss';
import { Switchers } from './Switchers';
export const FormRole = memo(({ isView, id, defaultValues, setHasFormChanged, setFormHasPermissions, setCurrentNameOfRole, onSubmit, }) => {
    var _a;
    const { t } = useTranslation();
    const methods = useForm({
        defaultValues: defaultValues || {},
    });
    const [sections, setSections] = useState(INIT_SECTIONS_STATE);
    const { control, formState: { isSubmitting, errors, dirtyFields }, handleSubmit, watch, setValue, } = methods;
    const currentNameOfRole = watch('name');
    const hasSwitchesField = watch('hasSwitches');
    useEffect(() => {
        setCurrentNameOfRole(currentNameOfRole);
    }, [currentNameOfRole]);
    const handleSetValue = (fieldName, item) => {
        setFormHasPermissions((prevValue) => {
            if (prevValue && (item === null || item === void 0 ? void 0 : item.value))
                return false;
            return prevValue;
        });
        setValue(fieldName, item === null || item === void 0 ? void 0 : item.value, {
            shouldDirty: true,
        });
    };
    useEffect(() => {
        setHasFormChanged(!isSubmitting &&
            (!!Object.keys(dirtyFields).length || hasSwitchesField));
    }, [hasSwitchesField, dirtyFields, isSubmitting, setHasFormChanged]);
    const exportsListItems = [
        {
            label: t('Нет доступа'),
            value: ACCESS_OPTIONS.NO_ACCESS,
        },
        {
            label: t('Есть доступ'),
            value: ACCESS_OPTIONS.ACCESS,
        },
    ];
    const shortListItems = [
        {
            label: t('Нет доступа'),
            value: ACCESS_OPTIONS.NO_ACCESS,
        },
        {
            label: t('Просмотр'),
            value: ACCESS_OPTIONS.ACCESS,
        },
    ];
    const longListItems = [
        {
            label: t('Нет доступа'),
            value: ACCESS_OPTIONS.NO_ACCESS,
        },
        {
            label: t('Просмотр'),
            value: ACCESS_OPTIONS.ACCESS,
        },
        {
            label: t('Редактирование'),
            value: ACCESS_OPTIONS.EDITING,
        },
    ];
    return (_jsx(FormProvider, Object.assign({}, methods, { children: _jsxs("form", { id: id, onSubmit: handleSubmit((formDate) => onSubmit(formDate, sections)), className: styles.form, children: [_jsx("div", { className: styles.switches, children: _jsx(Switchers, { isView: isView, sections: sections, defaultValues: defaultValues, setSections: setSections, setValue: setValue }) }), _jsxs("div", { className: styles.fields, children: [_jsx("div", { children: _jsx(FormFieldset, { fieldsetClassName: styles.fieldset, titleClassName: styles.fieldsetTitle, children: _jsx(FormFieldRow, { label: t('Название роли'), labelFor: 'name', controlSize: '200', help: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, isRequired: true, hasError: !!errors.name, children: _jsx(Controller, { name: 'name', control: control, rules: {
                                            maxLength: {
                                                value: 200,
                                                message: t('FIELD_MAX_LENGTH_ERROR', {
                                                    amount: numberRound(200),
                                                    count: 200,
                                                }),
                                            },
                                            required: {
                                                value: true,
                                                message: t('FIELD_REQUIRED_ERROR'),
                                            },
                                        }, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { id: 'name', hasError: !!errors.name, autoFocus: true, disabled: isView }))) }) }) }) }), sections.analytics && (_jsx(AnalyticsSection, { isView: isView, longListItems: longListItems, shortListItems: shortListItems, exportsListItems: exportsListItems, handleSetValue: handleSetValue })), sections.clients && (_jsx(ClientsSection, { isView: isView, longListItems: longListItems, handleSetValue: handleSetValue })), sections.tools && (_jsx(ToolsSections, { isView: isView, longListItems: longListItems, handleSetValue: handleSetValue })), sections.billing && (_jsx(BillingSections, { isView: isView, shortListItems: shortListItems, exportsListItems: exportsListItems, handleSetValue: handleSetValue })), sections.settings && (_jsx(SettingsSections, { isView: isView, longListItems: longListItems, handleSetValue: handleSetValue }))] })] }) })));
});
